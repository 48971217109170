import { useGeoCoordinates } from '../../hooks'
import { LatLng, Location } from '../../models'
import { CardList, CardListParentProps } from '../core'
import { LocationListItem, LOCATION_LIST_ITEM_HEIGHT } from './LocationListItem'

export interface LocationListProps extends CardListParentProps<Location> {
  distanceFrom?: LatLng
}

export const LocationList = ({
  distanceFrom,
  ...props
}: LocationListProps) => {
  const geoCoordinates = useGeoCoordinates()
  const _distanceFrom = distanceFrom ?? geoCoordinates

  return (
    <CardList<Location>
      itemHeight={LOCATION_LIST_ITEM_HEIGHT}
      renderItem={item => <LocationListItem location={item} distanceFrom={_distanceFrom} />}
      {...props}
    />
  )
}
