import { Box, Button, ButtonProps, Flex, FlexProps, Image, Stack, Text } from '@chakra-ui/react'
import { isValidElement, ReactNode } from 'react'
import { To } from 'react-router-dom'

export interface NoticeProps extends Omit<FlexProps, 'title'> {
  size?: 'md' | 'lg'
  image?: ReactNode
  imageAlt?: string
  title?: ReactNode
  message?: ReactNode
  button?: ReactNode | ButtonProps & { as?: any, to?: To }
}

export const Notice = ({
  size,
  image,
  imageAlt,
  title,
  message,
  children,
  button,
  ...props
}: NoticeProps) => (
  <Flex flexDir="column" alignItems="center" justifyContent="center" {...props}>
    {!!image && (
      <Flex justifyContent="center">
        {typeof image === 'string' ? (
          <Image src={image} h="118px" alt={imageAlt} />
        ) : image}
      </Flex>
    )}

    <Box maxW={size == 'lg' ? '360px' : '280px'}>
      {!!title && (
        <Text
          fontWeight={500}
          fontSize={size === 'lg' ? '2xl' : 'xl'}
          lineHeight={1.4}
          textAlign="center"
          mt={2.5}
        >
          {title}
        </Text>
      )}
      {!!message && (
        <Text
          fontSize={size === 'lg' ? 'md' : 'xs'}
          textAlign="center"
          lineHeight={1.4}
          mt={2.5}
        >
          {message}
        </Text>
      )}
    </Box>

    {children}

    {!!button && (
      <Stack justifyContent="center" mt={5} spacing={2.5}>
        {isValidElement(button) ? button : (
          <Button variant="tertiary" {...(button as ButtonProps)} />
        )}
      </Stack>
    )}
  </Flex>
)
