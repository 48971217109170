import { Global } from '@emotion/react'
import { getAssetUrl } from './helpers'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'The Future';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${getAssetUrl('fonts/the-future-regular.woff2')}') format('woff2'), url('${getAssetUrl('fonts/the-future-regular.otf')}') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'The Future';
        font-style: medium;
        font-weight: 500;
        font-display: swap;
        src: url('${getAssetUrl('fonts/the-future-medium.woff2')}') format('woff2'), url('${getAssetUrl('fonts/the-future-medium.otf')}') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'The Future';
        font-style: bold;
        font-weight: 700;
        font-display: swap;
        src: url('${getAssetUrl('fonts/the-future-bold.woff2')}') format('woff2'), url('${getAssetUrl('fonts/the-future-bold.otf')}') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Soehne';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${getAssetUrl('fonts/soehne-mono-buch.woff2')}') format('woff2'), url('${getAssetUrl('fonts/soehne-mono-buch.otf')}') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `}
  />
)

export default Fonts
