import { Box, BoxProps, Button, css, Divider, Fade, SpaceProps, Text, useDimensions } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '../../hooks'
import { Job } from '../../models'
import { analytics } from '../../services'

export interface CollapsibleJobDescriptionProps extends SpaceProps {
  job: Job
  size?: 'sm' | 'md'
  onCollapsedChange?: (collapsed: boolean) => void
}

export const CollapsibleJobDescription = memo(({
  onCollapsedChange,
  ...props
}: CollapsibleJobDescriptionProps) => {
  const descRef = useRef<HTMLDivElement | null>(null)
  const descEl = descRef.current
  const descDimensions = useDimensions(descRef, true)
  const descWidth = descDimensions?.contentBox
  const [collapsed, setCollapsed] = useState(true)
  const [descOverflowing, setDescOverflowing] = useState(false)
  const debouncedDescScrollHeight = useDebounce(descEl?.scrollHeight)

  const handleSetCollapsed = useCallback((collapsed: boolean) => {
    setCollapsed(collapsed)
    onCollapsedChange?.(collapsed)
  }, [onCollapsedChange])

  useEffect(() => {
    if (collapsed && descEl) {
      setDescOverflowing(descEl.scrollHeight > descEl.clientHeight)
    }
  }, [descWidth?.width])

  return (
    <CollapsibleJobDescriptionContent
      descRef={descRef}
      descHeight={debouncedDescScrollHeight}
      descOverflowing={descOverflowing}
      collapsed={collapsed}
      onSetCollapsed={handleSetCollapsed}
      {...props}
    />
  )
})

interface CollapsibleJobDescriptionContentProps extends BoxProps {
  job: Job
  descRef: MutableRefObject<HTMLDivElement | null>
  descHeight?: number
  descOverflowing: boolean
  collapsed: boolean
  size?: CollapsibleJobDescriptionProps['size']
  onSetCollapsed: (value: boolean) => void
}

const CollapsibleJobDescriptionContent = memo(({
  job,
  descRef,
  descHeight,
  descOverflowing,
  collapsed,
  size,
  onSetCollapsed,
  ...props
}: CollapsibleJobDescriptionContentProps) => {
  const { t } = useTranslation()
  const [transitioning, setTransitioning] = useState(false)

  const changeCollapsed = (value: boolean) => {
    onSetCollapsed(value)
    setTransitioning(true)
    setTimeout(() => setTransitioning(false), 300)
  }

  const descMaxHeight = collapsed ? (job.summary ? '0px' : '140px')
    : descHeight ? `${descHeight}px` : undefined

  const toggleButtonProps = {
    variant: 'tertiary',
    size,
    mt: 5,
    w: 'full'
  }

  return (
    <Box {...props}>
      {job.summary && (
        <Text fontSize="sm" color="gray.900">
          {job.summary}
        </Text>
      )}

      <Box
        ref={descRef}
        maxHeight={descMaxHeight}
        overflow="hidden"
        transition={transitioning ? 'max-height 0.3s ease-in-out' : undefined}
      >
        {job.summary && (
          <Divider my={5} color="gray.200" />
        )}

        <Description
          color="gray.900"
          dangerouslySetInnerHTML={{ __html: job.description as string }}
        />
      </Box>

      <Box
        as={Fade} in={descOverflowing && collapsed && !job.summary}
        pos="relative"
        pointerEvents="none"
      >
        <Box
          pos="absolute" top="-80px" bottom="-1px" w="full"
          bgImage="linear-gradient(rgba(255, 255, 255, 0), #FFF)"
        />
      </Box>

      {descOverflowing && (
        <>
          {collapsed ? (
            <Button
              {...toggleButtonProps}
              onClick={() => {
                analytics.trackDescriptionView()
                changeCollapsed(false)
              }}
            >
              {t('actions.readMore')}
            </Button>
          ) : (
            <Button
              {...toggleButtonProps}
              onClick={() => changeCollapsed(true)}
            >
              {t('actions.readLess')}
            </Button>
          )}
        </>
      )}
    </Box>
  )
})

export const Description = styled(Box)(({ theme }) => css({
  fontSize: 'sm',
  lineHeight: 1.4,
  whiteSpace: 'normal',
  p: {
    mb: '1em'
  },
  'ul, ol': {
    pl: '1.5em',
    mb: '1em'
  }
})(theme))
