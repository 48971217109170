import { BoxProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { JobsSection } from '../../../components/jobs'
import { useGeoCoordinates, useJobSearchQuery } from '../../../hooks'
import { Location } from '../../../models'
import { analytics } from '../../../services'

interface LocationJobsSectionProps extends BoxProps {
  location: Location
}

export const LocationJobsSection = ({
  location,
  ...props
}: LocationJobsSectionProps) => {
  const { t } = useTranslation()
  const coordinates = useGeoCoordinates()
  const query = useJobSearchQuery({ location_id: location.id, coordinates })

  return (
    <JobsSection
      title={t('views.locations.jobsAtThisLocation')}
      query={query}
      linkTo={`/locations/${location.id}/jobs`}
      scrollPreservationKey="locationJobs"
      onScrollStart={() => analytics.trackHorizontalScroll('location jobs', { location_id: location.id })}
      {...props}
    />
  )
}
