import { Box, BoxProps } from '@chakra-ui/react'
import { forwardRef, useEffect, useRef } from 'react'
import { useLayoutContext } from '../../../contexts'
import { MIN_PAGE_WIDTH } from './common'
import { ContentContainer } from './containers'

export function useFixedProps(): BoxProps {
  const { pageLeft, pageWidth } = useLayoutContext()
  return { pos: 'fixed', left: pageLeft, width: pageWidth, minW: MIN_PAGE_WIDTH }
}

export const FixedBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const fixedProps = useFixedProps()

  return (
    <Box
      ref={ref}
      {...fixedProps}
      {...props}
    />
  )
})

interface FixedContentProps extends BoxProps {
  contentProps?: BoxProps
}

export const FixedContent = forwardRef<HTMLDivElement, FixedContentProps>(({
  contentProps,
  children,
  ...props
}, ref) => {
  return (
    <FixedBox ref={ref} {...props}>
      <ContentContainer {...contentProps}>
        {children}
      </ContentContainer>
    </FixedBox>
  )
})

export const FixedDropdown = ({ isOpen, ...props }: BoxProps & { isOpen: boolean }) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      contentRef.current?.scrollTo({ top: 0 })
    }
  }, [isOpen])

  return (
    <FixedContent
      ref={contentRef}
      hidden={!isOpen}
      top={0}
      bottom={0}
      overflowY="auto"
      bg="white"
      zIndex="dropdown"
      {...props}
    />
  )
}
