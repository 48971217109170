import { Box, Flex, Icon, Text, useTheme, useToast as $useToast } from '@chakra-ui/react'
import { IconAlertCircle, IconCircleCheck, IconInfoCircle } from '@tabler/icons-react'
import { memo, useCallback } from 'react'

export interface ToastProps {
  status?: 'info' | 'success' | 'error'
  title?: string
  description?: string
  render?: () => React.ReactNode
}

export function useToast() {
  const theme = useTheme()
  const breakpoints = theme.__breakpoints!.asObject

  const toast = $useToast({
    containerStyle: {
      m: 0,
      p: 0,
      w: { base: '100%', sm: breakpoints.sm }
    }
  })

  return useCallback((props: ToastProps) => {
    toast.closeAll()
    toast({
      duration: 1500,
      render: props.render || (() => <ToastContent {...props} />)
    })
  }, [])
}

const TOAST_ICONS = {
  info: IconInfoCircle,
  success: IconCircleCheck,
  error: IconAlertCircle
}

const ToastContent = memo(({ status = 'info', title, description }: ToastProps) => {
  return (
    <Flex
      px={5} py="15px"
      bg="gray.900"
      color={status === 'error' ? 'red.400' : 'white'}
    >
      <Icon as={TOAST_ICONS[status]} boxSize={6} mr={3} />

      <Box>
        {!!title && (
          <Text fontSize="14px" fontWeight="medium">
            {title}
          </Text>
        )}
        {!!description && (
          <Text fontSize="2sm">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  )
})
