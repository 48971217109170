import { Box, BoxProps, Flex, HStack, Icon, Stack, StackProps, Text, TextProps } from '@chakra-ui/react'
import { Icon as TablerIcon, IconChevronRight } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { SettingsCard } from './SettingsCard'

interface GeneralSettingsCardProps extends Omit<StackProps, 'children'> {
  title?: string
  children?: ReactNode
}

export const GeneralSettingsSection = ({ title, children, ...props }: GeneralSettingsCardProps) => {
  return (
    <Stack spacing={2.5} {...props}>
      {title && (
        <Box borderBottom="1px" borderColor="gray.150">
          <GeneralSettingsText>
            {title}
          </GeneralSettingsText>
        </Box>
      )}
      {children}
    </Stack>
  )
}

export const GeneralSettingsItem = ({
  title,
  icon,
  to,
  ...props
}: {
  title: string
  icon: TablerIcon
  to?: string
} & BoxProps) => (
  <SettingsCard p={5} as={Link} to={to} {...props}>
    <Flex justify="space-between">
      <HStack spacing={2.5}>
        <Icon as={icon} boxSize={6} />
        <GeneralSettingsText>
          {title}
        </GeneralSettingsText>
      </HStack>
      <Icon as={IconChevronRight} boxSize={6} />
    </Flex>
  </SettingsCard>
)

const GeneralSettingsText = (props: TextProps) => (
  <Text fontWeight={500} my={2.5} lineHeight="16px" color="gray.900" {...props} />
)
