import { Box, Button, ButtonProps } from '@chakra-ui/react'
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useBulkApplyCart } from '../../hooks'
import { JobBase } from '../../models'

interface AddToCartButtonProps extends ButtonProps {
  job: JobBase
  textSize?: ButtonProps['size']
  onAdd?: () => void
  onRemove?: () => void
}

export const AddToCartButton = ({
  job,
  size,
  textSize = size,
  onAdd,
  onRemove,
  ...props
}: AddToCartButtonProps) => {
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.bulkApply' })
  const cart = useBulkApplyCart()
  const included = cart.includes(job.id)
  const textKey = included ? 'removeFromCart' : 'addToCart'
  let variantProps: ButtonProps

  if (included) {
    variantProps = {
      variant: 'tertiary',
      borderWidth: '3px',
      borderColor: 'brand.500',
      color: 'green.800',
      leftIcon: <IconCircleMinus />
    }
  } else {
    variantProps = {
      variant: 'primary',
      leftIcon: <IconCirclePlus />
    }
  }

  return (
    <Button
      size={size}
      iconSpacing={1.5}
      isDisabled={!included && !cart.availableCapacity}
      onClick={() => {
        if (!included) {
          cart.add(job)
          onAdd?.()
        } else {
          cart.remove(job.id)
          onRemove?.()
        }
      }}
      {...variantProps}
      {...props}
    >
      {textSize === 'lg' ? (
        vt(`${textKey}.default`)
      ) : (
        <>
          <Box as="span" display={{ base: 'inline', '2xs': 'none' }}>
            {vt(`${textKey}.small`)}
          </Box>
          <Box as="span" display={{ base: 'none', '2xs': 'inline' }}>
            {vt(`${textKey}.default`)}
          </Box>
        </>
      )}
    </Button >
  )
}
