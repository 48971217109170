import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import { useMemo, useState } from 'react'
import { DEFAULT_PAY_RANGE } from '../../config'
import { useSearchLocation } from '../../hooks'
import { SEARCH_FILTER_DEFAULTS, SEARCH_FILTER_KEYS, SearchFilters, SearchOptions } from './types'

export interface SearchControllerOptions {
  initialSearchOptions?: SearchOptions
  defaultFilters?: SearchFilters
}

export const useSearchController = ({
  initialSearchOptions,
  defaultFilters: defaultFiltersOpt,
}: SearchControllerOptions = {}) => {
  const defaultFilters: SearchFilters = useMemo(() => {
    return { ...SEARCH_FILTER_DEFAULTS, ...omitBy(defaultFiltersOpt, isUndefined) }
  }, [defaultFiltersOpt])

  const defaultLocation = useSearchLocation()

  const [searchOptions, setSearchOptions] = useState<SearchOptions>(() => {
    return cleanupSearchOptions({
      location: defaultLocation,
      ...defaultFilters,
      ...initialSearchOptions
    })
  })

  return useMemo(() => {
    return {
      defaultFilters,
      searchOptions,
      filterCount: getSearchFilterCount(defaultFilters, searchOptions),
      updateSearchOptions(options: SearchOptions) {
        setSearchOptions(cleanupSearchOptions({ ...searchOptions, ...options }))
      }
    }
  }, [searchOptions])
}

export type SearchController = ReturnType<typeof useSearchController>

export function getSearchFilterCount(defaultFilters: SearchFilters, searchOptions: SearchOptions) {
  let count = 0
  for (const key of SEARCH_FILTER_KEYS) {
    const filter = searchOptions[key]
    if (Array.isArray(filter)) {
      count += filter.length
    } else if (filter != null && filter !== defaultFilters[key]) {
      count++
    }
  }
  return count
}

export function cleanupSearchOptions(searchOptions: SearchOptions) {
  for (const [key, value] of Object.entries(searchOptions)) {
    if (value == null || value == '' || (Array.isArray(value) && !value.length)) {
      delete searchOptions[key]
    }
  }
  if (searchOptions.query) {
    searchOptions.query = sanitizeQuery(searchOptions.query)
  }
  if (searchOptions.living_wage) {
    searchOptions.min_pay = DEFAULT_PAY_RANGE.min
  } else {
    delete searchOptions.living_wage
  }
  return searchOptions
}

function sanitizeQuery(value: string) {
  return value.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').trim()
}
