import { BoxProps, Flex } from '@chakra-ui/react'
import { IconCurrencyDollar } from '@tabler/icons-react'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { JobBase, WorkplaceType } from '../../models'
import { Pill, PillProps } from '../core'
import { useSearch } from '../search'
import { DISPLAYED_SCHEDULE_TYPES } from './common'
import { SchedulePill } from './SchedulePill'
import { WorkplacePill } from './WorkplacePill'

export interface JobCardPillsProps extends BoxProps {
  job: JobBase
  size?: 'xs' | 'sm' | '2sm' | 'md'
  selected?: boolean
}

export const JobCardPills = ({
  job,
  fontWeight = 'medium',
  size = 'sm',
  selected,
  ...props
}: JobCardPillsProps) => {
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.search.fields' })
  const { schedule, position_tags: positionTags, living_wage: livingWage } = job
  const filteredSchedule =
    schedule &&
    DISPLAYED_SCHEDULE_TYPES.filter(scheduleType => schedule.includes(scheduleType))
  const isRemote = job.workplace === WorkplaceType.REMOTE

  const highlights = useSearch()?.searchOptions ?? {}

  const renderPill = <T extends PillProps>(
    PillComponent: ComponentType<T>,
    { highlighted, ...props }: T & { highlighted?: boolean }
  ) => {
    if (highlighted) {
      props = {
        ...props,
        bg: 'blue.100',
        color: 'gray.900',
        outline: '1px solid',
        outlineColor: 'blue.200'
      }
    } else if (selected) {
      props = {
        ...props,
        bg: 'white'
      }
    }

    return (
      <PillComponent
        size={size}
        fontWeight={fontWeight}
        {...props as T}
      />
    )
  }

  return (
    <>
      {!!(filteredSchedule?.length || positionTags?.length || isRemote || livingWage) && (
        <Flex
          flexWrap="wrap"
          gap={2}
          {...props}
        >
          {livingWage && renderPill(Pill, {
            icon: IconCurrencyDollar,
            bg: 'green.100',
            color: 'green.600',
            highlighted: highlights.living_wage,
            children: vt('livingWage.label')
          })}

          {filteredSchedule?.map(scheduleType => (
            renderPill(SchedulePill, {
              key: scheduleType,
              scheduleType,
              highlighted: highlights.schedule?.includes(scheduleType)
            })
          ))}

          {isRemote && renderPill(WorkplacePill, {
            workplace: job.workplace!,
            highlighted: highlights.workplaces?.includes(job.workplace!)
          })}

          {positionTags?.map(positionTag => (
            renderPill(Pill, {
              key: positionTag.name,
              icon: positionTag.icon,
              children: positionTag.name
            })
          ))}
        </Flex>
      )}
    </>
  )
}
