import {
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerContent, DrawerFooter, DrawerHeader,
  DrawerOverlay,
  Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps,
  useBreakpointValue
} from '@chakra-ui/react'
import { ReactNode, UIEventHandler } from 'react'
import { useLayoutContext } from '../../contexts'
import { CONTENT_PADDING, DismissButton, MIN_PAGE_WIDTH } from './layout'

export interface ModalSheetProps extends Omit<ModalProps, 'children'> {
  mode?: 'modal' | 'drawer'
  contentPadding?: BoxProps['p']
  drawerMaxH?: BoxProps['maxH']
  header?: ReactNode
  footer?: ReactNode
  onScroll?: UIEventHandler<HTMLDivElement>
  children?: ReactNode
}

export const ModalSheet = ({
  mode,
  contentPadding = CONTENT_PADDING,
  drawerMaxH = 'full',
  header,
  footer,
  onScroll,
  onClose,
  children,
  ...props
}: ModalSheetProps) => {
  const { pageLeft, pageWidth } = useLayoutContext()
  const isWide = useBreakpointValue({ base: false, sm: true }, { ssr: false })

  if (mode === undefined) {
    mode = isWide ? 'modal' : 'drawer'
  }

  const bookendProps = { p: 0, zIndex: 1 }

  const content = (
    <Box
      w={isWide ? '100%' : '100vw'}
      minW={MIN_PAGE_WIDTH}
    >
      {!header && header !== null && (
        <Flex pt={5} pr={CONTENT_PADDING} justifyContent="end">
          <DismissButton
            onClick={() => onClose()}
          />
        </Flex>
      )}

      <Box p={contentPadding}>
        {children}
      </Box>
    </Box>
  )

  // Modal for desktop/bigger screens | Drawer for mobile

  if (mode === 'modal') {
    return (
      <Modal
        preserveScrollBarGap
        onClose={onClose}
        {...props}
      >
        <ModalOverlay />
        <ModalContent
          whiteSpace="break-spaces"
          overflow="hidden"
        >
          {header && <ModalHeader {...bookendProps}>{header}</ModalHeader>}
          <ModalBody p={0} onScroll={onScroll}>{content}</ModalBody>
          {footer && <ModalFooter {...bookendProps}>{footer}</ModalFooter>}
        </ModalContent>
      </Modal>
    )
  } else {
    return (
      <Drawer
        placement="bottom"
        onClose={onClose}
        {...props}
      >
        <DrawerOverlay />
        <DrawerContent
          {...pageWidth ? { ml: pageLeft, w: pageWidth } : undefined}
          maxH={drawerMaxH}
          whiteSpace="break-spaces"
          overflowX="hidden"
        >
          {header && <DrawerHeader {...bookendProps}>{header}</DrawerHeader>}
          <DrawerBody p={0} overflowX="hidden" onScroll={onScroll}>
            {content}
          </DrawerBody>
          {footer && <DrawerFooter {...bookendProps}>{footer}</DrawerFooter>}
        </DrawerContent>
      </Drawer>
    )
  }
}
