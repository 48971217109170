import { useTranslation } from 'react-i18next'
import artworkSrc from '../../../assets/images/oops.png'
import { Notice, NoticeProps } from './Notice'

export interface NoResultsNoticeProps extends NoticeProps {}

export const NoResultsNotice = ({ title, ...props }: NoResultsNoticeProps) => {
  const { t } = useTranslation()
  return (
    <Notice
      image={artworkSrc}
      imageAlt={t('images.oops')}
      title={title ?? t('messages.noResults')}
      {...props}
    />
  )
}
