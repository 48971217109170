import { BoxProps } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { SearchForm } from '../../components/search/SearchForm'
import { useTrack } from '../../hooks'

export function useSearchPageForm() {
  const track = useTrack()
  const [filtersVisible, setFiltersVisible] = useState(false)

  const handleShowFilters = useCallback(() => {
    setFiltersVisible(true)
  }, [])

  const element = (
    <SearchForm
      filtersVisible={filtersVisible}
      filtersToggleVisible
      onSetFiltersVisible={setFiltersVisible}
    />
  )

  const resultsContainerProps = filtersVisible
    ? { pos: 'fixed', top: '100vh', opacity: 0, pointerEvents: 'none' } as BoxProps
    : undefined

  useEffect(() => {
    if (filtersVisible) {
      track.open('Search Filters')
    }
  }, [filtersVisible])

  return {
    element,
    filtersVisible,
    resultsContainerProps,
    showFilters: handleShowFilters
  }
}
