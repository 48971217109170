import { Text, TextProps } from '@chakra-ui/react'
import { memo } from 'react'
import { FormatAddressOptions } from '../helpers'
import { useFormatters } from '../hooks'
import { AddressRecord } from '../models'

interface AddressTextProps extends FormatAddressOptions, TextProps {}

export const AddressText = memo(({
  address,
  address2,
  city,
  state,
  zipcode,
  ...props
}: AddressTextProps) => {
  const fmt = useFormatters()
  return (
    <Text {...props}>
      {!!(address ?? address2) && (
        <>
          {fmt.address({ address, address2 })}
          <br/>
        </>
      )}
      {fmt.address({ city, state, zipcode })}
    </Text>
  )
})

interface AddressRecordTextProps extends TextProps {
  record: AddressRecord
}

export const AddressRecordText = ({ record, ...props }: AddressRecordTextProps) => (
  <AddressText
    address={record.address}
    address2={record.address2}
    city={record.city}
    state={record.state}
    zipcode={record.zipcode}
    {...props}
  />
)
