import { Box, Button, Skeleton } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAnalyticsParams } from '../../../contexts'
import { analytics } from '../../../services'
import { ContentContainer } from './containers'
import { SectionHeader, SectionHeaderProps } from './SectionHeader'

export interface ContentSectionProps extends SectionHeaderProps {
  linkPlacement?: 'header' | 'footer'
}

export const ContentSection = ({
  title,
  subtitle,
  linkTo,
  linkPlacement = 'header',
  rel,
  menuItems,
  isLoading,
  children,
  total,
  ...props
}: ContentSectionProps) => {
  const { t } = useTranslation()
  const { page, source } = useAnalyticsParams()
  const headerProps = {
    title,
    subtitle,
    linkTo: linkPlacement === 'header' ? linkTo : undefined,
    rel,
    menuItems,
    isLoading,
    total
  }

  return (
    <Box {...props}>
      <SectionHeader {...headerProps} />

      {children}

      {linkPlacement === 'footer' && linkTo && (
        <ContentContainer mt={5}>
          <Skeleton isLoaded={!isLoading}>
            <Button
              as={Link} to={linkTo} rel={rel} w="full" variant="tertiary"
              onClick={() => analytics.trackSeeAll({ page, source })}
            >
              {t('actions.seeAll')}
            </Button>
          </Skeleton>
        </ContentContainer>
      )}
    </Box>
  )
}
