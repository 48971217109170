import { Link as RawLink, List, ListItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { SearchLink } from '../../models'
import { ContentSection, ContentSectionProps, CONTENT_PADDING } from '../core'

interface SearchLinksSectionProps extends ContentSectionProps {
  searchLinks: SearchLink[]
}

export const SearchLinksSection = ({ searchLinks, ...props }: SearchLinksSectionProps) => {
  const { t } = useTranslation()

  return (
    <ContentSection
      title={t('views.search.searchLinks.title')}
      {...props}
    >
      <List spacing={2.5} mx={CONTENT_PADDING}>
        {searchLinks.map((link, i) => (
          <ListItem key={i}>
            <RawLink href={link.url} color="gray.400">
              {link.title}
            </RawLink>
          </ListItem>
        ))}
      </List>
    </ContentSection>
  )
}
