import { QueryObserverOptions } from '@tanstack/react-query'

export enum QKey {
  ACCOUNT = 'account',
  APPLICATIONS = 'applications',
  APPLICATION_ITEMS = 'application_items',
  APPLY_ORDER_ITEMS = 'apply_order_items',
  APPLY_ORDERS = 'apply_orders',
  BETTER_JOBS = 'better_jobs',
  CATEGORIES = 'categories',
  DIRECTIONS = 'directions',
  EDUCATION = 'education',
  EMPLOYMENT = 'employment',
  FEED = 'feed',
  FILES = 'files',
  HISTORY = 'history',
  INDUSTRIES = 'industries',
  INTERESTS = 'interests',
  JOBS = 'jobs',
  JOB_PREFERENCES = 'job_preferences',
  LIST = 'list',
  LOCATIONS = 'locations',
  MATCHED = 'matched',
  PAY_ESTIMATE = 'pay_estimate',
  PAY_RANGE = 'pay_range',
  POSITIONS = 'positions',
  RECOMMENDED = 'recommended',
  SEARCH = 'search',
  STARTING_LOCATION = 'starting_location'
}

export type SimpleQueryOptions<TData = unknown, TError = unknown> = QueryObserverOptions<TData, TError, TData, any, any>

export enum AuthMethod {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp'
}

export type SortType = 'ASC' | 'DESC'

export type Party = '1P' | '3P'
