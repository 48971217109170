import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

export const inputTheme = defineMultiStyleConfig({
  variants: {
    outline: {
      field: {
        bg: 'gray.100',
        color: 'gray.400',
        _placeholder: {
          color: 'gray.300'
        },
        borderRadius: 10
      }
    }
  },
  sizes: {
    lg: definePartsStyle({
      field: defineStyle({
        fontSize: '16px',
        h: '54px',
        _placeholder: {
          fontSize: '16px',
          color: 'gray.300'
        },
        pl: 5
      })
    })
  }
})
