import { createContext, useContext } from 'react'
import { useBulkApplyCheckoutController, useBulkApplyCollectController, useGamifyIntroController } from './hooks'

export const GamifyIntroContext = createContext({} as ReturnType<typeof useGamifyIntroController>)
export const BulkApplyCollectContext = createContext({} as ReturnType<typeof useBulkApplyCollectController>)
export const BulkApplyCheckoutContext = createContext({} as ReturnType<typeof useBulkApplyCheckoutController>)

export function useGamifyIntro() {
  return useContext(GamifyIntroContext)
}

export function useBulkApplyCollect() {
  return useContext(BulkApplyCollectContext)
}

export function useBulkApplyCheckout() {
  return useContext(BulkApplyCheckoutContext)
}
