import { Card, SpaceProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import jobDescriptionImg from '../../../assets/images/job-description.png'
import { ContentContainer } from '../../../components/core'
import { CollapsibleJobDescription } from '../../../components/jobs'
import { Job } from '../../../models'
import { JobImageHeader } from '../common'

export interface DescriptionSectionProps extends SpaceProps {
  job: Job
  collapsible?: boolean
}

export const DescriptionSection = ({ job, ...props }: DescriptionSectionProps) => {
  const { t } = useTranslation()

  return (
    <ContentContainer>
      <Card p={5} {...props}>
        <JobImageHeader
          mb={5}
          title={t('views.jobs.jobDescriptionTitle')}
          src={jobDescriptionImg}
          alt={t('images.jobDescription')}
          h={{ base: '35px', '2xs': '60px' }}
        />

        <CollapsibleJobDescription job={job} />
      </Card>
    </ContentContainer>
  )
}
