import { Button, Flex, Heading, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { memo, ReactNode, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useParams } from 'react-router-dom'
import { JobCompanyLogoStack } from '../../components/companies/CompanyLogoStack'
import { ContentFooter, CONTENT_PADDING } from '../../components/core'
import { JobListItem, JobListItemProps, JobListPage, JOB_LIST_ITEM_HEIGHT } from '../../components/jobs'
import { SearchFiltersBadge, SearchFiltersModal, SearchNoResults, SearchProvider, useSearch, useSearchController } from '../../components/search'
import { AnalyticsParamsProvider } from '../../contexts'
import { distanceBetween } from '../../helpers'
import { useBetterJobsQuery, useFormatters, useGeoCoordinates, useHourlyPayValues, useInfiniteQueryTotalLabel, useJobQuery } from '../../hooks'
import { Job, JobIndexRecord } from '../../models'
import { ComparisonPill } from './partials/ComparisonPill'

export const BetterJobs = () => {
  const searchController = useSearchController()

  return (
    <AnalyticsParamsProvider page="Better Jobs">
      <SearchProvider controller={searchController}>
        <BetterJobsContent />
      </SearchProvider>
    </AnalyticsParamsProvider>
  )
}

const BetterJobsContent = () => {
  const { t } = useTranslation(undefined)
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.jobs.betterJobs' })

  const { searchOptions, filterCount } = useSearch()
  const geoCoordinates = useGeoCoordinates()
  const params = useParams()
  const jobQuery = useJobQuery(params.slug)
  const job = jobQuery.data

  const betterJobQuery = useBetterJobsQuery(params.slug!, searchOptions)
  const jobs = betterJobQuery?.data?.pages[0].data
  const countLabel = useInfiniteQueryTotalLabel(betterJobQuery)

  const hourlyPayValues = useHourlyPayValues(job)
  const targetAvgHourlyPay = hourlyPayValues?.avgHourlyPay ?? 0

  const query = job ? betterJobQuery : jobQuery

  const jobCoordinate = job ? job.coordinate : undefined
  const targetDistance = geoCoordinates && jobCoordinate ? distanceBetween(geoCoordinates, jobCoordinate) : 0

  const estimateItemSize = (): number => JOB_LIST_ITEM_HEIGHT + 40

  const isInitialLoadedRef = useRef(false)

  const isEmpty = !!jobs && !jobs.length

  const filtersDisclosure = useDisclosure()

  if (isEmpty && !isInitialLoadedRef.current) {
    return <Navigate to=".." replace />
  }

  useEffect(() => {
    if (jobs) {
      isInitialLoadedRef.current = true
    }
  }, [jobs])

  return (
    <JobListPage
      pb={jobs?.length ? '130px' : 0}
      topContent={!query.error && (
        <BetterJobsHeader
          hidden={isEmpty}
          jobs={jobs}
          countLabel={countLabel}
        />
      )}
      query={query}
      renderJob={props => (
        <BetterJobsListItem
          targetPay={targetAvgHourlyPay}
          targetDistance={targetDistance}
          {...props}
        />
      )}
      renderNoResults={() => (
        <SearchNoResults mt={-150} onShowFilters={filtersDisclosure.onOpen} />
      )}
      estimateItemSize={estimateItemSize}
      aName="Better Jobs"
    >
      <Helmet title={t('views.jobs.betterJobs.pageTitle')} />

      {!!jobs?.length && (
        <ContentFooter px={CONTENT_PADDING} py={5}>
          <Stack spacing={2.5} w="full">
            <Button
              variant="secondary"
              onClick={filtersDisclosure.onOpen}
            >
              {t('views.search.filters')}
              <SearchFiltersBadge count={filterCount} ml={2} />
            </Button>
            <Button as={Link} to=".." replace variant="tertiary">
              {vt('backToListing')}
            </Button>
          </Stack>
        </ContentFooter>
      )}

      <SearchFiltersModal
        {...filtersDisclosure}
        queryInputVisible={false}
      />
    </JobListPage>
  )
}

interface BetterJobsHeaderProps {
  hidden?: boolean
  jobs?: JobIndexRecord[]
  countLabel?: string
}

const BetterJobsHeader = ({ hidden, jobs, countLabel }: BetterJobsHeaderProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'views.jobs.betterJobs' })

  return (
    <Flex visibility={hidden ? 'hidden' : undefined} direction="column" align="center" my={4} gap={3}>
      <JobCompanyLogoStack jobs={jobs} height="64px" spacing="-25px" />

      {!jobs ? (
        <>
          <Skeleton h="24px" w="200px" />
          <Skeleton h="18px" w="262px" />
        </>
      ) : (
        <>
          <Heading as="h2" fontSize="xl" fontWeight="medium" textAlign="center">
            {t('title', { countLabel })}
          </Heading>
          <Text maxW="262" align="center" fontSize="sm" fontWeight="regular">
            {t('subtitle')}
          </Text>
        </>
      )}
    </Flex>
  )
}

interface BetterJobsListItemProps extends JobListItemProps {
  targetPay: number
  targetDistance?: number
}

const BetterJobsListItem = memo(({ targetPay, targetDistance, job, distanceFrom, ...props }: BetterJobsListItemProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const { avgHourlyPay } = useHourlyPayValues(job as Job)
  const distanceFromUser = distanceFrom ? distanceBetween(distanceFrom, job.coordinate) : undefined

  const payDiff = avgHourlyPay - targetPay
  const distanceDiff = targetDistance && distanceFromUser ? targetDistance - distanceFromUser : 0
  const comparisons: string[] = []

  if (payDiff > 0) {
    const payDiffString = `${fmt.currency(payDiff)}/${t('units.hour.short')}`
    const more = t('views.jobs.betterJobs.comparisons.more', { value: payDiffString })
    comparisons.push(more)
  }

  if (distanceDiff > 0) {
    const distanceDiffNorm = fmt.distance(distanceDiff, { display: 'short', precision: 2 })
    const distance = t('views.jobs.betterJobs.comparisons.closer', { value: distanceDiffNorm })
    comparisons.push(distance)
  }

  let header: ReactNode = null
  if (comparisons.length > 0) {
    header = (
      <ComparisonPill variant="better" size="lg" mb={2}>
        {comparisons.join(' • ')}
      </ComparisonPill>
    )
  }

  return (
    <JobListItem
      job={job}
      distanceFrom={distanceFrom}
      header={header}
      {...props}
    />
  )
})
