import { HStack, Icon, StackProps } from '@chakra-ui/react'
import { IconBrandTelegram } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderIconButton, useToast } from '../../../components/core'
import { WEB_URL } from '../../../config'
import { JobBase, JobStatus } from '../../../models'

export const JobHeaderActions = memo(({
  job,
  ...props
}: { job: JobBase } & StackProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  const copyLinkToClipboard = async () => {
    const url = `${WEB_URL}/jobs/${job.slug}`
    await navigator.clipboard.writeText(url)
    toast({ status: 'success', title: t('messages.copiedToClipboard') })
  }

  if (job.status !== JobStatus.LIVE) return null

  return (
    <HStack spacing={4} {...props}>
      <HeaderIconButton
        aria-label={t('actions.share')}
        icon={<Icon as={IconBrandTelegram} boxSize={6} color="gray.900" />}
        onClick={copyLinkToClipboard}
      />
    </HStack>
  )
})
