import { Flex, Icon } from '@chakra-ui/react'
import { IconBrandTelegram } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderIconButton, HeaderIconButtonProps, useToast } from '../../../components/core'
import { WEB_URL } from '../../../config'
import { Location } from '../../../models'

export const LocationHeaderAction = memo(({
  location,
  ...props
}: { location: Location } & Omit<HeaderIconButtonProps, 'aria-label'>) => {
  const { t } = useTranslation()
  const toast = useToast()

  const copyLinkToClipboard = async () => {
    const url = `${WEB_URL}/locations/${location.id}`
    await navigator.clipboard.writeText(url)
    toast({ status: 'success', title: t('messages.copiedToClipboard') })
  }

  return (
    <Flex align="center">
      <HeaderIconButton
        aria-label={t('actions.share')}
        icon={<Icon as={IconBrandTelegram} boxSize={6} color="gray.900" />}
        onClick={copyLinkToClipboard}
        {...props}
      />
    </Flex>
  )
})
