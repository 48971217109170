export enum FileValidationStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  VALID = 'valid',
  INVALID = 'invalid',
  FAILED = 'failed'
}

export interface RawFileRecord {
  id: string
  name: string
  validation_status: FileValidationStatus
}

export interface FileRecord extends RawFileRecord {
  isValidating: boolean
  isError: boolean
}
