import { Box, BoxProps, Button, Fade, Flex, Icon, IconButton, Image } from '@chakra-ui/react'
import { IconChevronLeft, IconChevronRight, IconPlayerPlay } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as SwiperClass } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { useMediaImage } from '../../hooks'
import { Media } from '../../models'
import { CONTENT_PADDING, IconButtonWithOptionalAriaProps } from '../core'

interface MediaCarouselProps extends BoxProps {
  items: Media[]
  overlayActions?: ReactNode
  onPlayVideo: (media: Media) => void
  onSlideChange?: (swiper: SwiperClass) => void
}

export const MediaCarousel = ({
  items,
  overlayActions,
  onPlayVideo,
  onSlideChange,
  ...props
}: MediaCarouselProps) => {
  const { t } = useTranslation()
  const [activeSlide, setActiveSlide] = useState(items[0])

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveSlide(items[swiper.realIndex])
    if (onSlideChange) {
      onSlideChange(swiper)
    }
  }

  return (
    <Box
      as={Swiper}
      loop={true}
      onSlideChange={handleSlideChange}
      pos="relative"
      {...props}
    >
      {items.map((item, index) => {
        const { url, description } = useMediaImage(item)
        return (
          <SwiperSlide key={index}>
            <Box bg="gray.100" h="100%">
              <Image
                width="100%"
                height="100%"
                objectFit="cover"
                src={url}
                alt={description}
              />
            </Box>
          </SwiperSlide>
        )
      })}

      {items.length > 1 && (
        <Flex
          pos="absolute" left={0} top={0} right={0} bottom={0}
          alignItems="center"
        >
          <SwiperButton direction="left" />
          <SwiperButton direction="right" />
        </Flex>
      )}

      <Fade in={!!activeSlide.video}>
        <Flex
          pos="absolute"
          bottom={CONTENT_PADDING}
          left={CONTENT_PADDING}
          zIndex={10}
        >
          <Button
            variant="tertiary" size="sm" iconSpacing={1}
            px={2} bg="gray.100" rounded="full"
            rightIcon={<Icon as={IconPlayerPlay} boxSize="18px" mr={-0.5} />}
            onClick={() => {
              if (activeSlide.video) {
                onPlayVideo(activeSlide)
              }
            }}
          >
            {t('actions.playVideo')}
          </Button>
        </Flex>
      </Fade>

      {overlayActions && (
        <Flex
          alignItems="center"
          pos="absolute"
          right={CONTENT_PADDING}
          bottom={CONTENT_PADDING}
          zIndex={10}
        >
          {overlayActions}
        </Flex>
      )}
    </Box>
  )
}

interface SwiperButtonProps extends IconButtonWithOptionalAriaProps {
  direction: 'left' | 'right'
}

const SwiperButton = ({ direction, ...props }: SwiperButtonProps) => {
  const { t } = useTranslation()
  const swiper = useSwiper()

  return (
    <Box ml={direction === 'right' ? 'auto' : undefined} px={5}>
      <IconButton
        minW={7}
        boxSize={7}
        borderRadius="full"
        backgroundColor="rgba(255, 255, 255, 0.75)"
        aria-label={direction === 'right' ? t('actions.scrollRight') : t('actions.scrollLeft')}
        icon={
          <Icon
            as={direction === 'right' ? IconChevronRight : IconChevronLeft}
            boxSize="18px"
          />
        }
        onClick={() => {
          direction === 'right' ? swiper.slideNext() : swiper.slidePrev()
        }}
        zIndex={1}
        {...props}
      />
    </Box>
  )
}
