import { UseModalProps } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTrack } from '../../hooks'
import { ModalSheet } from '../core'
import { SearchForm } from './SearchForm'

interface SearchFiltersModalProps extends UseModalProps {
  queryInputVisible?: boolean
}

export const SearchFiltersModal = ({
  queryInputVisible = true,
  isOpen,
  onClose,
  ...props
}: SearchFiltersModalProps) => {
  const track = useTrack()

  useEffect(() => {
    if (isOpen) {
      track.open('Search Filters')
    }
  }, [isOpen])

  return (
    <ModalSheet
      {...props}
      isOpen={isOpen}
      mode="drawer"
      contentPadding={0}
      onClose={onClose}
    >
      <SearchForm
        mt={5}
        queryInputVisible={queryInputVisible}
        resetIncludesQuery={!!queryInputVisible}
        onSetFiltersVisible={visible => {
          if (!visible) {
            onClose()
          }
        }}
      />
    </ModalSheet>
  )
}
