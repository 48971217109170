import { Box, BoxProps, Spinner, Text } from '@chakra-ui/react'

const COMPARISON_COLORS = {
  same: {
    bg: '#E3F3EC',
    fg: '#147A59'
  },
  better: {
    bg: '#E3F3EC',
    fg: '#147A59'
  },
  worse: {
    bg: '#FEE9EB',
    fg: '#D61614'
  },
  unknown: {
    bg: 'gray.100',
    fg: 'gray.400'
  }
}

const SIZE_CONFIG = {
  md: {
    px: 3,
    py: 2,
    fontSize: '2sm'
  },
  lg: {
    px: 2,
    py: '5px',
    fontSize: 'md'
  }
}

export interface ComparisonPillProps extends BoxProps {
  comparisonValue?: number | boolean
  variant?: 'same' | 'better' | 'worse' | 'unknown'
  size?: 'md' | 'lg'
  multiLine?: boolean
  isLoading?: boolean
}

export const ComparisonPill = ({
  comparisonValue,
  variant = comparisonValue === undefined
    ? 'unknown'
    : comparisonValue === 0 ? 'same' : comparisonValue > 0 ? 'better' : 'worse',
  size = 'md',
  multiLine,
  isLoading,
  children,
  ...props
}: ComparisonPillProps) => {
  const colors = COMPARISON_COLORS[variant]

  return (
    <Box
      w="fit-content" bg={colors.bg} borderRadius="10px"
      {...SIZE_CONFIG[size]}
      {...props}
    >
      <Text
        lineHeight={1.2} fontWeight={500}
        color={colors.fg}
        {...(multiLine ? undefined : { noOfLines: 1, wordBreak: 'break-all' })}
      >
        {isLoading ? (
          <Spinner as="span" size="xs" />
        ) : children}
      </Text>
    </Box>
  )
}
