import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { Action } from '@remix-run/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { BulkApplyFooter, BULK_APPLY_FOOTER_MIN_HEIGHT } from '../../../components/bulkApply/BulkApplyFooter'
import { CardListSkeleton, CARD_SPACING_M, ContentContainer, CONTENT_PADDING, InfiniteList, SectionHeader, StatusOverlay } from '../../../components/core'
import { BulkApplyJobListItem, estimateJobListItemHeight, JobsSection, JOB_LIST_ITEM_HEIGHT, MatchPreferencesCard, MATCH_PREFERENCES_CARD_HEIGHT } from '../../../components/jobs'
import { JobDetailModal } from '../../../components/jobs/JobDetailModal'
import { SearchEvent, SearchLinksSection, SearchNoResults, SearchNoResultsProps, SearchOptions, SearchSERPProps, SortMenu, SORT_CONFIGS, useSearch } from '../../../components/search'
import { useAppState, useNavigationHistory } from '../../../contexts'
import { searchParamsToObject } from '../../../helpers'
import { useBulkApplyCart, useFormatters, useInfiniteQueryTotal, useJobListFilter, useJobPreferencesWizardCompleted, useJobSearchQuery, UseJobSearchQueryParams, useQueryItems, useScrollYTracker, useSendUserEvent } from '../../../hooks'
import { UserEventType } from '../../../models'
import { analytics } from '../../../services'

interface SearchResultsProps extends BoxProps {
  serpProps?: SearchSERPProps
  filtersVisible: boolean
  onShowFilters: () => void
}

export const SearchResults = ({
  serpProps,
  filtersVisible: _filtersVisible,
  onShowFilters,
  ...props
}: SearchResultsProps) => {
  const { t } = useTranslation()
  const cart = useBulkApplyCart()
  const fmt = useFormatters()
  const [{ authenticated }] = useAppState()
  const { searchOptions } = useSearch()
  const [searchParams] = useSearchParams()
  const query = useJobSearchQuery(toJobSearchQueryParams(searchOptions))
  const showMatchPrefsCard = !useJobPreferencesWizardCompleted()
  const [detailJobSlug, setDetailJobSlug] = useState<string>()

  const initialOffset = useScrollYTracker()

  const items = useQueryItems(query)
  const filteredItems = useJobListFilter(items)
  const total = useInfiniteQueryTotal(query)
  const hasResults = !!items?.length
  const isNoResults = items && !items.length
  const firstResultsPage = query.data?.pages[0]

  const { action: historyAction } = useNavigationHistory()

  const sendUserEvent = useSendUserEvent()

  useEffect(() => {
    if (historyAction !== Action.Pop && firstResultsPage) {
      analytics.trackSearch({
        ...searchParamsToObject(searchParams),
        numberOfResults: total
      })

      if (authenticated) {
        const searchEvent = convertSearchOptionsToSearchEvent(searchOptions)
        const job_id = firstResultsPage.data[0]?.id
        sendUserEvent({ type: UserEventType.JOB_SEARCH, job_id, data: searchEvent })
      }
    }
  }, [firstResultsPage])

  return (
    <Flex
      mt={5} pb={isNoResults ? 0 : BULK_APPLY_FOOTER_MIN_HEIGHT + 30}
      pos="relative"
      flexDir="column" flexGrow={1}
      {...props}
    >
      {hasResults && (
        <>
          {serpProps?.title ? (
            <ContentContainer mb={4}>
              <Flex justifyContent="space-between" borderBottom="1px" borderColor="gray.150">
                <Box pb={2.5}>
                  <Text
                    as="h1" fontSize="xl" fontWeight="medium"
                  >
                    {serpProps.title}
                  </Text>
                  {serpProps.subtitle && (
                    <Text
                      as="h2"
                      fontSize="13px" lineHeight={1.4}
                      color="gray.400" mt={2.5}
                    >
                      {serpProps.subtitle}
                    </Text>
                  )}
                </Box>
                {<SortMenu compact ml={2} mb={2} />}
              </Flex>
            </ContentContainer>
          ) : (
            <SectionHeader
              title={
                <>
                  {t('terms.results')}
                  {!!total && (
                    <Text as="span" color="gray.300" fontWeight="normal">
                      {' '}({fmt.number(total)})
                    </Text>
                  )}
                </>
              }
              menu={<SortMenu />}
            />
          )}
        </>
      )}

      <ContentContainer>
        <InfiniteList
          query={query}
          items={filteredItems}
          overscan={10}
          initialOffset={initialOffset}
          itemSpacing={CARD_SPACING_M}
          estimateItemSize={job => estimateJobListItemHeight(job)}
          tailSize={MATCH_PREFERENCES_CARD_HEIGHT}
          renderItem={job => (
            <BulkApplyJobListItem
              selected={cart.includes(job.id)}
              job={job}
              onShowDetails={() => {
                setDetailJobSlug(job.slug)
              }}
            />
          )}
          renderTail={showMatchPrefsCard ? () => <MatchPreferencesCard /> : undefined}
          onFetchNextPage={() => analytics.trackSeeMoreJobsInSearch()}
        />
      </ContentContainer>

      {isNoResults ? (
        <NoResults
          serpProps={serpProps}
          onShowFilters={onShowFilters}
        />
      ) : (
        <BulkApplyFooter />
      )}

      <StatusOverlay
        query={query}
        renderLoading={() => (
          <>
            <SectionHeader isLoading mb={CONTENT_PADDING} />
            <ContentContainer>
              <CardListSkeleton itemHeight={JOB_LIST_ITEM_HEIGHT} />
            </ContentContainer>
          </>
        )}
        renderNoResults={null}
        zIndex="docked"
      />

      <JobDetailModal
        isOpen={!!detailJobSlug}
        slug={detailJobSlug}
        onClose={() => setDetailJobSlug(undefined)}
      />
    </Flex>
  )
}

const NoResults = ({
  serpProps,
  onShowFilters
}: { serpProps?: SearchSERPProps } & SearchNoResultsProps) => {
  const { t } = useTranslation()

  return (
    <SearchNoResults onShowFilters={onShowFilters}>
      {!!serpProps?.suggested_jobs?.length && (
        <JobsSection
          title={t('views.search.suggestedJobs.title')}
          items={serpProps.suggested_jobs}
          total={serpProps.suggested_jobs.length}
          scrollPreservationKey="suggestedJobs"
          onScrollStart={() => analytics.trackHorizontalScroll('suggested jobs')}
          mt={10}
        />
      )}

      {!!serpProps?.search_links?.length && (
        <SearchLinksSection searchLinks={serpProps.search_links} mt={10} />
      )}
    </SearchNoResults>
  )
}

function toJobSearchQueryParams({ location, sort, ...rest }: SearchOptions) {
  const params = rest as UseJobSearchQueryParams

  if (location) {
    params.coordinates = location.coordinates
    params.city_slug = location.city_slug
    params.state = location.state
  }

  if (sort) {
    Object.assign(params, SORT_CONFIGS[sort])
    delete params.sort_by_similar_to
  }

  return params
}

function convertSearchOptionsToSearchEvent(searchOptions: SearchOptions): SearchEvent {
  const { location, ...rest } = searchOptions

  return {
    ...rest,
    location: location?.description
  }
}
