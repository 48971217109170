import { Checkbox, CheckboxProps, Grid, GridItem, GridProps, SpaceProps, Text, useCheckboxGroup } from '@chakra-ui/react'
import { forwardRef, ReactElement, Ref } from 'react'

type Choice = string | number

interface CheckboxGroupProps<T extends Choice> extends SpaceProps {
  value?: T[] | null
  choices: T[]
  columns?: number
  size?: CheckboxProps['size']
  gap?: GridProps['gap']
  isDisabled?: boolean
  getLabel?: (choice: T) => string
  onChange?: (value: T[]) => void
}

export const CheckboxGroup = forwardRef(({
  value,
  choices,
  columns = 1,
  gap = 2.5,
  size = 'xl',
  isDisabled,
  getLabel = defaultGetLabel,
  onChange,
  ...props
}, ref) => {
  const { getCheckboxProps } = useCheckboxGroup({
    value: value!,
    isDisabled,
    onChange
  })

  return (
    <Grid
      ref={ref}
      templateColumns={`repeat(${columns}, minmax(0, 1fr))`}
      gap={gap}
      w="full"
      {...props}
    >
      {choices.map(choice => (
        <GridItem key={choice}>
          <Checkbox size={size} {...getCheckboxProps({ value: choice })}>
            <Text fontSize="sm">
              {getLabel(choice)}
            </Text>
          </Checkbox>
        </GridItem>
      ))}
    </Grid>
  )
}) as <T extends Choice>(p: CheckboxGroupProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement

const defaultGetLabel = (value: Choice) => String(value)
