import { useEffect } from 'react'
import { Navigate, RouteObject, useSearchParams } from 'react-router-dom'
import { useAppState } from './contexts'
import { createLazyFactory } from './helpers'
import { usePostAuthNavigateTo } from './hooks'
import { AuthenticatedGuard } from './layouts/AuthenticatedGuard'
import { Bootstrap } from './layouts/Bootstrap'
import { MainLayout } from './layouts/MainLayout'
import { OnboardingLayout } from './layouts/OnboardingLayout'
import { RegisterLayout } from './layouts/RegisterLayout'
import { Root } from './layouts/Root'
import { AuthLinkSent } from './pages/auth/AuthLinkSent'
import { AuthStart } from './pages/auth/AuthStart'
import { CatchAll } from './pages/CatchAll'
import { CompanyLocations } from './pages/companies/CompanyLocations'
import { ErrorBoundary } from './pages/ErrorBoundary'
import { Home } from './pages/Home'
import { FeedSettings } from './pages/Home/FeedSettings'
import { StartingLocation } from './pages/Home/FeedSettings/StartingLocation'
import { JobCategoryDetail } from './pages/JobCategoryDetail'
import { JobDetail } from './pages/JobDetail'
import { ApplicationRedirect } from './pages/JobDetail/ApplicationRedirect'
import { BetterJobs } from './pages/JobDetail/BetterJobs'
import { RelatedJobs } from './pages/JobDetail/RelatedJobs'
import { SimilarJobs } from './pages/JobDetail/SimilarJobs'
import { LocationDetail } from './pages/locations/LocationDetail'
import { LocationJobs } from './pages/locations/LocationJobs'
import { MatchedJobs } from './pages/MatchedJobs'
import { RegisterStartingLocation } from './pages/Register/RegisterStartingLocation'
import { Search } from './pages/Search'
import { Settings } from './pages/Settings'
import { DeleteAccount } from './pages/Settings/DeleteAccount'
import { ManageAccount } from './pages/Settings/ManageAccount'
import { Notifications } from './pages/Settings/Notifications'
import { analytics } from './services'
import { AuthMethod } from './types'

const lazyApplications = createLazyFactory(() => import('./modules/applications'))
const lazyMatchPreferences = createLazyFactory(() => import('./modules/matchPreferences'))
const lazyMyGigs = createLazyFactory(() => import('./modules/myGigs'))
const lazyGamify = createLazyFactory(() => import('./modules/gamify'))
const lazyProfile = createLazyFactory(() => import('./modules/profile'))

const Index = () => {
  const [{ authenticated }] = useAppState()
  const postAuthNavigateTo = usePostAuthNavigateTo()
  const [searchParams] = useSearchParams()
  const authType = searchParams.get('auth_type')

  useEffect(() => {
    if (authenticated && authType === 'link') {
      analytics.trackAuth({ provider: 'email' })
      analytics.trackEvent('Magic Link Tapped')
    }
  }, [])

  if (!authenticated) {
    return <Navigate to="/search" replace />
  }

  if (postAuthNavigateTo) {
    return <Navigate to={postAuthNavigateTo} replace />
  }

  if (authType) {
    return <Navigate to="/" replace />
  }

  return null
}

const basicProfileRoute = {
  path: 'profile',
  children: [
    {
      index: true,
      lazy: lazyProfile('BasicProfileIndex')
    },
    {
      path: 'steps',
      children: [
        {
          index: true,
          lazy: lazyProfile('BasicProfileMain')
        },
        {
          path: ':stepId',
          lazy: lazyProfile('BasicProfileStep')
        }
      ]
    }
  ]
}

function createMyAppRoutes(mainElement = lazyProfile('MyAppMain')) {
  return [
    {
      index: true,
      lazy: mainElement,
    },
    {
      path: 'resume/analysis',
      lazy: lazyProfile('MyAppResumeAnalysis'),
    },
    {
      path: 'employment/new',
      lazy: lazyProfile('MyAppEmploymentNew'),
    },
    {
      path: 'employment/:id',
      lazy: lazyProfile('MyAppEmploymentEdit'),
    },
    {
      path: 'education/new',
      lazy: lazyProfile('MyAppEducationNew'),
    },
    {
      path: 'education/:id',
      lazy: lazyProfile('MyAppEducationEdit'),
    },
    {
      path: ':stepId',
      lazy: lazyProfile('MyAppWizard'),
    },
  ]
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <Bootstrap />,
        children: [
          {
            index: true,
            element: <Index />
          },
          {
            element: <OnboardingLayout redirectIfAuthenticated />,
            children: [
              {
                path: 'sign-in',
                element: <AuthStart method={AuthMethod.SIGN_IN} />
              },
              {
                path: 'sign-up',
                element: <AuthStart method={AuthMethod.SIGN_UP} />
              },
              {
                path: 'auth',
                children: [
                  {
                    path: 'link-sent',
                    element: <AuthLinkSent />
                  }
                ]
              }
            ]
          },
          {
            element: <AuthenticatedGuard />,
            children: [
              {
                path: 'register',
                element: <RegisterLayout />,
                children: [
                  {
                    path: 'location',
                    element: <RegisterStartingLocation />
                  }
                ]
              },
            ]
          },
          {
            element: <MainLayout />,
            children: [
              {
                path: 'search',
                children: [
                  {
                    index: true,
                    element: <Search />
                  }
                ]
              },
              {
                path: 'jobs/:slug',
                children: [
                  {
                    index: true,
                    element: <JobDetail />
                  },
                  {
                    path: 'related',
                    element: <RelatedJobs />
                  },
                  {
                    path: 'similar',
                    element: <SimilarJobs />
                  },
                  {
                    element: <AuthenticatedGuard />,
                    children: [
                      {
                        path: 'better',
                        element: <BetterJobs />
                      },
                      {
                        path: 'application-redirect',
                        element: <ApplicationRedirect />
                      },
                    ]
                  }
                ]
              },
              {
                path: 'companies/:id',
                children: [
                  {
                    path: 'locations',
                    element: <CompanyLocations />
                  }
                ]
              },
              {
                path: 'locations/:id',
                children: [
                  {
                    index: true,
                    element: <LocationDetail />
                  },
                  {
                    path: 'jobs',
                    element: <LocationJobs />
                  }
                ]
              },
              {
                path: 'just-for-you',
                element: <MatchedJobs />
              },
              {
                path: 'match-preferences',
                children: [
                  {
                    index: true,
                    lazy: lazyMatchPreferences('MatchPreferencesMain')
                  },
                  {
                    path: ':stepId',
                    lazy: lazyMatchPreferences('MatchPreferencesWizard'),
                  },
                ]
              },
              {
                path: 'bulk-apply',
                children: [
                  {
                    lazy: lazyGamify('BulkApplyCollectLayout'),
                    children: [
                      {
                        index: true,
                        lazy: lazyGamify('BulkApplySearch')
                      },
                      {
                        path: 'cart',
                        lazy: lazyGamify('BulkApplyCart'),
                      },
                    ]
                  },
                  {
                    path: 'checkout',
                    lazy: lazyGamify('BulkApplyCheckoutLayout'),
                    children: [
                      {
                        index: true,
                        lazy: lazyGamify('BulkApplyCheckoutIndex'),
                      },
                      {
                        path: 'auth',
                        lazy: lazyGamify('BulkApplyCheckoutAuth'),
                      },
                      {
                        path: ':orderId',
                        lazy: lazyGamify('BulkApplyCheckoutOrderLayout'),
                        children: [
                          ...createMyAppRoutes(lazyGamify('BulkApplyCheckoutMyApp')),
                          {
                            path: 'complete',
                            lazy: lazyGamify('BulkApplyCheckoutComplete'),
                          }
                        ]
                      }
                    ]
                  },
                ]
              },
              {
                element: <AuthenticatedGuard />,
                children: [
                  {
                    path: 'home',
                    children: [
                      {
                        index: true,
                        element: <Home />
                      },
                      {
                        path: 'settings',
                        children: [
                          {
                            index: true,
                            element: <FeedSettings />
                          },
                          {
                            path: 'starting-location',
                            element: <StartingLocation />
                          }
                        ]
                      },
                    ]
                  },
                  {
                    path: 'job-categories/:slug',
                    element: <JobCategoryDetail />
                  },
                  {
                    path: 'applications',
                    children: [
                      {
                        path: '',
                        index: true,
                        element: <Navigate to="/my-gigs/applied" replace />
                      },
                      {
                        path: ':id',
                        children: [
                          {
                            index: true,
                            lazy: lazyApplications('ApplicationDetail')
                          },
                          basicProfileRoute,
                          {
                            path: 'questions/:questionId',
                            lazy: lazyApplications('ApplicationQuestionForm')
                          },
                          {
                            path: 'complete',
                            lazy: lazyApplications('ApplicationComplete')
                          },
                          {
                            path: 'unqualified',
                            lazy: lazyApplications('InternalApplicationUnqualified')
                          },
                          {
                            path: 'submitted',
                            lazy: lazyApplications('ApplicationSubmitted')
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'settings',
                    children: [
                      {
                        index: true,
                        element: <Settings />
                      },
                      {
                        path: 'account',
                        children: [
                          {
                            index: true,
                            element: <ManageAccount />,
                          },
                          {
                            path: 'delete',
                            element: <DeleteAccount />
                          }
                        ]
                      },
                      {
                        path: 'notifications',
                        children: [
                          {
                            index: true,
                            element: <Notifications />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'my-app',
                    lazy: lazyProfile('MyAppLayout'),
                    children: createMyAppRoutes()
                  },
                  {
                    path: 'profile/*',
                    element: <Navigate to="/my-app" replace />
                  },
                  {
                    path: 'my-gigs',
                    lazy: lazyMyGigs('MyGigsLayout'),
                    children: [
                      {
                        index: true,
                        element: <Navigate to="saved" replace />
                      },
                      {
                        path: 'saved',
                        lazy: lazyMyGigs('SavedJobs'),
                      },
                      {
                        path: 'applied',
                        lazy: lazyMyGigs('Applications')
                      },
                    ]
                  },
                  {
                    path: 'my-gigs/apply-order-items/:id',
                    // TODO: Instead wrap ApplyOrderItemDetail in an ErrorBoundary
                    lazy: lazyMyGigs('ApplyOrderItemLayout'),
                    children: [
                      {
                        index: true,
                        lazy: lazyMyGigs('ApplyOrderItemDetail')
                      },
                      {
                        path: '',
                        lazy: lazyMyGigs('AutoApplyQuestionnaireLayout'),
                        children: [
                          {
                            path: 'pending-questions',
                            children: [
                              {
                                index: true,
                                lazy: lazyMyGigs('AutoApplyQuestionnaireWizard')
                              },
                              {
                                path: ':questionId',
                                lazy: lazyMyGigs('AutoApplyQuestionnaireWizard')
                              }
                            ]
                          },
                          {
                            path: 'questions/:questionId',
                            lazy: lazyMyGigs('AutoApplyQuestionDetail')
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: ':slug',
                element: <CatchAll />
              }
            ]
          }
        ]
      }
    ]
  }
]

export default routes
