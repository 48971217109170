import { createContext, PropsWithChildren, useContext } from 'react'
import { SearchController } from './useSearchController'

export const SearchContext = createContext({} as SearchController)

interface SearchProviderProps extends PropsWithChildren {
  controller: SearchController
}

export const SearchProvider = ({ controller, children }: SearchProviderProps) => {
  return (
    <SearchContext.Provider value={controller}>
      {children}
    </SearchContext.Provider>
  )
}

export function useSearch() {
  return useContext(SearchContext)
}
