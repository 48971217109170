import { createContext, PropsWithChildren, ReactNode, useContext, useMemo } from 'react'

export interface AnalyticsParams {
  page?: string
  source?: string
}

export const AnalyticsParamsContext = createContext({} as AnalyticsParams)

export interface AnalyticsParamsProviderProps extends PropsWithChildren {
  page?: ReactNode
  source?: ReactNode
}

export const AnalyticsParamsProvider = ({ page, source, children }: AnalyticsParamsProviderProps) => {
  const parent = useAnalyticsParams()

  const contextValue = useMemo(() => {
    const params = { ...parent }

    if (page && typeof page === 'string') {
      params.page = page
    }
    if (source && typeof source === 'string') {
      params.source = source
    }

    return params
  }, [parent, page, source])

  return (
    <AnalyticsParamsContext.Provider value={contextValue}>
      {children}
    </AnalyticsParamsContext.Provider>
  )
}

export function useAnalyticsParams() {
  return useContext(AnalyticsParamsContext)
}
