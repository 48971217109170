import { forwardRef, memo } from 'react'
import { NumericInput, NumericInputProps } from './NumericInput'

export interface CurrencyInputProps extends NumericInputProps {}

export const CurrencyInput = memo(forwardRef<HTMLInputElement, CurrencyInputProps>((props, ref) => {
  return (
    <NumericInput
      ref={ref}
      precision={2}
      prefix="$"
      {...props}
    />
  )
}))
