import { AnalyticsParamsProvider } from '../../contexts'
import { useJobListFilter, useQueryItems } from '../../hooks'
import { JobIndexRecord } from '../../models'
import { CardListSection, HCardListSection, OptionalHCardListSectionProps } from '../core'
import { JobCard, JobCardImageProps, JOB_CARD_HEIGHT } from './JobCard'
import { JobListItem, JOB_LIST_ITEM_HEIGHT } from './JobListItem'
import { SmallJobCard, SMALL_JOB_CARD_HEIGHT, SMALL_JOB_CARD_WIDTH } from './SmallJobCard'

export interface JobsSectionProps extends OptionalHCardListSectionProps<JobIndexRecord> {
  variant?: 'horizontal' | 'vertical'
  heroCardProps?: JobCardImageProps
  aName?: string
}

export const JobsSection = ({
  variant = 'horizontal',
  heroCardProps,
  aName,
  title,
  items,
  query,
  scrollPreservationKey,
  onScrollStart,
  ...props
}: JobsSectionProps) => {
  const queryItems = useQueryItems(items ? undefined : query)
  const _items = useJobListFilter(items ?? queryItems)
  const listProps = { ...props, query, items: _items }

  return (
    <AnalyticsParamsProvider source={aName ?? title}>
      {variant === 'horizontal' ? (
        <HCardListSection<JobIndexRecord>
          title={title}
          itemWidth={SMALL_JOB_CARD_WIDTH}
          itemHeight={SMALL_JOB_CARD_HEIGHT}
          renderItem={job => <SmallJobCard job={job} />}
          {...(heroCardProps && {
            heroCardHeight: JOB_CARD_HEIGHT,
            renderHeroCard: job => <JobCard job={job} {...heroCardProps} />
          })}
          scrollPreservationKey={scrollPreservationKey}
          onScrollStart={onScrollStart}
          {...listProps}
        />
      ) : (
        <CardListSection<JobIndexRecord>
          title={title}
          maxItems={10}
          itemHeight={JOB_LIST_ITEM_HEIGHT}
          renderItem={item => <JobListItem job={item} />}
          linkPlacement="footer"
          {...listProps}
        />
      )}
    </AnalyticsParamsProvider>
  )
}
