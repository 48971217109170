import { Box, Flex, Icon, InputGroup, InputLeftElement, Text } from '@chakra-ui/react'
import { IconMapPin } from '@tabler/icons-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { extractAddressComponents, fetchGeoLocation } from '../../helpers'
import { useAsyncCallback, useDevicePositionMutation, useGooglePlacesCombobox } from '../../hooks'
import { SearchLocation } from '../../models'
import { BOX_SHADOW_3 } from '../../styles'
import { GooglePlacesAutocompleteMenu, UseCurrentLocationButton } from '../autocomplete/googlePlaces'
import { AutocompleteInput, CONTENT_PADDING, InputClearElement } from '../core'

interface UseLocationAutocompleteProps {
  initialValue?: SearchLocation
  onSubmit?: (value: SearchLocation) => void
}

export const useLocationAutocomplete = ({
  initialValue,
  onSubmit,
}: UseLocationAutocompleteProps) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(initialValue)
  const devicePositionMutation = useDevicePositionMutation()
  const geoLocationRequest = useAsyncCallback(fetchGeoLocation)
  const combobox = useGooglePlacesCombobox({
    type: 'geocode',
    defaultInputValue: initialValue?.description,
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        devicePositionMutation.reset()
        geoLocationRequest.execute({ placeId: selectedItem.place_id })
      }
    }
  })

  const {
    items,
    isOpen,
    inputValue,
    highlightedIndex,
    getInputProps,
    getMenuProps,
    getItemProps,
    setInputValue,
    selectItem,
    openMenu,
    closeMenu,
    blurInput,
    clearItems
  } = combobox

  const handleSetValue = useCallback((location?: SearchLocation) => {
    setInputValue(location ? location.description : '')
    setValue(location)
  }, [])

  const handleClear = useCallback(() => {
    handleSetValue()
    openMenu()
  }, [])

  useEffect(() => {
    const run = async () => {
      if (devicePositionMutation.isSuccess) {
        try {
          const res = await geoLocationRequest.executeAsync(devicePositionMutation.data.coordinates)
          if (res) clearItems()
        } catch (err) { }
      }
    }
    run()
  }, [devicePositionMutation.isSuccess])

  useEffect(() => {
    const geoLocation = geoLocationRequest.result
    if (geoLocation) {
      const location = {
        description: geoLocation.description,
        coordinates: geoLocation.coordinates,
        zipcode: extractAddressComponents(geoLocation).zipcode
      }
      selectItem(null)
      handleSetValue(location)
      closeMenu()
      onSubmit?.(location)
    }
  }, [geoLocationRequest.result])

  const inputProps = getInputProps()
  const inputRef = inputProps.ref
  const placeholder = t('views.search.fields.location.placeholder')

  const input = (
    <Box>
      <InputGroup h="50px">
        <InputLeftElement h="full" w={12} pointerEvents="none">
          <Icon as={IconMapPin} boxSize="22px" color="gray.300" ml={-1} />
        </InputLeftElement>

        <AutocompleteInput
          h="full" pl="42px" pr={10}
          name="street-address"
          type="search"
          enterKeyHint="search"
          autoComplete="street-address"
          aria-label={t('views.search.fields.location.label')}
          placeholder={placeholder}
          rounded="10px"
          {...inputProps}
        />

        {isOpen ? (
          <InputClearElement
            w={12}
            hidden={!inputValue}
            onClear={handleClear}
          />
        ) : (
          <>
            <Flex
              pos="absolute" left="43px" top={1} right={5} bottom={1}
              alignItems="center"
              bg="gray.100"
              rounded="10px"
              pointerEvents="none"
            >
              <Text
                fontFamily="Soehne"
                fontSize="2sm"
                color={value ? 'gray.900' : 'gray.300'}
                noOfLines={1} textOverflow="clip" wordBreak="break-all"
              >
                {value ? value.description : placeholder}
              </Text>
            </Flex>
          </>
        )}
      </InputGroup>

      <UseCurrentLocationButton
        mt={1.5}
        isLoading={devicePositionMutation.isLoading}
        onClick={e => {
          e.stopPropagation()
          devicePositionMutation.mutate()
        }}
      />
    </Box>
  )

  const menu = (
    <GooglePlacesAutocompleteMenu
      pos="absolute" left={0} right={0}
      mt="-34px" px={CONTENT_PADDING} pt={3} pb={2}
      bg="white"
      boxShadow={BOX_SHADOW_3}
      zIndex="dropdown"
      hidden={!isOpen}
      items={items}
      isOpen={isOpen}
      highlightedIndex={highlightedIndex}
      isLoading={false}
      getItemProps={getItemProps}
      onUseCurrentLocation={() => {
        devicePositionMutation.mutate()
        closeMenu()
      }}
      onClick={closeMenu}
      {...getMenuProps()}
    />
  )

  return {
    input,
    inputRef,
    menu,
    isOpen,
    value,
    openMenu,
    setValue: handleSetValue,
    blurInput
  }
}
