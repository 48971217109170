import { Box, BoxProps } from '@chakra-ui/react'
import { memo } from 'react'
import { useLocalStorage } from '../../../contexts'
import { StatusOverlay } from '../StatusOverlay'
import { HlsVideo } from './HlsVideo'
import { useHlsVideoLoader } from './useHlsVideoLoader'
import { VideoControls } from './VideoControls'

export interface VideoPlayerProps extends BoxProps {
  url: string
  autoPlay?: boolean
  onVideoLoad?: (width: number, height: number) => void
}

export const VideoPlayer = memo(({
  url,
  autoPlay = true,
  onVideoLoad,
  ...props
}: VideoPlayerProps) => {
  const [muted, setMuted] = useLocalStorage('videoMuted')
  const loader = useHlsVideoLoader({ url, onVideoLoad })

  const {
    videoRef,
    loaded,
    error,
  } = loader

  return (
    <Box
      pos="relative" w="full" h="full"
      bg="black" color="white"
      {...props}
    >
      <HlsVideo
        loader={loader}
        autoPlay={autoPlay} muted={muted}
        hidden={!loaded}
      />

      {loaded && (
        <VideoControls
          video={videoRef.current!}
          muted={!!muted}
          onSetMuted={setMuted}
        />
      )}

      <StatusOverlay
        isLoading={!loaded && !error}
        error={error}
        bg="black"
        indicatorColor="white"
        indicatorSize="lg"
        p={5}
        zIndex={0}
      />
    </Box>
  )
})
