import { Box, BoxProps, Fade, Flex, FlexProps, Icon, IconButton, useDimensions } from '@chakra-ui/react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { forwardRef, memo, MouseEventHandler, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SCROLLBAR_HIDDEN } from '../../styles'
import { IconButtonWithOptionalAriaProps } from './types'

export interface HScrollerProps extends FlexProps {
  scrollContainerRef?: RefObject<HTMLDivElement>
  scrollContainerProps?: BoxProps
  onScrollLeft?: MouseEventHandler<HTMLButtonElement>
  onScrollRight?: MouseEventHandler<HTMLButtonElement>
}

export const HScroller = forwardRef<HTMLDivElement, HScrollerProps>(({
  scrollContainerRef,
  scrollContainerProps,
  onScrollLeft,
  onScrollRight,
  children,
  ...props
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [isTouchDevice, setIsTouchDevice] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  const _scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const scrollContainer = _scrollContainerRef.current

  const scrollContainerDimensions = useDimensions(_scrollContainerRef, true)

  const update = () => {
    if (!scrollContainer || !scrollContainerDimensions) return

    const containerW = scrollContainerDimensions.contentBox.width
    const childW = scrollContainer.firstElementChild?.clientWidth ?? 0
    const scrollX = scrollContainer.scrollLeft
    const maxScrollX = childW - containerW

    setCanScrollLeft(scrollX > 0)
    setCanScrollRight(scrollX < maxScrollX)
  }

  useEffect(() => {
    if (scrollContainer && scrollContainerDimensions) {
      update()
      const scrollListener = () => update()
      scrollContainer.addEventListener('scroll', scrollListener)
      return () => {
        _scrollContainerRef.current?.removeEventListener('scroll', scrollListener)
      }
    }
  }, [_scrollContainerRef.current, scrollContainerDimensions, visible])

  return (
    <Box
      ref={ref}
      pos="relative"
      onTouchStart={() => setIsTouchDevice(true)}
      onMouseEnter={() => !isTouchDevice && setVisible(true)}
      onMouseLeave={() => !isTouchDevice && setVisible(false)}
      {...props}
    >
      <Box
        ref={el => {
          _scrollContainerRef.current = el
          if (scrollContainerRef) {
            (scrollContainerRef as any).current = el
          }
        }}
        // Begin box shadow clipping fix
        pos="absolute"
        h="calc(100% + 20px)"
        top="-10px" left={0} right={0}
        pt="10px"
        // End box shadow clipping fix
        overflowX="auto"
        css={SCROLLBAR_HIDDEN}
        {...scrollContainerProps}
      >
        {children}
      </Box>

      <Flex
        pos="absolute" left={0} top={0} right={0} bottom={0}
        alignItems="center"
        pointerEvents="none"
        {...props}
      >
        <HScrollButton
          direction="left"
          visible={visible && canScrollLeft}
          onClick={onScrollLeft}
        />
        <HScrollButton
          direction="right"
          visible={visible && canScrollRight}
          onClick={onScrollRight}
        />
      </Flex>
    </Box>
  )
})

const HScrollButton = memo(({
  direction,
  visible,
  ...props
}: { direction: 'left' | 'right', visible: boolean } & IconButtonWithOptionalAriaProps) => {
  const { t } = useTranslation()
  const isRight = direction === 'right'

  return (
    <Box as={Fade} in={visible} unmountOnExit ml={isRight ? 'auto' : undefined}>
      <IconButton
        bg="gray.100"
        right={isRight ? 0 : 'unset'}
        left={isRight ? 'unset' : 0}
        w={10}
        h={65}
        borderTopLeftRadius={isRight ? 16 : 0}
        borderTopRightRadius={isRight ? 0 : 16}
        borderBottomLeftRadius={isRight ? 16 : 0}
        borderBottomRightRadius={isRight ? 0 : 16}
        aria-label={isRight ? t('actions.scrollRight') : t('actions.scrollLeft')}
        icon={
          <Icon
            as={isRight ? IconChevronRight : IconChevronLeft}
            boxSize={9}
            ml={isRight ? 1 : 'unset'}
            mr={isRight ? 'unset' : 1}
          />
        }
        boxShadow={'0px 0px 10px rgba(0, 0, 0, 0.25)'}
        pointerEvents="auto"
        zIndex={2}
        {...props}
      />
    </Box>
  )
})
