import { Action } from '@remix-run/router'
import { useIsMutating } from '@tanstack/react-query'
import { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { UNSAFE_DataRouterContext, useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage, useSessionStorage } from '../contexts'
import { locationEquals } from '../helpers'
import { QKey } from '../types'
import { useAccount } from './account'

export const useRouteAction = (dep?: any) => {
  const loc = useLocation()
  const navigate = useNavigate()
  const action = loc.hash.startsWith('#!') ? loc.hash.slice(2) : undefined
  const actionRef = useRef(action)
  const depRef = useRef(dep)

  useLayoutEffect(() => {
    if (action) {
      navigate({ pathname: loc.pathname, search: loc.search }, { replace: true })
    }
  }, [])

  return dep === depRef.current ? actionRef.current : undefined
}

export const usePostAuthNavigateTo = () => {
  const account = useAccount()
  const [startingLocation] = useLocalStorage('startingLocation')
  const isMutatingStartingLocation = useIsMutating([QKey.STARTING_LOCATION])
  const [storedPostAuthRedirect, setPostAuthRedirect] = useSessionStorage('postAuthRedirect')

  const postAuthRedirect = useMemo(() => storedPostAuthRedirect, [])
  const returnedPostAuthRedirectRef = useRef(false)

  // Clear postAuthRedirect so the "/" route doesn't keep
  // redirecting. Only clear if it was returned, so
  // the we can redirect to it after registration.
  useEffect(() => {
    if (returnedPostAuthRedirectRef.current) {
      setPostAuthRedirect(null)
    }
  }, [])

  if (!account) return null

  if (!account?.registration_completed_at || !startingLocation || isMutatingStartingLocation) {
    return '/register'
  }

  if (postAuthRedirect) {
    returnedPostAuthRedirectRef.current = true
    return postAuthRedirect
  }

  return '/home'
}

export function useBackNavigationListener(cb: () => void) {
  const cbRef = useRef(cb)
  cbRef.current = cb

  const location = useLocation()
  const locationRef = useRef(location)
  locationRef.current = location

  const { router } = useContext(UNSAFE_DataRouterContext)!

  useEffect(() => {
    const unsubscribe = router.subscribe(({ historyAction, location }) => {
      if (historyAction === Action.Pop && !locationEquals(location, locationRef.current)) {
        cbRef.current()
      }
    })
    return unsubscribe
  }, [])
}
