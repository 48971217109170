import { useLayoutEffect } from 'react'
import { ProgressModal } from '../../components/core'
import { useLocalStorage, useSessionStorage } from '../../contexts'
import { useStartingLocationMutation } from '../../hooks'
import { StartingLocationForm } from '../shared/StartingLocationForm'

export const RegisterStartingLocation = () => {
  const [authZipcode, setAuthZipcode] = useSessionStorage('authZipcode')
  const [jobSearchLocation] = useLocalStorage('jobSearchLocation')
  const [startingLocation] = useLocalStorage('startingLocation')
  const locationMutation = useStartingLocationMutation({ silent: true })

  useLayoutEffect(() => {
    if (startingLocation?.placeId) {
      locationMutation.mutate({ placeId: startingLocation.placeId })
    } else if (jobSearchLocation?.coordinates) {
      locationMutation.mutate(jobSearchLocation.coordinates)
    } else if (authZipcode) {
      setAuthZipcode(null)
      locationMutation.mutate({ address: authZipcode })
    }
  }, [])

  if (!locationMutation.isError && (
    locationMutation.isLoading || locationMutation.isSuccess
  )) {
    return <ProgressModal isOpen />
  }

  return (
    <StartingLocationForm pageHeaderProps={{ logo: true }} />
  )
}
