import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import { ComponentType, ReactNode, useEffect, useRef } from 'react'
import { useScrollStartCallback, useScrollXTracker } from '../../hooks'
import { ApiCursorResponse } from '../../lib/apiClient'
import { HScroller } from './HScroller'

export interface HInfiniteListProps<TItem extends any> extends BoxProps {
  contentPaddingX?: BoxProps['px']
  query?: UseQueryResult<TItem[]> | UseInfiniteQueryResult<ApiCursorResponse<TItem>>
  items?: TItem[]
  loaderSize?: number
  headSize?: number
  tailSize?: number
  overscan?: number
  initialOffset?: number
  scrollPreservationKey?: string
  estimateItemSize: (item: TItem, index: number) => number
  itemSpacing?: number
  ItemContainer?: ComponentType<BoxProps>
  renderItem: (item: TItem, index: number) => ReactNode
  renderLoader?: () => ReactNode
  renderHead?: () => ReactNode
  renderTail?: () => ReactNode
  onScrollStart?: () => void
}

export const HInfiniteList = <TItem,>({
  contentPaddingX,
  query,
  items,
  loaderSize,
  headSize,
  tailSize,
  overscan = 5,
  initialOffset,
  scrollPreservationKey,
  estimateItemSize,
  itemSpacing = 0,
  ItemContainer = Box,
  renderItem,
  renderLoader,
  renderHead,
  renderTail,
  onScrollStart,
  ...props
}: HInfiniteListProps<TItem>) => {
  const itemCount = items?.length ?? 0
  const hasHead = !!headSize && !!itemCount
  const hasTail = !!tailSize && !!itemCount
  const hasLoader = !!loaderSize

  const {
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = (query ?? {}) as UseQueryResult & UseInfiniteQueryResult<any>

  let virtualCount = itemCount + (hasHead ? 1 : 0)
  if (!!(hasLoader && hasNextPage) || !!(hasTail && !hasNextPage)) {
    virtualCount += 1
  }

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const initialScrollX = useScrollXTracker(scrollPreservationKey, scrollContainerRef.current)
  const contentPaddingXValue = useBreakpointValue(contentPaddingX as any ?? [0], { ssr: false })
  const contentPaddingXNum = contentPaddingXValue ? parseFloat(contentPaddingXValue) : 0

  if (initialOffset === undefined) {
    initialOffset = initialScrollX
  }

  const calculateItemSize = (index: number) => {
    const itemIndex = hasHead ? index - 1 : index
    if (hasHead && index === 0) {
      return headSize ?? 0
    }
    if (itemIndex > itemCount - 1) {
      return (hasNextPage ? loaderSize : tailSize) ?? 0
    }
    return estimateItemSize(items![itemIndex], itemIndex)
  }

  const listVirtualizer = useVirtualizer({
    horizontal: true,
    getScrollElement: () => scrollContainerRef.current!,
    count: virtualCount,
    estimateSize: index => {
      const size = calculateItemSize(index)
      if (size && index < itemCount - 1) {
        return size + itemSpacing
      }
      return size
    },
    overscan,
    initialOffset,
    scrollPaddingStart: contentPaddingXNum,
    scrollPaddingEnd: contentPaddingXNum,
    paddingStart: contentPaddingXNum,
    paddingEnd: contentPaddingXNum
  })

  const virtualItems = listVirtualizer.getVirtualItems()

  const handleScroll = useScrollStartCallback(onScrollStart)

  const handleScrollLeft = () => {
    const scrollContainer = scrollContainerRef.current!
    const leftEdge = scrollContainer.scrollLeft - scrollContainer.clientWidth

    for (const item of virtualItems) {
      if (item.start > leftEdge) {
        listVirtualizer.scrollToIndex(item.index, { align: 'start', behavior: 'smooth' })
        break
      }
    }
  }

  const handleScrollRight = () => {
    const scrollContainer = scrollContainerRef.current!
    const rightEdge = scrollContainer.scrollLeft + scrollContainer.clientWidth

    for (const item of virtualItems) {
      if ((item.start < rightEdge && item.start + item.size > rightEdge) || item.start > rightEdge) {
        listVirtualizer.scrollToIndex(item.index, { align: 'start', behavior: 'smooth' })
        break
      }
    }
  }

  useEffect(() => {
    if (!virtualItems.length) return
    const lastItem = virtualItems[virtualItems.length - 1]
    const itemIndex = hasHead ? lastItem.index - 1 : lastItem.index

    if (itemIndex >= itemCount - 1 && hasNextPage && !isFetchingNextPage && !isError) {
      void fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, itemCount, virtualItems.length, isFetchingNextPage])

  return (
    <HScroller
      scrollContainerRef={scrollContainerRef}
      onScrollLeft={handleScrollLeft}
      onScrollRight={handleScrollRight}
      scrollContainerProps={{ onScroll: handleScroll }}
      {...props}
    >
      {!!items && (
        <Box pos="relative" h="100%" w={`${listVirtualizer.getTotalSize()}px`}>
          {virtualItems.map(row => {
            const isHeadRow = hasHead && row.index === 0
            const itemIndex = hasHead ? row.index - 1 : row.index
            const isLastRow = itemIndex > items.length - 1
            const item = items[itemIndex]

            return (
              <ItemContainer
                key={String(row.key)}
                pos="absolute"
                top="0" left="0"
                w={`${row.size}px`} h="100%"
                style={{ transform: `translateX(${row.start}px)` }}
              >
                {
                  isHeadRow
                    ? renderHead?.()
                    : isLastRow
                      ? hasNextPage ? hasLoader && renderLoader?.() : hasTail && renderTail?.()
                      : renderItem(item, itemIndex)
                }
              </ItemContainer>
            )
          })}
        </Box>
      )}
    </HScroller>
  )
}
