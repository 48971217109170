import { Fade, Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode, useRef } from 'react'

export interface OverlayProps extends Omit<FlexProps, 'title' | 'children'> {
  visible?: boolean
  centeredContent?: boolean
  children?: ReactNode | (() => ReactNode)
}

export const Overlay = ({ visible, centeredContent, children, ...props }: OverlayProps) => {
  const childrenRef = useRef<ReactNode>()

  if (centeredContent) {
    props = {
      alignItems: 'center',
      justifyContent: 'center',
      ...props
    }
  }

  if (visible) {
    childrenRef.current = children instanceof Function
      ? children()
      : children
  }

  return (
    <Flex
      as={Fade}
      in={visible} initial={false} unmountOnExit
      pos="absolute" top={0} left={0} right={0} bottom={0}
      bg="white"
      zIndex="1201" // Just below PageHeader
      {...props}
    >
      {childrenRef.current}
    </Flex>
  )
}
