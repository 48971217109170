import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAppState, useLocalStorage, useLocalStorageSetter } from '../contexts'
import { AccountUpdateRequest } from '../dtos'
import { AppErrorCode, createAppError } from '../errors'
import { fetchGeoLocation, FetchGeoLocationInput } from '../helpers'
import { GeoLocation, LatLng } from '../models'
import { api } from '../services'
import { QKey } from '../types'
import { useAccount, usePreferencesMutation, useRegisterMutation } from './account'
import { useQueryErrorHandler } from './app'

export function useGeoCoordinates() {
  const [{ authenticated }] = useAppState()
  const [startingLocation] = useLocalStorage('startingLocation')
  const [searchLocation] = useLocalStorage('jobSearchLocation')

  // Only use `startingLocation` when authenticated.
  const location = authenticated
    ? startingLocation ?? searchLocation
    : searchLocation

  return location?.coordinates
}

export function useSearchLocation() {
  const [searchLocation] = useLocalStorage('jobSearchLocation')
  const [startingLocation] = useLocalStorage('startingLocation')
  return searchLocation ?? startingLocation
}

export function useStartingLocationMutation({ silent }: { silent?: boolean } = {}) {
  const account = useAccount()!
  const prefsMutation = usePreferencesMutation()
  const registerMutation = useRegisterMutation()
  const setStartingLocation = useLocalStorageSetter('startingLocation')
  const handleError = useQueryErrorHandler({ notify: !silent })
  const queryClient = useQueryClient()

  return useMutation(async (input: FetchGeoLocationInput) => {
    let geoLocation: GeoLocation | undefined

    try {
      geoLocation = await fetchGeoLocation(input, 'street_address')
    } catch (error) {
      console.error(error)
    }

    if (!geoLocation) {
      throw createAppError(AppErrorCode.GEO_LOCATION_ADDRESS_NOT_FOUND)
    }

    // Set startingLocation here for UI feedback.
    setStartingLocation(geoLocation)

    try {
      // TODO: The api should propbably handle updating regions
      // automatically in the future.
      const { data: regions } = await api.regions.list(geoLocation.coordinates)
      const defaultRegions = regions.slice(0, 10)

      const prefsUpdate: AccountUpdateRequest['preferences'] = {
        region_ids: defaultRegions.map(r => r.id)
      }

      if (geoLocation.placeId) {
        prefsUpdate.starting_place_id = geoLocation.placeId
      }

      await prefsMutation.mutateAsync(prefsUpdate)

      // Register account if necessary.
      if (!account.registration_completed_at) {
        await registerMutation.mutateAsync()
      }

      return geoLocation
    } catch (err) {
      // Clear startingLocation since dependent account requests failed.
      setStartingLocation(null)
      throw err
    }
  }, {
    mutationKey: [QKey.STARTING_LOCATION],
    onError: handleError,
    onSuccess() {
      queryClient.invalidateQueries([QKey.JOBS, QKey.MATCHED])
    }
  })
}

export function useDevicePositionMutation() {
  const [position, setPosition] = useLocalStorage('devicePosition')

  return useMutation(async () => {
    if (position && Date.now() - position.timestamp < 300000) {
      return position
    }

    return new Promise<{ coordinates: LatLng, timestamp: number }>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            coordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            timestamp: position.timestamp
          })
        },
        error => {
          if (!position) {
            reject(error)
          } else {
            resolve(position)
          }
        }
      )
    })
  }, {
    onSuccess(data) {
      setPosition(data)
    }
  })
}
