import { JobIndexRecord } from '../../models'
import { CONTENT_PADDING } from '../core'
import { HInfiniteList, HInfiniteListProps } from '../core/HInfiniteList'
import { SmallJobCard, SMALL_JOB_CARD_HEIGHT, SMALL_JOB_CARD_WIDTH } from './SmallJobCard'

export interface HorizontalJobListProps extends Omit<HInfiniteListProps<JobIndexRecord>, 'estimateItemSize' | 'renderItem'> { }

export const HorizontalJobList = ({ items, ...props }: HorizontalJobListProps) => {
  return (
    <HInfiniteList<JobIndexRecord>
      h={`${SMALL_JOB_CARD_HEIGHT + 10}px`}
      contentPaddingX={CONTENT_PADDING}
      items={items}
      estimateItemSize={() => SMALL_JOB_CARD_WIDTH}
      itemSpacing={10}
      renderItem={job => <SmallJobCard job={job} />}
      {...props}
    />
  )
}
