import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosProgressEvent } from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createSelector } from 'reselect'
import { FileRecord, FileValidationStatus, RawFileRecord } from '../models'
import { api } from '../services'
import { QKey } from '../types'

const FILE_VALIDATION_REFETCH_INTERVAL = 5000

export function useFileQuery(
  id?: string | null,
  options: { pollValidation?: boolean, enabled?: boolean } = {}
) {
  const { pollValidation, enabled = true } = options

  return useQuery([QKey.FILES, id], async () => {
    const body = await api.files.find(id!)
    return body.data
  }, {
    enabled: !!id && enabled,
    staleTime: 10_000,
    select: selectFileRecord,
    refetchInterval(data) {
      if (pollValidation && data?.isValidating) {
        return FILE_VALIDATION_REFETCH_INTERVAL
      }
      return false
    }
  })
}

export function useFileUpload(options?: {
  onSuccess: (data: FileRecord) => void
}) {
  const queryClient = useQueryClient()
  const abortControllerRef = useRef<AbortController>()
  const [progress, setProgress] = useState<number>()

  const handleProgress = useCallback((e: AxiosProgressEvent) => {
    setProgress(e.progress)
  }, [])

  const handleReset = useCallback(() => {
    abortControllerRef.current?.abort()
    mutation.reset()
    setProgress(undefined)
  }, [])

  const mutation = useMutation(async (file: File) => {
    if (ret.isLoading) {
      abortControllerRef.current?.abort()
    }
    const abortController = new AbortController()
    abortControllerRef.current = abortController
    setProgress(0)

    const { data: { presigned_url, token } } = await api.files.createUpload({
      content_type: file.type,
      name: file.name
    })

    await axios.put(presigned_url, file, {
      signal: abortController.signal,
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress: handleProgress
    })

    const { data } = await api.files.create({ token })
    queryClient.setQueryData([QKey.FILES, data.id], data)
    return selectFileRecord(data)
  }, options)

  const ret = useMemo(() => {
    return {
      data: mutation.data,
      isLoading: mutation.isLoading,
      isSuccess: mutation.isSuccess,
      error: mutation.error,
      progress,
      mutate: mutation.mutate,
      reset: handleReset,
    }
  }, [mutation, progress])

  useEffect(() => {
    return handleReset
  }, [])

  return ret
}

const selectFileRecord = createSelector(
  (rawFile: RawFileRecord) => rawFile,
  rawFile => {
    return {
      ...rawFile,
      isValidating: (
        [FileValidationStatus.PENDING, FileValidationStatus.PROCESSING]
          .includes(rawFile.validation_status)
      ),
      isError: (
        [FileValidationStatus.FAILED, FileValidationStatus.INVALID]
          .includes(rawFile.validation_status)
      )
    } as FileRecord
  }
)
