import { Flex, FlexProps, Skeleton } from '@chakra-ui/react'
import { memo, useMemo } from 'react'
import { Company, JobIndexRecord } from '../../models'
import { CompanyLogo } from './CompanyLogo'

export interface CompanyLogoStackProps extends FlexProps {
  companies?: Company[]
  spacing?: string | number
}

export const CompanyLogoStack = memo(({
  companies,
  spacing = '-25px',
  height = '40px',
  ...props
}: CompanyLogoStackProps) => {

  if (!companies || !companies.length) {
    const skeletonEx = Array(3).fill(0)
    const colors = [
      'gray.100',
      'gray.200',
      'gray.100',
      'gray.200',
      'gray.100',
      'gray.200',
    ]
    return (
      <Flex
        {...props}
        direction="row"
      >
        {skeletonEx.map((_, index) => (
          <Skeleton
            key={index}
            pos="relative"
            boxSize={height}
            borderRadius="full"
            opacity={1}
            marginLeft={index > 0 ? spacing : 0}
            startColor={colors[index]}
            endColor={colors[index + 1]}
          />
        ))}
      </Flex>
    )
  }

  return (
    <Flex
      {...props}
      direction="row"
    >
      {companies.map((company, index) => (
        <CompanyLogo
          key={company.id || index}
          pos="relative"
          company={company}
          boxSize={height}
          marginLeft={index > 0 ? spacing : 0}
        />
      ))}
    </Flex>
  )
})

interface JobCompanyLogoStackProps extends Omit<CompanyLogoStackProps, 'companies'> {
  jobs?: JobIndexRecord[]
}

export const JobCompanyLogoStack = memo(({
  jobs,
  ...props
}: JobCompanyLogoStackProps) => {
  const companies = useMemo(() => {
    if (jobs) {
      const uniqueCompanies = Array.from(new Map(jobs.map(job => [job.company.id, job.company])).values()) || []
      return uniqueCompanies.slice(0, 3) || []
    }
  }, [jobs])

  return <CompanyLogoStack companies={companies} {...props} />
})
