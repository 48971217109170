import { Action } from '@remix-run/router'
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UNSAFE_DataRouterContext as DataRouterContext } from 'react-router'
import { Location, To, useLocation, useNavigate } from 'react-router-dom'
import { locationEquals } from '../helpers'
import { useSessionStorage } from './SessionStorageContext'

const MAX_STORED_STACK_LENGTH = 50

export const NavigationHistoryContext = createContext({
  stack: [] as Location[],
  action: undefined as Action | undefined
})

export const NavigationHistoryProvider = ({ children }: PropsWithChildren) => {
  const { router } = useContext(DataRouterContext)!
  const location = useLocation()
  const [storedStack, setStack] = useSessionStorage('navigationHistory')
  const stack = useMemo(() => storedStack ?? [], [storedStack])
  const [action, setAction] = useState<Action>()
  const contextValue = useMemo(() => ({ stack, action }), [stack, action])

  useEffect(() => {
    const lastLocation = stack[stack.length - 1]
    if (!lastLocation || !locationEquals(lastLocation, location)) {
      setStack([location])
    }

    const updateStack = (fn: (value: Location[]) => Location[]) => {
      setStack(value => fn(value ?? []).slice(-MAX_STORED_STACK_LENGTH))
    }

    const unsubscribe = router.subscribe(({ historyAction, location }) => {
      // console.log(historyAction, location.pathname)

      setAction(historyAction)

      switch (historyAction) {
        case Action.Push:
          updateStack(value => {
            const lastLoc = value[value.length - 1]
            if (!lastLoc || !locationEquals(lastLoc, location)) {
              return [...value, location]
            }
            return value
          })
          break
        case Action.Replace: {
          updateStack(value => {
            return [...value.slice(0, -1), location]
          })
          break
        }
        case Action.Pop: {
          updateStack(value => {
            const lastLoc = value[value.length - 1]
            const prevLoc = value[value.length - 2]
            if (prevLoc && locationEquals(prevLoc, location)) {
              // Went back
              return value.slice(0, -1)
            } else if (!locationEquals(lastLoc, location)) {
              // Went forward
              return [...value, location]
            }
            return value
          })
        }
      }
    })

    return unsubscribe
  }, [])

  // useEffect(() => {
  //   console.log(stack.map(s => s.pathname.toLowerCase()))
  // }, [stack])

  return (
    <NavigationHistoryContext.Provider value={contextValue}>
      {children}
    </NavigationHistoryContext.Provider>
  )
}

export function useNavigationHistory() {
  return useContext(NavigationHistoryContext)
}

export function useGoBack(fallbackTo: To | null = '/') {
  const navigate = useNavigate()
  const { stack } = useNavigationHistory()

  return useCallback(() => {
    if (fallbackTo == null || stack.length > 1) {
      navigate(-1)
    } else {
      navigate(fallbackTo, { replace: true })
    }
  }, [stack, fallbackTo])
}
