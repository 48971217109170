import { useQuery } from '@tanstack/react-query'
import { useCombobox, UseComboboxProps } from 'downshift'
import { useCallback, useMemo } from 'react'
import { useGooglePlacesAutocomplete } from '../lib/useGooglePlacesAutocomplete'
import { googleApi } from '../services'
import { QKey } from '../types'
import { useAsyncComboboxQueryCallback, useEnhancedCombobox, UseEnhancedComboboxReturn } from './autocomplete'
import { useErrorToast } from './ui'

export interface UseGooglePlacesComboboxOptions extends Omit<UseComboboxProps<google.maps.places.AutocompletePrediction>, 'items'> {
  type: 'address' | 'geocode'
  defaultInputValue?: string
}

export function useGooglePlacesCombobox({
  type,
  defaultInputValue = '',
  onIsOpenChange,
  ...comboboxProps
}: UseGooglePlacesComboboxOptions) {
  const errorToast = useErrorToast()
  const {
    predictions: items,
    fetchPredictions,
    setPredictions
  } = useGooglePlacesAutocomplete({
    types: [type],
    onFetchError(err) {
      console.error(err)
      errorToast(err)
    }
  })

  const combobox = useEnhancedCombobox<google.maps.places.AutocompletePrediction>({
    initialInputValue: defaultInputValue,
    items,
    itemToString: item => item?.description ?? '',
    onIsOpenChange(change) {
      if (!change.isOpen && change.type !== useCombobox.stateChangeTypes.FunctionSetInputValue) {
        if (change.selectedItem) {
          setInputValue(change.selectedItem.description)
        } else {
          setInputValue(defaultInputValue)
        }
      }
      onIsOpenChange?.(change)
    },
    onInputValueChange: useAsyncComboboxQueryCallback(value => {
      if (value) {
        fetchPredictions(value)
      }
    }),
    ...comboboxProps
  })

  const { setInputValue, getInputProps } = combobox

  const _getInputProps: UseEnhancedComboboxReturn<google.maps.places.AutocompletePrediction>['getInputProps'] = (options, otherOptions) => {
    const { onKeyDown, ...restOptions } = options ?? {}
    return getInputProps({
      onKeyDown: e => {
        if (e.key === 'Enter' && combobox.highlightedIndex === -1 && items.length) {
          combobox.selectItem(items[0])
        }
        onKeyDown?.(e)
      },
      ...restOptions
    }, otherOptions)
  }

  return {
    ...combobox,
    items,
    clearItems: useCallback(() => setPredictions([]), []),
    getInputProps: _getInputProps
  }
}

export function useDirectionsQuery(params: google.maps.DirectionsRequest, options: { enabled?: boolean } = {}) {
  return useQuery([QKey.DIRECTIONS, params], async () => {
    return googleApi.getDirections(params)
  }, {
    cacheTime: 360_000,
    staleTime: Infinity,
    ...options
  })
}

export function useRouteStats(route?: google.maps.DirectionsRoute) {
  return useMemo(() => {
    if (!route) return

    let duration: number = 0
    let distance: number = 0

    for (const leg of route.legs) {
      duration += (leg.duration?.value ?? 0) // seconds
      distance += (leg.distance?.value ?? 0) // meters
    }

    return { duration, distance }
  }, [route])
}
