import { Image, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import usaFlagSrc from '../../../assets/images/flag-usa.png'
import { removeNonNumeric } from '../../../helpers'
import { useFormatters } from '../../../hooks'

export const PhoneNumberInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { t } = useTranslation()
  const fmt = useFormatters()

  /**
   * Fix autofilling phone number
   * Handle the following cases:
   * +1 (123) 456-7890
   * +1(123) 456-7890
   * +1 123 456-7890
   * 1 (123) 456-7890
   * 1(123) 456-7890
   * 1 123 456-7890
   * (123) 456-7890
   * 123 456-7890
   * 11234567890
   * 1234567890
   */
  const beforeMaskedStateChange = ({ currentState, nextState }) => {
    let { value } = nextState

    if (value === '(___) ___-____') {
      return { ...nextState, value: '' }
    }

    if (!currentState) {
      return nextState
    }

    const currentValue = currentState.value as string
    const currentNumericValue = removeNonNumeric(currentState.value)

    /**
     * Fix autofill phone number without country code twice
     * Ex. 1st time -> (123) 456-7890
     * then change number to (123) 456-7891
     * then 2nd time autofill should output (123) 456-7890
     * same result as the 1st time autofill
     */
    if (currentNumericValue.length === 10) {
      value = fmt.phoneNumber(currentNumericValue)
    }

    // Fix backspacing from number after ")" or "-". Ex. from 4 to 3 should output (12_) 456
    if (
      (nextState.value as string).includes('_)') ||
      (nextState.value as string).includes('_-')
    ) {
      value = nextState.value
    }

    // Detect if there is a country code then slice out the country code
    if (
      currentNumericValue.length >= 10 &&
      (currentValue.split('(')[0].length > 0 ||
        currentValue.split(' ').length === 3)
    ) {
      value = fmt.phoneNumber(currentNumericValue.slice(-10))
    }

    return {
      ...nextState,
      value
    }
  }

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" w={29} h={22} top={4} left={5}>
        <Image src={usaFlagSrc} borderRadius={5} alt="USA flag" />
      </InputLeftElement>
      <Input
        ref={ref}
        as={InputMask}
        mask="(999) 999-9999"
        beforeMaskedStateChange={beforeMaskedStateChange}
        type="tel"
        autoComplete="tel-national"
        placeholder={t('forms.common.fields.phoneNumber.label')}
        pl="59px"
        size="lg"
        {...props}
      />
    </InputGroup>
  )
})
