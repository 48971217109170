import { Icon, IconButton, InputRightElement, InputRightElementProps } from '@chakra-ui/react'
import { IconPlaystationX } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export interface InputClearElementProps extends InputRightElementProps {
  iconSize?: InputClearElementProps['boxSize']
  onClear: () => void
}

export const InputClearElement = memo(({
  iconSize = '20px',
  onClear,
  ...props
}: InputClearElementProps) => {
  const { t } = useTranslation()

  return (
    <InputRightElement h="full" {...props}>
      <IconButton
        variant="ghost" size="xs"
        aria-label={t('actions.clear')}
        icon={<Icon as={IconPlaystationX} boxSize={iconSize} />}
        onTouchStart={() => onClear()}
        onTouchEnd={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onMouseDown={e => {
          onClear()
          e.stopPropagation()
          e.preventDefault()
        }}
      />
    </InputRightElement>
  )
})
