import { BoxProps, Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatters } from '../../hooks'
import { useSearch } from './SearchContext'

export const SearchSummary = (props: BoxProps) => {
  const { t } = useTranslation()
  const { searchOptions } = useSearch()
  const { query, location, workplaces, radius } = searchOptions
  const fmt = useFormatters()

  const distanceSummary = useMemo(() => {
    let str: string | undefined

    if (radius) {
      const formattedDistance = fmt.distance(radius, {
        inputUnit: 'miles',
        display: 'long'
      })

      if (location?.zipcode) {
        str = t('views.search.distanceFrom', {
          distance: formattedDistance,
          zipcode: location.zipcode
        })
      } else {
        str = formattedDistance
      }
    }

    return str
  }, [location, radius])

  const summary = useMemo(() => {
    const parts = [
      workplaces?.slice(0, 2),
      distanceSummary
    ]
    return parts.filter(Boolean).join(' | ')
  }, [workplaces, distanceSummary])

  return (
    <Flex flexDir="column" justify="center" wordBreak="break-all" {...props}>
      <Text mb={0.5} fontSize="2sm" lineHeight={1.3} noOfLines={1}>
        {query}
      </Text>

      <Text
        color="gray.300"
        fontFamily="Soehne" fontSize="9px" textTransform="uppercase" noOfLines={1}
      >
        {summary}
      </Text>
    </Flex>
  )
}
