import { Box, BoxProps, Image } from '@chakra-ui/react'
import { memo } from 'react'

export interface JobMediaViewProps extends BoxProps {
  type: 'image' | 'video'
  url: string
  description?: string
}

export const JobMediaView = memo(({
  type: _type,
  url,
  description,
  ...props
}: JobMediaViewProps) => (
  <Box
    pos="absolute" left={0} right={0} top={0} bottom={0}
    bg="gray.100"
    {...props}
  >
    <Image
      width="100%"
      height="100%"
      objectFit="cover"
      src={url}
      alt={description}
    />
    <Box
      pos="absolute" left={0} right={0} top={0} bottom={0}
      bgImage={'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))'}
    />
  </Box>
))
