import { Button, FormControl, FormErrorMessage, HStack, Input, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { PageContainer, PageHeader, PageHeading, SubmitButton } from '../../components/core'
import { useGoBack } from '../../contexts'
import { useAccountDeletion, useFormSubmitHandler } from '../../hooks'
import { analytics } from '../../services'

export const DeleteAccount = () => {
  const { t } = useTranslation()
  const { mutateAsync: deleteAccount, isLoading } = useAccountDeletion()
  const goBack = useGoBack()
  const form = useForm()
  const {
    register,
    formState: { errors }
  } = form

  const submit = useFormSubmitHandler(form, {
    async onSubmit() {
      analytics.deleteUser()
      await deleteAccount()
    }
  })

  const confirmationStr = t('views.settings.deleteAccount.confirmation')

  return (
    <>
      <PageHeader />
      <PageContainer pb={5}>
        <PageHeading mb={2.5}>
          {t('views.settings.deleteAccount.title')}
        </PageHeading>
        <Text color="gray.400">
          {t('views.settings.deleteAccount.subtitle')}
        </Text>
        <Text fontFamily="Soehne" fontSize="12px" lineHeight="12px" mt={10} color="gray.900" textTransform="uppercase">
          <Trans
            i18nKey="views.settings.deleteAccount.enterToConfirm"
            values={{ confirmation: confirmationStr }}
          />
        </Text>
        <form onSubmit={submit}>
          <FormControl isInvalid={!!errors.confirmation}>
            <Input
              size="lg"
              mt={2.5}
              {...register('confirmation', {
                required: true,
                validate: (val: string) => {
                  if (val.trim().toLowerCase() !== confirmationStr.toLowerCase()) {
                    return t('messages.validation.equals')
                  }
                }
              })}
              maxLength={50}
            />
            <FormErrorMessage color="red">
              {errors.confirmation && t(`${errors.confirmation.message}`)}
            </FormErrorMessage>
          </FormControl>
          <HStack mt={10} gap={5}>
            <Button
              variant="secondary"
              size="lg"
              w="100%"
              onClick={goBack}
            >
              {t('actions.nevermind')}
            </Button>
            <SubmitButton
              type="submit"
              variant="primary"
              size="lg"
              w="100%"
              isLoading={isLoading}
            >
              {t('actions.delete')}
            </SubmitButton>
          </HStack>
        </form>
      </PageContainer>
    </>
  )
}
