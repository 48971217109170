import { Icon, InputGroup, InputLeftElement, UnorderedList } from '@chakra-ui/react'
import { IconSearch } from '@tabler/icons-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnhancedCombobox, useJobSearchSuggestionsRequest } from '../../hooks'
import { SuggestionItem } from '../../models'
import { BOX_SHADOW_3 } from '../../styles'
import { AutocompleteMenuItem } from '../autocomplete/AutocompleteMenuItem'
import { AutocompleteInput, CONTENT_PADDING, InputClearElement } from '../core'
import { SearchSummary } from './SearchSummary'

interface UseQueryAutocompleteProps {
  initialValue?: string
  filtersVisible: boolean
  onSubmit: (value: string) => void
}

export function useQueryAutocomplete({
  initialValue = '',
  filtersVisible,
  onSubmit
}: UseQueryAutocompleteProps) {
  const { t } = useTranslation()
  const request = useJobSearchSuggestionsRequest()
  const items = request.result ?? []

  const combobox = useEnhancedCombobox<SuggestionItem>({
    initialInputValue: initialValue,
    items,
    itemToString: item => item?.suggestion ?? '',
    // onInputValueChange: useAsyncComboboxQueryCallback(query => {
    //   if (query) {
    //     request.execute({ q: query, ...location?.coordinates })
    //   } else {
    //     request.reset()
    //   }
    // }, []),
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        submit(selectedItem.suggestion)
      }
    }
  })

  const submit = (value: string) => {
    onSubmit(value)
    closeMenu()

    // There seems to be a race condition that sometimes causes the
    // menu to stay open after hitting Enter. Until we find the root
    // cause, this is the workaround.
    // setTimeout(closeMenu, 100)
  }

  const {
    isOpen,
    inputValue,
    highlightedIndex,
    getInputProps,
    getMenuProps,
    getItemProps,
    setInputValue,
    selectItem,
    blurInput,
    openMenu,
    closeMenu
  } = combobox

  const inputProps = getInputProps({
    onKeyDown: e => {
      if (e.key === 'Enter' && highlightedIndex === -1) {
        submit(inputValue)
      }
    }
  })
  const inputRef = inputProps.ref
  const summaryVisible = !!initialValue && !filtersVisible && !isOpen

  const handleSetValue = useCallback((value: string) => {
    selectItem(null)
    setInputValue(value)
  }, [])

  const handleClear = useCallback(() => {
    setInputValue('')
    openMenu()
  }, [])

  const input = (
    <InputGroup h="50px">
      <InputLeftElement h="full" w="48px" pointerEvents="none">
        <Icon as={IconSearch} boxSize="24px" color="gray.900" ml={-1} />
      </InputLeftElement>
      <AutocompleteInput
        h="full" pl="42px" pr={inputValue ? 10 : 3}
        type="search"
        enterKeyHint="search"
        aria-label={t('views.search.fields.query.label')}
        placeholder={t('views.search.fields.query.placeholder')}
        rounded="10px"
        {...inputProps}
      />
      {!!inputValue && !summaryVisible && (
        <InputClearElement
          w={12}
          onClear={handleClear}
        />
      )}
      {summaryVisible && (
        <SearchSummary
          pos="absolute" left="42px" top={1} right={3} bottom={1}
          bg="gray.100" rounded="lg"
          zIndex="docked"
          pointerEvents="none"
        />
      )}
    </InputGroup>
  )

  const hasResults = !!request.result

  const menu = (
    <UnorderedList
      pos="absolute" left={0} right={0}
      m={0} mt={1}
      px={CONTENT_PADDING} py={hasResults ? 2 : 0}
      listStyleType="none"
      bg="white"
      boxShadow={BOX_SHADOW_3}
      zIndex="dropdown"
      hidden={!isOpen}
      onClick={closeMenu}
      {...getMenuProps()}
    >
      {isOpen && (
        <>
          {items.map((item, index) => {
            return (
              <AutocompleteMenuItem
                key={item.suggestion}
                title={item.suggestion}
                titleSize="2xl"
                highlighed={index === highlightedIndex}
                {...getItemProps({ item, index })}
              />
            )
          })}
        </>
      )}
    </UnorderedList>
  )

  return {
    input,
    inputRef,
    menu,
    isOpen,
    value: inputValue,
    setValue: handleSetValue,
    openMenu,
    closeMenu,
    blurInput
  }
}
