import { BoxProps, Flex, Icon, Image } from '@chakra-ui/react'
import { IconBuildingStore } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Company } from '../../models'
import { BOX_SHADOW_1 } from '../../styles'

export interface CompanyLogoProps extends BoxProps {
  company?: Company | null
}

export const CompanyLogo = memo(({ company, ...props }: CompanyLogoProps) => {
  const { t } = useTranslation()
  props = {
    flexShrink: 0,
    boxSize: 50,
    boxShadow: BOX_SHADOW_1,
    ...props
  }

  return (
    <>
      {company?.logo ? (
        <Image
          bg="white"
          src={company.logo}
          alt={t('terms.companyLogo', { name: company.name })}
          objectFit="contain"
          borderRadius="full"
          {...props}
        />
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          bg="gray.900"
          {...props}
        >
          <Icon as={IconBuildingStore} boxSize="50%" color="white" />
        </Flex>
      )}
    </>
  )
})
