import { BoxProps, useDisclosure, UseDisclosureReturn, useTheme } from '@chakra-ui/react'
import { createContext, PropsWithChildren, RefObject, useContext, useMemo, useRef } from 'react'

const DEFAULT_PAGE_HEADER_HEIGHT = 60

interface LayoutContextValue {
  pageLeft: BoxProps['left']
  pageWidth: BoxProps['width']
  pageHeaderHeight: number
  pageFooterHeight: number
  breakpointValues: {
    sm: number
    md: number
    lg: number
  }
  navDrawerButtonRef: RefObject<HTMLButtonElement>
  navDrawerDisclosure: UseDisclosureReturn
  hasSidebar?: boolean
}

export const LayoutContext = createContext({
  pageLeft: 0,
  pageWidth: 0,
  pageHeaderHeight: 0,
  pageFooterHeight: 0,
  breakpointValues: { sm: 0, md: 0, lg: 0 },
  navDrawerDisclosure: {}
} as LayoutContextValue)

export const useLayoutContext = () => useContext(LayoutContext)

interface LayoutProviderProps extends PropsWithChildren {
  value: Pick<LayoutContextValue, 'pageLeft' | 'pageWidth'> & {
    pageHeaderHeight?: number
    pageFooterHeight?: number
  }
}

export const LayoutProvider = ({
  value,
  children
}: LayoutProviderProps) => {
  const theme = useTheme()
  const breakpoints = theme.__breakpoints!.asObject
  const navDrawerDisclosure = useDisclosure()
  const navDrawerButtonRef = useRef<HTMLButtonElement>(null)

  const staticValues = useMemo(() => ({
    navDrawerButtonRef,
    breakpointValues: {
      sm: parseFloat(breakpoints.sm),
      md: parseFloat(breakpoints.md),
      lg: parseFloat(breakpoints.lg)
    },
  }), [])

  const contextValue = useMemo(() => ({
    ...staticValues,
    navDrawerDisclosure,
    pageHeaderHeight: DEFAULT_PAGE_HEADER_HEIGHT,
    pageFooterHeight: 0,
    ...value
  }), [navDrawerDisclosure, value])

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  )
}
