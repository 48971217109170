import { useQuery } from '@tanstack/react-query'
import { useAppState } from '../contexts'
import { PaginationParams } from '../dtos'
import { api } from '../services'
import { QKey, SortType } from '../types'

export function useApplyOrdersQuery(params?: {
  sort_by?: 'created_at'
  sort_type?: SortType
} & PaginationParams) {
  const [{ authenticated }] = useAppState()

  return useQuery([QKey.APPLY_ORDERS, QKey.LIST, params], async () => {
    const body = await api.applyOrders.list(params)
    return body.data
  }, {
    enabled: authenticated,
    staleTime: Infinity
  })
}
