import { InfiniteData, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import { useEffect, useMemo, useRef } from 'react'
import { isInfiniteQuery } from '../helpers'
import { ApiCursorResponse } from '../lib/apiClient'

export function useQueryItems<T = any>(query?: UseQueryResult<T[]> | UseInfiniteQueryResult<ApiCursorResponse<T>>) {
  return useMemo(() => {
    if (query?.data) {
      if (isInfiniteQuery(query)) {
        return query.data.pages.flatMap(page => page.data)
      }
      return query.data
    }
  }, [query?.data])
}

export function useInfiniteQueryTotal(query?: UseQueryResult | UseInfiniteQueryResult) {
  const data = query?.data as any
  return useMemo(() => {
    if (data && isInfiniteQuery(query!)) {
      const { pages } = data as InfiniteData<ApiCursorResponse<any>>
      return pages[pages.length - 1]?.total
    }
  }, [data])
}

/**
 * Returns the known total or an estimate (20+)
 */
export function useInfiniteQueryTotalLabel(
  query?: UseInfiniteQueryResult<ApiCursorResponse<any>>,
  perPage = 20
) {
  const data = query?.data
  return useMemo(() => {
    const firstPage = data?.pages[0]
    if (firstPage) {
      return firstPage.total
        ? String(firstPage.total)
        : firstPage?.cursor ? `${perPage}+` : String(firstPage.data.length)
    }
  }, [data])
}

export function useLoadingQuery(
  queries: Array<UseQueryResult | UseInfiniteQueryResult | undefined>,
  options?: {
    onSuccess?: () => void
  }
) {
  const loadingQuery = useMemo(() => {
    for (const query of queries) {
      if (query && query.data === undefined && (query.isError || query.fetchStatus !== 'idle')) {
        return query
      }
    }
  }, queries)

  const onSuccessRef = useRef<() => void>()
  onSuccessRef.current = options?.onSuccess

  useEffect(() => {
    if (!loadingQuery) {
      onSuccessRef.current?.()
    }
  }, [!!loadingQuery])

  return loadingQuery
}
