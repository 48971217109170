import { Box, BoxProps, ButtonProps, Flex, HStack, Icon, IconProps, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, StackProps, Text, VStack } from '@chakra-ui/react'
import { IconSearch, IconShoppingCart } from '@tabler/icons-react'
import { forwardRef, isValidElement, ReactNode, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AnalyticsParamsProvider, useLayoutContext, useNavigationHistory, useSessionStorage } from '../../../contexts'
import { useBulkApplyCart, useTrack, useUpdateEffect, useWindowScrollYThresholdFlag } from '../../../hooks'
import { Translate } from '../transitions'
import { BackButton, BackButtonProps, HeaderIconButton, NavDrawerButton } from './buttons'
import { HeaderLogo, MIN_PAGE_WIDTH } from './common'
import { ContentContainer } from './containers'

export interface PageHeaderProps extends Omit<BoxProps, 'title'> {
  variant?: 'default' | 'overlay-dark' | 'overlay-light'
  title?: string | ReactNode
  logo?: boolean
  search?: boolean
  menu?: boolean
  backButton?: boolean | BackButtonProps['kind']
  cart?: boolean
  visible?: boolean
  leftContent?: ReactNode
  rightContent?: ReactNode
  isRaisedOnScroll?: boolean
  unmountOnExit?: boolean
  onBackButtonClick?: () => void
}

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({
  variant,
  title,
  logo,
  menu,
  search,
  backButton,
  cart,
  visible = true,
  leftContent,
  rightContent,
  isRaisedOnScroll = visible,
  unmountOnExit,
  onBackButtonClick,
  children,
}: PageHeaderProps, ref) => {
  const { t } = useTranslation()
  const { stack: historyStack } = useNavigationHistory()
  const { pageLeft, pageWidth, pageHeaderHeight, hasSidebar } = useLayoutContext()
  const scrolled = useWindowScrollYThresholdFlag(0)
  const showLogo = !title && !backButton && (logo ?? historyStack.length < 2)
  const showMenu = menu === undefined ? !title : menu
  const initialVisibleRef = useRef(visible)
  const track = useTrack()

  let bg: BoxProps['bg'] = 'white'
  let buttonBg: BoxProps['bg']
  let buttonVariant: ButtonProps['variant']
  let logoFilter: string | undefined
  let scrolledProps: BoxProps | undefined

  if (variant === 'overlay-light' || variant === 'overlay-dark') {
    bg = 'transparent'
    isRaisedOnScroll = false
  }

  if (variant === 'overlay-light') {
    buttonBg = 'white'
    logoFilter = 'brightness(0) invert(1)'
  } else if (variant === 'overlay-dark') {
    buttonVariant = 'tertiary'
  }

  if (scrolled && isRaisedOnScroll) {
    scrolledProps = {
      borderBottomWidth: 1,
      borderColor: 'gray.200',
      boxShadow: 'md'
    }
  }

  const renderSearchButton = () => (
    <HeaderIconButton
      as={Link} to="/search?back=1"
      variant={buttonVariant}
      title={t('actions.search')}
      aria-label={t('actions.search')}
      renderIcon={iconProps => <Icon as={IconSearch} {...iconProps} />}
      bg={buttonBg}
      onClick={() => track.open('Search')}
    />
  )

  const sideContainerProps: StackProps = {
    spacing: 2.5,
    flex: title ? 1 : undefined
  }

  return (
    <AnalyticsParamsProvider source="Page Header">
      <Box
        ref={ref}
        pos="fixed" top="0" left={pageLeft}
        w={pageWidth} minW={MIN_PAGE_WIDTH}
        zIndex="1202" // Just above Overlay
      >
        {!!title && typeof title === 'string' && <Helmet title={title} />}

        <Translate
          offsetY={-pageHeaderHeight} in={visible} unmountOnExit={unmountOnExit}
          initial={initialVisibleRef.current ? false : 'exit'}
        >
          <Box {...scrolledProps}>
            <ContentContainer bg={bg}>
              <Flex w="full" h={`${pageHeaderHeight}px`} alignItems="center">
                <HStack {...sideContainerProps} pr={1.5}>
                  {showLogo ? (
                    <HeaderLogo
                      display={{ base: 'block', md: hasSidebar ? 'none' : 'block' }}
                      filter={logoFilter}
                    />
                  ) : backButton !== false && (
                    <BackButton
                      kind={typeof backButton === 'string' ? backButton : undefined}
                      variant={buttonVariant}
                      onClick={onBackButtonClick}
                    />
                  )}

                  {search && cart && renderSearchButton()}
                  {leftContent}
                </HStack>

                {title && (
                  <Box overflow="hidden">
                    {isValidElement(title) ? title : (
                      <Text
                        as="h1"
                        fontSize="xs"
                        fontWeight="medium" lineHeight={1.5}
                        color="gray.900" backgroundColor="gray.100"
                        borderRadius="md" p="5px"
                        whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
                      >
                        {title}
                      </Text>
                    )}
                  </Box>
                )}

                <HStack {...sideContainerProps} ml="auto" pl={1.5} justifyContent="flex-end">
                  {rightContent}
                  {cart ? (
                    <CartButton variant={buttonVariant} bg={buttonBg} />
                  ) : search && (
                    renderSearchButton()
                  )}
                  {showMenu && <NavDrawerButton variant={buttonVariant} bg={buttonBg} />}
                </HStack>
              </Flex>
            </ContentContainer>

            {children}
          </Box>
        </Translate>
      </Box>
    </AnalyticsParamsProvider>
  )
})

const CartButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  const {
    bounds,
    length: cartLength
  } = useBulkApplyCart()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isCartPopoverDismissed, setIsCartPopoverDismissed] = useSessionStorage('bulkApplyCartTipDismissed')
  const track = useTrack()

  const handleDismissCartPopover = () => {
    setIsPopoverOpen(false)
    setIsCartPopoverDismissed(true)
  }

  useUpdateEffect(() => {
    if (!isCartPopoverDismissed) {
      if (cartLength === 0) {
        setIsPopoverOpen(false)
      } else if (cartLength === 1) {
        setIsPopoverOpen(true)
      } else if (cartLength > 1) {
        setIsPopoverOpen(false)
        setIsCartPopoverDismissed(true)
      }
    }
  }, [cartLength, isCartPopoverDismissed])

  const renderCartIcon = (iconProps: IconProps) => {
    return (
      <>
        {cartLength > 0 && <Flex
          position="absolute" top="-4px" right="-5px"
          borderRadius="full" fontSize="10px"
          w={5} h={5}
          display="flex" alignItems="center" justifyContent="center"
          bg="#000" color="white"
          zIndex="1"
        >
          {cartLength}
        </Flex>}
        <Icon as={IconShoppingCart} transform="scale(-1, 1)" {...iconProps} />
      </>
    )
  }

  return (
    <Popover isOpen={isPopoverOpen} placement="bottom" closeOnBlur>
      <PopoverTrigger>
        <HeaderIconButton
          as={Link} to="/bulk-apply/cart"
          title={t('views.bulkApply.cart')}
          aria-label={t('views.bulkApply.cart')}
          renderIcon={renderCartIcon}
          onClick={() => track.open('Bulk Apply Cart')}
          {...props}
        />
      </PopoverTrigger>
      <PopoverContent mr={4} boxShadow="md">
        <PopoverArrow ml={2} />
        <PopoverCloseButton size="sm" mr={-1} onClick={handleDismissCartPopover} />
        <PopoverBody textAlign="center">
          <VStack spacing={1} py={1}>
            <Text fontWeight="bold">
              {t('views.bulkApply.cartNavPopover.title')}
            </Text>
            <Text fontSize="sm" lineHeight={1.3}>
              {t('views.bulkApply.cartNavPopover.body', { count: bounds.max })}
            </Text>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
