import { Box, BoxProps, Button, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { analytics } from '../../services'
import { NAVIGATION_ICONS } from './common'

export const SidebarNav = (props: BoxProps) => {
  const { t } = useTranslation()

  const links = useMemo(() => (
    [
      { href: '/home', label: t('links.home') },
      { href: '/search', label: t('actions.search') },
      { href: '/my-gigs', label: t('links.myGigs') },
      { href: '/my-app', label: t('links.myApp') },
      { href: '/match-preferences', label: t('views.matchPreferences.title') }
    ]
  ), [t])

  return (
    <VStack spacing={2.5} {...props}>
      {links.map(link => (
        <NavLink key={link.href} to={link.href} style={{ width: '100%' }}>
          {({ isActive }) => (
            <Button
              as={Box}
              w="full"
              px={2.5}
              rounded={20}
              variant={isActive ? 'secondary' : 'inactive'}
              onClick={() => analytics.trackNav(link.label, { source: 'Sidebar' })}
            >
              <HStack w="100%" spacing={2.5}>
                <Flex boxSize={34} alignItems="center" justifyContent="center">
                  <Icon as={NAVIGATION_ICONS[link.href]} boxSize={6} />
                </Flex>
                <Text>{link.label}</Text>
              </HStack>
              <Icon as={IconChevronRight} boxSize={6} />
            </Button>
          )}
        </NavLink>
      ))}
    </VStack>
  )
}
