import * as dateFns from 'date-fns'
import { t } from 'i18next'
import { DateObject } from '../models'

const monthNumToNameMap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

type DateParam = string | number | Date

export function formatDate(date: DateParam, format = 'PP') {
  let _date: number | Date
  if (typeof date === 'string') {
    _date = new Date(date)
    if (!hasTZComponent(date)) {
      _date = new Date(_date.getTime() + _date.getTimezoneOffset() * 60000)
    }
  } else {
    _date = date
  }
  return dateFns.format(_date, format)
}

export function formatDateRange(start: DateParam, end?: DateParam | null, format = 'PP') {
  return formatDate(start, format) + ' - ' + (end ? formatDate(end, format) : t('terms.current'))
}

export function formatRelativeTime(
  date: DateParam,
  baseDate: number | Date = Date.now(),
  options?: { addSuffix?: boolean }
) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return dateFns.formatDistanceStrict(date, baseDate, options)
}

export function formatRelativeTimeCompact(date: DateParam) {
  const oneDay = 24 * 60 * 60 * 1000

  const dateProvided = new Date(date).getTime()
  const now = new Date().getTime()
  const diffTime = now - dateProvided

  const days = Math.max(1, Math.floor(diffTime / oneDay))
  const threshold = 30
  const str = days <= threshold ? `${days} ${days === 1 ? 'day' : 'days'}` : `${threshold}+ days`

  return `${str} ago`
}

// Return format of Mmm DD, YYYY or default of MM/DD/YYYY
export function formatDateObject(dateObj: Partial<DateObject> | undefined | null, format?: 'Mmm DD, YYYY') {
  if (!dateObj || !dateObj.year || !dateObj.month || !dateObj.day) {
    return ''
  }

  // Add 0 in front of day
  const day = dateObj.day.toString().padStart(2, '0')

  if (format === 'Mmm DD, YYYY') {
    return `${monthNumToNameMap[dateObj.month - 1]} ${day}, ${dateObj.year}`
  }

  // Add 0 in front of month
  const month = (dateObj.month).toString().padStart(2, '0')

  return `${month}/${day}/${dateObj.year}`
}

export function dateStringToDateObject(dateString: string) {
  const arr = dateString.split('/')

  const dateObject: DateObject = {
    month: Number(arr[0]),
    day: Number(arr[1]),
    year: Number(arr[2])
  }

  return dateObject
}

export function dateToDateObject(date: Date): DateObject {
  return {
    month: date.getMonth() + 1,
    day: date.getDate(),
    year: date.getFullYear()
  }
}

export function dateObjectToDate(dateObject: DateObject) {
  return new Date(dateObject.year, dateObject.month - 1, dateObject.day)
}

function hasTZComponent(dateStr: string) {
  return dateStr.includes('Z') || dateStr.includes('+')
}
