import { t } from 'i18next'

export enum AppErrorCode {
  NOT_FOUND = 'notFound',
  UNEXPECTED = 'unexpected',
  GEO_LOCATION_ADDRESS_NOT_FOUND = 'geoLocationAddressNotFound'
}

export class AppError extends Error {
  code: AppErrorCode

  constructor(message: string, code: AppErrorCode) {
    super(message)
    this.code = code
    this.name = 'AppError'
  }
}

export function createAppError(code: AppErrorCode, message?: string) {
  return new AppError(message ?? t(`messages.errors.${code}`), code)
}

export function isAppError(error: unknown, code: AppErrorCode): error is AppError {
  return error instanceof AppError && error.code === code
}
