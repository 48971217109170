import { Navigate, useLocation } from 'react-router-dom'
import { AppErrorCode, createAppError } from '../errors'
import { useInitialPageConfig } from '../hooks'
import { Search } from './Search'

export const CatchAll = () => {
  const { pathname } = useLocation()
  const initialPageConfig = useInitialPageConfig()

  if (initialPageConfig?.key === 'jobs_serp') {
    return <Search />
  }

  // This is a workaround for an bug caused when you visit a Search SERP,
  // navigate to another page, refresh the browser, then navigate back.
  // Since the initial page config (APP_CONFIG) is no longer there,
  // we don't have the data we need to show the page. Instead, we
  // replace the history with the default search page.
  if (
    pathname.lastIndexOf('/') === 0 &&
    (pathname.includes('jobs-in-') || pathname.endsWith('-jobs'))
  ) {
    return <Navigate to="/search" replace />
  }

  throw createAppError(AppErrorCode.NOT_FOUND)
}
