import { Box, BoxProps, Flex, FlexProps, HStack, Text, TextProps } from '@chakra-ui/react'
import { distanceBetween } from '../../helpers'
import { useFormatters } from '../../hooks'
import { JobBase, LatLng } from '../../models'
import { Wage, WageProps } from './Wage'

interface BaseJobCardDetailsHeaderProps {
  job: JobBase
  titleNoLines?: TextProps['noOfLines']
}

interface BaseJobCardDetailsFooterProps {
  job: JobBase
  distanceFrom?: LatLng
  wageSize?: WageProps['size']
  displaySupplementalPay?: boolean
}

export interface JobCardDetailsProps extends BaseJobCardDetailsHeaderProps, BaseJobCardDetailsFooterProps, FlexProps {
  sectionSpacing?: FlexProps['mt']
}

export const JobCardDetailsHeader = ({
  job,
  titleNoLines = 2,
  size = 'md',
  ...props
}: BaseJobCardDetailsHeaderProps & { size?: 'md' | 'lg' } & BoxProps) => {
  return (
    <Box {...props}>
      {/*
        This is a hack to get line-clamp to work with a shorter line height.
        The Future font has unusual vertical spacing, which causes descenders
        on the last line to be clipped when using line-clamp.

        We get around this by setting the Text overflow back to visible and
        wrapping it in a Box with the attributes below.
      */}
      <Box pb={0.5} mb={-0.5} overflow="hidden">
        <Text
          as="h3"
          fontSize={size === 'md' ? 14 : 16}
          fontWeight="medium"
          lineHeight={1.2}
          noOfLines={titleNoLines}
          overflow="visible"
        >
          {job.title}
        </Text>
      </Box>
      <Text fontSize={size === 'md' ? 11 : 12} noOfLines={1} wordBreak="break-all">
        {job.company?.name}
      </Text>
    </Box>
  )
}

export const JobCardDetailsFooter = ({
  job,
  distanceFrom,
  wageSize = 'xs',
  displaySupplementalPay,
  ...props
}: BaseJobCardDetailsFooterProps & FlexProps) => {
  const fmt = useFormatters()

  return (
    <Flex flexDir="column" justify="flex-end" {...props}>
      <Wage job={job} size={wageSize} displaySupplementalPay={displaySupplementalPay} />

      {job.region_name && (
        <HStack fontSize="2xs" lineHeight="13px" color="gray.300" mt={0.5}>
          <Text noOfLines={1} wordBreak="break-all">
            {job.region_name}
          </Text>
          {distanceFrom && job.coordinate && (
            <Text>
              {fmt.distance(distanceBetween(distanceFrom, job.coordinate), { display: 'short' })}
            </Text>
          )}
        </HStack>
      )}
    </Flex>
  )
}
