import { Box, Divider, Flex, Stack, Text, TextProps, useModalContext, UseModalProps } from '@chakra-ui/react'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppState, useLocalStorageSetter, useSessionStorageSetter } from '../../contexts'
import { useFormatters, useGeoCoordinates, useIsPayEstimateSupported, useJobQuery, useSendUserEvent, useTrack } from '../../hooks'
import { isApiNotFoundError } from '../../lib/apiClient'
import { Job, UserEventType } from '../../models'
import { PayCalculator } from '../../pages/JobDetail/partials/PayCalculator'
import { analytics } from '../../services'
import { BOX_SHADOW_2, FOOTER_BOX_SHADOW } from '../../styles'
import { AddToCartButton } from '../bulkApply/AddToCartButton'
import { CompanyLogo } from '../companies'
import { CONTENT_PADDING, DismissButton, NoResultsNotice, Pill, StatusOverlay } from '../core'
import { ModalSheet } from '../core/ModalSheet'
import { RegionBadge } from '../RegionBadge'
import { CollapsibleJobDescription } from './CollapsibleJobDescription'
import { JobCardPills } from './JobCardPills'
import { RequirementList } from './RequirementList'
import { WagePill } from './WagePill'

export interface JobDetailModalProps extends UseModalProps {
  isOpen: boolean
  slug?: string
  actions?: ReactNode | ((job: Job) => ReactNode)
  feature?: string
}

export const JobDetailModal = ({
  isOpen,
  slug,
  actions,
  feature,
  onClose,
  ...props
}: JobDetailModalProps) => {
  const { t } = useTranslation()
  const [{ authenticated }] = useAppState()
  const sendUserEvent = useSendUserEvent()
  const track = useTrack()
  const [isOnTopScroll, setIsOnTopScroll] = useState(true)
  const jobQuery = useJobQuery(slug)
  const { data: job, error, isLoading } = jobQuery
  const notFound = !job && isApiNotFoundError(error) && !isLoading

  const setAuthZipcode = useSessionStorageSetter('authZipcode')
  const setLastViewedJobSlug = useLocalStorageSetter('lastViewedJobSlug')
  const setFallbackCoordinates = useLocalStorageSetter('fallbackCoordinates')

  useEffect(() => {
    if (isOpen) {
      track.open('Job Detail Modal')
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && job) {
      if (authenticated) {
        sendUserEvent({
          type: UserEventType.JOB_VIEW,
          job_id: job.id,
          data: feature ? { feature } : undefined
        })
      } else {
        setAuthZipcode(job.zipcode)
      }

      setLastViewedJobSlug(job.slug)
      setFallbackCoordinates(job.coordinate)
    }
  }, [isOpen, job?.id])

  useEffect(() => {
    if (isOpen && slug && notFound) {
      analytics.trackJobExpired(slug)
    }
  }, [isOpen, notFound])

  let scrollTimeout: number | null = null

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    if (scrollTimeout !== null) {
      cancelAnimationFrame(scrollTimeout)
    }

    scrollTimeout = requestAnimationFrame(() => {
      const target = event.target as HTMLElement
      const isTopScroll = target.scrollTop <= 0
      setIsOnTopScroll(isTopScroll)
    })
  }

  const handleOnClose = () => {
    setIsOnTopScroll(true)
    onClose()
  }

  if (job && actions === undefined) {
    actions = <DefaultActions job={job} onClose={onClose} />
  }

  return (
    <ModalSheet
      isOpen={isOpen}
      drawerMaxH="80%"
      size="lg"
      scrollBehavior="inside"
      header={job ? <DetailModalHeader job={job} scrolled={!isOnTopScroll} /> : null}
      footer={actions && job && (
        <Flex w="full" p={5} justify="center" bg="white" boxShadow={FOOTER_BOX_SHADOW}>
          {typeof actions === 'function' ? actions(job) : actions}
        </Flex>
      )}
      onScroll={handleScroll}
      onClose={handleOnClose}
      {...props}
    >
      <Box h={job ? undefined : '70vh'}>
        {job ? (
          <Body job={job} />
        ) : notFound && (
          <NoResultsNotice
            title={t('views.jobs.unavailableNotice.title')}
            message={t('views.jobs.unavailableNotice.message')}
            button={{
              children: t('actions.seeMoreGigs'),
              onClick: onClose
            }}
          />
        )}

        <StatusOverlay
          query={jobQuery}
          error={notFound ? null : error}
        />
      </Box>
    </ModalSheet>
  )
}

const DetailModalHeader = ({ job, scrolled }: { job: Job, scrolled: boolean }) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const { onClose } = useModalContext()

  return (
    <Flex
      w="full" px={CONTENT_PADDING} py={5}
      boxShadow={scrolled ? BOX_SHADOW_2 : undefined}
      transition="box-shadow 0.1s ease-in-out"
    >
      <Box flex={1} w={0} mr={2.5}>
        <Text fontWeight="bold" fontSize="xl" mb={2.5}>
          {job.title}
        </Text>

        <Flex alignItems="center" gap={1}>
          <CompanyLogo boxSize="14px" border="1px solid" borderColor="gray.900" company={job.company} />
          <Text fontSize="2xs" fontWeight="medium">
            {job.company.name}
          </Text>
          <Box>
            <Text fontSize="2xs" color="gray.300" ml={1.5}>
              {t('views.jobs.postedAt', {
                at: fmt.relativeTimeCompact(job.posted_date ?? job.created_at)
              })}
            </Text>
          </Box>
        </Flex>
      </Box>

      <DismissButton onClick={onClose} />
    </Flex>
  )
}

const Body = ({ job }: { job: Job }) => {
  const { t } = useTranslation()
  const geoCoordinates = useGeoCoordinates()
  const showPayCalculator = useIsPayEstimateSupported(job)

  return (
    <Box>
      <Stack spacing={5}>
        <Flex gap={2}>
          <RegionBadge record={job} distanceFrom={geoCoordinates} />
          <WagePill bg="transparent" job={job} />
        </Flex>

        <JobCardPills job={job} size="2sm" fontWeight="normal" />
      </Stack>

      <Divider my={5} />
      <Stack divider={<Divider />} spacing={5}>
        {!!job.requirements?.length && (
          <Box>
            <SectionHeading>
              {t('views.jobs.requirements')}
            </SectionHeading>
            <RequirementList items={job.requirements} />
          </Box>
        )}

        <Box>
          <SectionHeading>
            {t('views.jobs.jobDescriptionTitle')}
          </SectionHeading>
          <CollapsibleJobDescription job={job} size="sm" />
        </Box>

        {showPayCalculator && (
          <PayCalculator job={job} titleFontWeight="bold" />
        )}

        {!!job.benefits?.length && (
          <Box>
            <SectionHeading mb={4}>
              {t('views.jobs.benefitsAndPerks')}
            </SectionHeading>
            <Flex direction="row" wrap="wrap" gap={2.5}>
              {job.benefits?.map((item) => (
                <Pill key={item} size="lg">{item}</Pill>
              ))}
            </Flex>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

const SectionHeading = (props: TextProps) => (
  <Text fontSize="sm" fontWeight="bold" mb={2.5} {...props} />
)

const DefaultActions = ({ job, onClose }: { job: Job, onClose: () => void }) => {
  return <AddToCartButton job={job} size="lg" w="full" onAdd={onClose} />
}
