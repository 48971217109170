import { Modal, ModalContent, ModalOverlay, ModalProps, Spinner } from '@chakra-ui/react'

export const ProgressModal = (props: Omit<ModalProps, 'onClose' | 'children'>) => {
  return (
    <Modal
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {}}
      {...props}
    >
      <ModalOverlay />
      <ModalContent m={0} alignItems="center" justifyContent="center" bg="transparent" shadow="none">
        <Spinner size="lg" color="gray.900" />
      </ModalContent>
    </Modal>
  )
}
