import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CompanyInfoFooter, COMPANY_INFO_FOOTER_HEIGHT } from '../../components/companies'
import { LocationListPage } from '../../components/locations'
import { useLocationsQuery } from '../../hooks'

export const CompanyLocations = () => {
  const { t } = useTranslation()
  const params = useParams()
  const query = useLocationsQuery({ companyId: params.id })
  const company = query.data?.pages[0]?.data[0].company

  return (
    <LocationListPage
      pb={`${COMPANY_INFO_FOOTER_HEIGHT}px`}
      title={t('terms.locations')}
      query={query}
    >
      {company && (
        <>
          <Helmet title={`${company.name}: ${t('terms.locations')}`} />
          <CompanyInfoFooter company={company} />
        </>
      )}
    </LocationListPage>
  )
}
