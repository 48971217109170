import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { JobListPage } from '../../components/jobs'
import { useJobQuery, useRelatedJobsQuery } from '../../hooks'

export const RelatedJobs = () => {
  const { t } = useTranslation()
  const params = useParams()
  const jobQuery = useJobQuery(params.slug)
  const job = jobQuery.data
  const relatedJobsQuery = useRelatedJobsQuery(job)

  return (
    <JobListPage
      title={t('views.jobs.moreJobsAtThisCompany')}
      query={job ? relatedJobsQuery : jobQuery}
    />
  )
}
