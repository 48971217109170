import { Spinner, SpinnerProps } from '@chakra-ui/react'
import { Notice, NoticeProps } from './Notice'

export interface LoadingNoticeProps extends NoticeProps {
  indicator?: boolean
  indicatorSize?: SpinnerProps['size']
  indicatorColor?: SpinnerProps['color']
}

export const LoadingNotice = ({
  indicatorSize = 'lg',
  indicatorColor = 'gray.300',
  ...props
}: LoadingNoticeProps) => {
  return (
    <Notice
      image={<Spinner size={indicatorSize} color={indicatorColor} />}
      {...props}
    />
  )
}
