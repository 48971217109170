import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { analytics } from '../../services'
import { AuthMethod } from '../../types'
import { Dialog, DialogProps } from '../core'
import { AuthPanel } from './AuthPanel'


interface AuthDialogProps extends DialogProps {
  variant?: 'default' | 'apply' | 'matchedJobs' | 'readMore' | 'search'
  jobSlug?: string
  redirectTo?: string
  onSuccess?: () => void
}

export const AuthDialog = ({
  variant = 'default',
  title,
  message,
  jobSlug,
  redirectTo,
  isOpen,
  children,
  onClose,
  onCloseComplete,
  onSuccess,
  ...props
}: AuthDialogProps) => {
  const { t } = useTranslation()
  const [method, setMethod] = useState(AuthMethod.SIGN_UP)
  const [didClose, setDidClose] = useState(false)

  const dialogProps = {
    titleSize: '3xl',
    disclaimer: message,
    onClose: () => {
      setDidClose(true)
      onClose()
    },
    onCloseComplete: didClose ? onCloseComplete : undefined
  }

  const panelProps = {
    method,
    jobSlug,
    redirectTo,
    children,
    onSuccess
  }

  if (!title) {
    title = t(`views.auth.signUp.title.${variant}`)
  }

  useEffect(() => {
    if (isOpen) {
      analytics.trackLoadRegistration({
        type: 'modal',
        method,
        variant,
        emailAuthType: 'Magic Link'
      })
    }
  }, [isOpen, method])

  return (
    <>
      <Dialog
        isOpen={isOpen && method === AuthMethod.SIGN_UP}
        title={title}
        {...dialogProps}
        {...props}
      >
        <AuthPanel
          {...panelProps}
          onAltMethodClick={e => {
            e.preventDefault()
            setMethod(AuthMethod.SIGN_IN)
          }}
        />
      </Dialog>

      <Dialog
        isOpen={isOpen && method === AuthMethod.SIGN_IN}
        title={title}
        {...dialogProps}
        {...props}
      >
        <AuthPanel
          {...panelProps}
          onAltMethodClick={e => {
            e.preventDefault()
            setMethod(AuthMethod.SIGN_UP)
          }}
        />
      </Dialog>
    </>
  )
}
