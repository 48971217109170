import { Box, BoxProps } from '@chakra-ui/react'
import { useMemo } from 'react'
import { AnalyticsParamsProvider } from '../../../contexts'
import { JobCategoryFeedItem } from '../../../models'
import { analytics } from '../../../services'
import { ContentSection, CONTENT_PADDING } from '../../core'
import { HorizontalJobList } from '../../jobs/HorizontalJobList'
import { JobCard } from '../../jobs/JobCard'

export interface JobCategoryCardProps extends Omit<BoxProps, 'color'> {
  item: JobCategoryFeedItem
}

export const JobCategoryCard = ({ item, ...props }: JobCategoryCardProps) => {
  const { jobs } = item.data
  const firstJob = jobs[0]
  const category = item.data
  const listJobs = useMemo(() => jobs.slice(1), [jobs])
  const scrollPreservationKey = `job-categories/${category.slug}`

  return (
    <AnalyticsParamsProvider source={category.name}>
      <ContentSection
        title={item.data.name}
        linkTo={`/job-categories/${item.data.slug}`}
        pb={3}
        {...props}
      >
        <Box
          my={5}
          px={CONTENT_PADDING}
        >
          <JobCard
            job={firstJob}
            color={`#${category.color}`}
            imageSrc={category.image}
            imageAlt={category.name}
            {...props}
          />
        </Box>

        <HorizontalJobList
          items={listJobs}
          scrollPreservationKey={scrollPreservationKey}
          onScrollStart={() => analytics.trackHorizontalScroll(item.data.name)}
        />
      </ContentSection>
    </AnalyticsParamsProvider>
  )
}
