import { Box, BoxProps, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { IconPencil } from '@tabler/icons-react'
import { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface SettingsCardProps extends Omit<BoxProps, 'children'> {
  icon?: ReactElement
  title?: string
  editLink?: string
  children?: ReactNode
  to?: string // For using Box as react-router-dom Link
}

export const SettingsCard = ({ icon, title, editLink, children, ...props }: SettingsCardProps) => {
  const { t } = useTranslation()

  const content = (
    <Stack spacing={2}>
      {(title ?? editLink) && (
        <Flex>
          <HStack flex={1} spacing={1.5} alignItems="flex-start">
            {icon}
            {title && (
              <Text mr={3} fontWeight={500} lineHeight="22px">
                {title}
              </Text>
            )}
          </HStack>

          {editLink && (
            <Icon
              ml={2}
              mr={-1}
              as={IconPencil}
              boxSize={6}
            />
          )}
        </Flex>
      )}

      {children}
    </Stack>
  )

  return editLink ? (
    <WrapperBox
      as={Link}
      to={editLink}
      aria-label={t('actions.edit')}
      {...props}
    >
      {content}
    </WrapperBox>
  ) : (
    <WrapperBox {...props}>
      {content}
    </WrapperBox>
  )
}

const WrapperBox = (props: BoxProps) => (
  <Box
    pt={5}
    px={5}
    pb={5}
    bg="gray.100"
    rounded={20}
    {...props}
  />
)
