import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CompanyInfoFooter, COMPANY_INFO_FOOTER_HEIGHT } from '../../components/companies'
import { JobListPage } from '../../components/jobs'
import { useGeoCoordinates, useJobSearchQuery, useLocationQuery } from '../../hooks'

export const LocationJobs = () => {
  const { t } = useTranslation()
  const params = useParams()
  const locationId = params.id!
  const coordinates = useGeoCoordinates()
  const locationJobsQuery = useJobSearchQuery({ location_id: locationId, coordinates })
  const locationQuery = useLocationQuery(locationId)
  const location = locationQuery.data

  return (
    <>
      <JobListPage
        title={t('terms.jobs')}
        query={locationJobsQuery}
        pb={`${COMPANY_INFO_FOOTER_HEIGHT}px`}
        aName="Jobs at this location"
      >
        {location && (
          <>
            <Helmet title={`${location.company.name} (${location.region_name}): ${t('terms.jobs')}`} />
            <CompanyInfoFooter company={location.company} location={location} />
          </>
        )}
      </JobListPage>
    </>
  )
}
