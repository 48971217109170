import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useIsFeatureOn } from '../../contexts'
import { useInitialPageConfig, useJobQuery } from '../../hooks'
import { isApiNotFoundError } from '../../lib/apiClient'
import { SearchLink } from '../../models'
import { analytics } from '../../services'
import { QKey } from '../../types'
import { BulkApplyLanding } from './partials/BulkApplyLanding'
import { JobDetailDefault } from './partials/JobDetailDefault'

interface EmbeddedJobDetailProps {
  event_params?: { [key: string]: string }
  search_links?: SearchLink[]
}

export const JobDetail = () => {
  const initalPageConfig = useInitialPageConfig()
  const embeddedProps: EmbeddedJobDetailProps | undefined = initalPageConfig?.props
  const eventParams = embeddedProps?.event_params

  const params = useParams()
  const slug = params.slug!
  const [searchParams] = useSearchParams()
  const referrer = searchParams.get('ref') ?? undefined
  const source = searchParams.get('src') ?? undefined

  // If this is the inital page requested from the server,
  // the job data will already exist in the query cache if
  // the job is live. Otherwise, we are rendering a 404 or 410 page.
  const queryClient = useQueryClient()
  const initialNotFound = embeddedProps && !queryClient.getQueryData([QKey.JOBS, slug])

  const jobQuery = useJobQuery(initialNotFound ? undefined : slug)
  const { data: job, error } = jobQuery
  const notFound = !job && (initialNotFound || isApiNotFoundError(error))

  const hasBulkApplyLanding = useIsFeatureOn('bulk_apply_landing_page')

  useEffect(() => {
    if (notFound) {
      analytics.trackJobExpired(slug, eventParams)
    }
  }, [slug, notFound])

  const childProps = {
    slug,
    jobQuery,
    job,
    searchLinks: embeddedProps?.search_links,
    eventParams,
    referrer,
    source,
    notFound,
    initialNotFound
  }

  if (initalPageConfig && hasBulkApplyLanding) {
    return <BulkApplyLanding key={slug} {...childProps} />
  }

  return <JobDetailDefault key={slug} {...childProps} />
}
