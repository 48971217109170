import { Icon, IconProps } from '@chakra-ui/react'
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandReddit,
  IconBrandTiktok,
  IconBrandTwitter,
  IconBrandX,
  IconBrandYoutube
} from '@tabler/icons-react'

const ICON_COMPONENTS = {
  facebook: IconBrandFacebook,
  instagram: IconBrandInstagram,
  linkedin: IconBrandLinkedin,
  reddit: IconBrandReddit,
  tiktok: IconBrandTiktok,
  twitter: IconBrandTwitter,
  youtube: IconBrandYoutube,
  x: IconBrandX
}

interface SocialIconProps extends IconProps {
  platform: string
}

export const SocialIcon = ({ platform, ...props }: SocialIconProps) => {
  const IconComponent = ICON_COMPONENTS[platform]
  return <Icon as={IconComponent} {...props} />
}
