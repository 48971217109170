import { Button, Icon, Text } from '@chakra-ui/react'
import { IconMapPin } from '@tabler/icons-react'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../../components/core'
import { useAccount } from '../../../hooks'

export const FeedHeader = memo(() => {
  const account = useAccount()

  const regions = account?.preferences.regions
  const hasRegions = regions && regions.length > 0
  const regionInfo = hasRegions ? `${regions[0].name}, ${regions[0].state}` : ''

  return (
    <PageHeader
      logo={false}
      backButton={false}
      cart
      menu
      leftContent={
        <Button
          as={Link} to="settings/starting-location"
          pl={2} pr={3}
          rounded="full"
          whiteSpace="normal"
        >
          <Icon as={IconMapPin} boxSize={5} mr={1.5} />
          <Text
            w="full"
            fontFamily="Soehne"
            fontSize={12}
            noOfLines={1}
            wordBreak="break-all"
            textTransform="uppercase"
          >
            {regionInfo}
          </Text>
        </Button>
      }
    />
  )
})
