import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'

/**
 * This is a workaround for browser extensions like google translate
 * that manipulate dom text elements. A crash occurs when chakra-ui
 * Button text is translated, and the `isLoading` prop is
 * set to true. We avoid this crash by wrapping the button text
 * in a span element.
 */
export const SubmitButton = forwardRef<ButtonProps, 'button'>(({
  children,
  ...props
}, ref) => (
  <Button ref={ref} {...props}>
    <span>{children}</span>
  </Button>
))
