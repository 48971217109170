import { FieldValues, Path, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { isApiValidationError } from '../lib/apiClient'

export interface UseFormSubmitHandlerProps<TFieldValues extends FieldValues> {
  onSubmit: SubmitHandler<TFieldValues>
  onSuccess?: () => void
  onError?: (err: unknown) => void
}

export function useFormSubmitHandler<TFieldValues extends FieldValues = FieldValues>(form: UseFormReturn<TFieldValues>, props: UseFormSubmitHandlerProps<TFieldValues>) {
  const {
    onSubmit,
    onSuccess,
    onError
  } = props

  const submit = form.handleSubmit(async (...args) => {
    try {
      await onSubmit(...args)
      onSuccess?.()
    } catch (err) {
      if (isApiValidationError(err)) {
        try {
          const messages = err.details?.parameters
          let didSetError = false

          for (const [name, message] of Object.entries(messages)) {
            const fieldName = name as Path<TFieldValues>
            if (form.getFieldState(fieldName)) {
              form.setError(fieldName, { message: message[0] })
              didSetError = true
            }
          }

          if (!didSetError) {
            onError?.(err)
          }
        } catch (err) {
          console.error(err)
          onError?.(err)
        }

        return
      }

      onError?.(err)
    }
  })

  return submit
}
