import { Box, BoxProps, css, SliderMark, SliderMarkProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { memo } from 'react'

export const SliderRangeLabel = (props: BoxProps) => (
  <Box
    py={0.5} px={1.5}
    fontWeight="medium"
    fontSize="xs"
    lineHeight={1.3}
    bg="white"
    textAlign="center"
    whiteSpace="nowrap"
    rounded={2}
    {...props}
  />
)

interface SliderTooltipProps {
  value?: number
  label: string
}

export const SliderTooltip = ({ value, label }: SliderTooltipProps) => {
  return (
    <SliderMark value={value ?? 0}>
      <Box
        pos="absolute" top="14px" left={-1} boxSize="8px"
        bg="black"
        transform="rotate(45deg)"
      />

      <Box
        pos="relative" left="-50%" top="18px"
        minW={14} px={2.5} py={1}
        fontSize="xs" fontWeight="medium"
        textAlign="center" whiteSpace="nowrap"
        color="white"
        bg="gray.900"
        rounded={5}
        boxShadow="md"
      >
        {label}
      </Box>
    </SliderMark>
  )
}

interface SliderMarksProps {
  maxRange?: number
  rangeValues: number[]
}

export const SliderMarks = memo(({
  maxRange = 0,
  rangeValues = [],
}: SliderMarksProps) => {

  return (
    <>
      {rangeValues.map((value, index) => {
        return (
          <StyledSliderMark
            key={`${value}-${index}`}
            value={value}
            isWithinRange={value <= maxRange}
          />
        )
      })}
    </>
  )
})

interface StyledSliderMarkProps extends SliderMarkProps {
  isWithinRange: boolean
}

const StyledSliderMark = styled(SliderMark, {
  shouldForwardProp: (prop) => prop !== 'isWithinRange'
})<StyledSliderMarkProps>(({ theme, isWithinRange }) => css({
  width: '8px',
  height: '8px',
  borderRadius: '4px',
  backgroundColor: isWithinRange ? 'brand.500' : 'gray.900',
  transform: 'translate(-50%, -50%)'
})(theme))
