import { RJSFSchema } from '@rjsf/utils'
import { JSONSchema7 } from 'json-schema'

export function getQuestionTitles(formSchema: RJSFSchema) {
  if (formSchema.title) {
    return [formSchema.title]
  }

  const titles: string[] = []
  if (formSchema.properties) {
    for (const prop of Object.values(formSchema.properties)) {
      const p = prop as JSONSchema7
      if (p.title) {
        titles.push(p.title)
      }
    }
  }
  return titles
}
