import { Box, Flex, FlexProps, Image, Stack, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/logotype.svg'
import searchArtwork from '../../assets/images/search.png'
import { useLocalStorage } from '../../contexts'
import { prepareJobUserEvent } from '../../helpers'
import { useSendUserEvent } from '../../hooks'
import { Job, UserEventType } from '../../models'
import { analytics } from '../../services'
import { BOX_SHADOW_1 } from '../../styles'
import { CompanyLogo } from '../companies'
import { ContentContainer } from '../core'
import { AnimatedBox } from '../core/animated'

const COMPANY_LOGO_SIZE = '92px'
const DURATION = 1.5 // seconds

interface ApplicationRedirectContentProps extends FlexProps {
  job: Job
  gigsLogo?: boolean
  delay?: number // seconds
}

export const ApplicationRedirectContent = ({
  job,
  gigsLogo = true,
  children,
  delay = 0
}: ApplicationRedirectContentProps) => {
  const { t } = useTranslation()
  const applicationUrl = job.application_url!
  const [jobEventDataUtmParams] = useLocalStorage('jobEventDataUtmParams')
  const userEvent = prepareJobUserEvent(job.id, UserEventType.THIRD_PARTY_APPLY, jobEventDataUtmParams)
  const sendUserEvent = useSendUserEvent()

  useEffect(() => {
    sendUserEvent(userEvent)
    analytics.trackApply({ type: 'external', job_id: job.id })
    analytics.trackApplicationRedirect()

    const timer = setTimeout(() => {
      analytics.trackInterstitialClosed({ type: 'external', job_id: job.id })

      if (!window.__COSMOS__) {
        window.location.replace(applicationUrl)
      }
    }, (delay + DURATION) * 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <ContentContainer
      as={Flex} flex={1} flexDir="column" alignItems="center"
      maxH={800}
      textAlign="center"
    >
      {gigsLogo && (
        <Box flex={1}>
          <Image src={logo} h="40px" mt={10} mb={5} />
        </Box>
      )}

      <Stack spacing={8} w="full" alignItems="center">
        <Text>
          {t('views.jobs.applicationRedirect.heading')}
        </Text>

        <Box w={COMPANY_LOGO_SIZE} h={COMPANY_LOGO_SIZE}>
          <AnimatedBox
            pos="absolute"
            animate={{
              translateX: [-100, 50, 0],
              transition: { delay, duration: DURATION, ease: 'easeInOut' }
            }}
          >
            <Flex
              justifyContent="center" alignItems="center"
              borderRadius="full" bg="white"
              boxSize={COMPANY_LOGO_SIZE} boxShadow={BOX_SHADOW_1}
              rounded="full" p="18px"
            >
              <Image
                src={searchArtwork}
                boxSize="full"
                objectFit="contain"
                mt={-1}
              />
            </Flex>
          </AnimatedBox>

          <AnimatedBox
            pos="absolute"
            animate={{
              translateX: [100, -50, 0],
              transition: { delay, duration: DURATION, ease: 'easeInOut' }
            }}
          >
            <CompanyLogo boxSize={COMPANY_LOGO_SIZE} company={job.company} />
          </AnimatedBox>
        </Box>

        <Box
          w="full" p={5} alignItems="center"
          borderWidth={1} borderColor="gray.200" rounded="2xl"
        >
          <Text fontSize="xl" fontWeight="medium" lineHeight={1.2} mb={1}>
            {job.title}
          </Text>
          <Text>
            {job.company.name}
          </Text>
          <Text mt={1.5} fontSize="xs" color="gray.400">
            {job.region_name}
          </Text>
        </Box>
      </Stack>

      {children}
    </ContentContainer>
  )
}
