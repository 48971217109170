import { Flex, Modal, ModalContent, ModalOverlay, ModalProps, useMediaQuery } from '@chakra-ui/react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Media } from '../../../models'
import { ContentContainer, CONTENT_PADDING, DismissButton } from '../layout'
import { VideoPlayer } from './VideoPlayer'

export interface VideoPlayerModalProps extends Omit<ModalProps, 'children'> {
  media: Media
  children?: ReactNode
}

export const VideoPlayerModal = ({ media, children, onClose, ...props }: VideoPlayerModalProps) => {
  const [aspectRatio, setAspectRatio] = useState(0)
  const [isDesktop] = useMediaQuery('(min-width: 800px) and (min-height: 400px)', { ssr: false })

  const contentProps = useMemo(() => {
    if (isDesktop) {
      if (!aspectRatio) {
        return {
          rounded: '2xl',
        }
      }

      if (aspectRatio < 1) {
        return {
          w: `calc(100vh * ${aspectRatio})`,
          maxW: '420px'
        }
      } else {
        return {
          w: `calc(100vh * ${aspectRatio})`,
          maxW: '960px'
        }
      }
    } else {
      return {
        w: 'full',
        h: 'full'
      }
    }
  }, [aspectRatio, isDesktop])

  useEffect(() => {
    setAspectRatio(0)
  }, [media])

  return (
    <Modal
      size="full"
      isCentered
      preserveScrollBarGap
      onClose={onClose}
      {...props}
    >
      <ModalOverlay bg={{ base: 'black', md: 'blackAlpha.800' }} />
      <ModalContent
        w="auto" minH="auto" bg="transparent" overflow="clip"
        alignContent="center" justifyContent="center"
        {...contentProps}
      >
        <VideoPlayer
          url={media.video!}
          boxSize={!aspectRatio && isDesktop ? 300 : undefined}
          onVideoLoad={(width, height) => setAspectRatio(width / height)}
        />

        <ContentContainer
          as={Flex} justifyContent="space-between"
          pos="absolute" top={0} left={0} w="full" pt={CONTENT_PADDING}
        >
          <DismissButton
            onClick={() => onClose()}
          />
          {!!aspectRatio && children}
        </ContentContainer>
      </ModalContent>
    </Modal>
  )
}
