export const API_URL = import.meta.env.VITE_API_URL
export const APP_ENV = import.meta.env.VITE_APP_ENV as 'local' | 'development' | 'production'
export const ASSETS_URL = import.meta.env.VITE_ASSETS_URL as string
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const WEB_URL = import.meta.env.VITE_WEB_URL

export const APP_VERSION = '1.0.0'
export const SEARCH_HISTORY_LIMIT = 5

export const GOOGLE_OAUTH_URL = `${WEB_URL}/auth/google/login`

export const TERMS_OF_SERVICE_URL = '/terms-of-service'
export const PRIVACY_POLICY_URL = '/privacy-policy'

export const DEFAULT_DOCUMENT_TITLE = 'getgigs.co'
export const DEFAULT_MAX_DISTANCE = 30 // miles
export const DEFAULT_PAY_RANGE = { min: 7.25, max: 65 }
