import { Flex, FlexProps, Icon, IconButton, Text, Wrap, WrapItem, WrapProps } from '@chakra-ui/react'
import { Icon as TablerIcon, IconX } from '@tabler/icons-react'
import { forwardRef, Key, memo } from 'react'
import { useTranslation } from 'react-i18next'

export interface MultiSelectPillProps extends FlexProps {
  label: string
  icon?: TablerIcon
  deleteButton?: boolean
  onDelete?: () => void
}

const MultiSelectPillBase = forwardRef<HTMLDivElement, MultiSelectPillProps>(
  ({ label, icon, deleteButton, onDelete, ...props }, ref) => {
    const { t } = useTranslation()

    return (
      <Flex
        ref={ref}
        alignItems="center"
        w="fit-content"
        pl={2}
        pr={icon ?? deleteButton ? 0 : 2}
        py={2}
        border="1px"
        borderColor="gray.900"
        borderRadius="full"
        {...props}
      >
        {icon && (
          <Icon as={icon} ml={2} mr={2} boxSize={6} />
        )}

        <Text fontSize="sm" fontWeight={500} noOfLines={1} wordBreak="break-all">
          {label}
        </Text>

        {deleteButton && (
          <IconButton
            variant="ghost"
            size="sm"
            w={10}
            h={5}
            ml={-1}
            aria-label={t('actions.remove')}
            icon={<Icon as={IconX} boxSize={5} />}
            onClick={onDelete}
          />
        )}
      </Flex>
    )
  }
)

export const MultiSelectPill = memo(MultiSelectPillBase)

export interface MultiSelectPillListProps<TItem> extends WrapProps {
  items?: TItem[]
  itemPropsExtractor: (item: TItem, index: number) => MultiSelectPillProps & { key: Key }
  truncate?: number | boolean
}

const MultiSelectPillListBase = <TItem,>({
  items,
  itemPropsExtractor,
  truncate,
  ...props
}: MultiSelectPillListProps<TItem>) => {
  const { t } = useTranslation()
  let truncatedCount = 0
  let _items = items

  if (items && truncate != null) {
    if (typeof truncate !== 'number') {
      truncate = 6
    }
    _items = items.slice(0, truncate)
    truncatedCount = items.length - _items.length
  }

  return (
    <Wrap spacing={3} {...props}>
      {_items?.map((item, index) => {
        const { key, ...itemProps } = itemPropsExtractor(item, index)
        return (
          <WrapItem key={key}>
            <MultiSelectPill {...itemProps} />
          </WrapItem>
        )
      })}

      {!!truncatedCount && (
        <WrapItem overflow="hidden">
          <MultiSelectPill label={t('terms.plusMore', { count: truncatedCount })} />
        </WrapItem>
      )}
    </Wrap>
  )
}

export const MultiSelectPillList = memo(MultiSelectPillListBase) as typeof MultiSelectPillListBase
