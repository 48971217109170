import { Box, BoxProps, Link, Wrap } from '@chakra-ui/react'
import { ContentContainer, Pill, SocialLinks } from '../../../components/core'
import { RegionBadge } from '../../../components/RegionBadge'
import { LatLng, Location } from '../../../models'

interface LocationDetailInfoProps extends BoxProps {
  location: Location
  distanceFrom?: LatLng
}

export const LocationDetailInfo = ({
  location,
  distanceFrom,
  ...props
}: LocationDetailInfoProps) => {
  const companyUrl = location.company.url
  const { tags, social_urls } = location.company

  return (
    <ContentContainer {...props}>
      <Wrap spacingX={2.5} spacingY={2} justify="center">
        <RegionBadge record={location} distanceFrom={distanceFrom} />
        {!!tags?.length && (
          <>
            {tags.map((tag, i) => (
              <Pill key={i} multiLine borderRadius="5px">
                {tag.name}
              </Pill>
            ))}
          </>
        )}
      </Wrap>

      {companyUrl && (
        <Box mt={5} textAlign="center">
          <Link color="gray.300" href={companyUrl}
            textDecoration="underline" isExternal
            _hover={{
              color: 'gray.900'
            }}
          >
            {companyUrl}
          </Link>
        </Box>
      )}

      {!!social_urls?.length && (
        <Wrap mt={companyUrl ? 2.5 : 5} justify="center">
          <SocialLinks urls={social_urls} />
        </Wrap>
      )}
    </ContentContainer>
  )
}
