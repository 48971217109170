import { IconCircleX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { PageContainer, PageHeader } from '../../components/core'
import { GeneralSettingsItem, GeneralSettingsSection } from '../../components/settings/GeneralSettingsSection'

export const ManageAccount = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader title={t('views.settings.manageAccount.title')} />
      <PageContainer>
        {/* <GeneralSettingsSection title={t('views.settings.sectionLabels.accountInformation')}>
          <GeneralSettingsItem title={t('forms.common.fields.phoneNumber.label')} icon={IconDeviceMobile} />
          <GeneralSettingsItem title={t('forms.common.fields.email.label')} icon={IconMail} />
        </GeneralSettingsSection> */}

        <GeneralSettingsSection title={t('views.settings.sectionLabels.accountControl')}>
          <GeneralSettingsItem
            title={t('views.settings.deleteAccount.navTitle')}
            icon={IconCircleX}
            to="delete"
          />
        </GeneralSettingsSection>
      </PageContainer>
    </>
  )
}
