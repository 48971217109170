import { createSelector } from 'reselect'
import { buildWizardSteps, deriveWizardState, isRemoteOnly } from '../helpers'
import { Account, JobPreferences, RawAccount } from '../models'

export const selectAccount = createSelector(
  (rawAccount: RawAccount) => rawAccount,
  account => {
    const decorated: Account = {
      ...account,
      full_name: account.first_name && account.last_name
        ? `${account.first_name} ${account.last_name}`
        : undefined
    }
    return decorated
  }
)

export const selectBasicProfileWizardSteps = createSelector(
  (account: Account) => account.full_name,
  (account: Account) => account.contact_email,
  (account: Account) => account.contact_phone_number,
  (account: Account) => account.address,
  (account: Account) => account.city,
  (account: Account) => account.state,
  (account: Account) => account.zipcode,
  (name, contactEmail, contactPhoneNumber, address, city, state, zipcode) => {
    return buildWizardSteps({
      name,
      contact: (contactEmail && contactPhoneNumber),
      address: (address && city && state && zipcode)
    }, { nonLinear: true })
  }
)

export const selectJobPreferencesWizardState = createSelector(
  (prefs?: JobPreferences) => prefs,
  (prefs = {}) => {
    const completions = [
      ['workplace', prefs.workplaces],
      ['commute', prefs.commute_distance],
      ['schedule', prefs.schedule],
      ['industries', prefs.industries],
      ['positions', prefs.positions],
      ['experience', prefs.experience_levels],
      ['interests', prefs.interests],
      ['pay', prefs.pay],
    ]

    // Omit commute step if remote-only
    if (isRemoteOnly(prefs)) {
      const commuteStepIdx = completions.findIndex(([id]) => id === 'commute')
      completions.splice(commuteStepIdx, 1)
    }

    // Legacy interests support. Only include if they were set before depreciation.
    if (!prefs.interests) {
      const interestsStepIdx = completions.findIndex(([id]) => id === 'interests')
      completions.splice(interestsStepIdx, 1)
    }

    const steps = buildWizardSteps(completions)

    return deriveWizardState(steps)
  }
)
