export default class JSONStorage {
  store: Storage

  constructor(store = window.localStorage) {
    this.store = store
  }

  get length() {
    return this.store.length
  }

  getItem(key: string) {
    try {
      const item = this.store.getItem(key)
      return item == null ? item : JSON.parse(item)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  setItem(key: string, item: any) {
    try {
      if (item == null) {
        this.store.removeItem(key)
      } else {
        this.store.setItem(key, JSON.stringify(item))
      }
    } catch (err) {
      console.error(err)
    }
  }

  removeItem(key: string) {
    try {
      this.store.removeItem(key)
    } catch (err) {
      console.error(err)
    }
  }

  getAllKeys() {
    try {
      return this.store.getAllKeys()
    } catch (err) {
      console.error(err)
      return []
    }
  }

  clear() {
    try {
      this.store.clear()
    } catch (err) {
      console.error(err)
    }
  }

  key(index: number) {
    return this.store.key(index)
  }
}
