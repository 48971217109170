import { useModalContext } from '@chakra-ui/react'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useToast } from '../components/core'
import { useAppState, useUpdateAppState } from '../contexts'
import { getErrorMessage } from '../helpers'
import { ApiClientError } from '../lib/apiClient'
import { useDebouncedCallback, useUpdateLayoutEffect } from './util'

export function useIsMounted() {
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  return useCallback(() => mountedRef.current, [])
}

/**
 * Keeps track of window scrollY position on unmount so we can
 * restore InfiniteList scroll offset on mount.
 */
export const useScrollYTracker = () => {
  const [{ scrollYCache }, updateAppState] = useAppState()
  const location = useLocation()
  const key = useMemo(() => location.pathname, [])

  useLayoutEffect(() => {
    return () => {
      const { scrollY } = window
      updateAppState(current => ({
        scrollYCache: {
          ...current.scrollYCache,
          [key]: scrollY
        }
      }))
    }
  }, [])

  return scrollYCache[key] ?? 0
}

export const useScrollXTracker = (key?: string, element?: HTMLDivElement | null) => {
  const [{ scrollXCache }, updateAppState] = useAppState()
  const location = useLocation()
  const cacheKey = useMemo(() => `${location.pathname}:${key}`, [])

  useLayoutEffect(() => {
    if (key !== undefined && element) {
      return () => {
        const { scrollLeft } = element
        updateAppState(current => ({
          scrollXCache: {
            ...current.scrollXCache,
            [cacheKey]: scrollLeft
          }
        }))
      }
    }
  }, [key, element])

  return key !== undefined ? scrollXCache[cacheKey] ?? 0 : 0
}

export const useResetScrollXCache = () => {
  const updateAppState = useUpdateAppState()
  const { pathname } = useLocation()

  return useCallback((key?: string) => {
    if (key) {
      const cacheKey = `${pathname}:${key}`
      updateAppState(current => {
        if (current.scrollXCache[cacheKey]) {
          const updatedCache = { ...current.scrollXCache }
          delete updatedCache[cacheKey]
          return { scrollXCache: updatedCache }
        }
        return current
      })
    } else {
      updateAppState(({ scrollXCache }) => {
        const prefix = `${pathname}:`
        const updatedCache: { [key: string]: number } = {}
        for (const [cacheKey, value] of Object.entries(scrollXCache)) {
          if (!cacheKey.startsWith(prefix)) {
            updatedCache[cacheKey] = value
          }
        }
        return { scrollXCache: updatedCache }
      })
    }
  }, [pathname])
}

export function useRestoreScrollOnShow(hidden?: boolean, resetDep?: any) {
  const scrollRef = useRef([window.scrollX, window.scrollY])

  useMemo(() => {
    if (hidden) {
      scrollRef.current = [window.scrollX, window.scrollY]
    }
  }, [hidden])

  useMemo(() => {
    scrollRef.current = [window.scrollX, window.scrollY]
  }, [resetDep])

  useUpdateLayoutEffect(() => {
    if (!hidden) {
      window.scrollTo({ left: scrollRef.current[0], top: scrollRef.current[1] })
    }
  }, [hidden])
}

export interface UseErrorToastOptions {
  filter: (err: unknown) => string | boolean | undefined
  ignoreValidationError?: boolean
  ignoreUnauthorized?: boolean
}

export const useErrorToast = (initialOptions?: UseErrorToastOptions) => {
  const toast = useToast()

  return useCallback((error?: unknown, options?: UseErrorToastOptions) => {
    const {
      filter,
      ignoreValidationError,
      ignoreUnauthorized
    } = { ...initialOptions, ...options }

    if (error instanceof ApiClientError) {
      switch (error.status) {
        case 400:
        case 422:
          if (ignoreValidationError) return
          break
        case 401:
          if (ignoreUnauthorized) return
          break
      }
    }

    let message: string | undefined

    if (filter) {
      const ret = filter(error)
      if (ret === false) return
      if (typeof ret === 'string') {
        message = ret
      }
    }

    if (!message) {
      message = getErrorMessage(error)
    }

    toast({ status: 'error', title: message })
  }, [])
}

export const useWindowScrollYThresholdFlag = (offset: number) => {
  const [flag, setFlag] = useState(false)

  useLayoutEffect(() => {
    setFlag(window.scrollY > offset)
    const listener = () => {
      setFlag(window.scrollY > offset)
    }
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [offset])

  return flag
}

export function useScrollStartCallback(callback?: () => void) {
  const [scrolling, setScrolling] = useState(false)

  const scrollEndHandler = useDebouncedCallback(() => {
    setScrolling(false)
  }, [])

  const scrollHandler = useCallback(() => {
    if (!scrolling) {
      setScrolling(true)
      callback?.()
    }
    scrollEndHandler()
  }, [scrolling, callback])

  return scrollHandler
}

export function useIsInModal() {
  const resultRef = useRef<boolean>()
  if (resultRef.current === undefined) {
    try {
      resultRef.current = !!useModalContext()
    } catch { }
  }
  return resultRef.current
}
