import { JobBase } from './Job'
import { JobCategory } from './JobCategory'

export enum FeedItemType {
  JOB_CATEGORY = 'job_category',
  FEED_SETTINGS_PROMPT = 'feed_settings_prompt',
  SMS_NOTIFICATIONS_PROMPT = 'sms_notifications_prompt'
}

export interface FeedItem<T = unknown> {
  type: FeedItemType
  data: T
}

export type JobFeedItem = FeedItem<JobBase>

export type JobCategoryFeedItem = FeedItem<JobCategory>
