import { ChakraProvider } from '@chakra-ui/react'
import { Router } from '@remix-run/router'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren, useMemo } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
  AppState, AppStateProvider, FeaturesProvider, InjectedConfig, InjectedConfigProvider, LocalStorageProvider, SessionStorageProvider
} from './contexts'
import Fonts from './Fonts'
import routes from './routes'
import './setup'
import theme from './theme'

export interface AppProps extends PropsWithChildren {
  splitConfig?: SplitIO.IBrowserSettings
  queryClient?: QueryClient
  router?: Router
  injectedConfig?: InjectedConfig
  initialState?: Partial<AppState>
}

export const App = ({
  splitConfig,
  queryClient,
  router,
  injectedConfig,
  initialState,
  children
}: AppProps) => {
  const [_queryClient, _router] = useMemo(() => {
    return [
      queryClient ?? new QueryClient(),
      router ?? createBrowserRouter(routes)
    ]
  }, [])

  return (
    <SplitFactoryProvider config={splitConfig} factory={(window as any).splitFactory}>
      <FeaturesProvider>
        <ChakraProvider theme={theme} resetCSS>
          <Fonts />
          <QueryClientProvider client={_queryClient}>
            <SessionStorageProvider namespace="gigs">
              <LocalStorageProvider namespace="gigs">
                <InjectedConfigProvider value={injectedConfig}>
                  <AppStateProvider initialState={initialState}>
                    <HelmetProvider>
                      {children}
                      <RouterProvider router={_router} />
                    </HelmetProvider>
                  </AppStateProvider>
                </InjectedConfigProvider>
              </LocalStorageProvider>
            </SessionStorageProvider>
          </QueryClientProvider>
        </ChakraProvider>
      </FeaturesProvider>
    </SplitFactoryProvider>
  )
}
