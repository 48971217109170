import { useQuery } from '@tanstack/react-query'
import { api } from '../services'
import { QKey } from '../types'

export function useIndustriesQuery() {
  return useQuery([QKey.INDUSTRIES], async () => {
    const res = await api.industries.list()
    return res.data
  }, {
    cacheTime: Infinity,
    staleTime: Infinity
  })
}

export function useInterestsQuery() {
  return useQuery([QKey.INTERESTS], async () => {
    const res = await api.interests.list()
    return res.data
  }, {
    cacheTime: Infinity,
    staleTime: Infinity
  })
}

export function usePositionsQuery(params: { industries?: string[] } = {}) {
  return useQuery([QKey.POSITIONS, params], async () => {
    const res = await api.positions.list({
      industries: params.industries?.join(',')
    })
    return res.data
  }, {
    cacheTime: 60_0000,
    staleTime: Infinity
  })
}
