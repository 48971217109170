import { Box, CardProps, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import { IconBulb, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

export interface TipCardProps extends CardProps {
  message: string
  onDismiss?: () => void
}

export const TipCard = ({ message, onDismiss, ...props }: TipCardProps) => {
  const { t } = useTranslation()

  return (
    <Flex bg="yellow.200" rounded="15px" p={2} {...props}>
      <Box mr={2}>
        <Icon as={IconBulb} boxSize="36px" color="yellow.600" />
      </Box>

      <Text flex={1} fontSize={{ base: 'xs', '2xs': '2sm' }}>
        {message}
      </Text>

      <IconButton
        ml={1}
        variant="ghost"
        size="xs"
        aria-label={t('actions.dismiss')}
        onClick={onDismiss}
      >
        <Icon as={IconX} color="gray.900" boxSize={5} />
      </IconButton>
    </Flex>
  )
}
