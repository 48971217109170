import {
  useCombobox, UseComboboxProps, UseComboboxReturnValue, UseComboboxStateChange,
  useMultipleSelection, UseMultipleSelectionProps
} from 'downshift'
import { DependencyList, MutableRefObject, useCallback, useRef } from 'react'
import { useDebouncedCallback } from './util'

export interface UseEnhancedComboboxReturn<T> extends UseComboboxReturnValue<T> {
  inputRef: MutableRefObject<HTMLInputElement | undefined>
  didBlurInputRef: MutableRefObject<boolean>
  blurInput: () => void
}

export function useEnhancedCombobox<TItem>({
  stateReducer,
  onIsOpenChange,
  onStateChange,
  ...props
}: UseComboboxProps<TItem>): UseEnhancedComboboxReturn<TItem> {
  const inputRef = useRef<HTMLInputElement | undefined>(undefined)
  const didBlurInputRef = useRef(false)

  const defaultStateReducer: UseComboboxProps<TItem>['stateReducer'] = (state, { changes, type }) => {
    switch (type) {
      // Prevent menu from closing on blur.
      case useCombobox.stateChangeTypes.InputBlur:
        return didBlurInputRef.current ? state : changes
    }
    return changes
  }

  const combobox = useCombobox<TItem>({
    stateReducer: (...args) => {
      const result = defaultStateReducer(...args)
      if (stateReducer) {
        return { ...result, ...stateReducer(...args) }
      }
      didBlurInputRef.current = false
      return result
    },
    onIsOpenChange(change) {
      if (change.isOpen) {
        // Hack to fix mobile browsers moving fixed headers
        // when scrolled down.
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100)
      } else {
        // Not sure why we have to delay blurring so long.
        // Otherwise, it opens the menu again.
        setTimeout(() => {
          inputRef.current?.blur()
        }, 200)
      }

      onIsOpenChange?.(change)
    },
    onStateChange(change) {
      switch (change.type) {
        case useCombobox.stateChangeTypes.InputChange:
          combobox.setInputValue(change.inputValue!.trimStart())
          break
      }
      onStateChange?.(change)
    },
    ...props
  })

  const { isOpen, getInputProps } = combobox

  combobox.getInputProps = (options: any) => {
    const props = getInputProps({
      // Fix tapping between multiple inputs on iOS Safari.
      onTouchStart(e) {
        e.currentTarget.focus()
      },
      ...options
    })
    const { ref } = props
    props.ref = (el?: HTMLInputElement) => {
      inputRef.current = el
      ref(el)
    }
    return props
  }

  const blurInput = useCallback(() => {
    if (isOpen) {
      didBlurInputRef.current = true
      inputRef.current?.blur()
    }
  }, [isOpen])

  return {
    ...combobox,
    inputRef,
    blurInput,
    didBlurInputRef
  }
}

export function useMultiSelectionCombobox<TItem>(props: {
  itemToKey: (item: TItem) => string | number
} & UseComboboxProps<TItem> & UseMultipleSelectionProps<TItem>) {
  const { itemToKey } = props

  const multiSelection = useMultipleSelection({
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          multiSelection.setSelectedItems(newSelectedItems ?? [])
          break
        default:
          break
      }
    },
    ...props
  })

  const findSelectedItem = (key: number | string) => {
    return multiSelection.selectedItems.find(item => {
      return itemToKey(item) === key
    })
  }

  const combobox = useEnhancedCombobox<TItem>({
    selectedItem: null,
    stateReducer: (state, { changes, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
        case useCombobox.stateChangeTypes.ItemClick:
          if (type === useCombobox.stateChangeTypes.InputBlur && didBlurInputRef.current) {
            return {
              ...changes,
              isOpen: true,
              highlightedIndex: 0,
              inputValue: state.inputValue
            }
          } else {
            return {
              ...changes,
              ...(changes.selectedItem && { isOpen: true, highlightedIndex: 0 }),
              inputValue: state.inputValue
            }
          }
        default:
          return changes
      }
    },
    onStateChange({ type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (!findSelectedItem(itemToKey(selectedItem))) {
              multiSelection.addSelectedItem(selectedItem)
            } else {
              multiSelection.removeSelectedItem(selectedItem)
            }
          }
          break
      }
    },
    ...props
  })

  const { didBlurInputRef } = combobox

  return {
    ...multiSelection,
    ...combobox,
    findSelectedItem
  }
}

export function useAsyncComboboxQueryCallback(
  callback: (value: string) => void,
  deps?: DependencyList,
  options?: { minQueryLength?: number, debounce?: number }
) {
  const { minQueryLength = 2, debounce = 300 } = options ?? {}
  const debouncedCallback = useDebouncedCallback(callback, deps ?? [callback], debounce)

  return ({ type, inputValue }: UseComboboxStateChange<any>) => {
    if (type === useCombobox.stateChangeTypes.InputChange) {
      if (inputValue && inputValue.length >= minQueryLength) {
        debouncedCallback(inputValue)
      } else {
        debouncedCallback.cancel()
        callback('')
      }
    }
  }
}
