import { IconArticle, IconCalendar, IconCalendarEvent, IconCalendarQuestion, IconCalendarStats, IconMoonStars, IconSeeding, IconSun } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { JobScheduleType } from '../../models'
import { Pill, PillProps } from '../core'

const ICONS = {
  [JobScheduleType.FULL_TIME]: IconCalendar,
  [JobScheduleType.PART_TIME]: IconCalendarStats,
  [JobScheduleType.SEASONAL]: IconSeeding,
  [JobScheduleType.TEMPORARY]: IconCalendarEvent,
  [JobScheduleType.FLEXIBLE]: IconCalendarQuestion,
  [JobScheduleType.WEEKDAYS]: IconSun,
  [JobScheduleType.WEEKNIGHTS]: IconMoonStars,
  [JobScheduleType.WEEKEND_DAYS]: IconSun,
  [JobScheduleType.WEEKEND_NIGHTS]: IconMoonStars,
  [JobScheduleType.CONTRACT]: IconArticle,
  [JobScheduleType.PER_DIEM]: IconCalendar
}

interface SchedulePillProps extends PillProps {
  scheduleType: JobScheduleType
  showIcon?: boolean
}

export const SchedulePill = memo(({ scheduleType, showIcon = true, ...props }: SchedulePillProps) => {
  const { t } = useTranslation()
  const icon = ICONS[scheduleType]
  if (!icon) return null

  return (
    <Pill icon={showIcon ? icon : undefined} multiLine {...props}>
      {t(`views.jobs.schedule.${scheduleType}`)}
    </Pill>
  )
})
