const MODULES = {
  gamify: () => import('../modules/gamify'),
  profile: () => import('../modules/profile'),
}

type ModuleLoaders = typeof MODULES

interface ModuleLoader {
  status?: 'success' | 'error'
  suspender?: Promise<any>
  result?: any
}

const loaderCache: { [key: string]: ModuleLoader } = {}

export function useModule<T extends keyof ModuleLoaders>(name: T): Awaited<ReturnType<ModuleLoaders[T]>> {
  let loader = loaderCache[name]

  if (!loader) {
    loader = {}
    loader.suspender = (async () => {
      try {
        loader.result = await MODULES[name]()
        loader.status = 'success'
      } catch (e) {
        loader.result = e
        loader.status = 'error'
      }
    })()

    loaderCache[name] = loader
  }

  if (!loader.status) {
    throw loader.suspender
  } else if (loader.status === 'error') {
    throw loader.result
  }

  return loader.result
}
