import { BoxProps, Card, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import whatToExpectImg from '../../../assets/images/what-to-expect.png'
import { ContentContainer, Pill } from '../../../components/core'
import { Tag } from '../../../models'
import { JobImageHeader } from '../common'

export interface PositionTagsSectionProps extends BoxProps {
  items: Tag[]
}

export const PositionTagsSection = ({
  items,
  ...props
}: PositionTagsSectionProps) => {
  const { t } = useTranslation()

  return (
    <ContentContainer>
      <Card p={5} {...props}>
        <JobImageHeader
          mb={2.5}
          title={t('views.jobs.whatToExpect')}
          src={whatToExpectImg}
          alt={t('images.whatToExpect')}
          h={{ base: '35px', '2xs': '60px' }}
        />
        <Flex direction="row" wrap="wrap">
          {items?.map((item) => (
            <Pill mt={2.5} ml={0} mr={2.5} key={item.name} icon={item.icon} size="lg">
              {item.name}
            </Pill>
          ))}
        </Flex>
      </Card>
    </ContentContainer>
  )
}
