import { cssVar, defineStyleConfig } from '@chakra-ui/react'

// Adjust the arrow color
// Reference: https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $arrowBg = cssVar('popper-arrow-bg')

export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    fontSize: 'xs',
    fontWeight: 'medium',
    textAlign: 'center',
    color: 'white',
    bgColor: 'gray.900',
    [$arrowBg.variable]: '#111111',
    px: 2.5,
    py: 3,
    mt: 1,
    minW: 14,
    borderRadius: 5
  },
  variants: {
    embedded: {
      zIndex: 0
    }
  }
})
