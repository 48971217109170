import { Box, BoxProps, Divider, Flex, HStack, StackDivider, Text, useTheme } from '@chakra-ui/react'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { AuthPanel } from '../components/auth/AuthPanel'
import { ContentContainer, HeaderLogo, MIN_PAGE_WIDTH } from '../components/core'
import { NavDrawer, PublicLink, PUBLIC_LINKS, SidebarNav } from '../components/navigation'
import { LayoutProvider, useAppState, useLayoutContext, useSessionStorage, useSessionStorageSetter } from '../contexts'
import { useAccountQuery } from '../hooks'
import { AuthMethod } from '../types'


export const MainLayout = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const theme = useTheme()
  const breakpoints = theme.__breakpoints!.asObject
  const { data: account } = useAccountQuery()

  const setAuthZipcode = useSessionStorageSetter('authZipcode')
  const [postAuthRedirect, setPostAuthRedirect] = useSessionStorage('postAuthRedirect')

  const layoutValues = useMemo(() => ({
    pageLeft: {
      base: 0,
      sm: `calc(50% - (${breakpoints.sm} / 2))`,
      md: `calc(50% - (${breakpoints.md} / 2) + (${breakpoints.md} - ${breakpoints.sm}))`,
      lg: `calc(50% - (${breakpoints.lg} / 2) + (${breakpoints.lg} - ${breakpoints.sm}))`
    },
    pageWidth: {
      base: '100%',
      sm: breakpoints.sm
    },
    hasSidebar: true
  }), [breakpoints])

  const redirectToRegister = account && !account.registration_completed_at

  useEffect(() => {
    if (redirectToRegister) {
      const authZipcode = searchParams.get('auth_zip')
      if (authZipcode) {
        setAuthZipcode(authZipcode)
      }

      if (!postAuthRedirect) {
        const redirect = location.pathname + location.hash + location.search
        setPostAuthRedirect(redirect)
      }
    }
  }, [])

  if (redirectToRegister) {
    return <Navigate replace to="/register" />
  }

  const fixedProps = { pos: 'fixed', left: 0, right: 0, minWidth: MIN_PAGE_WIDTH, zIndex: 'sticky' } as BoxProps

  return (
    <LayoutProvider value={layoutValues}>
      <Scaffold {...fixedProps} top={0} zIndex={-1}>
        <Box minH="100vh" outline="1px solid" outlineColor="gray.200" />
      </Scaffold>

      <Scaffold
        {...fixedProps} top={0}
        leftContent={<Sidebar display={{ base: 'none', md: 'block' }} />}>
      </Scaffold>

      <Scaffold minH="100%" contentProps={{
        as: Flex,
        flexDir: 'column',
        minH: '100%'
      }}>
        <Outlet />
      </Scaffold>

      <NavDrawer />
    </LayoutProvider>
  )
}


const SIDEBAR_LINKS: Exclude<typeof PUBLIC_LINKS, 'forJobSeekers' | 'forEmployers'> = [
  'browseJobs',
  'about',
  'careers',
  'contact',
  'termsOfService',
  'privacyPolicy'
]

const Sidebar = (props: BoxProps) => {
  const { t } = useTranslation()
  const [{ authenticated }] = useAppState()
  const { pageHeaderHeight } = useLayoutContext()

  return (
    <ContentContainer {...props}>
      <Flex alignItems="center" h={`${pageHeaderHeight}px`} mb="10px">
        <HeaderLogo />
      </Flex>

      {authenticated ? (
        <SidebarNav mb={5} />
      ) : (
        <Box>
          <Text mb={5} fontSize="2xl" fontWeight="medium" lineHeight={1.4}>
            {t('views.auth.signUp.title.default')}
          </Text>

          <AuthPanel
            variant="sidebar"
            method={AuthMethod.SIGN_UP}
            buttonProps={{ variant: 'tertiary' }}
            mb={2}
          />

          <Divider mb={3.5} />
        </Box>
      )}

      <HStack
        color="gray.300"
        spacing={2.5}
        divider={<StackDivider borderColor="gray.300" h="13px" alignSelf="center" />}
        wrap="wrap"
      >
        {SIDEBAR_LINKS.map(name => (
          <PublicLink key={name} name={name} fontSize="2sm" />
        ))}
      </HStack>
    </ContentContainer>
  )
}

interface ScaffoldProps extends BoxProps {
  leftContent?: ReactNode
  contentProps?: BoxProps
}

const Scaffold = ({ children, leftContent, contentProps, ...props }: ScaffoldProps) => {
  const theme = useTheme()
  const breakpoints = theme.__breakpoints!.asObject

  return (
    <Flex mx="auto" w={{ base: '100%', md: breakpoints.md, lg: breakpoints.lg }} {...props}>
      <LeftContainer />
      {leftContent && (
        <LeftContainer position="absolute">
          {leftContent}
        </LeftContainer>
      )}
      {children && (
        <Box mx="auto" pos="relative" minW={MIN_PAGE_WIDTH} maxW={breakpoints.sm} flex={1} {...contentProps}>
          {children}
        </Box>
      )}
    </Flex>
  )
}

const LeftContainer = (props: BoxProps) => {
  const { breakpointValues } = useLayoutContext()
  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      width={{
        md: breakpointValues.md - breakpointValues.sm,
        lg: breakpointValues.lg - breakpointValues.sm
      }}
      {...props}
    />
  )
}
