import { Box, Flex } from '@chakra-ui/react'
import { UseQueryResult } from '@tanstack/react-query'
import { memo, ReactNode } from 'react'
import { ErrorNotice, ErrorNoticeProps, LoadingNotice, LoadingNoticeProps, NoResultsNotice } from './notices'
import { Overlay, OverlayProps } from './Overlay'

export interface StatusOverlayProps extends Omit<OverlayProps, 'children'>, ErrorNoticeProps {
  query?: UseQueryResult
  data?: any
  hasResults?: boolean
  isLoading?: boolean
  isFetching?: boolean
  isFetched?: boolean
  loadingText?: string
  indicatorColor?: LoadingNoticeProps['indicatorColor']
  indicatorSize?: LoadingNoticeProps['indicatorSize']
  noResultsText?: string
  renderLoading?: (() => ReactNode | undefined) | null
  renderNoResults?: (() => ReactNode | undefined) | null
  renderError?: ((error?: unknown) => ReactNode | undefined) | null
}

export const StatusOverlay = memo(({
  query,
  data = query?.data,
  hasResults,
  isLoading = !!query?.isLoading,
  isFetching = !!query?.isFetching,
  isFetched = !!query?.isFetched,
  error = query?.error,
  loadingText,
  indicatorColor,
  indicatorSize,
  noResultsText,
  renderLoading,
  renderNoResults,
  renderError,
  visible,
  onRefetch,
  ...props
}: StatusOverlayProps) => {
  if (hasResults === undefined) {
    if (data) {
      if (Array.isArray(data)) {
        hasResults = !!data.length
      } else if (Array.isArray(data.pages)) {
        hasResults = !!data?.pages[0]?.data.length
      }
    }
  }

  const isNoResults = isFetched && !isFetching && !error && hasResults === false
  const isError = (!data && !!error)

  if (visible === undefined) {
    visible = (isLoading && renderLoading !== null) || (isNoResults && renderNoResults !== null) || (isError && renderError !== null)
  }

  return (
    <Overlay visible={visible} overflow="hidden" {...props}>
      {() => (
        <Box pos="relative" width="100%" height="100%">
          {renderLoading !== null && (
            <Overlay display="block" visible={isLoading} bg="transparent">
              {() => (
                renderLoading ? renderLoading() : (
                  <Flex h="100%" alignItems="center" justifyContent="center">
                    <LoadingNotice
                      indicatorColor={indicatorColor}
                      indicatorSize={indicatorSize}
                      title={loadingText}
                    />
                  </Flex>
                )
              )}
            </Overlay>
          )}
          {renderNoResults !== null && (
            <Overlay visible={isNoResults} centeredContent bg="transparent">
              {() => (
                renderNoResults ? renderNoResults() : (
                  <NoResultsNotice title={noResultsText} />
                )
              )}
            </Overlay>
          )}
          {renderError !== null && (
            <Overlay visible={isError && !isLoading} centeredContent bg="transparent">
              {() => (
                renderError?.(error) ?? (
                  <ErrorNotice
                    error={error && !isLoading ? error : undefined}
                    isFetching={isFetching}
                    onRefetch={onRefetch ?? query?.refetch}
                  />
                )
              )}
            </Overlay>
          )}
        </Box>
      )}
    </Overlay>
  )
})
