import { Button } from '@chakra-ui/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import artworkSrc from '../../assets/images/mountain-with-flag.png'
import { AnalyticsParamsProvider, useAnalyticsParams } from '../../contexts'
import { analytics } from '../../services'
import { HighlightCard } from '../core'

export const MATCH_PREFERENCES_CARD_HEIGHT = 208

export const MatchPreferencesCard = memo(() => {
  const { t } = useTranslation()
  const analyticsParams = useAnalyticsParams()

  return (
    <AnalyticsParamsProvider source="Feature card">
      <HighlightCard
        title={t('views.matchPreferencesCard.title')}
        body={t('views.matchPreferencesCard.body')}
        imageSrc={artworkSrc}
        imageAlt={t('images.mountainWithFlag')}
        imageWidth={136}
        imageHeight={122}
        bg="#FFE9Eb"
        border="1px solid"
        borderColor="#FF3634"
      >
        <Button
          as={Link} to="/match-preferences"
          variant="secondary"
          onClick={() => analytics.trackTakeTheJobQuizClick(analyticsParams)}
        >
          {t('views.matchPreferencesCard.action')}
        </Button>
      </HighlightCard>
    </AnalyticsParamsProvider>
  )
})
