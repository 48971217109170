import { extendTheme } from '@chakra-ui/react'
import { alertTheme } from './alert'
import { badgeTheme } from './badge'
import { buttonTheme } from './button'
import { cardTheme } from './card'
import { checkboxTheme } from './checkbox'
import { formTheme } from './formControl'
import { formErrorTheme } from './formError'
import { inputTheme } from './input'
import { menuTheme } from './menu'
import { radioTheme } from './radio'
import { selectTheme } from './select'
import { sliderTheme } from './slider'
import { switchTheme } from './switch'
import { tabsTheme } from './tabs'
import { textareaTheme } from './textarea'
import { tooltipTheme } from './tooltip'

const theme = extendTheme({
  styles: {
    global: {
      'html, body, #root': {
        h: '100%',
        minH: '100%',
      },
      body: {
        minW: '300px',
        overflowY: 'scroll'
      },
      '#root': {
        whiteSpace: 'pre-line'
      },
      'p > a': {
        _hover: {
          textDecoration: 'underline'
        }
      },
      button: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      input: {
        whiteSpace: 'normal'
      },
      'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration': {
        'WebkitAppearance': 'none'
      },
      'input.hide-clear[type=search]::-ms-clear, input.hide-clear[type=search]::-ms-reveal': {
        display: 'none',
        width: 0,
        height: 0
      }
    },
  },
  breakpoints: {
    base: '0px',
    '2xs': '360px',
    xs: '400px',
    sm: '510px',
    md: '800px',
    lg: '880px',
    xl: '1200px',
    '2xl': '1200px'
  },
  colors: {
    gray: {
      100: '#F6F8FB',
      150: '#E5E7EA',
      200: '#DBDBDC',
      250: '#AEAEAE',
      300: '#888888',
      400: '#4F4F4F',
      900: '#111111'
    },
    brand: {
      100: '#E2F3EC',
      500: '#00B691'
    },
    pink: {
      300: '#FDABAC'
    },
    blue: {
      100: '#E1F1FF',
      200: '#ABD7FF',
      300: '#79BBF8'
    },
    green: {
      100: '#E2F3EC',
      200: '#BBE4D9',
      250: '#9ED6C1',
      300: '#50A99A',
      700: '#019E70',
      800: '#147A59'
    },
    yellow: {
      200: '#FFE5B4',
      300: '#FFE279',
      600: '#E1A73B'
    }
  },
  components: {
    Alert: alertTheme,
    Badge: badgeTheme,
    Button: buttonTheme,
    Card: cardTheme,
    Checkbox: checkboxTheme,
    Form: formTheme,
    FormError: formErrorTheme,
    Input: inputTheme,
    Menu: menuTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Slider: sliderTheme,
    Switch: switchTheme,
    Tabs: tabsTheme,
    Textarea: textareaTheme,
    Tooltip: tooltipTheme
  },
  fonts: {
    heading: '\'The Future\', sans-serif',
    body: '\'The Future\', sans-serif',
  },
  fontSizes: {
    '2sm': '13px'
  }
})

export default theme
