import { JobScheduleType } from '../../models'

export const DISPLAYED_SCHEDULE_TYPES = [
  JobScheduleType.FULL_TIME,
  JobScheduleType.PART_TIME,
  JobScheduleType.SEASONAL,
  JobScheduleType.TEMPORARY,
  JobScheduleType.FLEXIBLE,
  JobScheduleType.CONTRACT,
  JobScheduleType.PER_DIEM
]
