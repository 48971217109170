import { menuAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle
} from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  list: defineStyle({
    py: 1.5
  }),
  item: defineStyle({
    pr: 4,
    py: '5px',
    fontSize: 'sm',
    lineHeight: 1.4,
    '.chakra-menu__icon-wrapper': {
      mr: 2
    }
  })
})

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
})
