import { Link, Stack, StackProps } from '@chakra-ui/react'
import { memo } from 'react'
import { analytics } from '../../services'
import { SocialIcon } from './SocialIcon'

const SUPPORTED_SOCIAL_PLATFORMS = [
  'facebook',
  'instagram',
  'linkedin',
  'reddit',
  'tiktok',
  'twitter',
  'x',
  'youtube',
]

interface SocialLinksProps extends StackProps {
  urls: string[]
  iconSize?: StackProps['boxSize']
}

export const SocialLinks = memo(({
  urls,
  direction = 'row',
  iconSize = 7,
  ...props
}: SocialLinksProps) => {
  return (
    <Stack
      direction={direction}
      spacing={2}
      color="gray.300"
      {...props}
    >
      {urls.map((url, i) => {
        const platform = detectSocialPlatform(url)
        if (!platform) return null

        return (
          <Link
            key={i} href={url} isExternal
            aria-label={platform}
            _hover={{
              color: 'gray.900'
            }}
            onClick={() => {
              analytics.trackSocialClick({ type: platform })
            }}
          >
            <SocialIcon platform={platform} boxSize={iconSize} />
          </Link>
        )
      })}
    </Stack>
  )
})

function detectSocialPlatform(url: string) {
  try {
    const { hostname } = new URL(url)
    const domainParts = hostname.split('.')
    const platform = domainParts[domainParts.length - 2].toLowerCase()
    if (SUPPORTED_SOCIAL_PLATFORMS.includes(platform)) {
      return platform
    }
  } catch (err) {}
}
