import { Input, InputProps } from '@chakra-ui/react'
import { ChangeEventHandler, forwardRef, memo, useCallback, useRef } from 'react'

const PRECISION_REGEXPS = {
  1: /^(\d*\.?\d{0,1}).*$/,
  2: /^(\d*\.?\d{0,2}).*$/,
  3: /^(\d*\.?\d{0,3}).*$/
}

export interface NumericInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  value?: string | number
  precision?: 0 | 1 | 2 | 3
  prefix?: string
  onValueChange: (value: string) => void
}

export const NumericInput = memo(forwardRef<HTMLInputElement, NumericInputProps>(({
  value = '',
  precision,
  prefix,
  onValueChange,
  ...props
}, ref) => {
  const valueRef = useRef(value)
  valueRef.current = value

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    const inputValue = e.target.value
    let parsedValue: string

    if (precision) {
      parsedValue = parseDecimal(inputValue)
        .replace(PRECISION_REGEXPS[precision], '$1')
    } else {
      parsedValue = inputValue.replace(/[^0-9]/g, '')
    }

    if (parsedValue !== valueRef.current) {
      onValueChange(parsedValue)
    }
  }, [precision, onValueChange])

  const formattedValue = prefix && value !== undefined && value !== ''
    ? `${prefix}${value}`
    : value

  return (
    <Input
      ref={ref}
      value={formattedValue}
      type="text"
      inputMode="decimal"
      autoComplete="off"
      autoCorrect="off"
      minLength={1}
      maxLength={79}
      spellCheck="false"
      onChange={handleChange}
      {...props}
    />
  )
}))

const parseDecimal = (value: string) => {
  return value.replace(/[^0-9.]/g, '') // Remove all non-numeric except decimal point
    .replace(/^0+(?=\d)/, '') // Remove leading zeros
    .replace(/^\./, '0.') // Add 0 before leading decimal point
}
