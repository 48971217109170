import { UseQueryResult } from '@tanstack/react-query'
import { useEffect, useLayoutEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { WEB_URL } from '../../../config'
import { useAnalyticsParams, useAppState, useLocalStorage, useLocalStorageSetter, useModalManager, useSessionStorageSetter } from '../../../contexts'
import { prepareJobUserEvent, prepareJobUserEventParams } from '../../../helpers'
import {
  useApplicationQuery, useCreateApplication, useLocalJobState,
  useRouteAction, useSendUserEvent, useSetFavoriteJob
} from '../../../hooks'
import {
  ApplicationStatus,
  Job, UserEventType,
  UtmParams
} from '../../../models'
import { analytics } from '../../../services'
import { FinishApplicationDialog } from '../partials/FinishApplicationDialog'

interface JobDetailLoadedProps {
  pageType: string
  job: Job
  jobQuery: UseQueryResult<Job>
  eventParams?: UtmParams
  jobViewFeature?: string
  referrer?: string
  source?: string
}

export const useJobDetailLoaded = ({
  pageType,
  job,
  jobQuery,
  eventParams,
  jobViewFeature,
  referrer,
  source,
}: JobDetailLoadedProps) => {
  const navigate = useNavigate()
  const [{ authenticated }] = useAppState()
  const setAuthZipcode = useSessionStorageSetter('authZipcode')
  const modalManager = useModalManager()
  const sendUserEvent = useSendUserEvent()
  const [{ isFinishApplicationDialogViewed }, updateLocalState] = useLocalJobState(job.id)
  const setFavoriteJob = useSetFavoriteJob(job.id)
  const routeAction = useRouteAction(job.slug)
  const [eventParamsFromLocalStorage, setUserEventParams] = useLocalStorage('jobEventDataUtmParams')
  const setLastViewedJobSlug = useLocalStorageSetter('lastViewedJobSlug')
  const setFallbackCoordinates = useLocalStorageSetter('fallbackCoordinates')

  const userEventParams = prepareJobUserEventParams(job, eventParamsFromLocalStorage, eventParams)
  const applicationCreate = useCreateApplication(job.id, userEventParams)
  const createdApplication = useApplicationQuery(applicationCreate.data?.id).data
  const analyticsParams = useAnalyticsParams()

  const hasDraftApplication = job.application?.status === ApplicationStatus.DRAFT

  useLayoutEffect(() => {
    updateLocalState({ isViewed: true })

    const userEventJobView = prepareJobUserEvent(job.id, UserEventType.JOB_VIEW, userEventParams)
    if (authenticated || userEventJobView.data?.utm_source != null) {
      if (jobViewFeature) {
        userEventJobView.data = { ...userEventJobView.data, feature: jobViewFeature }
      }
      sendUserEvent(userEventJobView)
    }

    analytics.trackJobView(job, {
      source: source ?? referrer,
      pageType
    })

    setUserEventParams(userEventParams)
    setLastViewedJobSlug(job.slug)
    setFallbackCoordinates(job.coordinate)

    if (!authenticated) {
      setAuthZipcode(job.zipcode)
    } else {
      switch (routeAction) {
        case 'apply': {
          const { application } = job

          const trackStart = () => {
            if (referrer) {
              analytics.trackApplyStart({ job, page: referrer, source })
            }
          }

          const trackResume = () => {
            if (referrer) {
              analytics.trackApplyResume({ job, page: referrer, source })
            }
          }

          if (job.application_url) {
            if (job.has_prescreeners) {
              if (!application) {
                applicationCreate.mutate()
                trackStart()
              } else if (application.status === ApplicationStatus.DRAFT) {
                navigate(`/applications/${application.id}`, { replace: true })
                trackResume()
              } else {
                navigate(`/jobs/${job.slug}/application-redirect`, { replace: true })
                trackResume()
              }
            } else {
              navigate(`/jobs/${job.slug}/application-redirect`, { replace: true })
              trackStart()
            }
          } else {
            if (!application) {
              applicationCreate.mutate()
              trackStart()
            } else if (application.status === ApplicationStatus.DRAFT) {
              navigate(`/applications/${application.id}`, { replace: true })
              trackResume()
            }
          }
          break
        }
        case 'favorite': {
          setFavoriteJob.mutate(true)
          break
        }
      }
    }
  }, [])

  useEffect(() => {
    if (createdApplication) {
      navigate(`/applications/${createdApplication.id}`, { replace: true })
    }
  }, [createdApplication])

  useEffect(() => {
    if (
      !jobQuery.isFetching &&
      hasDraftApplication &&
      !createdApplication &&
      !isFinishApplicationDialogViewed
    ) {
      modalManager.open(FinishApplicationDialog, { job, analyticsParams })
      updateLocalState({ isFinishApplicationDialogViewed: true })
    }
  }, [jobQuery.isFetching])

  return useMemo(() => {
    return {
      job,
      applicationCreate,
      pageTitle: `${job.company.name} - ${job.title}`,
      canonicalUrl: `${WEB_URL}/jobs/${job.slug}`
    }
  }, [job, applicationCreate])
}
