import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAppState } from '../../contexts'
import { NoResultsNotice, NoResultsNoticeProps } from '../core'

export const NoJobResultsNotice = ({
  title,
  message,
  button,
  ...props
}: NoResultsNoticeProps) => {
  const { t } = useTranslation()
  const [{ authenticated }] = useAppState()

  return (
    <NoResultsNotice
      title={title ?? t('views.jobs.noResultsNotice.title')}
      message={message ?? t('views.jobs.noResultsNotice.message')}
      button={button ?? {
        as: Link,
        to: authenticated ? '/' : '/search',
        children: t('actions.seeMoreGigs')
      }}
      {...props}
    />
  )
}
