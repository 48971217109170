import { Box, BoxProps, Button, Flex, Icon, IconButton, Menu, MenuButton, MenuList, Skeleton, Text } from '@chakra-ui/react'
import { IconDots } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, To } from 'react-router-dom'
import { useAnalyticsParams } from '../../../contexts'
import { useFormatters } from '../../../hooks'
import { analytics } from '../../../services'
import { BOX_SHADOW_1 } from '../../../styles'
import { HeaderTitle } from './common'
import { CONTENT_PADDING } from './containers'

export interface SectionHeaderProps extends Omit<BoxProps, 'title'> {
  title?: ReactNode
  subtitle?: ReactNode
  linkTo?: To
  rel?: string
  menu?: ReactNode
  menuItems?: ReactNode
  isLoading?: boolean
  total?: number | false
}

export const SectionHeader = ({
  title,
  subtitle,
  linkTo,
  rel,
  menu,
  menuItems,
  isLoading,
  total,
  ...props
}: SectionHeaderProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const { page, source } = useAnalyticsParams()

  return (
    <Box minH="50px" px={CONTENT_PADDING} mb={5} {...props}>
      <Skeleton minH="40px" rounded="lg" isLoaded={!isLoading}>
        <Box pb={2.5} borderBottom="1px" borderColor="gray.150">
          <Flex h="40px" alignItems="flex-end">
            {title && (
              <HeaderTitle flex={1} pb={0.5}>
                {title}

                {!!total && (
                  <Text as="span" color="gray.300" fontWeight="normal">
                    {' '}({fmt.number(total)})
                  </Text>
                )}
              </HeaderTitle>
            )}

            {linkTo && (
              <Button
                as={Link} to={linkTo} rel={rel}
                size="md" px="8px" ml={1}
                fontSize="xs" fontWeight="medium"
                onClick={() => analytics.trackSeeAll({ page, source })}
              >
                {t('actions.seeAll')}
              </Button>
            )}

            {menu ?? (menuItems && (
              <Menu isLazy placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  icon={<Icon as={IconDots} boxSize={6} color="gray.900" />}
                  aria-label={t('actions.openMenu')}
                  size="sm"
                  boxSize="38px"
                  rounded="full"
                />
                <MenuList boxShadow={BOX_SHADOW_1} borderRadius={10} zIndex="dropdown">
                  {menuItems}
                </MenuList>
              </Menu>
            ))}
          </Flex>

          {subtitle && (
            <Text fontSize="11px" color="gray.300">
              {subtitle}
            </Text>
          )}
        </Box>
      </Skeleton>
    </Box>
  )
}
