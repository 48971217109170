import { Badge, FlexProps, HStack, Text } from '@chakra-ui/react'
import { Link, To } from 'react-router-dom'
import { distanceBetween } from '../helpers'
import { useFormatters } from '../hooks'
import { LatLng } from '../models'

export interface RegionBadgeProps extends FlexProps {
  record: {
    region_name?: string | null
    coordinate?: LatLng | null
  }
  size?: 'sm' | 'md'
  distanceFrom?: LatLng
  linkTo?: To
}

export const RegionBadge = ({
  record,
  size = 'md',
  distanceFrom,
  linkTo,
  ...props
}: RegionBadgeProps) => {
  const fmt = useFormatters()

  if (!record.region_name) {
    return null
  }

  return (
    <Badge
      variant="primary" size={size} w="fit-content"
      {...(linkTo && { as: Link, to: linkTo })}
      {...props}
    >
      <HStack spacing={1}>
        <Text wordBreak="break-all" noOfLines={1}>
          {record.region_name}
        </Text>

        {distanceFrom && record.coordinate && (
          <Text flexShrink={0} fontWeight="normal">
            {`(${fmt.distance(
              distanceBetween(distanceFrom, record.coordinate),
              {
                display: 'short',
              }
            )})`}
          </Text>
        )}
      </HStack>
    </Badge>
  )
}
