import { FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { AnalyticsParamsProvider } from '../../../contexts'
import { AnalyticsName } from '../../../services/analytics'
import { PageContainer } from './containers'
import { PageHeader, PageHeaderProps } from './PageHeader'

export interface SimplePageProps extends Omit<FlexProps, 'title'>,
  Pick<PageHeaderProps, 'title' | 'logo' | 'backButton' | 'menu' | 'onBackButtonClick'> {
  headerVariant?: PageHeaderProps['variant']
  aName?: AnalyticsName
  children?: ReactNode
}

export const SimplePage = ({
  title,
  headerVariant,
  logo = false,
  backButton,
  menu = true,
  aName,
  children,
  onBackButtonClick,
  ...props
}: SimplePageProps) => {
  return (
    <AnalyticsParamsProvider page={aName ?? title}>
      <PageContainer px={0} {...props}>
        <PageHeader
          variant={headerVariant}
          title={title}
          logo={logo}
          backButton={backButton}
          menu={menu}
          onBackButtonClick={onBackButtonClick}
        />
        {children}
      </PageContainer>
    </AnalyticsParamsProvider>
  )
}
