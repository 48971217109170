import { AnimatePresence, AnimatePresenceProps, motion } from 'framer-motion'
import { Key, PropsWithChildren } from 'react'

export interface PageTransitionProps extends PropsWithChildren<AnimatePresenceProps> {
  currentKey?: Key | null
}

export const PageTransition = ({ currentKey, children, ...props }: PageTransitionProps) => (
  <AnimatePresence mode="wait" initial={false} {...props}>
    <motion.div
      key={currentKey}
      style={{ width: '100%' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
)
