import { Box, BoxProps, Button, Card, Flex, HStack, Image, Skeleton, Stack, Text } from '@chakra-ui/react'
import { isValidElement, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import mountainSrc from '../../../assets/images/mountain-with-flag.png'
import searchSrc from '../../../assets/images/search.png'
import { CompanyLogo } from '../../../components/companies'
import { JobCompanyLogoStack } from '../../../components/companies/CompanyLogoStack'
import { ContentContainer, LinkCard } from '../../../components/core'
import { AnalyticsParamsProvider, useAnalyticsParams } from '../../../contexts'
import { isRemoteOnly } from '../../../helpers'
import { useBetterJobsQuery, useDirectionsQuery, useFormatters, useGeoCoordinates, useHourlyPayValues, useInfiniteQueryTotalLabel, useJobPreferences, useJobPreferencesWizardCompleted, useRouteStats } from '../../../hooks'
import { Company, Job, JobPreferences, PaySourceType } from '../../../models'
import { analytics } from '../../../services'
import { ComparisonPill } from './ComparisonPill'

export interface ComparisonSectionProps {
  job: Job
  showPrompt: boolean
}

interface ComparisonItem {
  label: string
  prefValue: ReactNode
  jobValue?: string
  jobValueLoading?: boolean
  meetsGoal?: boolean
}

export const ComparisonSection = memo(({ job, showPrompt }: ComparisonSectionProps) => {
  const jobPreferences = useJobPreferences()

  return (
    <AnalyticsParamsProvider source="Job comparison card">
      {useJobPreferencesWizardCompleted() ? (
        <ContentContainer>
          <ComparisonSummaryCard job={job} jobPreferences={jobPreferences!} />
        </ContentContainer>
      ) : showPrompt && (
        <ContentContainer>
          <MatchPrefsPromptCard />
        </ContentContainer>
      )}
    </AnalyticsParamsProvider>
  )
})

interface ComparisonSummaryCardProps {
  job: Job
  jobPreferences: JobPreferences
}

const ComparisonSummaryCard = ({ job, jobPreferences }: ComparisonSummaryCardProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const { minHourlyPay, maxHourlyPay, avgHourlyPay } = useHourlyPayValues(job)
  const geoCoordinates = useGeoCoordinates()

  const showCommute = !isRemoteOnly(jobPreferences)

  const directionsQuery = useDirectionsQuery({
    origin: geoCoordinates!,
    destination: job.coordinate,
    travelMode: 'DRIVING' as google.maps.TravelMode
  }, {
    enabled: showCommute
  })
  const commute = useRouteStats(directionsQuery.data?.routes[0])

  let jobPayValue: string | undefined

  if (avgHourlyPay) {
    const isEstimate = job.pay_source_type === PaySourceType.ESTIMATE || maxHourlyPay != minHourlyPay
    jobPayValue = `${isEstimate ? 'Est. ' : ''}${fmt.currency(avgHourlyPay, { decimals: 2 })}`
  }

  const jobCommuteDistance = commute?.distance

  const { commute_distance: prefCommuteDistance } = jobPreferences
  const prefPay = jobPreferences.pay!

  const renderMatchPrefsLink = (label: string, subPath: string) => (
    <Button
      as={Link} to={`/match-preferences/${subPath}`}
      width="full" variant="secondary" size="sm"
      aria-label={t('actions.addPreference', { label })}
    >
      {t('actions.add')}
    </Button>
  )

  const comparisonData: ComparisonItem[] = [
    {
      label: `${t('views.jobs.comparisonSummary.hourlyPay')}:`,
      prefValue: fmt.currency(prefPay, { decimals: 2 }),
      jobValue: jobPayValue,
      meetsGoal: avgHourlyPay ? avgHourlyPay >= prefPay : undefined
    }
  ]

  if (showCommute) {
    comparisonData.push({
      label: `${t('views.jobs.comparisonSummary.commute')}:`,
      prefValue: prefCommuteDistance
        ? fmt.distance(prefCommuteDistance, { display: 'short' })
        : renderMatchPrefsLink(t('views.search.fields.distance.label'), 'commute'),
      jobValue: jobCommuteDistance !== undefined
        ? fmt.distance(jobCommuteDistance, { display: 'short' })
        : undefined,
      jobValueLoading: directionsQuery.isLoading,
      meetsGoal: jobCommuteDistance !== undefined
        ? !prefCommuteDistance || jobCommuteDistance <= prefCommuteDistance
        : undefined
    })
  }

  return (
    <Card p={5}>
      <Text
        mb={4} textAlign="center"
        fontSize={{ base: 'xl', '2xs': '2xl' }} lineHeight={1.2} fontWeight={500} color="gray.900"
      >
        {t('views.jobs.comparisonSummary.title')}
      </Text>

      <ComparisonTable data={comparisonData} company={job.company} />
      <BetterJobsLinkCard job={job} />
    </Card>
  )
}


interface BetterJobsLinkCardProps {
  job: Job
}

const BetterJobsLinkCard = ({ job }: BetterJobsLinkCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'views.jobs.betterJobs.linkCard' })
  const betterJobsQuery = useBetterJobsQuery(job.slug)
  const { data, isLoading } = betterJobsQuery
  const jobs = data?.pages[0]?.data
  const countLabel = useInfiniteQueryTotalLabel(betterJobsQuery) ?? '0'

  if (jobs ? jobs.length < 2 : !isLoading) {
    return null
  }

  return (
    <Skeleton
      isLoaded={!isLoading}
      rounded="xl"
      mt={4}
    >
      <LinkCard
        p={3}
        to="better"
        title={t('title', { countLabel })}
        body={t('body')}
        image={<JobCompanyLogoStack jobs={jobs} />}
        bodyColor="#4F4F4F"
        fontSize="sm"
        aName="Better Jobs Card"
        aTo="Better Jobs"
      />
    </Skeleton>
  )
}

interface ComparisonTableProps extends BoxProps {
  data: ComparisonItem[]
  company: Company
}

const ComparisonTable = ({ data, company }: ComparisonTableProps) => {
  const { t } = useTranslation()
  return (
    <Flex direction="column">
      <HStack spacing={2} mb={2} textAlign="center">
        <Box flex="1"></Box>
        <Flex flex="1" direction="column" alignItems="center">
          <Image mb={1} src={mountainSrc} alt={t('images.mountainWithFlag')} h="40px" />
          <Text flex="1" fontSize="11px" lineHeight={1} fontWeight={500} color="gray.300">
            {`${t('views.jobs.comparisonSummary.myGoals')}:`}
          </Text>
        </Flex>
        <Flex flex="1" direction="column" alignItems="center">
          <CompanyLogo mb={1} company={company} boxSize="40px" />
          <Text flex="1" fontSize="11px" lineHeight={1} fontWeight={500} color="gray.300">
            {`${t('views.jobs.comparisonSummary.thisJob')}:`}
          </Text>
        </Flex>
      </HStack>

      <Stack spacing={2}>
        {data.map((item, index) => (
          <HStack key={index} spacing={2} py={2} textAlign="center">
            <Text
              flex="1" fontSize="11px" fontWeight={500} color="gray.300"
              noOfLines={1} wordBreak="break-all"
            >
              {item.label}
            </Text>

            <Box flex="1">
              {isValidElement(item.prefValue) ? item.prefValue : (
                <Text
                  fontSize="2sm" lineHeight={1.2} fontWeight={500} color="gray.900"
                  noOfLines={1} wordBreak="break-all"
                >
                  {item.prefValue}
                </Text>
              )}
            </Box>

            <ComparisonPill
              flex={1} px={1}
              comparisonValue={item.meetsGoal}
              isLoading={item.jobValueLoading}
            >
              {item.jobValue ?? t('terms.unknown')}
            </ComparisonPill>
          </HStack>
        ))}
      </Stack>
    </Flex>
  )
}

const MatchPrefsPromptCard = () => {
  const { t } = useTranslation()
  const analyticsParams = useAnalyticsParams()

  return (
    <LinkCard
      to="/match-preferences"
      title={t('views.jobs.matchPreferencesPrompt.title')}
      body={t('views.jobs.matchPreferencesPrompt.body')}
      image={<Image src={searchSrc} boxSize="56px" alt={t('images.search')} />}
      bg="#F0F7FE" borderColor="#00509C" chevronColor="#00509C"
      aName="Match Preferences Prompt Card"
      aTo="Match Preferences"
      onClick={() => {
        analytics.trackTakeTheJobQuizClick(analyticsParams)
      }}
    />
  )
}
