import { t } from 'i18next'
import { isPhoneNumberValid } from './tel'

export const requiredRule = () => ({
  value: true,
  message: t('messages.validation.required')
})

export const minLengthRule = (value: number) => ({
  value,
  message: t('messages.validation.minLength', { min: value })
})

export const maxLengthRule = (value: number) => ({
  value,
  message: t('messages.validation.maxLength', { max: value })
})

export const validateRequiredRule = () => ({
  value: validateRequired
})

export function validateRequired(v: any) {
  if (isValueEmpty(v)) {
    return t('messages.validation.required')
  }
  return true
}

export function validatePhoneNumber(v: any) {
  if (!v || !isPhoneNumberValid(v)) {
    return t('messages.validation.phone')
  }
  return true
}

export function isValueEmpty(v: any) {
  return v === null || v === undefined || v === '' || (Array.isArray(v) && !v.length)
}
