import { Box, BoxProps, Button, Card, CardBody, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFormatters, useGeoCoordinates, useJobUrl, useTrack } from '../../hooks'
import { JobIndexRecord } from '../../models'
import { CompanyLogo } from '../companies'
import { PILL_SIZE_CONFIG } from '../core'
import { RegionBadge } from '../RegionBadge'
import { FavoriteButton } from './elements'
import { JobCardPills } from './JobCardPills'
import { Wage } from './Wage'

export const SMALL_JOB_CARD_HEIGHT = 288
export const SMALL_JOB_CARD_WIDTH = 165

export interface SmallJobCardProps extends BoxProps {
  job: JobIndexRecord
}

export const SmallJobCard = ({ job, ...props }: SmallJobCardProps) => {
  const { t } = useTranslation()
  const geoCoordinates = useGeoCoordinates()
  const fmt = useFormatters()
  const url = useJobUrl(job.slug)
  const { created_at: createdAt, posted_date: postedDate } = job
  const track = useTrack()

  return (
    <Card w={`${SMALL_JOB_CARD_WIDTH}px`} h={`${SMALL_JOB_CARD_HEIGHT}px`} {...props}>
      <CardBody as={Flex} flexDir="column" flex={1}>
        <Flex justify="space-between" mb={1}>
          <CompanyLogo pos="relative" company={job.company} boxSize={50} />
          <FavoriteButton
            job={job}
            iconSize={6}
            mt={-2.5}
            mr={-2.5}
            color="gray.200"
            zIndex={2}
          />
        </Flex>

        <Flex h="80px" flexDir="column" overflow="hidden">
          <Text
            as="h3"
            fontSize={14}
            fontWeight="medium"
            lineHeight={1.4}
            noOfLines={3}
          >
            {job.title}
          </Text>
          <Text mt={0.5} fontSize={11} noOfLines={1} wordBreak="break-all">
            {job.company.name}
          </Text>
        </Flex>

        {geoCoordinates && (
          <RegionBadge record={job} distanceFrom={geoCoordinates} size="sm" mt={2} />
        )}
        <Wage job={job} size="xs" mt={1.5} />

        <JobCardPills
          job={job} size="xs"
          mt={2} h={PILL_SIZE_CONFIG['xs'].h} overflow="hidden"
        />

        <Box mt="auto">
          {(postedDate || createdAt) && (
            <Flex align="flex-end">
              {(job.posted_date || job.created_at) && (
                <Text fontSize="9px" color="gray.400">
                  {t('views.jobs.postedAt', {
                    at: fmt.relativeTimeCompact(postedDate ?? createdAt)
                  })}
                </Text>
              )}
            </Flex>
          )}
          <Button
            mt={1.5}
            as={Link} to={url}
            variant="primary" w="full" size="sm"
            zIndex={2}
            onClick={() => track.open('Job Detail', 'See job details')}
          >
            {t('actions.seeJobDetails')}
          </Button>
        </Box>
      </CardBody>

      <Flex
        h="100%"
        w="100%"
        as={Link}
        to={url}
        aria-label={t('actions.viewDetails')}
        borderRadius={20}
        pos="absolute"
        left={0}
        top={0}
        zIndex={1}
        onClick={() => track.open('Job Detail')}
      />
    </Card>
  )
}
