import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GooglePlacesAutocompleteMenu, UseCurrentLocationButton } from '../../components/autocomplete/googlePlaces'
import { ProgressModal } from '../../components/core'
import { useLocalStorage } from '../../contexts'
import {
  useDevicePositionMutation, useGooglePlacesCombobox, useStartingLocationMutation,
  useUpdateEffect
} from '../../hooks'
import { AutocompleteForm, AutocompleteFormProps } from './AutocompleteForm'

interface StartingLocationFormProps extends Pick<AutocompleteFormProps, 'pageHeaderProps' | 'children'> {
  onSuccess?: () => void
}

export const StartingLocationForm = ({ onSuccess, ...props }: StartingLocationFormProps) => {
  const { t } = useTranslation()
  const devicePositionMutation = useDevicePositionMutation()
  const [startingLocation] = useLocalStorage('startingLocation')
  const locationMutation = useStartingLocationMutation()

  const isMutating = locationMutation.isLoading || devicePositionMutation.isLoading

  const combobox = useGooglePlacesCombobox({
    type: 'address',
    defaultInputValue: startingLocation?.description,
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        locationMutation.mutate({ placeId: selectedItem.place_id })
      }
    }
  })

  const {
    items,
    isOpen,
    highlightedIndex,
    closeMenu,
    setInputValue,
    selectItem,
    getItemProps
  } = combobox

  useEffect(() => {
    if (locationMutation.isSuccess) {
      onSuccess?.()
    }
  }, [locationMutation.status])

  useEffect(() => {
    if (devicePositionMutation.isSuccess) {
      locationMutation.mutate(devicePositionMutation.data.coordinates)
      closeMenu()
    }
  }, [devicePositionMutation.isSuccess])

  useEffect(() => {
    if (locationMutation.isError) {
      if (startingLocation) {
        setInputValue(startingLocation.description)
      } else {
        selectItem(null)
        setInputValue('')
      }
    } else if (locationMutation.isSuccess) {
      setInputValue(locationMutation.data.description)
    }
  }, [locationMutation.status])

  useUpdateEffect(() => {
    if (startingLocation) {
      setInputValue(startingLocation.description)
    }
  }, [startingLocation])

  return (
    <AutocompleteForm
      title={t('views.startingLocationForm.title')}
      shortTitle={t('views.startingLocationForm.shortTitle')}
      placeholder={t('views.startingLocationForm.inputPlaceholder')}
      combobox={combobox}
      disabled={isMutating}
      inputPropsOptions={{
        name: 'street-address',
        autoComplete: 'street-address'
      }}
      renderMenu={props => (
        <GooglePlacesAutocompleteMenu
          items={items}
          isOpen={isOpen}
          highlightedIndex={highlightedIndex}
          isLoading={isMutating}
          getItemProps={getItemProps}
          onUseCurrentLocation={devicePositionMutation.mutate}
          {...props}
        />
      )}
      {...props}
    >
      <UseCurrentLocationButton
        mt={1}
        isLoading={devicePositionMutation.isLoading}
        disabled={locationMutation.isLoading}
        onClick={() => devicePositionMutation.mutate()}
      />

      <ProgressModal isOpen={locationMutation.isLoading} />
    </AutocompleteForm>
  )
}
