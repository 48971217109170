import { Button, Link as CLink } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { PageContainer, PageHeader, PageHeading } from '../components/core'
import { AppErrorCode, isAppError } from '../errors'
import { getErrorMessage } from '../helpers'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { isApiNotFoundError } from '../lib/apiClient'

export const ErrorBoundary = () => {
  const error = useRouteError()
  const { t } = useTranslation()
  const message = useMemo(() => getErrorMessage(error), [])
  const isNotFound = (
    (isRouteErrorResponse(error) && error.status === 404) ||
    isApiNotFoundError(error) ||
    isAppError(error, AppErrorCode.NOT_FOUND)
  )

  useEffect(() => {
    document.getElementById('splash')?.remove()

    if (!isNotFound) {
      Sentry.withScope(scope => {
        scope.setExtra('error', error)
        Sentry.captureException(error)
      })
      Sentry.flush()
    }
  }, [])

  return (
    <DefaultLayout>
      <Helmet>
        {isNotFound ? (
          <>
            <title>{t('terms.notFound')}</title>
            <meta name="robots" content="noindex, nofollow" />
          </>
        ) : (
          <title>{t('terms.error')}</title>
        )}
      </Helmet>
      <PageHeader />
      <PageContainer>
        <PageHeading setDocumentTitle={false}>
          {message}
        </PageHeading>
        <Button as={CLink} href="/" variant="primary" size="lg">
          {t('actions.goHome')}
        </Button>
      </PageContainer>
    </DefaultLayout>
  )
}
