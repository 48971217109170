import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { JobListItem, JobListItemProps } from '../jobs/JobListItem'
import { AddToCartButton } from './AddToCartButton'


export interface BulkApplyJobListItemProps extends JobListItemProps {
  onShowDetails: () => void
  selected?: boolean
}

export const BulkApplyJobListItem = ({
  job,
  selected,
  onShowDetails,
  ...props
}: BulkApplyJobListItemProps) => {
  const { t } = useTranslation()

  return (
    <JobListItem
      job={job}
      selected={selected}
      actions={
        <>
          <Button
            variant="tertiary" px={{ base: 2, xs: 4 }}
            onClick={onShowDetails}
          >
            {t('terms.details')}
          </Button>

          <AddToCartButton flex={1} job={job} />
        </>
      }
      onDefaultActionClick={onShowDetails}
      {...props}
    />
  )
}
