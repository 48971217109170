import { Image, Text } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import imageSrc from '../../../assets/images/search.png'
import { LinkCard } from '../../../components/core'

interface GamifyPromptCardProps {
  onStartGamify: () => void
}

export const GamifyPromptCard = ({ onStartGamify }: GamifyPromptCardProps) => {
  const { t } = useTranslation(undefined)

  return (
    <LinkCard
      to="#"
      title={t('views.jobs.gamifyPromptCard.title')}
      body={
        <Trans i18nKey="views.jobs.gamifyPromptCard.body">
          <Text as="span" fontWeight="bold" />
          <Text as="span" fontWeight="bold" textDecor="underline" />
        </Trans>
      }
      image={<Image src={imageSrc} boxSize="56px" alt={t('images.search')} />}
      bg="#F0F7FE" borderColor="#00509C" chevronColor="#00509C"
      aName="Gamify Prompt Card"
      aTo="Gamify"
      onClick={e => {
        e.preventDefault()
        onStartGamify()
      }}
    />
  )
}
