import { Navigate, Outlet } from 'react-router-dom'
import { PageContainer, PageTransition } from '../components/core'
import { useAppState } from '../contexts'
import { DefaultLayout } from './DefaultLayout'

interface OnboardingLayoutProps {
  pageTransition?: boolean
  redirectIfAuthenticated?: boolean
}

export const OnboardingLayout = ({ pageTransition, redirectIfAuthenticated }: OnboardingLayoutProps) => {
  const [{ authenticated }] = useAppState()

  if (redirectIfAuthenticated && authenticated) {
    return <Navigate to="/" replace />
  }

  return (
    <DefaultLayout>
      <PageContainer>
        {pageTransition ? (
          <PageTransition initial>
            <Outlet />
          </PageTransition>
        ) : (
          <Outlet />
        )}
      </PageContainer>
    </DefaultLayout>
  )
}
