import * as yup from 'yup'
import { createStorageContext } from '../lib/createStorageContext'
import { GeoLocation, LatLng, SearchLocation } from '../models'
import { localStorage } from '../services'
import { BridgedSessionState } from './SessionStorageContext'

const latLngSchema = yup.object({
  lat: yup.number().required(),
  lng: yup.number().required()
})

const geoLocationSchema = yup.object({
  description: yup.string().required(),
  coordinates: latLngSchema.required(),
  placeId: yup.string(),
  addressComponents: yup.array(yup.object({
    long_name: yup.string().required(),
    short_name: yup.string().required(),
    types: yup.array(yup.string()).required()
  }))
})

const STORAGE_SCHEMA = {
  fallbackCoordinates: latLngSchema,
  devicePosition: yup.object({
    coordinates: latLngSchema,
    timestamp: yup.number().required()
  }),
  startingLocation: geoLocationSchema,
  jobSearchLocation: yup.object({
    description: yup.string().required(),
    coordinates: latLngSchema,
    city_slug: yup.string(),
    state: yup.string(),
    zipcode: yup.string()
  }),
  jobSearchQueryHistory: yup.array(yup.string()),
  videoMuted: yup.boolean(),
  dismissedNotices: yup.object(),
  dismissedTips: yup.object(),
  jobEventDataUtmParams: yup.object().shape({}),
  lastViewedJobSlug: yup.string(),
  gamifyStartedAt: yup.number(),
  sessionBridge: yup.object({
    _ts: yup.number().required()
  })
}

interface LocalStorageState {
  // Used as a fallback when coordinates are required, .e.g `useMatchedJobsEstimateQuery`
  fallbackCoordinates?: LatLng
  devicePosition?: { coordinates: LatLng, timestamp: number }
  startingLocation?: GeoLocation
  jobSearchLocation?: SearchLocation
  jobSearchQueryHistory?: string[]
  videoMuted?: boolean
  dismissedNotices?: { [key: string]: true }
  dismissedTips?: { [key: string]: true }
  jobEventDataUtmParams?: { [key: string]: string }
  lastViewedJobSlug?: string
  gamifyStartedAt?: number
  // Stored for magic link auth to bridge sessions.
  sessionBridge?: BridgedSessionState & { _ts: number }
}

const { provider, hooks } = createStorageContext<LocalStorageState>({
  storage: localStorage,
  schema: STORAGE_SCHEMA,
  namespace: 'gigs'
})

export const LocalStorageProvider = provider
export const useLocalStorage = hooks.getterSetter
export const useLocalStorageState = hooks.state
export const useLocalStorageSetter = hooks.setter
export const useClearLocalStorage = hooks.clear
