import { Box, BoxProps, HStack, SpaceProps, Text } from '@chakra-ui/react'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import { WizardStep } from './types'

export interface WizardStepperProps extends SpaceProps {
  steps: WizardStep[]
  linkPrefix?: string
  activeStepId?: string
  renderActiveStepTitle?: boolean
}

export const WizardStepper = memo(({
  steps,
  linkPrefix = '',
  activeStepId,
  renderActiveStepTitle,
  ...props
}: WizardStepperProps) => {
  if (steps.length <= 1) return null

  let activeStepIndex = 0
  let activeStep: WizardStep | undefined

  return (
    <Box {...props}>
      <HStack spacing={1} justify="space-between">
        {steps.map((step, i) => {
          const isActive = step.id === activeStepId
          if (isActive) {
            activeStep = step
            activeStepIndex = i
          }
          return (
            <Step
              key={step.id}
              step={step}
              linkTo={`${linkPrefix}${step.id}`}
              isActive={isActive}
            />
          )
        })}
      </HStack>

      {renderActiveStepTitle && (
        <Text fontFamily="Soehne" fontSize="12px" color="gray.300" textTransform="uppercase">
          {activeStepIndex + 1}. {activeStep?.title ?? ''}
        </Text>
      )}
    </Box>
  )
})

interface StepProps extends BoxProps {
  step: WizardStep
  linkTo: string
  isActive: boolean
}

const Step = ({ step, linkTo, isActive }: StepProps) => {
  return (
    <Box
      flex={1} py={2} rounded="md"
      title={step.title}
      {...(step.status !== 'pending' && {
        as: Link,
        to: linkTo,
        replace: true
      })}
    >
      <Box
        h={2} rounded="sm"
        bg={
          isActive
            ? 'brand.500'
            : step.status === 'pending' ? 'gray.200' : step.status === 'visited' ? 'gray.300' : 'gray.900'
        }
      />
    </Box>
  )
}
