import { t } from 'i18next'
import { PaySourceType, PayType } from '../models'
import { metersToMiles } from './geo'

export interface NumberFormatOptions extends Intl.NumberFormatOptions {
  decimals?: number
  minDecimals?: number
  maxDecimals?: number
}

export interface CurrencyFormatOptions extends NumberFormatOptions {
  style?: 'currency' | 'decimal'
}

export function formatNumber(
  value: string | number,
  options: NumberFormatOptions = {}
) {
  let {
    decimals,
    minDecimals,
    maxDecimals,
    minimumFractionDigits = minDecimals,
    maximumFractionDigits = maxDecimals,
    ...opts
  } = options

  if (decimals !== undefined) {
    minimumFractionDigits = decimals
    maximumFractionDigits = decimals
  }

  return value.toLocaleString(undefined, {
    ...opts,
    minimumFractionDigits,
    maximumFractionDigits
  })
}

export function formatCurrency(
  value: string | number,
  options: CurrencyFormatOptions = {}
) {
  const {
    style = 'currency',
    minDecimals = 2,
    maxDecimals = 2,
    ...opts
  } = options

  const formattedValue = formatNumber(value, {
    ...opts,
    minDecimals,
    maxDecimals
  })

  if (style === 'currency') {
    return `$${formattedValue}`
  }

  return formattedValue
}

export function formatPercent(
  value: number,
  options: NumberFormatOptions = {}
) {
  return `${formatNumber(value, { maxDecimals: 3, ...options })}%`
}

export interface FormatDistanceOptions {
  inputUnit?: 'miles' | 'meters'
  display?: 'long' | 'short'
  precision?: number
}

export function formatDistance(value: number, options?: FormatDistanceOptions) {
  const { inputUnit = 'meters', display = 'long', precision = 1 } = options ?? {}
  const miles = inputUnit === 'meters' ? metersToMiles(value) : value
  const str = miles >= 1000 ? '+999' : `${formatNumber(miles, { maxDecimals: precision })}`
  return t(`formats.distance.${display}`, { count: str === '1' ? 1 : miles, value: str })
}

export function formatWage(min: number, max = min, type?: PayType | null, sourceType?: PaySourceType | null) {
  const pay = min === max
    ? `${formatCurrency(min)}/${formatPayType(type)}`
    : `${formatCurrency(min)}–${formatCurrency(max)}/${formatPayType(type)}`

  if (sourceType === PaySourceType.ESTIMATE) {
    return t('views.jobs.wage.estimated', { pay })
  } else {
    return pay
  }
}

export function formatPayType(payType?: PayType | null) {
  return !payType || payType !== PayType.DAILY ? t('units.hour.short') : t('units.day')
}

export interface FormatAddressOptions {
  address?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  zipcode?: string | null
}

export function formatAddress({
  address,
  address2,
  city,
  state,
  zipcode
}: FormatAddressOptions) {
  const streetAddress = [address, address2].filter(Boolean).join(', ')
  const stateZip = [state, zipcode].filter(Boolean).join(' ')
  return [streetAddress, city, stateZip].filter(Boolean).join(', ')
}
