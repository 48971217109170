import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { JobScheduleType } from '../models'

export function useSearchChoiceLabelFormatters() {
  const { t } = useTranslation()
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.search.fields' })

  return useMemo(() => ({
    workplaces(choice: string) {
      return t(`views.jobs.workplace.${choice}`)
    },
    schedule(choice: string) {
      if (choice === JobScheduleType.FLEXIBLE) {
        return vt('schedule.flexible')
      }
      return t(`views.jobs.schedule.${choice}`)
    },
    experience(choice: string) {
      return vt(`experience.choices.${choice}`)
    }
  } as const), [t])
}
