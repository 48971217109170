import Cookie from 'js-cookie'

export enum CookieKey {
  ACCESS_TOKEN = 'access_token',
  ANON_ID = 'anon_id'
}

export default {
  get(key: CookieKey) {
    return Cookie.get(key)
  },
  setAccessToken(value: string) {
    Cookie.set(CookieKey.ACCESS_TOKEN, value, { expires: 365 })
  },
  remove(key: CookieKey) {
    Cookie.remove(key)
  }
}
