import { Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { ComponentType, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContentContainer, ContentFooter } from '../../../components/core'
import { ACTION_FOOTER_HEIGHT, DetailActionFooter, DetailHeader, DetailHeaderActionLinkButton, DetailPageHeader, DetailPageHeaderTitle } from '../../../components/detail'
import { DiscoverJobsCard, FavoriteButton, SimilarJobsSection } from '../../../components/jobs'
import { JobDetailInfo } from '../../../components/jobs/JobDetailInfo'
import { SearchLinksSection } from '../../../components/search'
import { useLocalStorage } from '../../../contexts'
import { buildBulkApplyUrl } from '../../../helpers'
import { useApplyOrdersQuery, useBulkApplyCart, useCreateApplication, useFormatters, useIsMounted, useIsPayEstimateSupported } from '../../../hooks'
import { Application, ApplicationStatus, Job, SearchLink } from '../../../models'
import { GamifyIntroProps } from '../../../modules/gamify'
import { analytics } from '../../../services'
import { BenefitsSection } from './BenefitsSection'
import { ComparisonSection } from './ComparisonSection'
import { DescriptionSection } from './DescriptionSection'
import { GamifyPromptCard } from './GamifyPromptCard'
import { JobDetailActions } from './JobDetailActions'
import { JobHeaderActions } from './JobHeaderActions'
import { PayCalculatorCard } from './PayCalculator'
import { PositionTagsSection } from './PositionTagsSection'
import { RelatedJobsSection } from './RelatedJobsSection'
import { RequirementsSection } from './RequirementsSection'

const APPLICATION_SUBMITTED_FOOTER_HEIGHT = 130
const GAMIFY_AUTO_START_TTL = 86_400_000 * 30 // 30 days

export const JobDetailContent = ({ job, application, searchLinks }: {
  job: Job
  application?: Application
  applicationCreate?: ReturnType<typeof useCreateApplication>
  searchLinks?: SearchLink[]
}) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const navigate = useNavigate()

  const bulkApplyEnabled = !!job.application_url
  const gamifyIntroDisclosure = useDisclosure()
  const { data: applyOrders } = useApplyOrdersQuery({ per_page: 1 })
  const applyOrdersRef = useRef(applyOrders)
  applyOrdersRef.current = applyOrders
  const [gamify, setGamify] = useState<{ GamifyIntro: ComponentType<GamifyIntroProps> }>()
  const [gamifyStartedAt, setGamifyStartedAt] = useLocalStorage('gamifyStartedAt')
  const cart = useBulkApplyCart()

  const isMounted = useIsMounted()

  const _application = application ?? job.application
  // Only show application submitted state for internal applications
  const showApplicationSubmitted = (
    _application?.status === ApplicationStatus.SUBMITTED &&
    !job.application_url
  )

  const showPayCalculator = useIsPayEstimateSupported(job)

  const startGamify = async () => {
    // De-initialize cart if empty, so that default jobs
    // are added when we get to the collection phase.
    if (!cart.length) {
      cart.reset()
    }

    if (applyOrders?.length) {
      navigate(buildBulkApplyUrl('', { job: job.id }))
      setGamifyStartedAt(Date.now())
    } else {
      openGamifyIntro()
    }
  }

  const openGamifyIntro = async () => {
    try {
      const gamify = await import('../../../modules/gamify')
      setGamify(gamify)
      gamifyIntroDisclosure.onOpen()
      setGamifyStartedAt(Date.now())
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!bulkApplyEnabled) return
    if (!gamifyStartedAt || Date.now() - gamifyStartedAt > GAMIFY_AUTO_START_TTL) {
      setTimeout(() => {
        if (isMounted() && !applyOrdersRef.current?.length) {
          openGamifyIntro()
        }
      }, 500)
    }
  }, [job])

  return (
    <>
      <DetailPageHeader
        title={<DetailPageHeaderTitle title={job.title} subtitle={job.company.name} />}
        isOverlay={!!job.media?.length}
      />

      <DetailHeader
        company={job.company}
        title={job.title}
        subtitle={job.company.name}
        mediaItems={job.media}
        mediaAnalyticsParams={{ job_id: job.id }}
        mediaOverlayActions={
          <Flex
            h="30px" w="30px"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bg="white"
          >
            <FavoriteButton
              job={job}
              iconSize={6}
              color="gray.300"
              fillColor="brand.500"
            />
          </Flex>
        }
        videoPlayerModalActions={<JobHeaderActions job={job} />}
        action={
          !!job.location_job_count && (
            <DetailHeaderActionLinkButton
              to={`/locations/${job.location_id}`}
              onClick={() => analytics.trackGoToLocation({ job_id: job.id, location_id: job.location_id })}
            >
              {t('views.jobs.jobsAtThisLocation', {
                count: job.location_job_count,
                value: fmt.number(job.location_job_count)
              })}
            </DetailHeaderActionLinkButton>
          )
        }
      />

      <JobDetailInfo mt={4} job={job} zIndex={1} />

      <Stack
        spacing="30px" mt={5}
        mb={`${(showApplicationSubmitted ? APPLICATION_SUBMITTED_FOOTER_HEIGHT : ACTION_FOOTER_HEIGHT) + 30}px`}>

        {bulkApplyEnabled && (
          <ContentContainer>
            <GamifyPromptCard onStartGamify={startGamify} />
          </ContentContainer>
        )}

        <ComparisonSection job={job} showPrompt={!bulkApplyEnabled} />

        {!!job.requirements?.length && (
          <RequirementsSection items={job.requirements} />
        )}

        {job.description && (
          <DescriptionSection job={job} />
        )}

        {showPayCalculator && (
          <ContentContainer>
            <PayCalculatorCard job={job} />
          </ContentContainer>
        )}

        {!!job.benefits?.length && (
          <BenefitsSection items={job.benefits} />
        )}

        {!!job.position_tags?.length && (
          <PositionTagsSection items={job.position_tags} />
        )}

        <RelatedJobsSection job={job} />

        <SimilarJobsSection variant="vertical" jobSlug={job.slug} />

        <ContentContainer mb={20}>
          <DiscoverJobsCard />
        </ContentContainer>

        {!!searchLinks?.length && (
          <SearchLinksSection searchLinks={searchLinks} />
        )}
      </Stack>

      {!showApplicationSubmitted ? (
        <DetailActionFooter>
          <JobDetailActions job={job} />
        </DetailActionFooter>
      ) : (
        <ContentFooter h={APPLICATION_SUBMITTED_FOOTER_HEIGHT} bg="gray.900" color="white">
          <ContentContainer py={5}>
            <Text fontSize="lg" fontWeight="medium">
              {t('views.jobs.applicationSubmittedFooter.title')}
            </Text>
            <Text fontSize="xs">
              {t('views.jobs.applicationSubmittedFooter.message', { company: job.company.name })}
            </Text>
          </ContentContainer>
        </ContentFooter>
      )}

      {gamify && <gamify.GamifyIntro job={job} {...gamifyIntroDisclosure} />}
    </>
  )
}
