import { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useAppState, useFeaturesReady, useLocalStorage } from '../contexts'
import { fetchGeoLocation } from '../helpers'
import { useAccount, useAccountQuery, useBulkApplyCart, useJobPreferencesQuery, usePostAuthPersist } from '../hooks'
import { isApiUnauthorizedError } from '../lib/apiClient'

export const Bootstrap = () => {
  const [_, setAppState] = useAppState()
  const steps = useMemo(() => [
    Step1,
    Step2,
    Step3
  ], [])
  const [stepIndex, setStepIndex] = useState(0)

  const CurrentStep = steps[stepIndex]

  const handleDone = () => {
    const nextIndex = stepIndex + 1
    setStepIndex(nextIndex)

    if (!steps[nextIndex]) {
      setAppState({ ready: true })
    }
  }

  return CurrentStep
    ? <CurrentStep onDone={handleDone} />
    : <Outlet />
}

interface StepProps {
  onDone: () => void
}

const Step1 = ({ onDone }: StepProps) => {
  const accountQuery = useAccountQuery()
  const jobPreferencesQuery = useJobPreferencesQuery()
  const cart = useBulkApplyCart()

  if (accountQuery.error && !isApiUnauthorizedError(accountQuery.error)) {
    throw accountQuery.error
  }
  if (jobPreferencesQuery.error && !isApiUnauthorizedError(jobPreferencesQuery.error)) {
    throw jobPreferencesQuery.error
  }

  const isInitialLoading = (
    accountQuery.isInitialLoading ||
    jobPreferencesQuery.isInitialLoading ||
    cart.jobsQuery.isInitialLoading
  )

  useEffect(() => {
    if (!isInitialLoading) {
      onDone()
    }
  }, [isInitialLoading])

  return null
}

const Step2 = ({ onDone }: StepProps) => {
  const account = useAccount()
  const [startingLocation, setStartingLocation] = useLocalStorage('startingLocation')
  const postAuthPersist = usePostAuthPersist()

  const postAuth = () => {
    if (account) {
      return postAuthPersist()
    }
  }

  useEffect(() => {
    (async () => {
      const ensureStartingLocation = async () => {
        if (account) {
          const { starting_place_id } = account.preferences
          // Restore startingLocation if necessary.
          if (starting_place_id && !startingLocation) {
            try {
              const startingLocation = await fetchGeoLocation({ placeId: starting_place_id }, 'street_address')
              setStartingLocation(startingLocation)
            } catch (e) {
              console.error(e)
            }
          }
        }
      }

      await Promise.all([ensureStartingLocation(), postAuth()])

      onDone()
    })()
  }, [])

  return null
}

const Step3 = ({ onDone }: StepProps) => {
  const featuresReady = useFeaturesReady()

  useEffect(() => {
    if (featuresReady) {
      document.getElementById('splash')?.remove()
      onDone()
    }
  }, [featuresReady])

  return null
}
