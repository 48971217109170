import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_MAPS_API_KEY } from '../config'

// Shim for Maps Javascript SDK on old browsers
if (!window.HTMLDialogElement) {
  window.HTMLDialogElement = window.HTMLDivElement as any
}

export default {
  get isInitialized() {
    return !!window.google && !!window.google.maps && !!window.google.maps.places
  },

  async initialize() {
    if (!this.isInitialized) {
      const apiKey = GOOGLE_MAPS_API_KEY
      await new Loader({ apiKey, ...{ libraries: ['places'] } }).load()
    }
  },

  async geocode(params: google.maps.GeocoderRequest) {
    await this.initialize()

    return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      const geocoder = new google.maps.Geocoder()
      // Geocoder will hang indefinitely if there is a permissions error.
      const timer = setTimeout(() => reject(new Error('Timeout')), 3000)

      geocoder.geocode(params, (results, status) => {
        clearTimeout(timer)
        if (status !== google.maps.GeocoderStatus.OK) {
          return reject(status)
        }
        return resolve(results!)
      })
    })
  },

  async getDirections(params: google.maps.DirectionsRequest) {
    await this.initialize()

    return new Promise<google.maps.DirectionsResult | null>((resolve, reject) => {
      const service = new google.maps.DirectionsService()
      service.route(params, (result, status) => {
        if (status !== google.maps.DirectionsStatus.OK) {
          return reject(status)
        }
        return resolve(result)
      })
    })
  }
}
