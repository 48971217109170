import { Flex, Icon, IconButton, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, SpaceProps, Text } from '@chakra-ui/react'
import { IconSortDescending } from '@tabler/icons-react'
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { analytics } from '../../services'
import { BOX_SHADOW_1 } from '../../styles'
import { useSearch } from './SearchContext'
import { SortChoice } from './types'

export interface SortMenuProps extends SpaceProps {
  compact?: boolean
}

export const SortMenu = memo(({ compact, ...buttonProps }: SortMenuProps) => {
  const { t } = useTranslation()
  const { searchOptions, updateSearchOptions } = useSearch()

  const sortChoices = useMemo(() => {
    let choices = Object.values(SortChoice)
    if (!searchOptions.location?.coordinates) {
      choices = choices.filter(sc => sc !== SortChoice.CLOSEST)
    }
    return choices
  }, [searchOptions])

  const value = searchOptions.sort ?? sortChoices[0]

  const handleChange = useCallback((value: any) => {
    const sort = value === SortChoice.DEFAULT ? undefined : value
    updateSearchOptions({ sort })
    analytics.trackSearchSorted(sort)
  }, [updateSearchOptions])

  return (
    <Menu isLazy placement="bottom-end" onOpen={() => analytics.trackSortMenuView()}>
      <MenuButton
        as={IconButton}
        size="sm"
        height="38px"
        minW="38px"
        px={compact ? 0 : 3}
        rounded="full"
        aria-label={t('actions.sort')}
        {...buttonProps}
      >
        <Flex align="center" justify="center">
          {!compact && (
            <>
              {value == SortChoice.DEFAULT ? (
                <Text mr={1}>
                  {t('actions.sort')}
                </Text>
              ) : (
                <Text fontFamily="Soehne" textTransform="uppercase" mr={1}>
                  {t(`views.search.sortChoices.${value}`)}
                </Text>
              )}
            </>
          )}
          <Icon as={IconSortDescending} boxSize="18px" color="gray.900" />
        </Flex>
      </MenuButton>

      <MenuList boxShadow={BOX_SHADOW_1} borderRadius={10} zIndex="dropdown" minW="auto">
        <MenuOptionGroup defaultValue={value} onChange={handleChange}>
          {sortChoices.map(choice => {
            return (
              <MenuItemOption key={choice} value={choice} type="radio">
                {t(`views.search.sortChoices.${choice}`)}
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
})
