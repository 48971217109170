import { ReactNode } from 'react'
import { ContentContainer, SimplePage, SimplePageProps } from '../core'
import { JobList, JobListProps } from './JobList'

type ListProps = Pick<JobListProps, 'query' | 'items' | 'estimateItemSize' | 'renderJob' | 'renderNoResults' | 'renderError'>

interface JobListPageProps extends SimplePageProps, ListProps {
  topContent?: ReactNode
}

export const JobListPage = ({
  title,
  aName,
  topContent,
  children,
  ...listProps
}: JobListPageProps) => {
  return (
    <SimplePage pb={5} title={title} aName={aName}>
      {topContent && (
        <ContentContainer>
          {topContent}
        </ContentContainer>
      )}

      <JobList {...listProps} />
      {children}
    </SimplePage>
  )
}
