import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const buttonTheme = defineStyleConfig({
  baseStyle: defineStyle({
    fontWeight: 'medium',
    borderRadius: '10px',
    _hover: {
      textDecor: 'none'
    }
  }),
  variants: {
    primary: defineStyle({
      bg: 'brand.500',
      color: 'white',
      _hover: {
        _disabled: {
          bg: 'brand.500',
        }
      }
    }),
    secondary: defineStyle({
      bg: 'gray.900',
      color: 'white',
      _hover: {
        bg: 'gray.900'
      }
    }),
    tertiary: defineStyle({
      bg: 'white',
      color: 'gray.900',
      _hover: {
        bg: 'white'
      },
      border: '1px',
      borderColor: 'gray.200'
    }),
    quaternary: defineStyle({
      fontFamily: 'Soehne',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      bg: 'transparent',
      color: 'white',
      _hover: {
        bg: 'transparent'
      },
      border: '1px',
      borderColor: 'gray.400'
    }),
    danger: defineStyle({
      bg: 'red.500',
      color: 'white',
      _hover: {
        _disabled: {
          bg: 'red.500',
        }
      }
    }),
    ghost: defineStyle({
      _hover: {
        bg: 'transparent',
        // This will make it so IconButtons don't have a hover state, but text will have an underline.
        textDecoration: 'underline'
      },
      _active: {
        bg: 'transparent',
        opacity: 0.6
      }
    }),
    inactive: defineStyle({
      bg: 'gray.100',
      color: 'gray.300',
      _hover: {
        bg: 'gray.100'
      }
    }),
    inactive2: defineStyle({
      bg: 'gray.200 !important',
      color: 'gray.400 !important',
      _disabled: {
        opacity: 1
      }
    }),
    input: defineStyle({
      bg: 'gray.100',
      color: 'gray.900',
      border: '1px solid',
      borderColor: 'gray.200'
    }),
    link: defineStyle({
      fontWeight: 'normal',
      bg: 'transparent',
      color: 'brand.500',
      _hover: {
        textDecor: 'underline'
      }
    })
  },
  sizes: {
    xs: defineStyle({
      h: 22,
      fontSize: 10,
      borderRadius: 5
    }),
    sm: defineStyle({
      h: 30,
      fontSize: '2sm',
      borderRadius: 5
    }),
    md: defineStyle({
      fontSize: 'sm',
      h: 10,
      px: 5,
      py: 3
    }),
    lg: defineStyle({
      fontSize: 'sm',
      h: '54px'
    }),
    xl: defineStyle({
      fontSize: '2xl',
      h: '60px'
    }),
  }
})
