import { SimplePage, SimplePageProps } from '../core'
import { LocationList, LocationListProps } from './LocationList'

interface LocationListPageProps extends SimplePageProps, Pick<LocationListProps, 'query' | 'items'> {}

export const LocationListPage = ({ title, children, ...listProps }: LocationListPageProps) => {
  return (
    <SimplePage title={title} pb={5}>
      <LocationList {...listProps} />
      {children}
    </SimplePage>
  )
}
