import { Box, BoxProps, Flex, FlexProps, HStack, Text } from '@chakra-ui/react'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useModalManager } from '../../contexts'
import { useFormatters } from '../../hooks'
import { JobBase, PaySourceType, SupplementalPayType } from '../../models'
import { Dialog, IconButtonWithOptionalAriaProps } from '../core'
import { InfoButton } from '../core/InfoButton'

export interface WageProps extends FlexProps {
  job: JobBase
  size?: '2xs' | 'xs' | 'sm' | '2sm' | 'md'
  // NOTE: "md" is not used here, default returns "sm" (12px).
  displaySupplementalPay?: boolean
}

export const Wage = memo(({ size = 'md', displaySupplementalPay, ...props }: WageProps) => {
  switch (size) {
    case '2xs':
      return (
        <BaseWage
          fontSize={10}
          infoButtonProps={{ iconSize: 3, top: '-10px', left: '-1px' }}
          displaySupplementalPay={displaySupplementalPay}
          {...props}
        />
      )
    case 'xs':
      return (
        <BaseWage
          fontSize={11}
          infoButtonProps={{ iconSize: 3.5, top: '-12px', left: '-1px' }}
          displaySupplementalPay={displaySupplementalPay}
          {...props}
        />
      )
    case 'sm':
      return (
        <BaseWage
          fontSize={12}
          infoButtonProps={{ iconSize: '14px', top: '-12px', left: '-1px' }}
          displaySupplementalPay={displaySupplementalPay}
          {...props}
        />
      )
    case '2sm':
      return (
        <BaseWage
          fontSize="2sm"
          infoButtonProps={{ top: '-12px', left: '-1px' }}
          displaySupplementalPay={displaySupplementalPay}
          {...props}
        />
      )
    default:
      return (
        <BaseWage
          fontSize="sm"
          infoButtonProps={{ top: '-12px', left: '1px' }}
          displaySupplementalPay={displaySupplementalPay}
          {...props}
        />
      )
  }
})

interface BaseWageProps extends BoxProps {
  job: JobBase
  infoButtonProps: EstimatedWageInfoButtonProps
  displaySupplementalPay?: boolean
}

export const BaseWage = ({ job, infoButtonProps, displaySupplementalPay, ...props }: BaseWageProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()

  if (!job.min_pay || !job.max_pay) {
    return null
  }

  const pay = fmt.wage(job.min_pay, job.max_pay, job.pay_type, job.pay_source_type)

  const hasSupplementalPay = displaySupplementalPay && job.supplemental_pay &&
    Object.values(SupplementalPayType).includes(job.supplemental_pay)

  let i18nKey: string | undefined
  let i18nValues: any

  if (hasSupplementalPay) {
    i18nValues = {
      pay,
      supplementalPay: t(`views.jobs.supplementalPay.${job.supplemental_pay}`)
    }
    i18nKey = 'views.jobs.wage.withSupplementalPay'
  }

  return (
    <HStack spacing={1} fontWeight="medium" {...props}>
      <Text noOfLines={1} wordBreak="break-all">
        {!i18nKey ? pay : (
          <Trans i18nKey={i18nKey} values={i18nValues}>
            <Box as="span" fontWeight="normal" />
          </Trans>
        )}
      </Text>

      {job.pay_source_type === PaySourceType.ESTIMATE && (
        <Flex boxSize={3.5} justifyContent="center" alignItems="center">
          <EstimatedWageInfoButton
            pos="unset"
            zIndex={2}
            {...infoButtonProps}
          />
        </Flex>
      )}
    </HStack>
  )
}

interface EstimatedWageInfoButtonProps extends IconButtonWithOptionalAriaProps {
  iconSize?: IconButtonWithOptionalAriaProps['boxSize']
}

const EstimatedWageInfoButton = memo((props: EstimatedWageInfoButtonProps) => {
  const { t } = useTranslation()
  const modalManager = useModalManager()

  return (
    <InfoButton
      aria-label={t('views.jobs.estimatedWageHelp.title')}
      onClick={() => {
        modalManager.open(Dialog, {
          title: t<string>('views.jobs.estimatedWageHelp.title'),
          message: t<string>('views.jobs.estimatedWageHelp.message')
        })
      }}
      {...props}
    />
  )
})
