export enum TaxFilingStatus {
  SINGLE = 'single',
  MARRIED_FILING_JOINTLY = 'married_filing_jointly',
  HEAD_OF_HOUSEHOLD = 'head_of_household'
}

export interface AddressRecord {
  address: string | null
  address2?: string | null
  city: string | null
  state: string | null
  zipcode: string | null
}

export interface DateObject {
  year: number // YYYY
  month: number // 1-12
  day: number // 1-31
}

export interface LatLng {
  lat: number
  lng: number
}

export interface SuggestionItem {
  suggestion: string
}

export interface Tag {
  icon: string
  name: string
}
