import { Box, Icon, ListItem, ListItemProps, Text, TextProps } from '@chakra-ui/react'
import { IconCheck, IconPlus } from '@tabler/icons-react'
import { forwardRef, memo } from 'react'

export interface AutocompleteMenuItemProps extends ListItemProps {
  title: string
  titleSize?: TextProps['fontSize']
  subtitle?: string
  highlighed?: boolean
  selected?: boolean
}

export const BaseAutocompleteMenuItem = forwardRef<HTMLLIElement, AutocompleteMenuItemProps>(({
  title,
  titleSize,
  subtitle,
  highlighed,
  selected,
  ...props
}, ref) => (
  <ListItem ref={ref} display="flex" py={2} alignItems="center" cursor="pointer" {...props}>
    <Box>
      <Text fontSize={titleSize} textDecoration={highlighed ? 'underline' : undefined} noOfLines={1} wordBreak="break-all">
        {title}
      </Text>
      {!!subtitle && (
        <Text color="gray.300" noOfLines={1} wordBreak="break-all">
          {subtitle}
        </Text>
      )}
    </Box>
    {selected !== undefined && (
      <Icon
        as={selected ? IconCheck : IconPlus}
        ml="auto" boxSize={6} color={selected ? undefined : 'brand.500'}
      />
    )}
  </ListItem>
))

export const AutocompleteMenuItem = memo(BaseAutocompleteMenuItem)
