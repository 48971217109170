import { Text } from '@chakra-ui/react'
import { memo, ReactNode } from 'react'
import { useWindowScrollYThresholdFlag } from '../../hooks'
import { PageHeader } from '../core'

export const DetailPageHeader = ({ title, isOverlay }: {
  title: ReactNode
  isOverlay?: boolean
}) => {
  const scrolled = useWindowScrollYThresholdFlag(50)

  return (
    <>
      <PageHeader
        variant={isOverlay ? 'overlay-light' : undefined}
        search cart visible={!scrolled} unmountOnExit
      />
      <PageHeader title={title} search menu={false} visible={scrolled} unmountOnExit />
    </>
  )
}

export const DetailPageHeaderTitle = memo(({ title, subtitle }: { title: string, subtitle?: string }) => (
  <Text
    as="h1" fontSize="12px" color="gray.400"
    whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
  >
    <Text as="span" fontWeight="medium" color="gray.900">
      {title}
    </Text>
    {subtitle && (
      <>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {subtitle}
      </>
    )}
  </Text>
))
