import { IconMessage, IconSettings } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { PageContainer, PageHeader } from '../../components/core'
import { GeneralSettingsItem, GeneralSettingsSection } from '../../components/settings/GeneralSettingsSection'
import { useAccount } from '../../hooks'

export const Settings = () => {
  const { t } = useTranslation()
  const account = useAccount()!

  return (
    <>
      <PageHeader title={t('views.settings.title')} logo={false} backButton={false} menu />
      <PageContainer>
        <GeneralSettingsSection title={t('terms.account')}>
          <GeneralSettingsItem
            title={t('views.settings.manageAccount.title')}
            icon={IconSettings}
            to="account"
          />
        </GeneralSettingsSection>

        {account.contact_phone_number && (
          <GeneralSettingsSection title={t('views.settings.sectionLabels.content')} mt={5}>
            <GeneralSettingsItem
              title={t('views.settings.notifications.title')}
              icon={IconMessage}
              to="notifications"
            />
          </GeneralSettingsSection>
        )}
      </PageContainer>
    </>
  )
}
