import { IconAdjustmentsAlt, IconLogout, IconNews, IconSearch, IconSettings } from '@tabler/icons-react'
import { IconFileSmile, IconHome } from '../core'

export const NAVIGATION_ICONS = {
  '/home': IconHome,
  '/search': IconSearch,
  '/my-app': IconNews,
  '/settings': IconSettings,
  '/sign-up': IconLogout,
  '/my-gigs': IconFileSmile,
  '/match-preferences': IconAdjustmentsAlt
}
