import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { JobListPage } from '../../components/jobs'
import { useSimilarJobsQuery } from '../../hooks'

export const SimilarJobs = () => {
  const { t } = useTranslation()
  const params = useParams()
  const query = useSimilarJobsQuery(params.slug!)

  return (
    <JobListPage
      title={t('views.jobs.jobsYouMightLike')}
      query={query}
    />
  )
}
