import { t } from 'i18next'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { PageContainer, PageHeader } from '../../components/core'
import { SearchProvider, SearchSERPProps } from '../../components/search'
import { AnalyticsParamsProvider } from '../../contexts'
import { useRouteAction } from '../../hooks'
import { SearchResults } from './partials/SearchResults'
import { useSearchPageController } from './useSearchPageController'
import { useSearchPageForm } from './useSearchPageForm'

export const Search = () => {
  const [searchParams] = useSearchParams()
  const showHeaderLogo = searchParams.get('back') !== '1'
  const searchController = useSearchPageController()
  const { serpProps } = searchController
  const routeAction = useRouteAction()

  return (
    <AnalyticsParamsProvider page="Search">
      <PageContainer px={0}>
        <PageHeader logo={showHeaderLogo} cart />
        {!serpProps && <Helmet title={t('actions.search')} />}

        <SearchProvider controller={searchController}>
          {searchController.isReady && (
            <SearchContent
              serpProps={searchController.serpProps}
              routeAction={routeAction}
            />
          )}
        </SearchProvider>
      </PageContainer>
    </AnalyticsParamsProvider>
  )
}

const SearchContent = ({ serpProps, routeAction }: { serpProps?: SearchSERPProps, routeAction?: string | undefined }) => {
  const {
    element: searchForm,
    resultsContainerProps,
    filtersVisible,
    showFilters
  } = useSearchPageForm()

  useEffect(() => {
    if (routeAction === 'show-filters') {
      showFilters()
    }
  }, [])

  return (
    <>
      {searchForm}

      <SearchResults
        {...resultsContainerProps}
        serpProps={serpProps}
        filtersVisible={filtersVisible}
        onShowFilters={showFilters}
      />
    </>
  )
}
