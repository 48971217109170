import { Heading, HeadingProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'

export interface PageHeadingProps extends PropsWithChildren<HeadingProps> {
  setDocumentTitle?: boolean
}

export const PageHeading = ({ setDocumentTitle, children, ...props }: PageHeadingProps) => (
  <>
    {setDocumentTitle !== false && typeof children === 'string' && (
      <Helmet title={children} />
    )}

    <Heading
      as="h2" mt={8} mb={8}
      fontSize="3xl" fontWeight="medium" lineHeight="38px"
      {...props}
    >
      {children}
    </Heading>
  </>
)

export const PageSubheading = ({ children, ...props }: HeadingProps) => (
  <Heading
    as="h3" fontSize="md" fontWeight="normal" lineHeight={1.4}
    color="gray.400"
    {...props}
  >
    {children}
  </Heading>
)
