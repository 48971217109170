import { Box, BoxProps, Flex, Progress, ProgressProps } from '@chakra-ui/react'
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { ReactNode, useEffect } from 'react'
import { ApiCursorResponse } from '../../lib/apiClient'

const EMPTY_ARRAY = []

export interface RequiredInfiniteListProps<TItem extends any> {
  estimateItemSize: (item: TItem, index: number) => number
  renderItem: (item: TItem, index: number) => ReactNode
}

export interface OptionalInfiniteListProps<TItem extends any> extends BoxProps {
  query?: UseQueryResult<TItem[]> | UseInfiniteQueryResult<ApiCursorResponse<TItem>>
  itemSpacing?: number
  items?: TItem[]
  loaderSize?: number
  headSize?: number
  tailSize?: number
  overscan?: number
  initialOffset?: number
  paddingStart?: number
  autoFetchNextPage?: boolean
  renderLoader?: () => ReactNode
  renderHead?: () => ReactNode
  renderTail?: () => ReactNode
  onFetchNextPage?: () => void
}

export interface InfiniteListProps<TItem extends any>
  extends RequiredInfiniteListProps<TItem>, OptionalInfiniteListProps<TItem> { }

export const InfiniteList = <TItem,>({
  query,
  items = EMPTY_ARRAY,
  loaderSize = 20,
  headSize,
  tailSize,
  overscan = 5,
  initialOffset,
  paddingStart,
  autoFetchNextPage = true,
  estimateItemSize,
  itemSpacing = 0,
  renderItem,
  renderLoader = renderDefaultLoader,
  renderHead,
  renderTail,
  onFetchNextPage,
  ...props
}: InfiniteListProps<TItem>) => {
  const itemCount = items?.length ?? 0
  const hasHead = !!headSize && !!renderHead && !!itemCount
  const hasTail = !!tailSize && !!renderTail && !!itemCount
  const hasLoader = !!loaderSize
  const {
    data,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = (query ?? {}) as UseQueryResult & UseInfiniteQueryResult<any>

  let virtualCount = itemCount + (hasHead ? 1 : 0)
  if (!!(hasLoader && hasNextPage) || !!(hasTail && !hasNextPage)) {
    virtualCount += 1
  }

  const calculateItemSize = (index: number) => {
    const itemIndex = hasHead ? index - 1 : index
    if (hasHead && index === 0) {
      return headSize ?? 0
    }
    if (itemIndex > itemCount - 1) {
      return (hasNextPage ? loaderSize : hasTail ? tailSize : 0) ?? 0
    }
    return estimateItemSize(items[itemIndex], itemIndex)
  }

  const listVirtualizer = useWindowVirtualizer({
    count: virtualCount,
    estimateSize: index => {
      const size = calculateItemSize(index)
      if (size && index < itemCount - (hasTail ? 0 : 1)) {
        return size
      }
      return size
    },
    gap: itemSpacing,
    overscan,
    paddingStart,
    initialOffset
  })

  const virtualItems = listVirtualizer.getVirtualItems()

  useEffect(() => {
    if (!autoFetchNextPage || !virtualItems.length) return
    const lastItem = virtualItems[virtualItems.length - 1]
    const itemIndex = hasHead ? lastItem.index - 1 : lastItem.index

    if (itemIndex >= items.length - 1 && hasNextPage && !isFetchingNextPage && !isError) {
      fetchNextPage()
      onFetchNextPage?.()
    }
  }, [autoFetchNextPage, hasNextPage, fetchNextPage, items.length, virtualItems.length, isFetchingNextPage])

  return (
    <Box position="relative" {...props}>
      {(!!data || !!items) && (
        <Box position="relative" width="100%" height={`${listVirtualizer.getTotalSize()}px`}>
          {virtualItems.map(row => {
            const isHeadRow = hasHead && row.index === 0
            const itemIndex = hasHead ? row.index - 1 : row.index
            const isLastRow = itemIndex > items.length - 1
            const item = items[itemIndex]

            return (
              <div
                key={String(row.key)}
                ref={listVirtualizer.measureElement}
                data-index={row.index}
                style={{
                  position: 'absolute',
                  top: `${row.start}px`,
                  left: '0',
                  width: '100%',
                  height: `${row.size}`
                }}
              >
                {
                  isHeadRow
                    ? renderHead?.()
                    : isLastRow
                      ? hasNextPage ? hasLoader && renderLoader?.() : hasTail && renderTail?.()
                      : renderItem(item, itemIndex)
                }
              </div>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export const InfiniteListProgress = (props: ProgressProps) => (
  <Progress size="xs" isIndeterminate width="100%" rounded="full" color="gray.200" colorScheme="blackAlpha" {...props} />
)

const renderDefaultLoader = () => (
  <Flex height="100%" alignItems="center">
    <InfiniteListProgress />
  </Flex>
)
