import { Flex, Switch, Text } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageContainer, PageHeader } from '../../components/core'
import { useAccount, useAccountMutation } from '../../hooks'

export const Notifications = () => {
  const { t } = useTranslation()
  const account = useAccount()!
  const { mutate: updateAccount, isError } = useAccountMutation()

  const [isChecked, setIsChecked] = useState(!!account.receive_sms)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked)
    updateAccount({ receive_sms: e.target.checked })
  }

  useEffect(() => {
    if (isError) {
      setIsChecked(!!account.receive_sms)
    }
  }, [isError])

  return (
    <>
      <PageHeader title={t('views.settings.notifications.title')} />
      <PageContainer>

        <Flex
          justify="space-between"
          alignItems="center"
          bg="gray.100"
          px={5}
          mt={5}
          h={62}
          rounded={20}
        >
          <Text color="gray.900" fontSize="sm">
            {t('forms.common.fields.receiveSms.label')}
          </Text>
          <Switch
            colorScheme="brand"
            size="lg"
            isChecked={isChecked}
            onChange={onChange}
            disabled={!account?.contact_phone_number}
          />
        </Flex>
      </PageContainer>
    </>
  )
}
