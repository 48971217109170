import { Button, Stack } from '@chakra-ui/react'
import { LayoutProps } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useIsInModal } from '../../hooks'
import { ContentContainer, ContentFooter, CONTENT_PADDING } from '../core'
import { FilterFields } from './FilterFields'

interface FiltersSheetProps extends LayoutProps {
  onReset: () => void
}

export const FiltersSheet = ({
  onReset,
  ...props
}: FiltersSheetProps) => {
  const { t } = useTranslation()
  const isInModal = useIsInModal()

  return (
    <ContentContainer
      pb={5} bg="white"
      {...props}
    >
      <FilterFields pb={120} />

      <ContentFooter {...isInModal ? { left: 0 } : undefined}>
        <Stack
          spacing={2.5}
          w="full" py={5} px={CONTENT_PADDING}
        >
          <Button variant="primary" type="submit">
            {t('actions.search')}
          </Button>

          <Button variant="tertiary" size="sm" onClick={onReset}>
            {t('views.search.resetFilters')}
          </Button>
        </Stack>
      </ContentFooter>
    </ContentContainer>
  )
}
