import { Location } from '../../models'
import { HCardListSection, OptionalHCardListSectionProps } from '../core'
import { LocationCard, LOCATION_CARD_HEIGHT, LOCATION_CARD_WIDTH } from './LocationCard'

interface HorizontalLocationListSectionProps extends OptionalHCardListSectionProps<Location> {}

export const HorizontalLocationListSection = (props: HorizontalLocationListSectionProps) => {
  return (
    <HCardListSection<Location>
      itemWidth={LOCATION_CARD_WIDTH}
      itemHeight={LOCATION_CARD_HEIGHT}
      renderItem={location => <LocationCard location={location} />}
      {...props}
    />
  )
}
