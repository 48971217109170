import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { LatLng } from '../models'
import { api } from '../services'
import { QKey } from '../types'
import { useGeoCoordinates } from './geo'

export function useLocationsQuery(params?: { companyId?: string, coordinates?: LatLng }) {
  const geoCoordinates = useGeoCoordinates()
  const { companyId, coordinates = geoCoordinates } = params ?? {}
  const queryKey = [QKey.LOCATIONS, QKey.LIST, companyId, coordinates]

  return useInfiniteQuery(queryKey, async ({ pageParam }) => {
    return api.locations.list({
      company_id: companyId,
      ...coordinates,
      cursor: pageParam
    })
  }, {
    getNextPageParam: lastPage => lastPage.cursor
  })
}

export function useLocationQuery(id?: string) {
  return useQuery([QKey.LOCATIONS, id!], async () => {
    const { data } = await api.locations.find(id!)
    return data
  }, {
    enabled: !!id,
    staleTime: 10_000
  })
}
