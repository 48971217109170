import { BoxProps, Card, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import thumbsUpImg from '../../../assets/images/thumbs-up.png'
import { ContentContainer, Pill } from '../../../components/core'
import { JobImageHeader } from '../common'

export interface BenefitsSectionProps extends BoxProps {
  items: string[]
}

export const BenefitsSection = ({
  items,
  ...props
}: BenefitsSectionProps) => {
  const { t } = useTranslation()

  return (
    <ContentContainer>
      <Card p={5} {...props}>
        <JobImageHeader
          mb={5}
          title={t('views.jobs.benefitsAndPerks')}
          src={thumbsUpImg}
          alt={t('images.thumbsUp')}
          h={{ base: '35px' }}
        />
        <Flex direction="row" wrap="wrap" gap={2.5}>
          {items?.map((item) => (
            <Pill key={item} size="lg">{item}</Pill>
          ))}
        </Flex>
      </Card>
    </ContentContainer>
  )
}
