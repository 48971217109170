import { useTranslation } from 'react-i18next'
import { useSimilarJobsQuery } from '../../hooks'
import { analytics } from '../../services'
import { JobsSection, JobsSectionProps } from './JobsSection'

export interface SimilarJobsSectionProps extends JobsSectionProps {
  jobSlug: string
}

export const SimilarJobsSection = ({
  jobSlug,
  ...props
}: SimilarJobsSectionProps) => {
  const { t } = useTranslation()
  const query = useSimilarJobsQuery(jobSlug)

  return (
    <JobsSection
      title={t('views.jobs.jobsYouMightLike')}
      query={query}
      linkTo={`/jobs/${jobSlug}/similar`}
      rel="nofollow"
      scrollPreservationKey="similarJobs"
      onScrollStart={() => analytics.trackHorizontalScroll('similar jobs', { job_slug: jobSlug })}
      {...props}
    />
  )
}
