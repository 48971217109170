import { IconCoin } from '@tabler/icons-react'
import { JobBase } from '../../models'
import { Pill, PillProps } from '../core'
import { Wage } from './Wage'

export interface WagePillProps extends Omit<PillProps, 'icon'> {
  job: JobBase
  displaySupplementalPay?: boolean
  showIcon?: boolean
}

export const WagePill = ({ job, displaySupplementalPay, showIcon = true, ...props }: WagePillProps) => {
  if (!job.min_pay || !job.max_pay) {
    return null
  }

  return <Pill icon={showIcon ? IconCoin : undefined} {...props}>
    <Wage
      job={job}
      size={props.size === 'sm' ? 'xs' : 'sm'}
      displaySupplementalPay={displaySupplementalPay}
    />
  </Pill>
}
