import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    borderRadius: 'md',
    fontSize: 'sm',
    lineHeight: 1.4
  }
})

export const alertTheme = defineMultiStyleConfig({ baseStyle })
