import { Box, BoxProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { NoJobResultsNotice } from '../jobs/NoJobResultsNotice'

export interface SearchNoResultsProps extends PropsWithChildren, BoxProps {
  onShowFilters: () => void
}

export const SearchNoResults = ({
  onShowFilters,
  children,
  ...props
}: SearchNoResultsProps) => {
  const { t } = useTranslation()

  return (
    <Box mt={5} mb="58px" {...props}>
      <NoJobResultsNotice
        message={t('views.search.noResultsMessage')}
        button={{
          children: t('actions.tryAnotherSearch'),
          onClick: onShowFilters
        }}
      />

      {children}
    </Box>
  )
}
