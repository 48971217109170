import { Card, CardBody, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFormatters, useGeoCoordinates } from '../../hooks'
import { LatLng, Location } from '../../models'
import { AddressText } from '../AddressText'
import { CompanyLogo } from '../companies'
import { RegionBadge } from '../RegionBadge'

export const LOCATION_CARD_HEIGHT = 207
export const LOCATION_CARD_WIDTH = 164

interface LocationCardProps {
  location: Location
  distanceFrom?: LatLng
}

export const LocationCard = ({
  location,
  distanceFrom,
  ...props
}: LocationCardProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const geoCoordinates = useGeoCoordinates()
  const _distanceFrom = distanceFrom ?? geoCoordinates

  return (
    <Card
      as={Link} to={`/locations/${location.id}`}
      w={`${LOCATION_CARD_WIDTH}px`} h={`${LOCATION_CARD_HEIGHT}px`}
      {...props}
    >
      <CardBody as={Flex} flexDir="column" pt="30px">
        <CompanyLogo company={location.company} boxSize={50} />

        <RegionBadge record={location} distanceFrom={_distanceFrom} mt="15px" />

        <AddressText
          address={location.address}
          address2={location.address2}
          city={location.city}
          state={location.state}
          zipcode={location.zipcode}
          fontSize={11} lineHeight="14px"
          color="gray.900" mt={2.5} noOfLines={3}
        />

        {!!location.job_count && (
          <Text fontSize="sm" color="gray.300" lineHeight={1.4} mt="auto">
            {t('views.locations.jobsAvailable.long', {
              count: location.job_count,
              value: fmt.number(location.job_count)
            })}
          </Text>
        )}
      </CardBody>
    </Card>
  )
}
