import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

export type InjectedConfigQueryCache = Array<{ key: any[], data: any }>

export interface InjectedConfig {
  initial_page?: {
    key?: string
    pathname: string
    meta?: {
      title: string
      description: string
    }
    props?: any
  }
  query_cache?: InjectedConfigQueryCache
}

export const InjectedConfigContext = createContext(null as InjectedConfig | null)

interface InjectedConfigProviderProps extends PropsWithChildren {
  value?: InjectedConfig
}

export const InjectedConfigProvider = ({ value, ...props }: InjectedConfigProviderProps) => {
  const defaultValue = useMemo(() => {
    const config = window.APP_CONFIG ?? null
    // Release raw APP_CONFIG from memory.
    delete window.APP_CONFIG
    return config
  }, [])

  return <InjectedConfigContext.Provider value={value ?? defaultValue} {...props} />
}

export function useInjectedConfig() {
  return useContext(InjectedConfigContext)
}
