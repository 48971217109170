import {
  Box, BoxProps, Card, Flex, Text, VStack
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export interface FeatureCardProps extends Omit<BoxProps, 'title'> {
  image: ReactNode
  title: ReactNode
  body?: ReactNode
}

export const FeatureCard = ({
  image,
  title,
  body,
  children,
  ...props
}: FeatureCardProps) => {
  return (
    <Card p="15px" {...props}>
      <Flex justifyContent="center" mt={5} flexGrow={1}>
        {image}
      </Flex>

      <VStack spacing={2.5} mt={2.5} textAlign="center">
        <Text
          fontSize="2xl"
          fontWeight="medium"
          color="gray.900"
          lineHeight="33px"
        >
          {title}
        </Text>
        {body && (
          <Text color="gray.400" lineHeight="22px">
            {body}
          </Text>
        )}
      </VStack>

      {children && (
        <Box mt="auto" pt={5}>
          {children}
        </Box>
      )}
    </Card>
  )
}
