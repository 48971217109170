import qs, { IStringifyOptions } from 'qs'
import { Location } from 'react-router-dom'

export function addQueryString(url: string, params: object, options?: IStringifyOptions) {
  const queryStr = qs.stringify(params, { skipNulls: true, ...options })
  return queryStr ? `${url}?${queryStr}` : url
}

export function locationEquals(locA: Location, locB: Location) {
  return locationToUrl(locA) === locationToUrl(locB)
}

export function locationToUrl(loc: Location) {
  return loc.pathname + loc.search + loc.hash
}

export function searchParamsToObject(searchParams: URLSearchParams) {
  const result: { [key: string]: any } = {}
  for (const [key, value] of searchParams) {
    result[key] = value
  }
  return result
}

export function encodeQueryParams(params: { [key: string]: any }) {
  const encodedKVPairs: string[] = []

  for (const [key, param] of Object.entries(params)) {
    if (param == null) continue

    let encodedParam: string

    if (Array.isArray(param)) {
      if (!param.length) continue

      encodedParam = param.map(value => {
        if (value == null) return ''
        return encodeQueryParamValue(value)
      }).join(',')
    } else {
      encodedParam = encodeQueryParamValue(param)
    }

    encodedKVPairs.push(`${key}=${encodedParam}`)
  }

  return encodedKVPairs.join('&')
}

function encodeQueryParamValue(value: any) {
  return encodeURIComponent(String(value)).replace(/%20/g, '+')
}

export function buildJobUrl(slug: string, params: { ref?: string, src?: string, action?: string } = {}) {
  return addPageUrlParams(`/jobs/${slug}`, params)
}

export function buildBulkApplyUrl(path?: string, params: { job?: string, action?: string } = {}) {
  let url = '/bulk-apply'
  if (path) {
    url += `/${path}`
  }
  return addPageUrlParams(url, params)
}

export function addPageUrlParams(url: string, params: { [key: string]: any }) {
  const { action, ...queryParams } = params
  url = addQueryString(url, queryParams)
  if (action) {
    url += `#!${action}`
  }
  return url
}
