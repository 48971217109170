import { Badge, BadgeProps } from '@chakra-ui/react'

interface SearchFiltersBadgeProps extends BadgeProps {
  count: number
}

export const SearchFiltersBadge = ({ count, ...props }: SearchFiltersBadgeProps) => {
  if (!count) return null

  return (
    <Badge
      minW="18px" py={0.5} px={0.5}
      fontSize="2xs" rounded="3px"
      {...props}
    >
      {count}
    </Badge>
  )
}
