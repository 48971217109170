import { useParams } from 'react-router-dom'
import { JobListPage } from '../components/jobs'
import { useGeoCoordinates, useJobCategoryQuery } from '../hooks'

export const JobCategoryDetail = () => {
  const params = useParams()
  const geoCoordinates = useGeoCoordinates()!
  const query = useJobCategoryQuery(params.slug!, geoCoordinates)
  const category = query.data

  return (
    <JobListPage
      title={category?.name}
      query={query as any}
      items={category?.jobs}
    />
  )
}
