import { Box, SpaceProps } from '@chakra-ui/react'
import pick from 'lodash/pick'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateEffect } from '../../hooks'
import { FiltersSheet } from './FiltersSheet'
import { useSearch } from './SearchContext'
import { SearchFormHeader } from './SearchFormHeader'
import { SEARCH_FILTER_KEYS, SearchFilters, SearchOptions } from './types'
import { useLocationAutocomplete } from './useLocationAutocomplete'
import { useQueryAutocomplete } from './useQueryAutocomplete'

export interface SearchFormProps extends SpaceProps {
  queryInputVisible?: boolean
  filtersVisible?: boolean
  filtersToggleVisible?: boolean
  resetIncludesQuery?: boolean
  onSetFiltersVisible: (visible: boolean) => void
}

export const SearchForm = ({
  queryInputVisible = true,
  filtersVisible = true,
  filtersToggleVisible,
  resetIncludesQuery,
  onSetFiltersVisible,
  ...props
}: SearchFormProps) => {
  const { defaultFilters, searchOptions, updateSearchOptions } = useSearch()
  const filters = useMemo(() => {
    return pick(searchOptions, SEARCH_FILTER_KEYS)
  }, [searchOptions])

  const form = useForm<SearchFilters>({
    defaultValues: filters
  })

  useMemo(() => {
    if (filtersVisible) {
      form.reset(filters)
    }
  }, [filtersVisible])

  const submitSearchOptions = (values?: SearchOptions) => {
    const update: SearchOptions = {
      location: locationAutocomplete.value,
      ...form.getValues(),
      ...values
    }

    if (queryInputVisible && values?.query === undefined) {
      update.query = queryAutocomplete.value
    }

    updateSearchOptions(update)
    onSetFiltersVisible(false)
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 0)
  }
  const submitSearchOptionsRef = useRef(submitSearchOptions)
  submitSearchOptionsRef.current = submitSearchOptions

  const submit = form.handleSubmit(() => {
    submitSearchOptionsRef.current()
  })

  const handleReset = useCallback(() => {
    let options = defaultFilters as SearchOptions
    if (resetIncludesQuery) {
      options = { ...options, query: '' }
    }
    submitSearchOptionsRef.current(options)
  }, [defaultFilters, resetIncludesQuery])

  const queryAutocomplete = useQueryAutocomplete({
    initialValue: searchOptions.query,
    filtersVisible,
    onSubmit(query) {
      submitSearchOptionsRef.current({ query })
    }
  })

  const locationAutocomplete = useLocationAutocomplete({
    initialValue: searchOptions.location
  })

  const handleToggleFilters = useCallback(() => {
    if (filtersVisible) {
      onSetFiltersVisible(false)
    } else {
      onSetFiltersVisible(true)
    }
  }, [filtersVisible])

  useUpdateEffect(() => {
    queryAutocomplete.setValue(searchOptions.query ?? '')
    if (searchOptions.location) {
      locationAutocomplete.setValue(searchOptions.location)
    }
  }, [searchOptions])

  useLayoutEffect(() => {
    if (filtersVisible) {
      locationAutocomplete.setValue(searchOptions.location)
    }
  }, [filtersVisible])

  useEffect(() => {
    if (queryAutocomplete.isOpen) {
      onSetFiltersVisible(true)
    }
  }, [queryAutocomplete.isOpen])

  return (
    <Box
      as="form" pb={2.5}
      onSubmit={submit}
      {...props}
    >
      <SearchFormHeader
        queryAutocomplete={queryAutocomplete}
        locationAutocomplete={locationAutocomplete}
        queryInputVisible={queryInputVisible}
        filtersVisible={filtersVisible}
        filtersToggleVisible={filtersToggleVisible}
        onToggleFilters={handleToggleFilters}
      />

      {filtersVisible && (
        <FormProvider {...form}>
          <FiltersSheet onReset={handleReset} />
        </FormProvider>
      )}
    </Box>
  )
}
