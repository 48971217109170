import { Box, Stack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useInfiniteQueryTotal, useQueryItems } from '../../hooks'
import {
  CardListProps, CardListSkeleton, CARD_SPACING_M, ContentContainer,
  ContentSection, ContentSectionProps
} from '../core'
import { defaultKeyExtractor } from './util'

export interface OptionalCardListSectionProps<T = any> extends
  Pick<CardListProps<T>, 'query' | 'items'>,
  ContentSectionProps {
  maxItems?: number
  isLoading?: boolean
  isError?: boolean
}

export interface CardListSectionProps<T> extends
  OptionalCardListSectionProps<T>,
  Pick<CardListProps<T>, 'itemSpacing' | 'renderItem'> {
  keyExtractor?: typeof defaultKeyExtractor
  itemHeight: number
}

export const CardListSection = <T,>({
  query,
  items,
  keyExtractor = defaultKeyExtractor,
  renderItem,
  itemHeight,
  itemSpacing = CARD_SPACING_M,
  maxItems = 3,
  isLoading = query?.isLoading,
  isError = query?.isError,
  total,
  linkTo,
  ...props
}: CardListSectionProps<T>) => {
  const queryItems = useQueryItems(items ? undefined : query)
  const _items = items ?? queryItems
  const _itemsSlice = useMemo(() => _items?.slice(0, maxItems), [_items, maxItems])
  const isEmpty = !!_items && _items.length === 0

  const queryTotal = useInfiniteQueryTotal(total === undefined ? query : undefined)
  const _total = typeof total === 'boolean' ? undefined : total ?? queryTotal

  if (!isLoading && (isEmpty || isError)) {
    return null
  }

  return (
    <ContentSection
      isLoading={isLoading}
      linkTo={isEmpty ? undefined : linkTo}
      total={_total}
      {...props}
    >
      <ContentContainer>
        {isLoading ? (
          <CardListSkeleton itemHeight={itemHeight} itemSpacing={itemSpacing} count={maxItems} />
        ) : (
          <Stack spacing={`${itemSpacing}px`}>
            {_itemsSlice?.map((item, index) => (
              <Box key={keyExtractor(item, index)}>
                {renderItem(item, index)}
              </Box>
            ))}
          </Stack>
        )}
      </ContentContainer>
    </ContentSection>
  )
}
