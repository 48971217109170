import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useRef } from 'react'
import { SearchFilters, SearchOptions } from '../components/search'
import { DEFAULT_PAY_RANGE } from '../config'
import { LocalJobState, useAnalyticsParams, useAppState } from '../contexts'
import { buildJobUrl } from '../helpers'
import { encodeLatLng, milesToMeters } from '../helpers/geo'
import { ExperienceLevel, Job, JobBase, JobIndexRecord, JobPreferences, JobScheduleType, LatLng, PayType, SuggestionItem, WorkplaceType } from '../models'
import { analytics, api } from '../services'
import { QKey, SimpleQueryOptions, SortType } from '../types'
import { useGeoCoordinates } from './geo'
import { useJobPreferencesWizardCompleted } from './jobPreferences'
import { useAsyncCallback } from './util'

export function useJobUrl(slug: string, params: { action?: string, src?: string } = {}) {
  const analyticsScope = useAnalyticsParams()
  return useMemo(() => {
    return buildJobUrl(slug, { ref: analyticsScope.page, src: analyticsScope.source, ...params })
  }, [slug, params?.action, params?.src, analyticsScope.source])
}

// This is not currently supported by the api.
export const useJobSearchSuggestionsRequest = () => {
  return useAsyncCallback(async (_params: { q?: string, lat?: number, lng?: number }) => {
    // const { data } = await api.search.listSuggestions(params)
    // return data
    return [] as SuggestionItem[]
  })
}

export interface UseJobSearchQueryParams {
  query?: string
  min_pay?: number
  radius?: number // miles
  workplaces?: WorkplaceType[]
  schedule?: JobScheduleType[]
  experience_levels?: ExperienceLevel[]
  industries?: string[]
  similar_to?: string
  company_id?: string
  location_id?: string
  coordinates?: LatLng
  city_slug?: string
  state?: string
  external_application?: boolean
  sort_by?: string
  sort_type?: SortType
  sort_by_similar_to?: string
  per_page?: number
}

export const useJobSearchQuery = (
  params: UseJobSearchQueryParams = {},
  options: { enabled?: boolean } = {}
) => {
  const {
    query,
    similar_to,
    company_id,
    location_id,
    coordinates,
    city_slug,
    state,
    external_application,
    sort_by,
    sort_type,
    sort_by_similar_to,
    per_page,
    ...filters
  } = params

  const queryKey = [
    QKey.JOBS,
    QKey.SEARCH,
    {
      query,
      ...filters,
      similar_to,
      company_id,
      location_id,
      coordinates,
      city_slug,
      state,
      external_application,
      sort_by,
      sort_type,
      sort_by_similar_to,
      per_page
    }
  ]

  return useInfiniteQuery(queryKey, async ({ pageParam }) => {
    const crd = coordinates ? encodeLatLng(coordinates) : undefined
    const filterParams = searchFiltersToApiParams(filters)

    return api.search.list({
      q: query,
      ...filterParams,
      crd,
      similar_to,
      company_id,
      location_id,
      city_slug,
      state,
      external_application,
      sort_by,
      sort_type,
      sort_by_similar_to,
      per_page,
      cursor: pageParam
    })
  }, {
    cacheTime: Infinity,
    staleTime: Infinity,
    getNextPageParam: lastPage => lastPage.cursor,
    ...options
  })
}

export const useRecommendedJobsQuery = (job: JobIndexRecord) => {
  const [{ authenticated }] = useAppState()
  return useQuery([QKey.JOBS, QKey.RECOMMENDED, job.id], async () => {
    const { data } = await api.recommendedJobs.list({ crd: encodeLatLng(job.coordinate), similar_to: job.id })
    return data
  }, {
    enabled: authenticated
  })
}

export const useRelatedJobsQuery = (job?: JobIndexRecord) => {
  const defaultCoordinates = useGeoCoordinates()
  return useJobSearchQuery({
    company_id: job?.company.id,
    coordinates: job?.coordinate ?? defaultCoordinates
  }, {
    enabled: !!job
  })
}

export const useSimilarJobsQuery = (jobSlug: string) => {
  const defaultCoordinates = useGeoCoordinates()
  const job = useQueryClient().getQueryData<JobBase>([QKey.JOBS, jobSlug])
  return useJobSearchQuery({
    similar_to: jobSlug,
    coordinates: job?.coordinate ?? defaultCoordinates
  })
}

export const useJobQuery = (idOrSlug?: string, overrideOptions?: SimpleQueryOptions<Job>) => {
  const options = {
    enabled: !!idOrSlug,
    staleTime: 10_000,
    ...overrideOptions
  }

  return useQuery([QKey.JOBS, idOrSlug], async () => {
    const { data } = await api.jobs.find(idOrSlug!)
    return data
  }, options)
}

export const useApplicationRedirectJobQuery = (idOrSlug: string, utmParams?: { [key: string]: any }) => {
  const queryKey: any[] = [QKey.JOBS, idOrSlug]
  if (utmParams) {
    utmParams = { ...utmParams }
    delete utmParams.jobSlug
    delete utmParams.job_id
    if (Object.keys(utmParams)) {
      queryKey.push(utmParams)
    }
  }

  return useQuery(queryKey, async () => {
    const { data } = await api.jobs.find(idOrSlug, utmParams)
    return data
  })
}

export const useJobGroupQuery = (group: 'favorites' | 'applied') => (
  useInfiniteQuery([QKey.JOBS, QKey.LIST, { group }], async ({ pageParam }) => {
    return api.jobs.list({ group, cursor: pageParam })
  }, {
    staleTime: 10_000,
    getNextPageParam: lastPage => lastPage.cursor,
  })
)

export const useJobCategoriesQuery = () => {
  return useQuery([QKey.JOBS, QKey.CATEGORIES], async () => {
    const { data } = await api.jobCategories.list()
    return data
  }, {
    staleTime: 300_000
  })
}

export const useJobCategoryQuery = (slug: string, coordinates: LatLng) => {
  return useQuery([QKey.JOBS, QKey.CATEGORIES, slug, coordinates], async () => {
    const { data } = await api.jobCategories.find(slug, coordinates)
    return data
  })
}

export const useSetFavoriteJob = (jobId: string) => {
  const [_localState, updateLocalState] = useLocalJobState(jobId)
  const queryClient = useQueryClient()

  return useMutation(async (isFavorite?: boolean) => {
    if (isFavorite) {
      analytics.trackFavorite({ job_id: jobId })
      await api.favoriteJobs.add(jobId)
    } else {
      await api.favoriteJobs.delete(jobId)
    }
    return isFavorite
  }, {
    async onMutate(isFavorite) {
      updateLocalState({ isFavorite })
    },
    onSuccess() {
      queryClient.invalidateQueries([QKey.JOBS, 'favorites'], {
        type: 'inactive',
        refetchType: 'none'
      })
    }
  })
}

export const useToggleFavoriteJob = (job: JobBase) => {
  const setFavoriteJob = useSetFavoriteJob(job.id)
  const [localState] = useLocalJobState(job.id)
  const isFavorite = localState?.isFavorite !== undefined ? localState.isFavorite : job.is_favorite
  const isFavoriteRef = useRef(isFavorite)
  isFavoriteRef.current = isFavorite

  return useMemo(() => ({
    ...setFavoriteJob,
    mutate() {
      setFavoriteJob.mutate(!isFavoriteRef.current)
    }
  }), [])
}

export function useLocalJobState(id: string) {
  const [{ jobStateMap }, updateAppState] = useAppState()

  const update = useCallback((state?: LocalJobState) => {
    updateAppState(current => {
      let currentMap = current.jobStateMap
      if (!state) {
        currentMap = { ...currentMap }
        delete currentMap[id]
        return { jobStateMap: currentMap }
      } else {
        return {
          jobStateMap: {
            ...currentMap,
            [id]: { ...currentMap[id], ...state }
          }
        }
      }
    })
  }, [id])

  return useMemo(() => {
    return [jobStateMap[id] ?? {} as LocalJobState, update] as const
  }, [jobStateMap[id], update])
}

export function usePayRangeQuery(params: { positions?: string[], coordinates?: LatLng }) {
  return useQuery([QKey.PAY_RANGE, params], async () => {
    if (!params.coordinates) {
      return DEFAULT_PAY_RANGE
    }
    const { data } = await api.payRange.get({
      positions: params.positions?.join(','),
      ...params.coordinates
    })
    if (data.max === 0) {
      return DEFAULT_PAY_RANGE
    }
    return data
  })
}

export function useMatchedJobsEstimateQuery(
  params: JobPreferences & LatLng,
  options: { enabled?: boolean, onSuccess?: (data: { total: number }) => void } = {}
) {
  return useQuery([QKey.JOBS, QKey.MATCHED, 'estimate', params], async () => {
    const { data } = await api.matchedJobs.createEstimate(params)
    return data
  }, {
    cacheTime: 60_0000,
    staleTime: 60_0000,
    ...options
  })
}

export function useMatchedJobsQuery() {
  return useInfiniteQuery([QKey.JOBS, QKey.MATCHED], async ({ pageParam }) => {
    return api.matchedJobs.list({ cursor: pageParam })
  }, {
    enabled: useJobPreferencesWizardCompleted(),
    getNextPageParam: lastPage => lastPage.cursor,
    cacheTime: 60_0000,
    staleTime: 60_0000
  })
}
interface HourlyPayValues {
  minHourlyPay: number
  maxHourlyPay: number
  avgHourlyPay: number
  minHourlyTips: number
  maxHourlyTips: number
  avgHourlyTips: number
}

export function useHourlyPayValues<T extends Job | undefined>(job: T): T extends Job ? HourlyPayValues : undefined {
  return useMemo(() => {
    if (!job) return undefined as any
    const isDailyPay = job.pay_type === PayType.DAILY
    const divisor = isDailyPay ? 8 : 1

    const minHourlyPay = job.min_pay ? job.min_pay / divisor : 0
    const maxHourlyPay = job.max_pay ? job.max_pay / divisor : 0
    const minHourlyTips = job.min_tips ? job.min_tips / divisor : 0
    const maxHourlyTips = job.max_tips ? job.max_tips / divisor : 0

    const calculateAverage = (min: number, max: number) => {
      return Number(((min * 100 + max * 100) / 100 / 2).toFixed(2))
    }

    return {
      minHourlyPay,
      maxHourlyPay,
      avgHourlyPay: calculateAverage(minHourlyPay, maxHourlyPay),
      minHourlyTips,
      maxHourlyTips,
      avgHourlyTips: calculateAverage(minHourlyTips, maxHourlyTips)
    }
  }, [job])
}

export function useBetterJobsQuery(slug: string, searchOptions: SearchOptions = {}) {
  const defaultCoordinates = useGeoCoordinates()
  const { location, ...options } = searchOptions
  const coordinates = location?.coordinates ?? defaultCoordinates

  const queryKey = [
    QKey.JOBS,
    slug,
    QKey.BETTER_JOBS,
    {
      coordinates,
      ...options
    }
  ]

  return useInfiniteQuery(queryKey, async ({ pageParam }) => {
    const filterParams = searchFiltersToApiParams(searchOptions)
    return api.jobs.listBetterJobs(slug, {
      ...coordinates,
      ...filterParams,
      cursor: pageParam
    })
  }, {
    getNextPageParam: lastPage => lastPage.cursor,
    cacheTime: 60_0000,
    staleTime: 60_0000
  })
}

export function useJobListFilter(jobs?: JobIndexRecord[]) {
  const [{ jobStateMap }] = useAppState()
  return useMemo(() => {
    return jobs?.filter(job => !jobStateMap[job.id]?.isDisliked)
  }, [jobs, jobStateMap])
}

function searchFiltersToApiParams(filters: SearchFilters) {
  const {
    radius,
    living_wage,
    min_pay,
    workplaces,
    schedule,
    experience_levels,
    industries,
  } = filters

  const radiusInMeters = radius
    ? Math.round(milesToMeters(radius))
    : undefined

  return {
    radius: radiusInMeters,
    living_wage,
    min_pay,
    workplaces: workplaces?.join(','),
    schedule: schedule?.join(','),
    experience_levels: experience_levels?.join(','),
    industries: industries?.join(',')
  }
}
