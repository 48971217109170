import { Box, BoxProps } from '@chakra-ui/react'

export const FeedCard = (props: BoxProps) => (
  <Box
    pos="relative"
    height="100%"
    bg="gray.100"
    rounded={20}
    overflow="hidden"
    {...props}
  />
)
