import { useQueryItems, useScrollYTracker } from '../../hooks'
import { CardListSkeleton } from './CardListSkeleton'
import { InfiniteList, InfiniteListProps } from './InfiniteList'
import { CARD_SPACING_M, ContentContainer, CONTENT_PADDING } from './layout'
import { NoResultsNotice } from './notices'
import { StatusOverlay, StatusOverlayProps } from './StatusOverlay'

export interface CardListProps<T> extends
  Omit<InfiniteListProps<T>, 'estimateItemSize'>,
  Pick<StatusOverlayProps, 'renderNoResults' | 'renderError'> {
  estimateItemSize?: InfiniteListProps<T>['estimateItemSize']
  itemHeight: number
}

export interface CardListParentProps<T> extends
  Pick<CardListProps<T>, 'query' | 'items' | 'renderNoResults' | 'renderError' | 'estimateItemSize'> { }

export const CardList = <T,>({
  pt,
  pb,
  px = CONTENT_PADDING,
  estimateItemSize,
  itemHeight,
  itemSpacing = CARD_SPACING_M,
  query,
  items,
  renderNoResults = defaultRenderNoResults,
  renderError,
  children,
  ...listProps
}: CardListProps<T>) => {
  const initialOffset = useScrollYTracker()
  const queryItems = useQueryItems(items ? undefined : query)
  const _items = items ?? queryItems

  return (
    <ContentContainer flexGrow={1} pb={pb} px={px} pt={pt}>
      {_items && !_items.length ? renderNoResults?.() : (
        <InfiniteList<T>
          pb={CONTENT_PADDING}
          itemSpacing={itemSpacing}
          initialOffset={initialOffset}
          query={query}
          items={_items}
          estimateItemSize={estimateItemSize ?? (() => itemHeight)}
          overscan={10}
          {...listProps}
        />
      )}

      <StatusOverlay
        query={query}
        renderLoading={() => (
          <CardListSkeleton px={CONTENT_PADDING} itemHeight={itemHeight} itemSpacing={itemSpacing} />
        )}
        renderNoResults={null}
        renderError={renderError}
      />

      {children}
    </ContentContainer>
  )
}

function defaultRenderNoResults() {
  return <NoResultsNotice />
}
