import type Hls from 'hls.js'
import { useEffect, useRef, useState } from 'react'
import { useIsMounted } from '../../../hooks'

export interface UseHlsVideoLoaderProps {
  url: string
  onVideoLoad?: (width: number, height: number) => void
}

export function useHlsVideoLoader({ url, onVideoLoad }: UseHlsVideoLoaderProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [initializing, setInitializing] = useState(true)
  const hlsRef = useRef<Hls>()
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState<unknown>()
  const isMounted = useIsMounted()

  useEffect(() => {
    const video = videoRef.current!

    const loadHls = async () => {
      try {
        const { default: Hls } = await import('hls.js')
        if (!isMounted()) return

        if (Hls.isSupported()) {
          const hls = new Hls()
          hlsRef.current = hls
          hls.attachMedia(video)
          hls.on(Hls.Events.ERROR, (_event, { fatal, error }) => {
            if (fatal) {
              setError(error)
              console.error(error)
            }
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        setInitializing(false)
      }
    }

    const handleError = () => {
      setError(video.error)
    }

    video.addEventListener('error', handleError)

    loadHls()

    return () => {
      video.removeEventListener('error', handleError)
      hlsRef.current?.destroy()
    }
  }, [])

  useEffect(() => {
    if (initializing) return

    setLoaded(false)
    setError(undefined)
    hlsRef.current?.loadSource(url)

    const timer = setInterval(() => {
      const video = videoRef.current
      if (video?.videoWidth) {
        setLoaded(true)
        onVideoLoad?.(video.videoWidth, video.videoHeight)
      }
    }, 50)

    return () => clearInterval(timer)
  }, [initializing, url])

  /**
   * This is a workaround for a webkit mobile bug that prevents video
   * dimensions from updating when the screen orientation changes
   */
  useEffect(() => {
    if (!window.matchMedia) return
    const portrait = window.matchMedia('(orientation: portrait)')
    const handleOrientationChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        const video = videoRef.current!
        video.style.objectFit = 'cover'
        setTimeout(() => {
          video.style.objectFit = 'contain'
        }, 100)
      }
    }
    portrait.addEventListener('change', handleOrientationChange)

    return () => {
      portrait.removeEventListener('change', handleOrientationChange)
    }
  })

  return {
    url,
    videoRef,
    hlsRef,
    initializing,
    loaded,
    error
  }
}

export type HlsVideoLoader = ReturnType<typeof useHlsVideoLoader>
