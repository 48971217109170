import { Image, Link as RawLink, LinkProps, Text, TextProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import logo from '../../../assets/images/logotype.svg'

export const MIN_PAGE_WIDTH = '300px'
export const CARD_SPACING_S = 10
export const CARD_SPACING_M = 20

export const HeaderTitle = (props: TextProps) => (
  <Text
    flex={1}
    fontSize={{ base: '18px', xs: '20px', sm: '24px' }}
    fontWeight={500}
    noOfLines={1}
    lineHeight={1.4}
    wordBreak="break-all"
    color="gray.900"
    {...props}
  />
)

export const HeaderLogo = (props: LinkProps) => {
  const { t } = useTranslation()
  return (
    <RawLink display="block" href="/" {...props}>
      <Image src={logo} height="40px" alt={t('images.logo')} mt="3px" />
    </RawLink>
  )
}
