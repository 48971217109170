import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_PAY_RANGE } from '../../config'
import { useFormatters } from '../../hooks'
import { SimpleSlider, SimpleSliderProps } from '../core'

export const PaySlider = forwardRef<HTMLDivElement, SimpleSliderProps>(({
  value = DEFAULT_PAY_RANGE.min,
  min = DEFAULT_PAY_RANGE.min,
  max = DEFAULT_PAY_RANGE.max,
  ...props
}, ref) => {
  const { t } = useTranslation()
  const fmt = useFormatters()

  return (
    <SimpleSlider
      ref={ref}
      value={value}
      min={min}
      max={max}
      step={0.25}
      showRangeLabels
      rangeLabelWidth={12}
      minRangeLabelValue={fmt.currency(min)}
      maxRangeLabelValue={fmt.currency(max)}
      tooltipLabel={fmt.currency(value ?? 0)}
      aria-label={t('views.search.fields.pay.label')}
      {...props}
    />
  )
})
