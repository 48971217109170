import { GeoLocation, LatLng } from '../models'
import { googleApi } from '../services'

export enum GeoDistanceUnit {
  K = 'Kilometers',
  N = 'Nautical miles'
}

/**
 * Calculate lat lng distance in meters
 * Ref: https://www.geodatasource.com/developers/javascript
 * To re-check the distance: https://www.meridianoutpost.com/resources/etools/calculators/calculator-latitude-longitude-distance.php?
 */
export function distanceBetween(origin: LatLng, destination: LatLng) {
  if ((origin.lat == destination.lat) && (origin.lng == destination.lng)) {
    return 0
  } else {
    const radlat1 = Math.PI * origin.lat / 180
    const radlat2 = Math.PI * destination.lat / 180
    const theta = origin.lng - destination.lng
    const radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)

    if (dist > 1) {
      dist = 1
    }

    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515

    return dist * 1609.344
  }
}

export type FetchGeoLocationInput = { placeId: string } | { address: string } | LatLng

export async function fetchGeoLocation(input: FetchGeoLocationInput, preferredType?: string) {
  let results: google.maps.GeocoderResult[]
  const anyInput = input as any

  if (anyInput.placeId) {
    results = await googleApi.geocode({ placeId: anyInput.placeId })
  } else if (anyInput.address) {
    results = await googleApi.geocode({ address: anyInput.address })
  } else {
    results = await googleApi.geocode({ location: anyInput })
  }

  let result = preferredType
    ? results.find(r => r.types.includes(preferredType))
    : undefined

  if (!result) result = results[0]

  if (result) {
    const { location } = result.geometry

    const data: GeoLocation = {
      description: result.formatted_address,
      coordinates: { lat: location.lat(), lng: location.lng() },
      placeId: result.place_id,
      addressComponents: result.address_components
    }

    return data
  }
}

export function encodeLatLng(coordinates: LatLng): string {
  const coordString = `${coordinates.lat}:${coordinates.lng}`
  return btoa(coordString)
}

export function decodeLatLng(encoded: string): LatLng {
  const parts = atob(encoded).split(':')
  return { lat: Number(parts[0]), lng: Number(parts[1]) }
}

export function milesToMeters(miles: number) {
  return miles * 1609.344
}

export function metersToMiles(meters: number) {
  return meters / 1609.344
}

export function extractAddressComponents(location: GeoLocation) {
  return {
    zipcode: location.addressComponents?.find(c => c.types[0] === 'postal_code')?.short_name
  }
}

export function sanitizeZipcode(zipcode: string) {
  zipcode = zipcode.trim()
  if ((/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(zipcode)) {
    return zipcode
  }
}
