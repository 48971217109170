import { Helmet } from 'react-helmet-async'
import { PageHeader, StatusOverlay } from '../../../components/core'
import { SearchContext, useSearchController } from '../../../components/search'
import { AnalyticsParamsProvider } from '../../../contexts'
import { useModule } from '../../../hooks'
import { Job } from '../../../models'
import { BulkApplyCollectContext } from '../../../modules/gamify/contexts'
import { JobDetailVariantProps } from '../common'
import { useJobDetailLoaded } from '../hooks/useJobDetailLoaded'

export const BulkApplyLanding = ({
  slug,
  job,
  ...props
}: JobDetailVariantProps) => {
  const { useBulkApplyCollectController, BulkApplySearchPage } = useModule('gamify')
  const controller = useBulkApplyCollectController(job?.id)
  const searchController = useSearchController()
  const { loadingQuery } = controller

  return (
    <AnalyticsParamsProvider page="Bulk Apply Landing">
      <PageHeader variant="overlay-dark" backButton={false} logo={false} />

      {!loadingQuery && (
        <SearchContext.Provider value={searchController}>
          <BulkApplyCollectContext.Provider value={controller}>
            <BulkApplySearchPage anchorJobSlug={slug} />
          </BulkApplyCollectContext.Provider>
        </SearchContext.Provider>
      )}

      {job && <JobLoaded {...props} job={job} slug={slug} />}

      <StatusOverlay
        query={loadingQuery}
        error={null}
      />
    </AnalyticsParamsProvider>
  )
}

interface LoadedProps extends JobDetailVariantProps {
  job: Job
}

const JobLoaded = (props: LoadedProps) => {
  const { pageTitle, canonicalUrl } = useJobDetailLoaded({
    ...props,
    pageType: 'Bulk Apply',
    jobViewFeature: 'bulk-apply-landing'
  })

  return (
    <Helmet title={pageTitle}>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  )
}
