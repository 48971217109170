import { useCallback, useEffect, useState } from 'react'
import { googleApi } from '../services'

export interface UseGooglePlacesAutocompleteOptions {
  // https://developers.google.com/maps/documentation/javascript/supported_types
  query?: string
  types?: google.maps.places.AutocompletionRequest['types']
  country?: string
  onInitError?: (err: unknown) => void
  onFetchError?: (err: unknown) => void
}

export function useGooglePlacesAutocomplete(options: UseGooglePlacesAutocompleteOptions) {
  const {
    types,
    country = 'us',
    onInitError = console.error,
    onFetchError = console.error
  } = options ?? {}
  const [placesService, setPlacesService] = useState<google.maps.places.AutocompleteService | undefined>(undefined)
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([])

  const initializeService = () => {
    setPlacesService(new window.google.maps.places.AutocompleteService())
  }

  useEffect(() => {
    const init = async () => {
      await googleApi.initialize()
      initializeService()
    }

    init().catch(onInitError)
  }, [])

  const fetchPredictions = useCallback(async (input: string) => {
    if (!placesService) return

    try {
      const result = await placesService.getPlacePredictions({
        input,
        types,
        componentRestrictions: { country }
      })
      if (result?.predictions) {
        setPredictions(result.predictions)
      } else {
        onFetchError(new Error('Google API Error'))
      }
    } catch (err) {
      onFetchError(err)
    }
  }, [placesService, types?.join(','), location, country])

  return {
    initialized: !!placesService,
    predictions,
    fetchPredictions,
    setPredictions
  }
}
