import { BoxProps, HStack, Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { SliderMarks, SliderRangeLabel, SliderTooltip } from './sliderElements'

export interface SimpleSliderProps extends SliderProps {
  showRangeLabels?: boolean
  rangeLabelWidth?: BoxProps['w']
  minRangeLabelValue?: number | string
  maxRangeLabelValue?: number | string
  stepMarksValues?: number[]
  tooltipLabel?: string
  floatingTooltip?: boolean
}

export const SimpleSlider = forwardRef<HTMLDivElement, SimpleSliderProps>(({
  showRangeLabels = false,
  rangeLabelWidth = 10,
  minRangeLabelValue = 0,
  maxRangeLabelValue = 1,
  stepMarksValues,
  tooltipLabel = '',
  floatingTooltip,
  value,
  ...sliderProps
}, ref) => {

  return (
    <HStack spacing={3} pb={floatingTooltip ? 0 : 10}>
      {showRangeLabels && (
        <SliderRangeLabel minW={rangeLabelWidth}>
          {minRangeLabelValue}
        </SliderRangeLabel>
      )}

      <Slider
        ref={ref}
        value={value}
        {...sliderProps}
      >
        {stepMarksValues && stepMarksValues.length > 0 && (
          <SliderMarks maxRange={value} rangeValues={stepMarksValues} />
        )}
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>

        <SliderThumb />

        <SliderTooltip value={value} label={tooltipLabel} />
      </Slider>

      {showRangeLabels && (
        <SliderRangeLabel minW={rangeLabelWidth}>
          {maxRangeLabelValue}
        </SliderRangeLabel>
      )}
    </HStack>
  )
})
