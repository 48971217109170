import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { cookie } from '../services'
import { CookieKey } from '../services/cookie'
import { useSessionStorageSetter } from './SessionStorageContext'

export interface LocalJobState {
  isViewed?: boolean
  isFavorite?: boolean
  isFinishApplicationDialogViewed?: boolean
  isDisliked?: boolean
}

export interface AppState {
  ready: boolean
  authenticated: boolean
  authEmail?: string
  jobStateMap: { [id: string]: LocalJobState }
  scrollYCache: { [key: string]: number } // state for useScrollYTracker
  scrollXCache: { [key: string]: number } // state for useScrollXTracker
}

type AppStateUpdateValues = Partial<AppState> | ((state: AppState) => Partial<AppState>)

interface AppStateContextValue {
  state: AppState
  update: (values: AppStateUpdateValues) => void
}

export const AppStateContext = createContext({} as AppStateContextValue)

export interface AppStateProviderProps extends PropsWithChildren {
  initialState?: Partial<AppState>
}

export const AppStateProvider = ({ initialState, children }: AppStateProviderProps) => {
  const [state, setState] = useState<AppState>(() => ({
    ready: false,
    authenticated: !!cookie.get(CookieKey.ACCESS_TOKEN),
    jobStateMap: {},
    scrollYCache: {},
    scrollXCache: {},
    ...initialState
  }))

  const authenticatedRef = useRef(state.authenticated)
  authenticatedRef.current = state.authenticated

  const update = useCallback((values: AppStateUpdateValues) => {
    setState(prevState => ({
      ...prevState,
      ...(typeof values === 'function' ? values(prevState) : values)
    }))
  }, [])

  const contextValue: AppStateContextValue = useMemo(() => ({ state, update }), [state])

  const setPostAuthRedirect = useSessionStorageSetter('postAuthRedirect')

  const handleWindowFocus = useCallback(() => {
    if (window.__COSMOS__) return

    if (!authenticatedRef.current && cookie.get(CookieKey.ACCESS_TOKEN)) {
      // Prevent post-auth redirect when reloading after authentication.
      setPostAuthRedirect(null)
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('focus', handleWindowFocus)
    return () => {
      window.removeEventListener('focus', handleWindowFocus)
    }
  }, [])

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  const context = useContext(AppStateContext)
  return [context.state, context.update] as [AppState, AppStateContextValue['update']]
}

export const useUpdateAppState = () => {
  return useContext(AppStateContext).update
}
