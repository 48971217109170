import { BoxProps, HStack, Text, TextProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { FeedItemType } from '../../../models'
import { SCROLLBAR_HIDDEN } from '../../../styles'
import { CONTENT_PADDING } from '../../core'

export const FEED_CARD_HEIGHTS = {
  location: 320,
  ending: 250,
  [FeedItemType.JOB_CATEGORY]: 706,
  [FeedItemType.FEED_SETTINGS_PROMPT]: 190,
  [FeedItemType.SMS_NOTIFICATIONS_PROMPT]: 203
}

export const FeedCardHeaderText = (props: PropsWithChildren<TextProps>) => (
  <Text fontWeight={500} fontSize="xl" lineHeight="26px" {...props} />
)

export const FeedCardDetailText = (props: PropsWithChildren<TextProps>) => (
  <Text fontSize="xs" {...props} />
)

export const HorizontalList = (props: BoxProps) => (
  <HStack
    mt={5}
    px={CONTENT_PADDING}
    pb={3}
    overflowX="auto"
    css={SCROLLBAR_HIDDEN}
    {...props}
  />
)
