import { sliderAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'brand.500',
    boxSize: '18px'
  },
  track: {
    bg: 'gray.900',
  },
  filledTrack: {
    bg: 'brand.500'
  }
})

export const sliderTheme = defineMultiStyleConfig({ baseStyle })
