import { Button, Icon } from '@chakra-ui/react'
import { IconAlertTriangle } from '@tabler/icons-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGoBack } from '../../../contexts'
import { getErrorMessage } from '../../../helpers'
import { ApiClientError } from '../../../lib/apiClient'
import { SubmitButton } from '../SubmitButton'
import { Notice, NoticeProps } from './Notice'

export interface ErrorNoticeProps extends NoticeProps {
  isFetching?: boolean
  error?: unknown
  onRefetch?: () => void
}

export const ErrorNotice = ({
  title,
  error,
  button,
  isFetching,
  onRefetch,
  ...props
}: ErrorNoticeProps) => {
  const { t } = useTranslation()
  const errorMessage = useMemo(() => {
    if (error) {
      return getErrorMessage(error)
    }
  }, [error])

  if (button === undefined) {
    if (error instanceof ApiClientError && error.status === 404) {
      button = <BackButton />
    } else if (onRefetch) {
      button = (
        <SubmitButton variant="secondary" size="sm" isLoading={isFetching} onClick={() => onRefetch?.()}>
          {t('actions.tryAgain')}
        </SubmitButton>
      )
    }
  }

  return (
    <Notice
      image={
        <Icon
          as={IconAlertTriangle}
          boxSize={12}
        />
      }
      title={title ?? errorMessage}
      button={button}
      {...props}
    />
  )
}

const BackButton = () => {
  const { t } = useTranslation()
  const goBack = useGoBack()

  return (
    <Button variant="secondary" size="sm" onClick={goBack}>
      {t('actions.goBack')}
    </Button>
  )
}
