import { Flex, Icon, List, ListItem, SpaceProps, Stack, Text } from '@chakra-ui/react'
import { IconDiscountCheck } from '@tabler/icons-react'

export interface RequirementListProps extends SpaceProps {
  items: string[]
}

export const RequirementList = ({
  items,
  ...props
}: RequirementListProps) => (
  <List as={Stack} spacing={2.5} {...props}>
    {items?.map((item, index) => (
      <ListItem
        as={Flex}
        w="fit-content"
        key={index}
        borderRadius={20}
      >
        <Icon as={IconDiscountCheck} boxSize={6} my="1px" />
        <Flex alignItems="center">
          <Text fontSize="sm" lineHeight={1.4} ml="8px" fontWeight="400">
            {item}
          </Text>
        </Flex>
      </ListItem>
    ))}
  </List>
)
