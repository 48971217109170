import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppState, useClearLocalStorage, useUpdateAppState } from '../contexts'
import { AccountUpdateRequest } from '../dtos'
import { deriveWizardState } from '../helpers'
import {
  selectAccount, selectBasicProfileWizardSteps
} from '../selectors/account'
import { analytics, api } from '../services'
import { QKey } from '../types'
import { useRevokeAuth } from './auth'

export const useAccountQuery = () => {
  const [{ authenticated }] = useAppState()

  return useQuery([QKey.ACCOUNT], async () => {
    const { data } = await api.account.get()
    return data
  }, {
    select: selectAccount,
    enabled: authenticated,
    cacheTime: Infinity,
    staleTime: 120_000
  })
}

export const useAccount = () => {
  return useAccountQuery().data
}

export const useAccountMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(async (data: AccountUpdateRequest) => {
    const body = await api.account.update(data)
    return body.data
  }, {
    onSuccess(account) {
      queryClient.setQueryData([QKey.ACCOUNT], account)
    }
  })
}

export const usePreferencesMutation = () => {
  const queryClient = useQueryClient()
  const updateAppState = useUpdateAppState()

  return useMutation(async (data: AccountUpdateRequest['preferences']) => {
    const body = await api.account.update({ preferences: data })
    return body.data
  }, {
    onSuccess(data) {
      queryClient.setQueryData([QKey.ACCOUNT], data)
      // Reset feed and categories.
      queryClient.resetQueries([QKey.FEED])
      queryClient.resetQueries([QKey.JOBS, QKey.CATEGORIES])
      updateAppState(prev => ({
        scrollYCache: { ...prev.scrollYCache, '/home': 0 }
      }))
    }
  })
}

export const useRegisterMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(async () => {
    const body = await api.account.register()
    return body.data
  }, {
    onSuccess(account) {
      queryClient.setQueryData([QKey.ACCOUNT], account)
      analytics.trackRegistrationComplete()
    }
  })
}

export const useAccountDeletion = () => {
  const revokeAuth = useRevokeAuth()
  const clearLocalStorage = useClearLocalStorage()

  return useMutation(async () => {
    await api.account.delete()
  }, {
    onSuccess() {
      clearLocalStorage()
      revokeAuth()
    }
  })
}

export function useBasicProfileWizardState() {
  const account = useAccount()!
  return deriveWizardState(selectBasicProfileWizardSteps(account))
}
