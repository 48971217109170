import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { DependencyList, EffectCallback, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const updateRef = useRef(false)
  useEffect(() => {
    if (updateRef.current) {
      return effect()
    }
    updateRef.current = true
  }, deps)
}

export function useUpdateLayoutEffect(effect: EffectCallback, deps?: DependencyList) {
  const updateRef = useRef(false)
  useLayoutEffect(() => {
    if (updateRef.current) {
      return effect()
    }
    updateRef.current = true
  }, deps)
}

export function useDebounce<T>(value: T, delay = 300): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])
  return debouncedValue
}

export function useDebouncedCallback<T extends (...args: any) => any>(callback: T, deps: DependencyList, delay = 300) {
  const debouncedFnRef = useRef<DebouncedFunc<T>>()
  return useMemo(() => {
    debouncedFnRef.current?.flush()
    const fn = debounce(callback, delay)
    debouncedFnRef.current = fn
    return fn
  }, [...deps, delay])
}

export function useAsyncCallback<R = unknown, Args extends any[] = any[]>(fn: (...args: Args) => (Promise<R> | R)) {
  const [state, setState] = useState<{ counter: number, isLoading: boolean, error?: unknown, result?: R }>({ counter: 0, isLoading: false })
  const executeRef = useRef<(throwError: boolean, ...args: Args) => Promise<R | undefined>>()

  executeRef.current = async (throwError: boolean, ...args: Args) => {
    const counter = state.counter + 1
    setState(prevState => ({ ...prevState, counter, isLoading: true, error: undefined }))

    try {
      const result = await fn(...args)
      let canceled = false
      setState(prevState => {
        canceled = prevState.counter !== counter
        return canceled ? prevState : { ...prevState, isLoading: false, result }
      })
      return canceled ? undefined : result
    } catch (error) {
      let canceled = false
      setState(prevState => {
        canceled = prevState.counter !== counter
        return canceled ? prevState : { ...prevState, isLoading: false, error }
      })
      if (!canceled && throwError) {
        throw error
      }
    }
  }

  const execute = useCallback((...args: Args) => {
    executeRef.current!(false, ...args)
  }, [])

  const executeAsync = useCallback(async (...args: Args) => {
    return executeRef.current!(true, ...args)
  }, [])

  const reset = useCallback(async (result?: R) => {
    setState({ counter: 0, isLoading: false, result })
  }, [])

  return useMemo(() => ({
    ...state,
    execute,
    executeAsync,
    reset,
  }), [state])
}
