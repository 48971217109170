import { Box, Flex, FormControl, FormErrorMessage, ListProps, SlideFade } from '@chakra-ui/react'
import noop from 'lodash/noop'
import { ReactNode, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { AutocompleteToggleButton } from '../../components/autocomplete/AutocompleteToggleButton'
import { AutocompleteInput, ClearableInputGroup, FixedContent, FixedDropdown, HeaderTitle, PageHeader, PageHeaderProps, PageHeading } from '../../components/core'
import { UseEnhancedComboboxReturn } from '../../hooks'

export interface AutocompleteFormProps {
  pageHeaderProps?: PageHeaderProps
  title: string
  shortTitle?: string
  placeholder: string
  combobox: UseEnhancedComboboxReturn<any>
  inputPropsOptions?: any
  disabled?: boolean
  errorMessage?: string
  renderMenu: (props: ListProps) => ReactNode
  children?: ReactNode
  onSuccess?: () => void
}

export const AutocompleteForm = ({
  pageHeaderProps,
  title,
  shortTitle = title,
  placeholder,
  combobox,
  inputPropsOptions,
  disabled,
  errorMessage,
  renderMenu,
  children
}: AutocompleteFormProps) => {
  const {
    isOpen,
    inputValue,
    getInputProps,
    getMenuProps,
    getToggleButtonProps,
    setInputValue,
    openMenu,
    blurInput
  } = combobox

  const inputProps = getInputProps(inputPropsOptions)
  const commonInputProps = {
    placeholder,
    value: inputProps.value,
    'aria-label': placeholder,
    isDisabled: disabled
  }

  const handleClear = useCallback(() => {
    setInputValue('')
    openMenu()
  }, [])

  return (
    <>
      <Helmet title={shortTitle || title} />

      {/* Suppress downshift input ref error */}
      <Box ref={inputProps.ref} />

      <PageHeader {...pageHeaderProps} visible={!isOpen} />

      <FixedContent as="form" top={0} bg="white" zIndex="sticky">
        <SlideFade offsetY={50} in={isOpen} unmountOnExit>
          <Flex alignItems="center" mt={3}>
            <HeaderTitle>{shortTitle}</HeaderTitle>
            <Box ml="auto">
              <AutocompleteToggleButton ml={3} {...getToggleButtonProps()} />
            </Box>
          </Flex>

          <ClearableInputGroup mt={3.5} value={inputValue} onClear={handleClear}>
            <AutocompleteInput {...inputProps} {...commonInputProps} />
          </ClearableInputGroup>
        </SlideFade>
      </FixedContent>

      <SlideFade offsetY={-60} initial={false} in={!isOpen}>
        <PageHeading setDocumentTitle={false}>{title}</PageHeading>

        <FormControl isInvalid={!!errorMessage}>
          <ClearableInputGroup size="lg" value={inputValue} onClear={handleClear}>
            <AutocompleteInput
              {...commonInputProps}
              pr={inputValue ? 12 : undefined}
              isDisabled={isOpen}
              onChange={noop}
              onFocus={openMenu}
            />
          </ClearableInputGroup>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>

        {children}
      </SlideFade>

      <FixedDropdown pt="95px" isOpen={isOpen} onTouchStart={blurInput}>
        {renderMenu({ ...getMenuProps(), mt: 4 })}
      </FixedDropdown>
    </>
  )
}
