import { Box, Button, ButtonProps, Flex, forwardRef, Icon, Text } from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import { ReactNode, useCallback } from 'react'
import { Link, To } from 'react-router-dom'
import { Swiper as SwiperClass } from 'swiper'
import { useLayoutContext, useModalManager } from '../../contexts'
import { Company, Media } from '../../models'
import { analytics } from '../../services'
import { BOX_SHADOW_1 } from '../../styles'
import { CompanyLogo } from '../companies'
import { ContentContainer, VideoPlayerModal } from '../core'
import { MediaCarousel } from '../media'

export const DETAIL_MEDIA_HEIGHT = { base: 260, sm: 320 }

interface DetailHeaderProps {
  company?: Company | null
  title: string
  subtitle?: string
  mediaItems?: Media[] | null
  mediaOverlayActions?: ReactNode
  mediaAnalyticsParams: { [key: string]: any }
  videoPlayerModalActions?: ReactNode
  action?: ReactNode
}

export const DetailHeader = ({
  company,
  title,
  subtitle,
  mediaItems,
  mediaOverlayActions,
  mediaAnalyticsParams,
  videoPlayerModalActions,
  action
}: DetailHeaderProps) => {
  const { pageHeaderHeight } = useLayoutContext()
  const modalManager = useModalManager()
  const hasMedia = !!mediaItems?.length

  const handlePlayVideo = useCallback(
    (media: Media) => {
      modalManager.open(VideoPlayerModal, {
        children: videoPlayerModalActions,
        media,
      })
      analytics.trackPlayVideo({ ...mediaAnalyticsParams, video: media.video })
    },
    [location]
  )

  const handleSlideChange = (swiper: SwiperClass) => {
    const slideIndex = swiper.realIndex
    analytics.trackSwipeMediaCarousel({
      ...mediaAnalyticsParams,
      slide_index: slideIndex,
    })
  }

  return (
    <>
      {hasMedia && (
        <MediaCarousel
          h={DETAIL_MEDIA_HEIGHT} w="100%"
          items={mediaItems}
          overlayActions={mediaOverlayActions}
          _before={{
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 'full',
            height: 'full',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%)',
            pointerEvents: 'none',
            zIndex: 2
          }}
          onPlayVideo={handlePlayVideo}
          onSlideChange={handleSlideChange}
        />
      )}

      <ContentContainer
        as={Flex} flex={1} flexDir="column" alignItems="center"
        textAlign="center"
        zIndex={1}
      >
        <Flex
          pos="relative"
          {...(hasMedia ? { top: '-35px', mb: '-28px' } : { mt: `${pageHeaderHeight + 20}px`, mb: '10px' })}
          align="center" justify="center"
          boxSize={70}
          borderRadius="full" bg="white"
          boxShadow={BOX_SHADOW_1}
          zIndex={-1}
        >
          <CompanyLogo
            company={company}
            boxSize={66}
            boxShadow="none"
          />
        </Flex>

        {action && (
          <Box mt={2} mb={4}>
            {action}
          </Box>
        )}

        <Text
          as="h1"
          w="full" mt={action ? 0 : 2} mb={2}
          fontSize="2xl" fontWeight={500} lineHeight={1.2} color="gray.900"
        >
          {title}
        </Text>

        {subtitle && (
          <Text
            as="h2"
            w="full"
            fontSize="md" lineHeight={1.2} color="gray.400"
          >
            {subtitle}
          </Text>
        )}
      </ContentContainer>
    </>
  )
}

export const DetailHeaderActionButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    ref={ref}
    variant="tertiary"
    size="xs"
    h="100%"
    py={0.5}
    px={2.5}
    fontSize="13px" lineHeight={1.2} color="gray.900"
    {...props}
  />
))

export const DetailHeaderActionLinkButton = (props: ButtonProps & { to: To }) => (
  <DetailHeaderActionButton
    as={Link}
    rightIcon={<Icon as={IconChevronRight} boxSize="16px" mt={0.5} />}
    iconSpacing={1}
    {...props}
  />
)
