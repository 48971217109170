import clamp from 'lodash/clamp'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { cleanupSearchOptions, getSearchFilterCount } from '../../components/search'
import { SearchOptions, SearchSERPProps, SEARCH_FILTER_DEFAULTS, SortChoice, SORT_CONFIGS } from '../../components/search/types'
import { DEFAULT_MAX_DISTANCE, DEFAULT_PAY_RANGE } from '../../config'
import { useLocalStorage, useLocalStorageSetter, useSessionStorage } from '../../contexts'
import { decodeLatLng, encodeLatLng, encodeQueryParams, extractAddressComponents, fetchGeoLocation, sanitizeZipcode } from '../../helpers'
import { useInitialPageConfig, useSearchLocation } from '../../hooks'
import { ExperienceLevel, GeoLocation, JobScheduleType, SearchLocation, WorkplaceType } from '../../models'
import { analytics } from '../../services'

interface QueryParams {
  q?: string // query
  crd?: string // encoded lat/lng
  l?: string // location description
  z?: string // zipcode
  c?: string // city_slug
  s?: string // state
  r?: number // radius
  p?: number // min_pay
  lw?: string // living_wage
  min_pay?: number // Support better-jobs backend page
  w?: string[] // workplaces
  sc?: string[] // schedule
  exp?: string[] // experience_levels
  i?: string[] // industries
  sim?: string // sort_by_similar_to
  sort?: string
  back?: string
}

type SerializedQueryParams = {
  [P in keyof QueryParams]: string
}

export const useSearchPageController = () => {
  const navigate = useNavigate()
  const pageConfig = useInitialPageConfig()
  const [searchParams] = useSearchParams()
  const zipcodeParam = searchParams.get('zipcode')
  const backParam = searchParams.get('back')
  const [initialSearchOptions, setInitialSearchOptions] = useSessionStorage('searchPageOptions')
  const defaultLocation = useSearchLocation()
  const setDefaultLocation = useLocalStorageSetter('jobSearchLocation')
  const [lastViewedJobSlug] = useLocalStorage('lastViewedJobSlug')
  const [fallbackCoordinates] = useLocalStorage('fallbackCoordinates')
  const [isReady, setIsReady] = useState(false)

  const defaultFilters = SEARCH_FILTER_DEFAULTS

  const serpProps = pageConfig?.key === 'jobs_serp'
    ? pageConfig.props as SearchSERPProps
    : undefined

  const searchOptions = useMemo(() => {
    if (serpProps) {
      return {
        ...serpProps.search_options,
        sort: SortChoice.NEWEST
      }
    }

    let options: SearchOptions = {}
    const params: SerializedQueryParams = {}

    for (const [key, value] of searchParams) {
      if (value) params[key] = value
    }

    const minPayParam = params.p ?? params.min_pay

    if (params.q) {
      options.query = params.q
    }
    if (params.l && (params.crd || params.s)) {
      const location: SearchLocation = {
        description: params.l
      }

      if (params.crd) {
        try {
          location.coordinates = decodeLatLng(params.crd)
        } catch (e) {
          console.error(e)
        }
      }
      if (params.c) {
        location.city_slug = params.c
      }
      if (params.s) {
        location.state = params.s
      }
      if (params.z) {
        location.zipcode = sanitizeZipcode(params.z)
      }
      options.location = location
    }
    if (params.r) {
      const radius = parseFloat(params.r)
      if (!isNaN(radius)) {
        options.radius = clamp(radius, 0.5, DEFAULT_MAX_DISTANCE)
      }
    }
    if (params.lw) {
      options.living_wage = true
    }
    if (minPayParam) {
      const minPay = parseFloat(minPayParam)
      if (!isNaN(minPay)) {
        options.min_pay = Number(clamp(minPay, DEFAULT_PAY_RANGE.min, DEFAULT_PAY_RANGE.max).toFixed(2))
      }
    }
    if (params.w) {
      options.workplaces = paramToArray(params.w, Object.values(WorkplaceType)) as WorkplaceType[]
    }
    if (params.sc) {
      options.schedule = paramToArray(params.sc, Object.values(JobScheduleType)) as JobScheduleType[]
    }
    if (params.exp) {
      options.experience_levels = paramToArray(params.exp, Object.values(ExperienceLevel)) as ExperienceLevel[]
    }
    if (params.i) {
      options.industries = paramToArray(params.i)
    }
    if (params.sim) {
      options.sort_by_similar_to = params.sim
    }
    if (
      SORT_CONFIGS[params.sort as string] &&
      (params.sort !== SortChoice.CLOSEST || options.location?.coordinates)
    ) {
      options.sort = params.sort
    }

    if (!Object.keys(options).length) {
      options = initialSearchOptions ?? {}
    }

    if (!options.radius) {
      options.radius = defaultFilters.radius
    }
    if (!options.min_pay) {
      options.min_pay = defaultFilters.min_pay
    }

    options = cleanupSearchOptions(options)

    return options
  }, [searchParams, serpProps])

  const setSearchOptions = useCallback((
    options: SearchOptions = {},
    navigationOpts?: { replace?: boolean }
  ) => {
    options = cleanupSearchOptions(options)

    const params: QueryParams = {}
    const { location } = options

    if (options.query) {
      params.q = options.query
    }
    if (location) {
      params.l = location.description
      if (location.coordinates) {
        params.crd = encodeLatLng(location.coordinates)
      }
      params.c = location.city_slug
      params.s = location.state
      params.z = location.zipcode
    }
    params.r = options.radius
    params.lw = options.living_wage ? '1' : undefined
    params.p = options.min_pay
    params.w = options.workplaces
    params.sc = options.schedule
    params.exp = options.experience_levels
    params.i = options.industries
    params.sim = options.sort_by_similar_to
    params.sort = options.sort
    params.back = backParam ?? undefined

    const queryString = encodeQueryParams(params)

    if (serpProps) {
      navigate(`/search?${queryString}`)
    } else {
      navigate(`/search?${queryString}`, navigationOpts)
    }
  }, [])

  const updateSearchOptions = useCallback((options: SearchOptions) => {
    if (options.query) {
      options = { ...options }
      options.sort_by_similar_to = undefined
    }
    setSearchOptions({ ...searchOptions, ...options })
  }, [searchOptions])

  useLayoutEffect(() => {
    (async () => {
      if (serpProps) {
        setIsReady(true)
        return
      }

      let { query, location, sort_by_similar_to } = searchOptions
      const searchOptionsUpdate: SearchOptions = {}

      if (!location) {
        setIsReady(false)

        // Handle zipcode query param set by ssr public page forms.
        if (zipcodeParam) {
          const zipcode = sanitizeZipcode(zipcodeParam)
          if (zipcode) {
            try {
              const geoLocation = await fetchGeoLocation({ address: zipcode })
              if (geoLocation) {
                location = resolveSearchLocation(geoLocation)
                location.zipcode = zipcode
              }
            } catch (error) {
              console.error(error)
            }
          }
        }

        if (!location) {
          if (defaultLocation) {
            location = resolveSearchLocation(defaultLocation)
          } else if (fallbackCoordinates) {
            try {
              const geoLocation = await fetchGeoLocation(fallbackCoordinates)
              if (geoLocation) {
                location = resolveSearchLocation(geoLocation)
              }
            } catch (error) {
              console.error(error)
            }
          }
        }

        if (location) {
          searchOptionsUpdate.location = location
        }
      }

      if (!sort_by_similar_to && !query && lastViewedJobSlug) {
        searchOptionsUpdate.sort_by_similar_to = lastViewedJobSlug
      }

      if (Object.keys(searchOptionsUpdate).length) {
        setSearchOptions({ ...searchOptions, ...searchOptionsUpdate }, { replace: true })
      }

      setIsReady(true)
    })()
  }, [searchOptions])

  useEffect(() => {
    if (searchOptions.location) {
      setDefaultLocation(searchOptions.location)
    }
  }, [searchOptions.location])

  useEffect(() => {
    const { location: _1, sort_by_similar_to: _2, ...savedSearchOptions } = searchOptions
    setInitialSearchOptions(savedSearchOptions)

    if (isReady) {
      analytics.trackSpecialPageView()
    }
  }, [searchOptions])

  return useMemo(() => {
    return ({
      defaultFilters,
      isReady,
      serpProps,
      searchOptions,
      filterCount: getSearchFilterCount(defaultFilters, searchOptions),
      updateSearchOptions
    })
  }, [isReady, serpProps, searchOptions])
}

function paramToArray(param: string, acceptedValues?: string[]) {
  let values = decodeURIComponent(param).split(',').map(value => value.trim())
  if (acceptedValues) {
    values = values.filter(value => acceptedValues.includes(value))
  }
  if (values.length) {
    return values
  }
}

function resolveSearchLocation(loc: SearchLocation | GeoLocation) {
  const location: SearchLocation = {
    description: loc.description,
    coordinates: loc.coordinates
  }
  if ((loc as SearchLocation).zipcode) {
    location.zipcode = (loc as SearchLocation).zipcode
  } else {
    location.zipcode = extractAddressComponents(loc as GeoLocation).zipcode
  }
  return location
}
