import { ButtonProps, FormControl, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../contexts'
import { requiredRule } from '../../helpers'
import { useCreateAuthLink, useFormSubmitHandler } from '../../hooks'
import { Job } from '../../models'
import { AuthMethod } from '../../types'
import { EmailInput, FieldErrorMessage, SubmitButton } from '../core'

interface FormValues {
  email: string
}

export interface EmailAuthFormProps {
  method: AuthMethod
  job?: Job
  emailPlaceholder?: string
  submitLabel?: string
  submitButtonProps?: ButtonProps
  disabled?: boolean
  onSubmitting?: (submitting: boolean) => void
  onSuccess?: () => void
}

export const EmailAuthForm = ({
  method,
  emailPlaceholder,
  submitLabel,
  submitButtonProps,
  disabled,
  onSubmitting,
  onSuccess,
}: EmailAuthFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [{ authEmail }, updateAppState] = useAppState()
  const createAuthLink = useCreateAuthLink()

  const defaultValues = {
    email: authEmail ?? ''
  }

  const form = useForm<FormValues>({
    defaultValues
  })
  const { register, formState: { isSubmitting, errors } } = form

  const submit = useFormSubmitHandler(form, {
    async onSubmit(values) {
      onSubmitting?.(true)

      try {
        await createAuthLink.mutateAsync({ email: values.email })

        updateAppState({ authEmail: values.email })

        onSuccess?.()

        navigate(`/auth/link-sent?method=${method}`)
      } catch (err) {
        onSubmitting?.(false)
        throw err
      }
    }
  })

  return (
    <Stack as="form" spacing={5} onSubmit={submit}>
      <FormControl isInvalid={!!errors.email}>
        <EmailInput
          size="lg"
          isDisabled={disabled}
          {...(emailPlaceholder ? { placeholder: emailPlaceholder } : undefined)}
          {...register('email', { required: requiredRule() })}
        />
        <FieldErrorMessage error={errors.email} />
      </FormControl>

      <SubmitButton
        variant="primary" size="lg" type="submit"
        isLoading={isSubmitting}
        isDisabled={disabled}
        {...submitButtonProps}
      >
        {submitLabel ?? t('views.auth.continueWithEmail')}
      </SubmitButton>
    </Stack>
  )
}
