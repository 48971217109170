import { BoxProps } from '@chakra-ui/react'
import { ContentFooter, CONTENT_PADDING } from '../core'

export const ACTION_FOOTER_HEIGHT = 70

export const DetailActionFooter = ({ children, ...props }: BoxProps) => {
  return (
    <ContentFooter
      h={`${ACTION_FOOTER_HEIGHT}px`} px={CONTENT_PADDING} pt={3}
      zIndex={2}
      {...props}
    >
      {children}
    </ContentFooter>
  )
}
