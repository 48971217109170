import { Link as RawLink, Text, TextProps } from '@chakra-ui/react'
import { memo, MouseEventHandler } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../config'
import { addQueryString } from '../../helpers'
import { AuthMethod } from '../../types'

export const AuthDisclaimer = memo(({ sx, ...props }: TextProps) => (
  <Text
    fontSize="2xs"
    color="gray.300"
    sx={{
      ...sx,
      a: { textDecor: 'underline' }
    }}
    {...props}
  >
    <Trans i18nKey="views.auth.disclaimer">
      <RawLink href={TERMS_OF_SERVICE_URL} target="_blank" whiteSpace="nowrap"></RawLink>
      <RawLink href={PRIVACY_POLICY_URL} target="_blank" whiteSpace="nowrap"></RawLink>
    </Trans>
  </Text>
))

interface AuthAltMethodMessageProps extends TextProps {
  method: AuthMethod
  jobSlug?: string
  referrer?: string
  source?: string
  onLinkClick?: MouseEventHandler<HTMLElement>
}

export const AuthAltMethodMessage = memo(({
  method,
  jobSlug,
  referrer,
  source,
  onLinkClick,
  ...props
}: AuthAltMethodMessageProps) => {
  const path = method === AuthMethod.SIGN_UP ? '/sign-in' : '/sign-up'
  const to = addQueryString(path, { job: jobSlug, ref: referrer, src: source })

  return (
    <Text fontSize="2sm" color="gray.300" {...props}>
      <Trans i18nKey={`views.auth.${method}.altMethodMessage`}>
        <Text as={Link} to={to} color="teal.500" textDecor="underline" onClick={onLinkClick} />
      </Trans>
    </Text>
  )
})
