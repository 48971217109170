import { Box, ButtonProps, Divider, Flex, SpaceProps, Stack, Text, useDimensions } from '@chakra-ui/react'
import { MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GOOGLE_CLIENT_ID, GOOGLE_OAUTH_URL } from '../../config'
import { useModalManager, useSessionStorageSetter } from '../../contexts'
import { buildJobUrl, isWebview } from '../../helpers'
import { useDebounce, useGoogleAuth, usePersistSessionBridge } from '../../hooks'
import { APPLY_START_HASH } from '../../pages/JobDetail/common'
import { AuthMethod } from '../../types'
import { AuthAltMethodMessage, AuthDisclaimer } from './elements'
import { EmailAuthForm } from './EmailAuthForm'

export interface AuthPanelProps extends SpaceProps, PropsWithChildren {
  variant?: 'default' | 'sidebar'
  method: AuthMethod
  message?: ReactNode
  buttonProps?: ButtonProps
  redirectTo?: string
  jobSlug?: string
  referrer?: string
  source?: string
  onAltMethodClick?: MouseEventHandler<HTMLElement>
  onSuccess?: () => void
}

export const AuthPanel = ({
  variant,
  method = AuthMethod.SIGN_IN,
  message,
  redirectTo,
  buttonProps,
  jobSlug,
  referrer,
  source,
  onAltMethodClick,
  onSuccess,
  children,
  ...props
}: AuthPanelProps) => {
  const setPostAuthRedirect = useSessionStorageSetter('postAuthRedirect')
  const modalManager = useModalManager()
  const persistSessionBridge = usePersistSessionBridge()
  const [submitting, setSubmitting] = useState(false)

  const handleSuccess = useCallback(() => {
    modalManager.close()
    onSuccess?.()
  }, [onSuccess])

  const handleGoogleAuthSubmitting = useCallback((submitting: boolean) => {
    setSubmitting(submitting)
    if (submitting) {
      setPostAuthRedirect(redirectTo)
    }
  }, [redirectTo])

  const handleEmailAuthSuccess = useCallback(() => {
    let postAuthRedirect: string | undefined
    if (redirectTo) {
      postAuthRedirect = redirectTo
    } else if (jobSlug) {
      postAuthRedirect = buildJobUrl(jobSlug + APPLY_START_HASH)
    }
    setPostAuthRedirect(postAuthRedirect)
    persistSessionBridge({ postAuthRedirect })
    handleSuccess()
  }, [redirectTo, jobSlug, usePersistSessionBridge, handleSuccess])

  return (
    <Box
      pb={2.5}
      color="gray.400"
      {...props}
    >
      <Stack spacing={5}>
        {message && (
          <Text lineHeight={1.3}>{message}</Text>
        )}

        {children}

        {!isWebview() && isGoogleSdkLoaded() && (
          <>
            <GoogleAuth
              method={method}
              submitting={submitting}
              onSubmitting={handleGoogleAuthSubmitting}
              onSuccess={handleSuccess}
            />
            <OrDivider />
          </>
        )}

        <EmailAuthForm
          method={method}
          submitButtonProps={buttonProps}
          disabled={submitting}
          onSuccess={handleEmailAuthSuccess}
          onSubmitting={setSubmitting}
        />

        <Divider />
        <AuthDisclaimer />

        {variant !== 'sidebar' && (
          <AuthAltMethodMessage
            method={method}
            jobSlug={jobSlug}
            referrer={referrer}
            source={source}
            onLinkClick={onAltMethodClick}
          />
        )}
      </Stack>
    </Box>
  )
}

interface GoogleAuthProps {
  method: AuthPanelProps['method']
  submitting?: boolean
  onSubmitting: (submitting: boolean) => void
  onSuccess: () => void
}

const GoogleAuth = ({
  method,
  submitting,
  onSubmitting,
  onSuccess
}: GoogleAuthProps) => {
  const googleAuth = useGoogleAuth()
  const containerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  useDimensions(containerRef, true)
  // useDimesions width is inaccurate in modals.
  const width = containerRef.current?.clientWidth
  const roundedWidth = width ? Math.floor(width * 0.8) : width
  const debouncedWidth = useDebounce(roundedWidth)

  useLayoutEffect(() => {
    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      context: method === AuthMethod.SIGN_UP ? 'signup' : 'signin',
      auto_select: true,
      ux_mode: 'popup',
      login_uri: GOOGLE_OAUTH_URL,
      callback: async ({ credential }) => {
        onSubmitting(true)
        try {
          await googleAuth.mutateAsync({ token: credential })
          onSuccess()
        } catch { }
        onSubmitting(false)
      },
    })
  }, [])

  useLayoutEffect(() => {
    if (debouncedWidth) {
      google.accounts.id.renderButton(
        buttonRef.current!,
        {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          shape: 'rectangular',
          width: debouncedWidth
        }
      )
    }
  }, [debouncedWidth])

  return (
    <Box
      ref={containerRef}
      mb={1} h="50px" pl="11.1%" pt="5px"
      bg="gray.100" rounded="md"
      opacity={submitting ? 0.5 : 1}
      pointerEvents={submitting ? 'none' : 'auto'}
      overflow="hidden"
      sx={{
        '> *': {
          transform: 'scale(1.25, 1.25)'
        }
      }}
    >
      <div ref={buttonRef} />
    </Box>
  )
}

const OrDivider = () => {
  const { t } = useTranslation()

  return (
    <Flex width="full" justify="center">
      <Text my={-2.5} fontSize="xs">
        {t('terms.or')}
      </Text>
    </Flex>
  )
}

function isGoogleSdkLoaded() {
  return !!window.google?.accounts?.id
}
