
import { useSplitTreatments } from '@splitsoftware/splitio-react'
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'

const FORCE_READY_DELAY = 1_000

export interface Feature {
  flag: string
  config?: { [key: string]: any }
}

export interface BoolFeature extends Feature {
  flag: 'on' | 'off' | 'control'
}

export interface Features {
  match_preferences_cta: {
    flag: 'default' | 'custom'
    config?: {
      label?: string
    }
  }
  apply_cta_variant: BoolFeature
  gamify_prompt_modal_variant: {
    flag: 'a' | 'b'
  }
  bulk_apply_landing_page: BoolFeature
}

type FeatureName = keyof Features

const FEATURE_NAMES: FeatureName[] = [
  'match_preferences_cta',
  'apply_cta_variant',
  'gamify_prompt_modal_variant',
  'bulk_apply_landing_page'
]

interface FeaturesContextValue {
  features?: Features
}

export const FeaturesContext = createContext({} as FeaturesContextValue)

export const FeaturesProvider = ({ children }: PropsWithChildren) => {
  const { treatments, isReady } = useSplitTreatments({ names: FEATURE_NAMES })
  const [forceReady, setForceReady] = useState(false)
  const _isReady = isReady || forceReady

  const contextValue = useMemo(() => {
    if (!_isReady) return {}

    const features: Partial<Features> = {}

    for (const name of FEATURE_NAMES) {
      const treatment = treatments[name]
      let config: Feature['config']

      if (treatment?.config) {
        try {
          config = JSON.parse(treatment.config)
        } catch (err) {
          console.error(`Unable to parse config for treatment '${name}':`, treatment.config)
        }
      }

      features[name as string] = {
        flag: treatment?.treatment ?? 'control',
        config
      }
    }

    return {
      features: features as Features
    }
  }, [treatments, _isReady])

  useEffect(() => {
    const timer = setTimeout(() => setForceReady(true), FORCE_READY_DELAY)
    return () => clearTimeout(timer)
  }, [])

  return (
    <FeaturesContext.Provider value={contextValue}>
      {children}
    </FeaturesContext.Provider>
  )
}

export function useFeatures() {
  const { features } = useContext(FeaturesContext)
  if (!features) {
    throw new Error('Features are not ready')
  }
  return features
}

export function useFeaturesReady() {
  const { features } = useContext(FeaturesContext)
  return !!features
}

export function useFeature<T extends FeatureName>(name: T): Features[T] {
  return useFeatures()[name]
}

export function useFeatureFlag<T extends FeatureName>(name: T): Features[T]['flag'] {
  return useFeature<T>(name).flag
}

export function useIsFeatureOn<T extends FeatureName>(name: T) {
  return useFeatureFlag(name) === 'on'
}
