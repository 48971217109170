import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogProps } from '../../../components/core'
import { AnalyticsParams } from '../../../contexts'
import { Job } from '../../../models'
import { analytics } from '../../../services'

export interface FinishApplicationDialogProps extends DialogProps {
  job: Job
  analyticsParams?: AnalyticsParams
}

export const FinishApplicationDialog = ({
  job,
  analyticsParams,
  ...props
}: FinishApplicationDialogProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog
      title={t('views.jobs.finishApplicationDialog.title')}
      message={t('views.jobs.finishApplicationDialog.message', { company: job.company.name })}
      primaryButton={t('actions.finishApplying')}
      onPrimaryClick={() => {
        analytics.trackApplyResume({ ...analyticsParams, job, source: 'Finish Application Dialog' })
        navigate(`/applications/${job.application!.id}`)
      }}
      {...props}
    />
  )
}
