import { Input, InputProps } from '@chakra-ui/react'
import { ChangeEventHandler, FocusEventHandler, forwardRef, Ref, useCallback, useRef } from 'react'

export const AutocompleteInput = forwardRef(({
  onChange,
  onBlur,
  ...props
}: InputProps, ref: Ref<HTMLInputElement>) => {
  // The following logic is a workaround for undesired behavior
  // in iOS Safari, which blurs the input after autofilling.
  const changeTsRef = useRef(0)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    changeTsRef.current = Date.now()
    onChange?.(e)
  }, [onChange])

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(e => {
    if (Date.now() - changeTsRef.current < 200) {
      e.target.focus()
    } else {
      onBlur?.(e)
    }
  }, [onBlur])

  return (
    <Input
      ref={ref}
      pr={props.value ? 9 : undefined}
      autoComplete="no"
      rounded="full"
      fontFamily="Soehne"
      fontWeight="400"
      fontSize="2sm"
      _placeholder={{ fontSize: '2sm', color: 'gray.300' }}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  )
})
