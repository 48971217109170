import { Box, BoxProps } from '@chakra-ui/react'
import { memo } from 'react'
import { HlsVideoLoader } from './useHlsVideoLoader'

export interface HlsVideoProps extends BoxProps {
  loader: HlsVideoLoader
  alt?: string
  autoPlay?: boolean
  loop?: boolean
  muted?: boolean
}

export const HlsVideo = memo(({ loader, ...props }: HlsVideoProps) => {
  const {
    url,
    videoRef,
    hlsRef,
    initializing
  } = loader

  return (
    <Box
      as="video" ref={videoRef}
      controls={false} playsInline
      src={
        initializing
          ? undefined
          : !hlsRef.current ? url : undefined
      }
      w="full" h="full"
      objectFit="contain"
      {...props}
    />
  )
})
