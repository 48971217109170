import { Flex, Icon, IconButton, Image, SpaceProps, Text, useToast } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import { memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import imageSrc from '../../assets/images/thumbs-up.png'
import { BOX_SHADOW_1 } from '../../styles'

interface SuccessToastProps extends SpaceProps {
  message: ReactNode
}

export const SuccessToast = memo(({ message, ...props }: SuccessToastProps) => {
  const { t } = useTranslation()
  const toast = useToast()

  return (
    <Flex px={2.5} py={5} {...props}>
      <Flex
        w="full" px={4} py={4}
        align="center"
        bg="green.100" border="2px" borderColor="green.700" borderRadius={10}
        boxShadow={BOX_SHADOW_1}
      >
        <Image
          src={imageSrc} alt={t('images.thumbsUp')}
          width="40px" height="37px"
        />

        <Text
          ml={{ base: 5, '2xs': 8 }} mr={5} flex={1}
          fontSize="sm" fontWeight="medium" color="gray.900" lineHeight={1.3}
        >
          {message}
        </Text>

        <IconButton
          size="xs"
          boxSize={6}
          icon={<Icon as={IconX} boxSize={3.5} />}
          aria-label={t('actions.dismiss')}
          bg="green.250"
          _hover={{
            bg: 'green.300'
          }}
          onClick={() => toast.closeAll()}
        />
      </Flex>
    </Flex>
  )
})
