import axios, { AxiosError, CreateAxiosDefaults } from 'axios'

interface ApiClientConfig extends CreateAxiosDefaults<any> {}

export function createApiClient(config: ApiClientConfig = {}) {
  const defaults: CreateAxiosDefaults<any> = {
    responseType: 'json',
    transformRequest: (data, headers) => {
      if (data && !(data instanceof FormData)) {
        headers.set('Content-Type', 'application/json')
        return JSON.stringify(data)
      }
      return data
    }
  }

  const client = axios.create({ ...defaults, ...config })

  client.interceptors.response.use(
    response => response,
    (err: AxiosError<any>) => {
      if (err.response) {
        const details = parseErrorDetails(err.response.data.error)
        throw new ApiClientError(details?.message ?? err.message, err.response.status, details)
      } else if (err.request) {
        throw new ApiClientError(err.message)
      }
      throw err
    }
  )

  return client
}

interface ApiClientErrorParameters {
  [key: string]: string[]
}

export interface ApiClientErrorDetails {
  message: string
  code: string
  parameters?: ApiClientErrorParameters
}

export interface ApiValidationErrorDetails extends ApiClientErrorDetails {
  parameters: ApiClientErrorParameters
}

export class ApiClientError extends Error {
  status?: number
  details?: ApiClientErrorDetails

  constructor(message: string, status?: number, details?: ApiClientErrorDetails) {
    super(message)
    Object.setPrototypeOf(this, ApiClientError.prototype)
    this.status = status
    this.details = details
  }
}

export interface ApiValidationError extends ApiClientError {
  details: ApiValidationErrorDetails
}

export interface ApiObjectResponse<T> {
  data: T
}

export interface ApiIndexResponse<T> {
  data: T[]
}

export interface ApiCursorResponse<T> extends ApiIndexResponse<T> {
  data: T[]
  cursor?: string | null
  total?: number
}

export function isApiValidationError(err: unknown): err is ApiValidationError {
  return (
    err instanceof ApiClientError &&
    (err.status === 400 || err.status === 422) &&
    !!err.details?.parameters
  )
}

export function isApiForbiddenError(err: unknown): err is ApiClientError {
  return err instanceof ApiClientError && err.status === 403
}

export function isApiNotFoundError(err: unknown): err is ApiClientError {
  return err instanceof ApiClientError && err.status === 404
}

export function isApiUnauthorizedError(err: unknown): err is ApiClientError {
  return err instanceof ApiClientError && err.status === 401
}

function parseErrorDetails(details: any) {
  if (details?.parameters) {
    try {
      const parameters = flattenErrorParameters(details.parameters)
      return { ...details, parameters }
    } catch (err) {
      console.error(err)
      const { parameters: _, ...rest } = details
      return rest
    }
  }

  return details
}

// Example error parameters:
// [
//   {
//     "birthday": {
//       "year": [
//         "Must be greater than or equal to 1900 and less than or equal to 2050."
//       ]
//     }
//   }
// ]
//
// TODO: Handle nested parameters if necessary
function flattenErrorParameters(parameters: Array<{ [key: string]: any }> | { [key: string]: any }) {
  const result: ApiClientErrorParameters = {}
  if (!Array.isArray(parameters)) {
    parameters = [parameters]
  }

  for (const param of parameters as Array<{ [key: string]: any }>) {
    for (const [key, value] of Object.entries(param)) {
      if (Array.isArray(value)) {
        result[key] = value
      }
    }
  }

  return result
}
