import { Location } from 'react-router-dom'
import * as yup from 'yup'
import { SearchOptions } from '../components/search/types'
import { createStorageContext } from '../lib/createStorageContext'
import { JobPreferences } from '../models'
import { sessionStorage } from '../services'

export interface MyAppState {
  wizardSteps?: { [key: string]: boolean }
  resumeFileId?: string
}

export interface BridgedSessionState {
  authZipcode?: string
  bulkApplyCart?: string[]
  bulkApplyCartTipDismissed?: boolean
  jobPreferences?: Partial<JobPreferences>
  postAuthRedirect?: string
  searchPageOptions?: Omit<SearchOptions, 'location'>
}

export const BRIDGED_SESSION_KEYS: Array<keyof BridgedSessionState> = [
  'authZipcode',
  'bulkApplyCart',
  'bulkApplyCartTipDismissed',
  'jobPreferences',
  'postAuthRedirect',
  'searchPageOptions'
]

interface SessionStorageState extends BridgedSessionState {
  myApp?: MyAppState
  navigationHistory?: Location[]
}

const STORAGE_SCHEMA = {
  authZipcode: yup.string(),
  bulkApplyCart: yup.array(yup.string()),
  bulkApplyCartTipDismissed: yup.boolean(),
  jobPreferences: yup.object(),
  myApp: yup.object(),
  navigationHistory: yup.array(yup.object()),
  postAuthRedirect: yup.string(),
  searchPageOptions: yup.object()
}

const { provider, hooks } = createStorageContext<SessionStorageState>({
  storage: sessionStorage,
  schema: STORAGE_SCHEMA,
  namespace: 'gigs'
})

export const SessionStorageProvider = provider
export const useSessionStorage = hooks.getterSetter
export const useSessionStorageSetter = hooks.setter
export const useSessionStorageState = hooks.state
export const useClearSessionStorage = hooks.clear
