import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { StatusOverlay } from '../../components/core'
import { DetailPageHeader, DetailPageHeaderTitle, DetailSkeleton } from '../../components/detail'
import { useAppState, useSessionStorageSetter } from '../../contexts'
import { useGeoCoordinates, useJobSearchQuery, useLoadingQuery, useLocationQuery } from '../../hooks'
import { LocationDetailContent } from './partials/LocationDetailContent'

export const LocationDetail = () => {
  const params = useParams()
  const locationId = params.id!
  const [{ authenticated }] = useAppState()
  const setAuthZipcode = useSessionStorageSetter('authZipcode')
  const coordinates = useGeoCoordinates()

  const locationQuery = useLocationQuery(locationId)
  const location = locationQuery.data

  const jobSearchQuery = useJobSearchQuery({ location_id: locationId, coordinates })

  const loadingQuery = useLoadingQuery([locationQuery, jobSearchQuery])

  useEffect(() => {
    if (!location) return

    if (!authenticated && location.zipcode) {
      setAuthZipcode(location.zipcode)
    }
  }, [location])

  return (
    <>
      <DetailPageHeader
        title={location && (
          <DetailPageHeaderTitle title={location.company.name} subtitle={location.region_name} />
        )}
        isOverlay={!location || !!location.company.media?.length}
      />

      {!loadingQuery && (
        <LocationDetailContent location={location!} />
      )}

      <StatusOverlay
        query={loadingQuery}
        renderLoading={() => <DetailSkeleton />}
      />
    </>
  )
}
