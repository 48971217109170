import { formAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle
} from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const $fg = cssVar('form-control-color')

const baseStyleHelperText = defineStyle({
  [$fg.variable]: 'colors.gray.300',
  mt: 1.5,
  fontSize: 'xs',
  textAlign: 'right'
})

const baseStyle = definePartsStyle({
  helperText: baseStyleHelperText,
})

export const formTheme = defineMultiStyleConfig({
  baseStyle,
})
