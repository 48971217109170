import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppState, useSessionStorage } from '../contexts'
import { JobPreferences } from '../models'
import { selectJobPreferencesWizardState } from '../selectors/account'
import { analytics, api } from '../services'
import { QKey } from '../types'
import { useQueryErrorHandler } from './app'

export const useJobPreferencesQuery = () => {
  const [{ authenticated }] = useAppState()

  return useQuery([QKey.JOB_PREFERENCES], async () => {
    const { data } = await api.account.jobPreferences.get()
    return data ?? null
  }, {
    enabled: authenticated,
    cacheTime: Infinity,
    staleTime: 120_000
  })
}

export function useJobPreferences() {
  const { data } = useJobPreferencesQuery()
  return data ?? null
}

export const useUpdateJobPreferences = () => {
  const queryClient = useQueryClient()
  const onError = useQueryErrorHandler({ notify: true })

  return useMutation(async (preferences: JobPreferences) => {
    const { data } = await api.account.jobPreferences.update(preferences)
    return data
  }, {
    onSuccess(data) {
      queryClient.setQueryData([QKey.JOB_PREFERENCES], data)
      queryClient.invalidateQueries([QKey.JOBS, QKey.MATCHED])
    },
    onError
  })
}

export function usePostAuthSaveJobPreferences() {
  const [localJobPrefs, setLocalJobPrefs] = useSessionStorage('jobPreferences')
  const updateJobPreferences = useUpdateJobPreferences()

  return async () => {
    if (localJobPrefs) {
      setLocalJobPrefs(null)

      try {
        const savedPrefs = await updateJobPreferences.mutateAsync(localJobPrefs)
        if (getJobPreferencesWizardCompleted(savedPrefs)) {
          analytics.trackMatchPreferencesProgress('complete', savedPrefs)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export function useJobPreferencesWizardState() {
  const [localPrefs] = useSessionStorage('jobPreferences')
  return selectJobPreferencesWizardState(localPrefs)
}

export function useJobPreferencesWizardCompleted() {
  const prefs = useJobPreferences()
  if (prefs) {
    return getJobPreferencesWizardCompleted(prefs)
  }
  return false
}

/**
 * Returns whether all of the original job preference fields were saved,
 * which means that the preferences wizard was completed.
 */
function getJobPreferencesWizardCompleted(prefs: JobPreferences) {
  return !!(
    prefs.schedule &&
    prefs.industries &&
    prefs.positions &&
    prefs.experience_levels &&
    prefs.pay
  )
}
