export enum UserEventType {
  APPLY_START = 'apply_start',
  BULK_APPLY_OPT_OUT = 'bulk_apply_opt_out',
  COMPLETE_FAILED_APPLY_ORDER_ITEM = 'complete_failed_apply_order_item',
  FEED_SETTINGS_VIEW = 'feed_settings_view',
  GAMIFY_PROGRESS = 'gamify_progress',
  JOB_DISLIKE = 'job_dislike',
  JOB_SEARCH = 'job_search',
  JOB_VIEW = 'job_view',
  REGION_SETTINGS_VIEW = 'region_settings_view',
  START_JOB_PREFERENCE = 'start_job_preference',
  THIRD_PARTY_APPLY = 'third_party_apply',
}

export interface UserEvent {
  type: UserEventType
  job_id?: string
  data?: { [key: string]: any }
}
