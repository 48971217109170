import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { BOX_SHADOW_1 } from '../styles'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    backgroundColor: '#FFF',
    overflow: 'hidden'
  },
  body: {
    p: 2.5,
  }
})

const sizes = {
  md: definePartsStyle({
    container: {
      borderRadius: '15px',
      boxShadow: BOX_SHADOW_1
    },
  }),
}

export const cardTheme = defineMultiStyleConfig({ baseStyle, sizes })
