import { useMemo } from 'react'
import { Media } from '../models'

export function useMediaImage<T extends Media | null | undefined,
  R = T extends Media ? { url: string, description?: string } : undefined>(
  media: T,
  size: 'sm' | 'md' | 'lg' = 'md'
) {
  return useMemo(() => {
    if (!media) return undefined as R
    const source = media.image
    let url: string | undefined = source[size]

    if (!url) {
      switch (size) {
        case 'lg':
          url = source.md ?? source.sm
          break
        case 'md':
          url = source.lg ?? source.sm
          break
        default:
          url = source.md ?? source.lg
          break
      }
    }

    return {
      url: url!,
      description: media.description ?? undefined
    } as R
  }, [media, size])
}
