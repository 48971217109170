import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { APP_ENV, SENTRY_DSN } from './config'

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  tracesSampleRate: 0,
  normalizeDepth: 6
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
