import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react'
import { useLayoutContext } from '../../../contexts'

export const CONTENT_PADDING = { base: '20px', sm: '28px' }

export const ContentContainer = (props: BoxProps) => (
  <Box pos="relative" px={CONTENT_PADDING} {...props} />
)

export const PageContainer = ({ children, ...props }: FlexProps) => {
  const { pageHeaderHeight } = useLayoutContext()
  return (
    <ContentContainer
      as={Flex} flexDir="column" flex={1}
      pt={`${pageHeaderHeight + 10}px`}
      {...props}
    >
      <Flex pos="relative" flexDir="column" flex={1} w="100%">
        {children}
      </Flex>
    </ContentContainer>
  )
}
