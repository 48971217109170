import {
  Box, Flex, FlexProps, Image, Text
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export interface HighlightCardProps extends Omit<FlexProps, 'title'> {
  title: ReactNode
  body?: ReactNode
  imageSrc: string
  imageAlt: string
  imageWidth: Exclude<FlexProps['w'], undefined>
  imageHeight: Exclude<FlexProps['h'], undefined>
}

export const HighlightCard = ({
  title,
  body,
  imageSrc,
  imageAlt,
  imageWidth,
  imageHeight,
  children,
  ...props
}: HighlightCardProps) => {
  return (
    <Flex
      p={5}
      rounded="20px"
      overflow="hidden"
      {...props}
    >
      <Flex flexDir="column" w={172} flexShrink={0}>
        {title && (
          <Text fontWeight={500} fontSize="xl" lineHeight={1.25} mb={1}>
            {title}
          </Text>
        )}
        {body && (
          <Text fontSize="xs">
            {body}
          </Text>
        )}

        <Box mt="auto" pt={5}>
          {children}
        </Box>
      </Flex>

      <Flex minW={imageWidth} flex={1} alignItems="center" justifyContent="flex-end" overflow="hidden">
        <Image src={imageSrc} alt={imageAlt} h={imageHeight} />
      </Flex>
    </Flex>
  )
}
