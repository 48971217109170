import { BoxProps, Flex, HStack, Image, Text, Wrap } from '@chakra-ui/react'
import { UseQueryResult } from '@tanstack/react-query'
import { ReactNode } from 'react'
import { ContentContainer, ContentSection, ContentSectionProps } from '../../components/core'
import { Job, SearchLink } from '../../models'

export const APPLY_START_HASH = '#apply-start'

export interface JobDetailVariantProps {
  slug: string
  jobQuery: UseQueryResult<Job>
  job?: Job
  searchLinks?: SearchLink[]
  eventParams?: { [key: string]: string }
  referrer?: string
  source?: string
  notFound?: boolean
  initialNotFound?: boolean
}

export interface JobImageHeaderProps extends Omit<BoxProps, 'title'> {
  title: string | ReactNode
  src: string
  alt: string
  h: object
}


export const JobImageHeader = ({
  title,
  src,
  alt,
  h,
  ...props
}: JobImageHeaderProps) => {
  return (
    <HStack alignItems="center" justifyContent="space-between" spacing={5} {...props}>
      <Flex direction="column">
        {typeof title === 'string' ? (
          <Text fontSize={{ base: 'xl', '2xs': '2xl' }} lineHeight={1.2} fontWeight="500" color="gray.900">
            {title}
          </Text>
        ) : (
          title
        )}
      </Flex>
      <Image src={src} alt={alt} h={h} />
    </HStack>
  )
}

export const WrapSection = ({ title, children, ...props }: ContentSectionProps) => (
  <ContentSection title={title} {...props}>
    <ContentContainer>
      <Wrap mt={5} spacing={2.5}>
        {children}
      </Wrap>
    </ContentContainer>
  </ContentSection>
)
