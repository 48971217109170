import { CardProps, Flex, FlexProps, Icon, Text } from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import { memo, ReactNode } from 'react'
import { Link, To } from 'react-router-dom'
import { useTrack } from '../../hooks'
import { AnalyticsName } from '../../services/analytics'

export interface LinkCardProps extends Omit<FlexProps, 'title'> {
  to?: To
  image: ReactNode
  title?: ReactNode
  body?: ReactNode
  bodyColor?: CardProps['color']
  chevronColor?: CardProps['color']
  aName: string
  aTo: AnalyticsName
}

export const LinkCard = memo(({
  image,
  title,
  body,
  bodyColor,
  borderColor = 'gray.200',
  chevronColor = 'gray.300',
  color = 'gray.900',
  aName,
  aTo,
  children,
  onClick,
  ...props
}: LinkCardProps) => {
  const track = useTrack()

  return (
    <Flex
      as={Link}
      p={5}
      align="center"
      border="1px solid"
      borderColor={borderColor}
      rounded="15px"
      color={color}
      onClick={e => {
        track.open(aTo, aName)
        onClick?.(e)
      }}
      {...props}
    >
      {image}

      <Flex ml={3} direction="column" flex={1}>
        {!!title && (
          <Text mb={2} fontSize="md" fontWeight={500} lineHeight={1.2}>
            {title}
          </Text>
        )}

        {!!body && (
          <Text fontSize="sm" lineHeight={1.2} color={bodyColor}>
            {body}
          </Text>
        )}

        {children}
      </Flex>

      <Icon ml={2} mr={-2} as={IconChevronRight} boxSize={6} color={chevronColor} />
    </Flex>
  )
})
