import { Link, LinkProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const PUBLIC_LINK_CONFIG = {
  browseJobs: '/browse-jobs',
  about: '/about',
  careers: '/careers',
  contact: () => (window as any).FreshworksWidget?.('open'),
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  forJobSeekers: '/',
  forEmployers: '/employers'
}

export type PublicLinkName = keyof typeof PUBLIC_LINK_CONFIG

export const PUBLIC_LINKS = Object.keys(PUBLIC_LINK_CONFIG) as PublicLinkName[]

export interface PublicLinkProps extends Omit<LinkProps, 'href' | 'children'> {
  name: PublicLinkName
}

export const PublicLink = ({ name, onClick, ...props }: PublicLinkProps) => {
  const { t } = useTranslation()
  const config = PUBLIC_LINK_CONFIG[name]
  let linkProps: LinkProps
  
  if (typeof config === 'function') {
    linkProps = {
      href: '#',
      onClick(e) {
        config()
        e.preventDefault()
        onClick?.(e)
      }
    }
  } else {
    linkProps = {
      href: config,
      isExternal: true,
      onClick
    }
  }

  return (
    <Link {...linkProps} {...props}>
      {t(`links.${name}`)}
    </Link>
  )
}
