import { Select, SelectProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import states from './states.json'

export const USStateSelect = forwardRef<HTMLSelectElement, SelectProps>((props: SelectProps, ref) => {
  return (
    <Select ref={ref} {...props}>
      {states.map(item => (
        <option key={item.abbreviation} value={item.abbreviation}>
          {item.name}
        </option>
      ))}
    </Select>
  )
})
