import { Input, InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import InputMask from 'react-input-mask'

export const DateInput = forwardRef<HTMLInputElement, Omit<InputProps, 'placeholder'>>(({
  value,
  ...props
}, ref) => {
  return (
    <Input
      ref={ref}
      as={InputMask}
      type="text"
      value={value}
      mask={value ? '99/99/9999' : ''}
      // The placeholder should not be overriden because autocomplete
      // is informed by the MM/DD/YYYY format.
      placeholder="MM/DD/YYYY"
      beforeMaskedStateChange={({ nextState }) => {
        if (nextState.value === '__/__/____') {
          return { ...nextState, value: '' }
        }
        return nextState
      }}
      {...props}
    />
  )
})
