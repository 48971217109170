import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'
import * as yup from 'yup'

interface StorageState {
  [key: string]: any
}

type StorageSetterValue<TState extends StorageState, K extends keyof TState> = TState[K] | null | ((value: TState[K]) => TState[K] | null)

type StorageSetter<TState extends StorageState, K extends keyof TState> = (value?: StorageSetterValue<TState, K>) => void

interface StorageContextValue<TState extends StorageState> {
  state: TState
  setters: { [K in keyof TState]: StorageSetter<TState, K> }
  methods: {
    multiSet: (values: Partial<TState> | ((values: Partial<TState>) => TState)) => void
    clear: () => void
    refresh: () => void
  }
}

interface StorageProviderProps extends PropsWithChildren {
  namespace?: string
}

export function createStorageContext<TState extends StorageState>(options: {
  storage: Storage
  schema: { [key: string]: yup.AnySchema }
  namespace?: string
  resolveInvalidItem?: (key: keyof TState, value?: any) => any
}) {
  const { namespace, schema, storage, resolveInvalidItem } = options

  const StorageContext = createContext({
    state: {},
    setters: {},
    methods: {}
  } as StorageContextValue<TState>)

  const StorageProvider = ({ children }: StorageProviderProps) => {
    const prefix = namespace !== undefined ? `${namespace}.` : ''

    const getStorageState = () => {
      const state: StorageState = {}

      for (const [key, valueSchema] of Object.entries(schema)) {
        const fullKey = prefix + key
        const value = storage.getItem(fullKey) ?? undefined

        if (value !== undefined) {
          try {
            valueSchema.validateSync(value)
            state[key] = value
          } catch (err: any) {
            if (import.meta.env.DEV) {
              console.error(`Invalid storage value '${fullKey}':`, err.message)
            }

            if (resolveInvalidItem) {
              try {
                const resolvedValue = resolveInvalidItem(key, value)
                if (value != null) {
                  storage.setItem(fullKey, resolvedValue)
                  continue
                }
              } catch (err) {
                console.error(err)
              }
            }

            storage.removeItem(fullKey)
          }
        }
      }

      return state as TState
    }

    const [state, setState] = useState(getStorageState)

    const setters = useMemo(() => {
      const ret: Partial<StorageContextValue<TState>['setters']> = {}
      for (const key of Object.keys(schema)) {
        ret[key as keyof TState] = (value: any) => {
          setState(prevState => {
            const resolvedValue = typeof value === 'function' ? value(prevState[key]) : value
            storage.setItem(prefix + key, resolvedValue)
            return {
              ...prevState,
              [key]: resolvedValue ?? undefined
            }
          })
        }
      }
      return ret as StorageContextValue<TState>['setters']
    }, [])

    const methods = useMemo(() => ({
      multiSet(values) {
        setState(prevState => {
          const resolvedValues = typeof values === 'function' ? values(prevState) : values
          for (const [key, value] of Object.entries(resolvedValues)) {
            storage.setItem(prefix + key, value)
          }
          return {
            ...prevState,
            ...resolvedValues
          }
        })
      },
      clear() {
        for (const key of Object.keys(schema)) {
          storage.removeItem(prefix + key)
        }
        setState({} as TState)
      },
      refresh() {
        setState(getStorageState())
      }
    } as StorageContextValue<TState>['methods']), [])

    const contextValue = useMemo(() => ({
      state,
      setters,
      methods,
    }), [state])

    return (
      <StorageContext.Provider value={contextValue}>
        {children}
      </StorageContext.Provider>
    )
  }

  const getterSetterHook = <K extends keyof TState>(key: K) => {
    const context = useContext(StorageContext)
    return [context.state[key], context.setters[key] as StorageSetter<TState, K>] as const
  }

  const setterHook = <K extends keyof TState>(key: K) => {
    const context = useContext(StorageContext)
    return context.setters[key] as StorageSetter<TState, K>
  }

  const stateHook = () => {
    const context = useContext(StorageContext)
    return [context.state, context.methods.multiSet] as const
  }

  const clearHook = () => {
    return useContext(StorageContext).methods.clear
  }

  return {
    context: StorageContext,
    provider: StorageProvider,
    hooks: {
      getterSetter: getterSetterHook,
      setter: setterHook,
      state: stateHook,
      clear: clearHook
    }
  }
}
