import { useSplitClient, useTrack } from '@splitsoftware/splitio-react'
import { useQueryClient } from '@tanstack/react-query'
import { MutableRefObject, useEffect, useMemo, useRef } from 'react'
import { Helmet, HelmetProps } from 'react-helmet-async'
import { Outlet, ScrollRestoration, useLocation, useSearchParams } from 'react-router-dom'
import { DEFAULT_DOCUMENT_TITLE } from '../config'
import { ModalManagerProvider, NavigationHistoryProvider, useAppState, useInjectedConfig } from '../contexts'
import { retryCallback } from '../helpers'
import { useAccount, useInitialPageConfig, useJobPreferences, useJobPreferencesWizardCompleted, useQueryErrorHandler, useRestoreBridgedSessionStorage } from '../hooks'
import { isApiValidationError } from '../lib/apiClient'
import { analytics } from '../services'

const PERSISTENT_SCROLL_PATHS = ['/', '/search', '/profile']

export const Root = () => {
  const [{ ready }] = useAppState()
  const helmetChangeHandlerRef = useRef<() => void>(null)

  useRestoreBridgedSessionStorage()

  return (
    <>
      <ScrollRestoration
        getKey={location => {
          return PERSISTENT_SCROLL_PATHS.includes(location.pathname)
            ? location.pathname
            : location.key
        }}
      />

      <HelmetMain onChangeClientState={() => helmetChangeHandlerRef.current?.()} />
      <QueryClientSetup />
      {ready && <AnalyticsSetup helmetChangeHandlerRef={helmetChangeHandlerRef} />}

      <ModalManagerProvider>
        <NavigationHistoryProvider>
          <Outlet />
        </NavigationHistoryProvider>
      </ModalManagerProvider>
    </>
  )
}

const HelmetMain = (props: HelmetProps) => {
  const { origin } = window.location
  const location = useLocation()
  const initialPageMeta = useInitialPageConfig()?.meta

  return (
    <Helmet
      titleTemplate={'%s | getgigs.co'}
      defaultTitle={DEFAULT_DOCUMENT_TITLE}
      {...props}
    >
      {initialPageMeta ? (
        <>
          <title>{initialPageMeta.title}</title>
          <meta name="description" content={initialPageMeta.description} />
        </>
      ) : (
        <meta name="description" content="Finding the right gig shouldn't be a full time job. Apply to gigs that excite you the most, faster." />
      )}

      {origin && <link rel="canonical" href={`${origin}${location.pathname}`} />}
    </Helmet>
  )
}

const QueryClientSetup = () => {
  const queryClient = useQueryClient()
  const handleQueryError = useQueryErrorHandler()
  const handleMutationError = useQueryErrorHandler({
    notify: err => !isApiValidationError(err)
  })
  const injectedConfig = useInjectedConfig()

  useMemo(() => {
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: false,
        retry: retryCallback,
        onError: handleQueryError
      },
      mutations: {
        retry: 0,
        onError: handleMutationError
      }
    })

    // Initialize query cache with injected data.
    if (injectedConfig?.query_cache) {
      for (const query of injectedConfig.query_cache) {
        try {
          queryClient.setQueryData(query.key, query.data)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }, [queryClient])

  return null
}

interface AnalyticsSetupProps {
  helmetChangeHandlerRef: MutableRefObject<(() => void) | null>
}

const AnalyticsSetup = ({ helmetChangeHandlerRef }: AnalyticsSetupProps) => {
  const [searchParams] = useSearchParams()
  const conversionId = searchParams.get('conversion_id')
  const location = useLocation()
  const account = useAccount()
  const jobPreferences = useJobPreferences()
  const hasOriginalJobPreferences = useJobPreferencesWizardCompleted()
  const { client: splitClient } = useSplitClient({ updateOnSdkReady: false, updateOnSdkReadyFromCache: false })

  useTrack()

  analytics.splitClient = splitClient

  useMemo(() => {
    if (account && conversionId) {
      analytics.trackConversion(conversionId)
    }
  }, [])

  useEffect(() => {
    if (account) {
      analytics.identifyUser(account.id)
    }
  }, [account?.id])

  useEffect(() => {
    analytics.setUserProperties({ registeredUser: !!account?.registration_completed_at })
  }, [account?.registration_completed_at])

  useEffect(() => {
    analytics.setUserProperties({
      hasJobPreferences: !!hasOriginalJobPreferences,
      matchPreferences: jobPreferences
    })
  }, [jobPreferences])

  useEffect(() => {
    // Debounce page view tracking to ignore momentary redirects
    const debounceTimer = setTimeout(() => {
      if (document.title === DEFAULT_DOCUMENT_TITLE) {
        // If the document title is the default value, this means that
        // it will change when the page's data has loaded.
        helmetChangeHandlerRef.current = () => analytics.trackPageView()
      } else {
        analytics.trackPageView()
      }
    }, 100)

    return () => {
      clearTimeout(debounceTimer)
      helmetChangeHandlerRef.current = null
    }
  }, [location.pathname, location.search])

  return null
}
