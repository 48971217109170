import { DEFAULT_MAX_DISTANCE, DEFAULT_PAY_RANGE } from '../../config'
import { ExperienceLevel, JobIndexRecord, JobScheduleType, SearchLink, SearchLocation, WorkplaceType } from '../../models'
import { SortType } from '../../types'

export interface SearchFilters {
  radius?: number
  living_wage?: boolean
  min_pay?: number
  workplaces?: WorkplaceType[]
  schedule?: JobScheduleType[]
  experience_levels?: ExperienceLevel[]
  industries?: string[]
}

export const SEARCH_FILTER_KEYS: Array<keyof SearchFilters> = [
  'radius',
  'living_wage',
  'min_pay',
  'workplaces',
  'schedule',
  'experience_levels',
  'industries'
]

export const SEARCH_FILTER_DEFAULTS: SearchFilters & Required<Omit<SearchFilters, 'living_wage'>> = {
  radius: DEFAULT_MAX_DISTANCE,
  living_wage: undefined,
  min_pay: DEFAULT_PAY_RANGE.min,
  workplaces: [],
  schedule: [],
  experience_levels: [],
  industries: []
}

export interface SearchOptions extends SearchFilters {
  query?: string
  location?: SearchLocation
  sort_by_similar_to?: string
  sort?: string
}

export interface SearchEvent extends Omit<SearchOptions, 'location'> {
  location?: string
}

export interface SearchSERPProps {
  title: string
  subtitle?: string | null
  search_links: SearchLink[] | null
  suggested_jobs: JobIndexRecord[] | null
  search_options: SearchOptions
}

export enum SortChoice {
  DEFAULT = 'default',
  CLOSEST = 'closest',
  NEWEST = 'newest',
  HIGHEST_PAY = 'highest_pay'
}

export interface SortConfig {
  sort_by?: string
  sort_type?: SortType
}

export const SORT_CONFIGS: { [P in SortChoice]: SortConfig } = {
  [SortChoice.DEFAULT]: {},
  [SortChoice.CLOSEST]: { sort_by: 'distance', sort_type: 'ASC' },
  [SortChoice.NEWEST]: { sort_by: 'posted_date', sort_type: 'DESC' },
  [SortChoice.HIGHEST_PAY]: { sort_by: 'max_pay', sort_type: 'DESC' }
}
