import { useLocation, useNavigate } from 'react-router-dom'
import { AddToCartButton } from '../../../components/bulkApply/AddToCartButton'
import { AnalyticsParamsProvider } from '../../../contexts'
import { Job } from '../../../models'
import { APPLY_START_HASH } from '../common'
import { C2AModal } from './C2AModal'

export const JobDetailActions = ({ job }: { job: Job }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const showC2AModal = location.hash === APPLY_START_HASH

  const handleCloseC2AModal = () => {
    if (location.hash === APPLY_START_HASH) {
      navigate({
        pathname: location.pathname,
        search: location.search
      }, { replace: true })
    }
  }

  return (
    <AnalyticsParamsProvider source="Footer">
      <C2AModal job={job} isOpen={showC2AModal} onClose={handleCloseC2AModal} />
      <AddToCartButton job={job} textSize="lg" w="full" />
    </AnalyticsParamsProvider>
  )
}
