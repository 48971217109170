import { Skeleton, Stack, StackProps } from '@chakra-ui/react'
import { memo } from 'react'
import { CARD_SPACING_M } from './layout'

export const CardListSkeleton = memo(({ itemHeight, itemSpacing = CARD_SPACING_M, count = 10, ...props }: {
  itemHeight: number
  itemSpacing?: number
  count?: number
} & StackProps) => (
  <Stack w="full" spacing={`${itemSpacing}px`} {...props}>
    {new Array(count).fill(0).map((_, i) => (
      <Skeleton key={i} h={`${itemHeight}px`} minH={`${itemHeight}px`} rounded="15px" />
    ))}
  </Stack>
))
