import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useClearSessionStorage, useLocalStorageSetter, useSessionStorageSetter, useUpdateAppState } from '../contexts'
import { AuthLinkRequest, GoogleAuthRequest } from '../dtos'
import { buildJobUrl, locationToUrl } from '../helpers'
import { ApiClientError } from '../lib/apiClient'
import { analytics, api, cookie } from '../services'
import { CookieKey } from '../services/cookie'
import { AuthMethod, QKey } from '../types'
import { usePostAuthSaveJobPreferences } from './jobPreferences'
import { useJobQuery } from './jobs'

export const useCreateAuthLink = (options?: UseMutationOptions<void, ApiClientError, AuthLinkRequest>) => {
  return useMutation(async (data: AuthLinkRequest) => {
    await api.auth.createLink(data)
    analytics.trackEvent('Magic Link Sent')
  }, options)
}

export const useGoogleAuth = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const updateAppState = useUpdateAppState()
  const postAuthPersist = usePostAuthPersist()

  const mutation = useMutation(async (data: GoogleAuthRequest) => {
    const body = await api.auth.google(data)
    return body.data
  }, {
    onSuccess({ conversion_id, account }) {
      queryClient.setQueryData([QKey.ACCOUNT], account)
      updateAppState({ authenticated: true })
      postAuthPersist()
      analytics.trackAuth({ provider: 'google' })

      if (conversion_id) {
        analytics.trackConversion(conversion_id)
      }
    }
  })

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/')
    }
  }, [mutation.isSuccess])

  return mutation
}

export const useRevokeAuth = () => {
  const updateAppState = useUpdateAppState()
  const queryClient = useQueryClient()
  const setJobSearchQueryHistory = useLocalStorageSetter('jobSearchQueryHistory')
  const setPostAuthRedirect = useSessionStorageSetter('postAuthRedirect')
  const clearSessionStorage = useClearSessionStorage()
  const navigate = useNavigate()

  const location = useLocation()
  const locationRef = useRef(location)
  locationRef.current = location

  return useCallback((options?: { redirectToSignIn?: boolean }) => {
    analytics.resetUser()
    cookie.remove(CookieKey.ACCESS_TOKEN)
    setJobSearchQueryHistory(null)
    clearSessionStorage()
    queryClient.cancelQueries().catch(console.log)

    if (options?.redirectToSignIn) {
      updateAppState({ authenticated: false, authEmail: undefined })
      setPostAuthRedirect(locationToUrl(locationRef.current))

      // Wait a render cycle before navigating to /sign-in
      // or app state will be stale when it mounts.
      setTimeout(() => {
        navigate('/sign-in', { replace: true })

        // Wait until /sign-in is mounted before clearing the query cache,
        // or currently mounted queries will be refetched.
        setTimeout(() => {
          queryClient.clear()
        }, 0)
      }, 0)
    } else {
      window.location.href = '/'
    }
  }, [])
}

export function usePostAuthPersist() {
  const saveJobPrefs = usePostAuthSaveJobPreferences()

  return () => {
    saveJobPrefs()
  }
}

export function useAuthPage(analyticsParams: { type: string, method: AuthMethod }) {
  const [params] = useSearchParams()
  const referrer = params.get('ref') ?? undefined
  const source = params.get('src') ?? undefined
  const jobSlug = params.get('job') ?? undefined
  const { data: job, isLoading } = useJobQuery(jobSlug, { enabled: !!jobSlug })
  const setAuthZipcode = useSessionStorageSetter('authZipcode')

  useEffect(() => {
    if (job) {
      setAuthZipcode(job.zipcode)
    }
  }, [job])

  useEffect(() => {
    analytics.trackLoadRegistration({
      ...analyticsParams,
      variant: jobSlug ? 'apply' : 'default',
      emailAuthType: 'Magic Link'
    })
  }, [])

  const redirectTo = job
    ? buildJobUrl(job.slug, { action: 'apply', ref: referrer, src: source })
    : undefined

  return {
    referrer,
    source,
    jobSlug,
    job,
    redirectTo,
    isLoading
  }
}
