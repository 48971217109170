import { useInfiniteQuery } from '@tanstack/react-query'
import { api } from '../services'
import { QKey } from '../types'
import { useGeoCoordinates } from './geo'

export const useFeedQuery = () => {
  const geoCoordinates = useGeoCoordinates()

  return useInfiniteQuery([QKey.FEED], async ({ pageParam }) => {
    return api.feed.list({
      cursor: pageParam,
      ...geoCoordinates
    })
  }, {
    cacheTime: Infinity,
    staleTime: Infinity,
    getNextPageParam: (lastPage) => lastPage.cursor,
  })
}

export type FeedData = ReturnType<typeof useFeedQuery>['data']
