import { JobBase, JobProvider, JobStatus } from '../models'

export function isFirstParty(job: JobBase) {
  return job.provider === JobProvider.CSV || job.provider === JobProvider.FIRST_PARTY
}

export function getParty(job: JobBase) {
  return isFirstParty(job) ? '1P' : '3P'
}

export function isExternalApplication(job: JobBase) {
  return !!job.application_url
}

export function isJobLive(job: JobBase) {
  return (
    job.status === JobStatus.LIVE ||
    job.status === JobStatus.SALES_DEMO
  )
}

export function isJobActive(job: JobBase) {
  return isJobLive(job) || job.status === JobStatus.NEEDS_MODERATION
}
