import { Application } from './Application'
import { AddressRecord, LatLng, Tag } from './common'
import { Company } from './Company'
import { Enterprise } from './Enterprise'
import { Media } from './Media'

export enum JobProvider {
  FIRST_PARTY = 'firstparty',
  CSV = 'csv'
  // Other values exist but are not used by the webapp
}

export enum JobStatus {
  LIVE = 'live',
  NEEDS_MODERATION = 'needs_moderation',
  SALES_DEMO = 'sales_demo',
  DEACTIVATED = 'deactivated',
  EXPIRED = 'expired'
}

export enum JobScheduleType {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  SEASONAL = 'seasonal',
  TEMPORARY = 'temporary',
  FLEXIBLE = 'flexible',
  WEEKDAYS = 'weekdays',
  WEEKNIGHTS = 'weeknights',
  WEEKEND_DAYS = 'weekend_days',
  WEEKEND_NIGHTS = 'weekend_nights',
  CONTRACT = 'contract',
  PER_DIEM = 'per_diem'
}

export const SCHEDULE_CHOICES = [
  JobScheduleType.FULL_TIME,
  JobScheduleType.PART_TIME,
  JobScheduleType.SEASONAL,
  JobScheduleType.FLEXIBLE,
  JobScheduleType.CONTRACT,
  JobScheduleType.PER_DIEM,
  JobScheduleType.TEMPORARY
]

export enum PaySourceType {
  LISTING_DIRECT = 'listing_direct',
  LISTING_INFERRED = 'listing_inferred',
  ESTIMATE = 'estimate'
}

export enum PayType {
  DAILY = 'daily',
  HOURLY = 'hourly',
}

export enum SupplementalPayType {
  TIPS = 'tips',
  COMMISSION = 'commission',
  BONUS = 'bonus'
}

export enum WorkplaceType {
  ON_SITE = 'on_site',
  HYBRID = 'hybrid',
  REMOTE = 'remote',
  MOBILE = 'mobile'
}

export interface JobBase extends AddressRecord {
  id: string
  slug: string
  provider: JobProvider
  status?: JobStatus
  title: string
  media: Media[]
  pay_type: PayType | null
  pay_source_type: PaySourceType
  min_pay: number | null
  max_pay: number | null
  supplemental_pay: SupplementalPayType | null
  schedule: JobScheduleType[] | null
  position_tags: Tag[] | null
  requirements: string[] | null
  interest_tags: string[] | null
  application: Application | null
  application_url: string | null
  coordinate: LatLng | null
  company: Company | null
  region_name: string | null
  is_favorite: boolean
  is_viewed: boolean
  has_prescreeners: boolean
  estimated_apply_time: number | null
  posted_date: string
  created_at: string
  workplace?: WorkplaceType | null
  summary: string | null
  living_wage: boolean
}

export interface JobIndexRecord extends JobBase {
  coordinate: LatLng
  company: Company
}

export interface Job extends JobIndexRecord {
  description: string | null
  location_id: string | null
  location_job_count: number | null
  enterprise: Enterprise | null
  benefits: string[] | null
  position: string
  clean_title: string | null
  min_tips: number | null
  max_tips: number | null
  weekly_hours: number
  is_gig?: boolean
}
