import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: defineStyle({
    bg: 'white',
    borderColor: 'gray.300',
    borderWidth: 1,
    borderRadius: 3
  })
})

const sizes = {
  lg: definePartsStyle({
    label: { fontSize: 'md', ml: 2 }
  }),
  xl: definePartsStyle({
    control: { boxSize: 6, borderRadius: 4 },
    label: { fontSize: 'md', ml: 2 }
  }),
}

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    colorScheme: 'brand',
  }
})
