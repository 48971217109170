import { InfiniteData, QueryClient, QueryKey, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import { ApiClientError, ApiCursorResponse } from '../lib/apiClient'

export function retryCallback(failureCount: number, error: any) {
  if (failureCount >= 2) return false
  return error instanceof ApiClientError ? error.status === undefined : false
}

export function filterInfiniteData<T = any>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (record: T) => any
) {
  const infiniteData = queryClient.getQueryData<InfiniteData<ApiCursorResponse<T>>>(
    queryKey
  )
  if (infiniteData) {
    const pages = infiniteData.pages.map(page => (
      { data: page.data.filter(filter) }
    ))
    queryClient.setQueryData<InfiniteData<any>>(queryKey, data => ({
      pages,
      pageParams: data!.pageParams
    }))
  }
}

export function isInfiniteQuery(
  query: UseQueryResult | UseInfiniteQueryResult
): query is UseInfiniteQueryResult {
  return !!(query as UseInfiniteQueryResult<any>).fetchNextPage
}
