import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useSessionStorage } from '../contexts'
import { JobBase } from '../models'
import { api } from '../services'
import { useTrack } from './analytics'

const CART_BOUNDS = { min: 1, max: 40 }
const CART_QKEY = ['bulk_apply_cart']

export function useBulkApplyCart() {
  const [cart, setCart] = useSessionStorage('bulkApplyCart')
  const queryClient = useQueryClient()
  const track = useTrack()
  const jobsQuery = useBulkApplyCartJobsQuery(cart)

  const bounds = CART_BOUNDS

  return useMemo(() => {
    const initialized = !!cart
    const jobs = jobsQuery.data ?? []
    const length = jobs?.length ?? 0
    const jobsLeftToAdd = Math.max(0, bounds.min - length)
    const availableCapacity = Math.max(0, bounds.max - length)

    const includes = (jobId: string) => {
      return !!jobs?.find(job => job.id === jobId)
    }

    const set = (jobs: JobBase[]) => {
      queryClient.setQueryData(CART_QKEY, jobs)
      setCart(jobs.map(job => job.id))
    }

    const reset = () => {
      queryClient.setQueryData(CART_QKEY, undefined)
      setCart(undefined)
    }

    return {
      initialized,
      bounds,
      jobs,
      length,
      jobsLeftToAdd,
      availableCapacity,
      jobsQuery,

      includes,

      set,

      add(job: JobBase) {
        if (includes(job.id)) return
        set([...jobs, job])
        track.event('Add to cart', { job_id: job.id })
      },

      remove(jobId: string) {
        set(jobs.filter(job => job.id !== jobId))
      },

      reset
    }
  }, [cart, jobsQuery])
}


function useBulkApplyCartJobsQuery(jobIds?: string[]) {
  return useQuery(CART_QKEY, async () => {
    const body = await api.jobs.list({ ids: jobIds!.join(','), per_page: CART_BOUNDS.max })
    return body.data
  }, {
    enabled: !!jobIds?.length,
    cacheTime: Infinity,
    staleTime: Infinity
  })
}
