import { BoxProps, Flex, Icon, Text, VStack } from '@chakra-ui/react'
import { IconArrowBackUp, IconInputSearch, IconMapPin } from '@tabler/icons-react'
import { useQueryClient } from '@tanstack/react-query'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { QKey } from '../../../types'
import { HorizontalList } from './common'

export const EndingCard = memo((props: BoxProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const handleBackToTopClick = () => {
    queryClient.resetQueries([QKey.FEED])
    queryClient.resetQueries([QKey.JOBS, QKey.CATEGORIES])
    window.scrollTo({ top: 0 })
  }

  return (
    <HorizontalList mt={5} mb={10} {...props}>
      <EndingCardButtonWrapper onClick={handleBackToTopClick}>
        <Icon
          as={IconArrowBackUp}
          boxSize={6}
          css={{
            rotate: '90deg',
          }}
        />
        <Text fontSize="sm" fontWeight={500} lineHeight="18px">
          {t('actions.backToTop')}
        </Text>
      </EndingCardButtonWrapper>
      <EndingCardButtonWrapper as={Link} to="/search">
        <Icon as={IconInputSearch} boxSize={6} />
        <Text fontSize="sm" fontWeight={500} lineHeight="18px">
          {t('actions.browseAllGigs')}
        </Text>
      </EndingCardButtonWrapper>
      <EndingCardButtonWrapper as={Link} to="settings/starting-location">
        <Icon as={IconMapPin} boxSize={6} />
        <Text fontSize="sm" fontWeight={500} lineHeight="18px">
          {t('actions.changeLocation')}
        </Text>
      </EndingCardButtonWrapper>
    </HorizontalList>
  )
})

const EndingCardButtonWrapper = ({ children, ...props }: { to?: string } & BoxProps) => (
  <Flex
    h={190}
    w={163}
    bg="gray.100"
    justifyContent="center"
    alignItems="center"
    borderRadius={20}
    flexShrink={0}
    {...props}
  >
    <VStack gap={6}>{children}</VStack>
  </Flex>
)
