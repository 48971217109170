import { useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import hotJobsImg from '../../../assets/images/hot-jobs.svg'
import { useMatchedJobsQuery } from '../../../hooks'
import { analytics } from '../../../services'
import { JobsSection, JobsSectionProps } from '../JobsSection'

export interface MatchedJobsListSectionProps {
  variant?: JobsSectionProps['variant']
  jobId?: string
  showHeroCard?: boolean
  onLoad?: () => void
}

export const MatchedJobsListSection = ({
  jobId,
  showHeroCard,
  onLoad,
  ...props
}: MatchedJobsListSectionProps) => {
  const { t } = useTranslation()
  const query = useMatchedJobsQuery()
  const analyticsParams = jobId ? { job_id: jobId } : undefined

  const heroCardProps = useMemo(() => ({
    color: '#7FCECE',
    imageSrc: hotJobsImg,
    imageAlt: t('images.hotJobs'),
    imageSize: '80%'
  }), [t])

  useLayoutEffect(() => {
    if (query.isFetched) {
      onLoad?.()
    }
  }, [query.isFetched])

  return (
    <JobsSection
      title={t('views.jobs.justForYou')}
      query={query}
      scrollPreservationKey="matchedJobs"
      total={false}
      heroCardProps={showHeroCard ? heroCardProps : undefined}
      linkTo="/just-for-you"
      onScrollStart={() => analytics.trackHorizontalScroll('matched jobs', analyticsParams)}
      {...props}
    />
  )
}
