/**
 * NOTE: Changes to the apply flow usually require modifications
 * to the following files:
 *
 * - ApplyButton.tsx
 * - ApplicationRedirect.tsx
 * - AuthStart.tsx
 * - JobDetail/index.tsx
 */

import { Flex, Text } from '@chakra-ui/react'
import { Trans } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { ApplicationRedirectContent } from '../../components/applications'
import { StatusOverlay } from '../../components/core'
import { JobUnavailable } from '../../components/jobs'
import { useLocalStorage } from '../../contexts'
import { useApplicationRedirectJobQuery } from '../../hooks'
import { isApiNotFoundError } from '../../lib/apiClient'
import { ApplicationStatus } from '../../models'

export const ApplicationRedirect = () => {
  const params = useParams()
  const jobSlug = params.slug!
  const [jobEventDataUtmParams] = useLocalStorage('jobEventDataUtmParams')
  const validUtmParams = jobEventDataUtmParams?.jobSlug === jobSlug
    ? jobEventDataUtmParams
    : undefined
  const query = useApplicationRedirectJobQuery(jobSlug, validUtmParams)
  const { data: job, isLoading, error } = query
  const applicationUrl = job?.application_url
  const notFound = isApiNotFoundError(error)

  if (!isLoading) {
    if (job && !applicationUrl) {
      return <Navigate to=".." replace />
    }

    if (job?.has_prescreeners && job.application?.status !== ApplicationStatus.SUBMITTED) {
      return <Navigate to=".." replace />
    }
  }

  return (
    <>
      {job ? (
        <ApplicationRedirectContent job={job}>
          <Flex flex={1.65} flexDir="column" justifyContent="center">
            <Text pt={8} pb="30%" fontSize="sm" lineHeight={1.4} color="gray.400">
              <Trans i18nKey="views.jobs.applicationRedirect.message">
                <Text as="span" color="brand.500" />
              </Trans>
            </Text>
          </Flex>
        </ApplicationRedirectContent>
      ) : notFound && (
        <JobUnavailable slug={jobSlug} />
      )}

      <StatusOverlay query={query} error={notFound ? null : error} />
    </>
  )
}
