import { useEffect, useRef } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useLocalStorage } from '../contexts'
import { useAccount, usePostAuthNavigateTo } from '../hooks'
import { OnboardingLayout } from './OnboardingLayout'

export const RegisterLayout = () => {
  const currentPath = useLocation().pathname
  const prevPathRef = useRef<string>()
  const account = useAccount()
  const [startingLocation] = useLocalStorage('startingLocation')
  const postAuthNavigateTo = usePostAuthNavigateTo()

  useEffect(() => {
    prevPathRef.current = currentPath
  }, [currentPath])

  if (!account) return null

  if (postAuthNavigateTo && postAuthNavigateTo !== '/register') {
    return <Navigate to={postAuthNavigateTo} replace />
  }

  let path: string | undefined

  if (!startingLocation || !account.preferences.regions.length) {
    path = 'location'
  }

  if (path && currentPath !== `/register/${path}`) {
    return <Navigate to={path} replace />
  }

  return <OnboardingLayout pageTransition />
}
