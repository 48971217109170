import { InputGroup, InputGroupProps } from '@chakra-ui/react'
import { InputClearElement } from './InputClearElement'

export interface ClearableInputGroupProps extends InputGroupProps {
  value?: any
  onClear: () => void
}

export const ClearableInputGroup = ({ value, onClear, children, ...props }: ClearableInputGroupProps) => {

  return (
    <InputGroup {...props}>
      {children}
      <InputClearElement hidden={!value} onClear={onClear} />
    </InputGroup>
  )
}
