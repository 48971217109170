import { Button, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Navigate, useSearchParams } from 'react-router-dom'
import { PageHeader, PageHeading, PageSubheading } from '../../components/core'
import { useAppState, useGoBack } from '../../contexts'
import { analytics } from '../../services'
import { AuthMethod } from '../../types'

export const AuthLinkSent = () => {
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.auth.linkSent' })
  const [params] = useSearchParams()
  const [{ authEmail }] = useAppState()
  const backToFallback = params.get('method') === AuthMethod.SIGN_UP ? '/sign-up' : '/sign-in'
  const goBack = useGoBack(backToFallback)

  if (!authEmail) {
    return <Navigate to={backToFallback} replace />
  }

  return (
    <>
      <PageHeader logo />

      <PageHeading mb={2}>
        {vt('title')}
      </PageHeading>

      <PageSubheading>
        {vt('message')}<br />
        {authEmail}
      </PageSubheading>

      <Flex mt={10} justify="center">
        <Button
          variant="link" color="gray.300" textDecor="underline" px={1}
          onClick={() => {
            analytics.trackEvent('Back to login')
            goBack()
          }}
        >
          {vt('backToLogin')}
        </Button>
      </Flex>
    </>
  )
}
