import { BoxProps, Card, CardBody, Flex, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFormatters } from '../../hooks'
import { LatLng, Location } from '../../models'
import { AddressText } from '../AddressText'
import { CompanyLogo } from '../companies'
import { RegionBadge } from '../RegionBadge'

export const LOCATION_LIST_ITEM_HEIGHT = 83

export interface LocationListItemProps extends BoxProps {
  location: Location
  distanceFrom?: LatLng
}

export const LocationListItem = ({
  location,
  distanceFrom,
  ...props
}: LocationListItemProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()

  return (
    <Card
      as={Link} to={`/locations/${location.id}`}
      h={`${LOCATION_LIST_ITEM_HEIGHT}px`}
      {...props}
    >
      <CardBody as={Flex} justify="space-between">
        <Flex gap="13px">
          <CompanyLogo company={location.company} boxSize={51} />

          <Stack spacing="9px">
            <RegionBadge record={location} distanceFrom={distanceFrom} />

            <AddressText
              address={location.address}
              address2={location.address2}
              city={location.city}
              state={location.state}
              zipcode={location.zipcode}
              fontSize={11} lineHeight={1.3} noOfLines={2}
              color="gray.900"
            />
          </Stack>
        </Flex>

        {!!location.job_count && (
          <Text fontSize="sm" color="gray.300" lineHeight="18px" ml={2} flexShrink={0}>
            {t('views.locations.jobsAvailable.short', {
              count: location.job_count,
              value: fmt.number(location.job_count)
            })}
          </Text>
        )}
      </CardBody>
    </Card>
  )
}
