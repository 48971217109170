import { Button, ButtonProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

export const AutocompleteToggleButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation()
  return (
    <Button
      ref={ref}
      size="sm"
      rounded="full"
      {...props}
    >
      {t('actions.cancel')}
    </Button>
  )
})
