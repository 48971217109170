import { t } from 'i18next'
import { isRouteErrorResponse } from 'react-router-dom'
import { AppError } from '../errors'
import { ApiClientError } from '../lib/apiClient'

const HTTP_STATUS_MESSAGES: { [key: number]: string } = {
  400: 'badRequest',
  401: 'unauthorized',
  403: 'forbidden',
  404: 'notFound',
  422: 'badRequest',
  429: 'tooManyRequests',
  500: 'serverError'
}

export function getErrorMessage(err: unknown) {
  if (typeof err === 'string') {
    return err
  }

  if (err instanceof AppError) {
    return err.message
  }

  if (err instanceof ApiClientError || isRouteErrorResponse(err)) {
    let key = err.status === undefined ? 'offline' : HTTP_STATUS_MESSAGES[err.status]
    if (!key) key = 'failedRequest'
    return t(`messages.errors.${key}`)
  }

  if (err instanceof GeolocationPositionError) {
    return t('messages.errors.geoLocationServiceFailed')
  }

  return t('messages.errors.unexpected')
}
