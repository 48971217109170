import { BoxProps, Card } from '@chakra-ui/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import notificationsImg from '../../../assets/images/notifications-sm.png'
import { ContentContainer } from '../../../components/core'
import { RequirementList } from '../../../components/jobs'
import { JobImageHeader } from '../common'

export interface RequirementsSectionProps extends BoxProps {
  items: string[]
}

export const RequirementsSection = memo(({
  items,
  ...props
}: RequirementsSectionProps) => {
  const { t } = useTranslation()

  return (
    <ContentContainer>
      <Card p={5} {...props}>
        <JobImageHeader
          title={t('views.jobs.requirements')}
          src={notificationsImg}
          alt={t('images.notifications')}
          h={{ base: '35px', '2xs': '49px' }}
        />

        <RequirementList mt={2.5} items={items} />
      </Card>
    </ContentContainer>
  )
})
