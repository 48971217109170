import {
  Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent,
  DrawerHeader, DrawerOverlay, HStack, Icon, Image, Stack, StackDivider, StackProps, Text, useBreakpointValue, VStack
} from '@chakra-ui/react'
import { IconLogin } from '@tabler/icons-react'
import { memo, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import artworkSrc from '../../assets/images/profile.png'
import { useAppState, useLayoutContext } from '../../contexts'
import { useAccount, useRevokeAuth } from '../../hooks'
import { analytics } from '../../services'
import { NAVIGATION_ICONS } from './common'
import { PublicLink, PublicLinkName } from './PublicLink'

export const NavDrawer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const pathnameRef = useRef(location.pathname)
  const { navDrawerButtonRef, navDrawerDisclosure } = useLayoutContext()
  const { isOpen, onClose } = navDrawerDisclosure
  const [{ authenticated }] = useAppState()
  const revokeAuth = useRevokeAuth()

  const companyLinks: PublicLinkName[] = authenticated
    ? ['about', 'careers', 'contact']
    : ['about', 'careers', 'forJobSeekers', 'forEmployers', 'contact']

  const isWide = useBreakpointValue({ base: false, sm: true }, { ssr: false })

  const handleClose = useCallback(() => {
    onClose()
    analytics.trackEvent('Close Hamburger Menu')
  }, [onClose])

  const handleSignOut = useCallback(() => {
    revokeAuth()
  }, [])

  useEffect(() => {
    if (isOpen) {
      pathnameRef.current = location.pathname
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && location.pathname !== pathnameRef.current) {
      onClose()
    }
  }, [location.pathname])

  return (
    <Drawer
      finalFocusRef={navDrawerButtonRef}
      placement="right"
      size={{ base: 'full', 'sm': 'xs' }}
      isOpen={isOpen}
      blockScrollOnMount={!isWide}
      onClose={handleClose}
    >
      <DrawerOverlay />
      <DrawerContent bg="gray.900" minW="250px">
        <DrawerCloseButton
          right={6}
          top={6}
          size="md"
          bg="gray.400"
          borderRadius="lg"
          color="gray.900"
          boxSize={10}
          _hover={{
            bg: 'gray.400'
          }}
        />
        {!authenticated && (
          <DrawerHeader pt={6} pb={2}>
            <HStack spacing={{ base: 1.5, '2xs': 2.5 }} pr={12}>
              <Button
                as={Link} to="/sign-in"
                variant="quaternary" fontSize="xs"
              >
                {t('actions.logIn')}
              </Button>
              <Button
                as={Link} to="/sign-up"
                variant="quaternary" fontSize="xs"
              >
                {t('actions.signUp')}
              </Button>
            </HStack>
          </DrawerHeader>
        )}

        <DrawerBody display="flex" color="gray.300" pb="120px">
          <Box w="full" mt={20} mb="auto">
            <InternalLinksSection mb={5} onClose={handleClose} />

            <Divider opacity={1} />

            <VStack
              spacing={0} align="start"
              divider={<StackDivider />}
            >
              <PublicLinksSection
                title={t('terms.company')}
                links={companyLinks}
                pt={2.5} pb={5}
                onLinkClick={handleClose}
              />

              <PublicLinksSection
                title={t('terms.legal')}
                links={['termsOfService', 'privacyPolicy']}
                pt={1.5} pb={authenticated ? 5 : 0}
                onLinkClick={handleClose}
              />

              {authenticated && (
                <Button
                  variant="ghost" h="18px" iconSpacing={1}
                  leftIcon={<Icon as={IconLogin} boxSize={5} strokeWidth={1.5} />}
                  p={0} mt={1.5} fontWeight="normal"
                  onClick={handleSignOut}
                >
                  {t('actions.signOut')}
                </Button>
              )}
            </VStack>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const InternalLinksSection = memo(({ onClose, ...props }: StackProps & { onClose: () => void }) => {
  const { t } = useTranslation()
  const [{ authenticated }] = useAppState()
  const account = useAccount()

  return (
    <Stack
      spacing={1} alignItems="flex-start" color="white"
      onClick={e => {
        if (e.target !== e.currentTarget) {
          onClose()
        }
      }}
      {...props}
    >
      {authenticated && <ProfileSection mb={8} />}

      {authenticated && <PrimaryLink title={t('links.home')} to="/home" />}
      <PrimaryLink title={t('actions.search')} to="/search" />
      {authenticated && <PrimaryLink title={t('links.myGigs')} to="/my-gigs" />}
      {authenticated && <PrimaryLink title={t('links.myApp')} to="/my-app" />}
      <PrimaryLink title={t('views.matchPreferences.title')} to="/match-preferences" />
      {authenticated && account?.registration_completed_at && (
        <PrimaryLink title={t('views.settings.title')} to="/settings" />
      )}
    </Stack>
  )
})

const ProfileSection = ({ ...props }: StackProps) => {
  const { t } = useTranslation()
  const account = useAccount()
  const fullName = account?.full_name

  return (
    <HStack as={Link} to="/my-app" spacing={5} {...props}>
      <Image
        src={artworkSrc} alt={t('images.profile')}
        boxSize={75} borderRadius="full"
        bg="pink.300" flexShrink={0}
      />

      <Box>
        {fullName && (
          <Text
            fontWeight="medium" lineHeight={1.2}
            wordBreak="break-word" mb={2.5}
          >
            {fullName}
          </Text>
        )}
        <Text
          fontSize="xs" fontFamily="Soehne"
          color="gray.300" textTransform="uppercase"
          lineHeight={1}
        >
          {t('actions.viewMyApp')}
        </Text>
      </Box>
    </HStack>
  )
}

const PrimaryLink = ({ title, to }: { title: string, to: string }) => (
  <NavLink
    to={to}
    onClick={() => analytics.trackNav(title, { source: 'Hamburger Menu' })}
  >
    {({ isActive }) => (
      <Button
        as={Box} variant="ghost" size="xl" h={9}
        color={isActive ? 'white' : 'gray.300'}
        leftIcon={<Icon as={NAVIGATION_ICONS[to]} boxSize={26} mr={0.5} />}
      >
        {title}
      </Button>
    )}
  </NavLink>
)

const PublicLinksSection = ({
  title,
  links,
  onLinkClick,
  ...props
}: {
  title: string
  links: PublicLinkName[]
  onLinkClick: () => void
} & StackProps) => {
  return (
    <VStack align="start" spacing={1} {...props}>
      <Text fontWeight="medium" lineHeight={1}>
        {title}
      </Text>
      {links.map(link => (
        <PublicLink
          key={link}
          name={link}
          fontSize="2sm"
          onClick={onLinkClick}
        />
      ))}
    </VStack>
  )
}
