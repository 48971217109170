import { Box, Button, ButtonProps, HStack, Icon, Stack } from '@chakra-ui/react'
import { IconAdjustmentsHorizontal, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { CONTENT_PADDING } from '../core'
import { useSearch } from './SearchContext'
import { SearchFiltersBadge } from './SearchFiltersBadge'
import { useLocationAutocomplete } from './useLocationAutocomplete'
import { useQueryAutocomplete } from './useQueryAutocomplete'

interface SearchFormHeaderProps {
  queryAutocomplete: ReturnType<typeof useQueryAutocomplete>
  locationAutocomplete: ReturnType<typeof useLocationAutocomplete>
  queryInputVisible?: boolean
  filtersVisible: boolean
  filtersToggleVisible?: boolean
  onToggleFilters: () => void
}

export const SearchFormHeader = ({
  queryAutocomplete,
  locationAutocomplete,
  queryInputVisible,
  filtersVisible,
  filtersToggleVisible,
  onToggleFilters
}: SearchFormHeaderProps) => {
  return (
    <Box>
      {/** Suppress downshift ref error */}
      <div ref={queryAutocomplete.inputRef} />
      <div ref={locationAutocomplete.inputRef} />

      {!queryInputVisible && queryAutocomplete.menu}
      {!filtersVisible && locationAutocomplete.menu}

      <Stack
        spacing={5}
        px={CONTENT_PADDING} pb={filtersVisible ? 5 : 0}
      >
        {queryInputVisible && (
          <HStack spacing={2.5}>
            <Box flex={1}>
              {queryAutocomplete.input}
              {queryAutocomplete.menu}
            </Box>

            {filtersToggleVisible && (
              <FiltersToggle
                filtersVisible={filtersVisible}
                onClick={onToggleFilters}
              />
            )}
          </HStack>
        )}

        {filtersVisible && (
          <Box
            pb={2.5}
            borderBottom="1px" borderColor="gray.200"
          >
            {locationAutocomplete.input}
            {locationAutocomplete.menu}
          </Box>
        )}
      </Stack>
    </Box>
  )
}

const FiltersToggle = ({
  filtersVisible,
  ...props
}: ButtonProps & { filtersVisible: boolean }) => {
  const { t } = useTranslation()
  const { filterCount } = useSearch()

  return (
    <Button
      variant={filtersVisible ? 'secondary' : 'input'}
      w="70px" h="50px"
      aria-label={filtersVisible ? t('actions.cancel') : t('views.search.showFilters')}
      {...props}
    >
      {filtersVisible ? (
        <Icon as={IconX} ml={-0.5} boxSize={6} />
      ) : (
        <Icon as={IconAdjustmentsHorizontal} ml={-0.5} boxSize={6} />
      )}

      <SearchFiltersBadge
        count={filterCount}
        ml={2}
        bg={filtersVisible ? undefined : 'gray.900'}
        color={filtersVisible ? undefined : 'white'}
      />
    </Button>
  )
}
