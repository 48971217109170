// Ref to add/remove dashes https://codesandbox.io/s/zdg30?file=/src/utils/index.ts:0-398
export function removeNonNumeric(phoneNumber: string) {
  // eslint-disable-next-line no-useless-escape
  return phoneNumber.trim().replace(/[^0-9]/g, '')
}

export function phoneNumberAutoFormat(phoneNumber: string) {
  const number = removeNonNumeric(phoneNumber)

  if (number.length < 4) return number
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '($1) $2')
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '($1) $2-$3')

  return number.replace(/(\d{3})(\d{4})(\d{4})/, '($1) $2-$3')
}

export function isPhoneNumberValid(phoneNumber: string) {
  return phoneNumber.length === 10
}
