import { Box, Button, Grid, GridItem, GridProps } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { forwardRef, memo, ReactElement, Ref, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useErrorToast } from '../../../hooks'

type Choice = string | number

interface BaseToggleGroupProps<T extends Choice> extends GridProps {
  choices: T[]
  getLabel?: (choice: T) => string
}

interface ToggleGroupSingleProps<T extends Choice> {
  value?: T | null
  multiple?: false
  maxSelections: undefined
  onChange?: (value: T | null) => void
}

interface ToggleGroupMultipleProps<T extends Choice> {
  value?: T[]
  multiple: true
  maxSelections?: number
  onChange?: (value: T[]) => void
}

export type ToggleGroupProps<T extends Choice> = BaseToggleGroupProps<T> & (ToggleGroupSingleProps<T> | ToggleGroupMultipleProps<T>)

export const ToggleGroup = forwardRef(({
  value,
  choices,
  multiple,
  maxSelections,
  getLabel = defaultGetLabel,
  onChange = noop,
  ...props
}, ref) => {
  const { t } = useTranslation()
  const toast = useErrorToast()

  const valueRef = useRef(value)
  valueRef.current = value
  const choicesRef = useRef(choices)
  choicesRef.current = choices

  // Clean up value if it is missing from choices.
  useEffect(() => {
    if (value === undefined) return
    if (multiple) {
      if (value) {
        const cleanedValue = (value as Choice[]).filter(v => choices.includes(v as any))
        if (!isEqual(value, cleanedValue)) {
          onChange(cleanedValue)
        }
      }
    } else {
      if (value !== null && !choices.includes(value as any)) {
        onChange(null)
      }
    }
  }, [choices, multiple])

  const isSelected = (choice: Choice) => {
    return multiple
      ? !!(valueRef.current as Choice[])?.includes(choice)
      : valueRef.current === choice
  }

  const handleToggle = useCallback((choice: Choice) => {
    if (multiple) {
      const _value = (valueRef.current as Choice[] ?? [])
      if (isSelected(choice)) {
        onChange(_value.filter(v => v !== choice))
      } else {
        if (maxSelections !== undefined && _value.length === maxSelections) {
          toast(t('messages.maxSelectionsReached'))
        } else {
          onChange([..._value, choice])
        }
      }
    } else {
      if (isSelected(choice)) {
        onChange(null)
      } else {
        onChange(choice)
      }
    }
  }, [multiple, onChange])

  return (
    <Grid
      ref={ref}
      templateColumns={'repeat(2, minmax(0, 1fr))'}
      gap={2.5}
      w="full"
      role={multiple ? undefined : 'radiogroup'}
      {...props}
    >
      {choices.map(choice => (
        <GridItem key={choice}>
          <ToggleButton
            role={multiple ? 'checkbox' : 'radio'}
            choice={choice}
            label={getLabel(choice)}
            selected={isSelected(choice)}
            onToggle={handleToggle}
          />
        </GridItem>
      ))}
    </Grid>
  )
}) as <T extends Choice>(p: ToggleGroupProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement

interface ToggleButtonProps {
  role: 'checkbox' | 'radio'
  choice: Choice
  label: string
  selected: boolean
  onToggle: (choice: Choice) => void
}

const ToggleButton = memo(({ role, choice, label, selected, onToggle }: ToggleButtonProps) => {
  return (
    <Button
      variant={selected ? 'secondary' : undefined}
      w="full" h="54px"
      px={1} py={0}
      role={role}
      overflow="hidden"
      aria-checked={selected ? 'true' : 'false'}
      _hover={selected ? undefined : {
        '@media(hover: hover)': {
          bg: 'gray.200'
        }
      }}
      onClick={() => onToggle(choice)}
    >
      <Box mt={-0.5} fontWeight="medium" whiteSpace="normal" noOfLines={3} lineHeight={1.38}>
        {label}
      </Box>
    </Button>
  )
})

const defaultGetLabel = (value: Choice) => String(value)
