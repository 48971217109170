import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

export const tabsTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: 'primary'
  },
  variants: {
    primary: definePartsStyle({
      tab: {
        height: '26px',
        fontFamily: 'Soehne',
        fontSize: 'xs',
        textTransform: 'uppercase',
        color: 'gray.300',
        background: 'white',
        borderRadius: 8,
        mx: 1,
        _focusVisible: {
          boxShadow: 'none',
        },
        _selected: {
          color: 'white',
          background: 'gray.900'
        }
      },
      tablist: {
        p: '9px',
        borderRadius: 10,
        background: 'gray.100'
      }
    })
  }
})
