import { Stack, StackProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { buildBulkApplyUrl } from '../../helpers'
import { useBulkApplyCart } from '../../hooks'
import { CONTENT_PADDING, ContentFooter } from '../core'
import { BulkApplyProgressBox } from './BulkApplyProgressBox'

export const BULK_APPLY_FOOTER_MIN_HEIGHT = 70

export interface BulkApplyFooterProps extends StackProps {
  anchorJobId?: string
  children?: ReactNode
}

export const BulkApplyFooter = ({ anchorJobId, children, ...props }: BulkApplyFooterProps) => {
  const { t } = useTranslation()
  const cart = useBulkApplyCart()
  const cartPath = buildBulkApplyUrl('cart', { job: anchorJobId })

  return (
    <ContentFooter minH={BULK_APPLY_FOOTER_MIN_HEIGHT}>
      <Stack w="full" px={CONTENT_PADDING} py={3} align="center" {...props}>
        {cart.jobsLeftToAdd ? (
          <BulkApplyProgressBox>
            {t('views.bulkApply.addAtLeast', { count: cart.jobsLeftToAdd })}
          </BulkApplyProgressBox>
        ) : (
          <BulkApplyProgressBox linkTo={cartPath}>
            {t('views.bulkApply.viewCart')}
          </BulkApplyProgressBox>
        )}

        {children}
      </Stack>
    </ContentFooter>
  )
}
