import { Box, Flex, HStack, Icon, IconButton, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'
import { IconArrowBackUp, IconPlayerPause, IconPlayerPlay, IconVolume, IconVolumeOff } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface VideoControlsProps {
  video: HTMLVideoElement
  muted: boolean
  onSetMuted: (value: boolean) => void
}

export const VideoControls = ({
  video,
  muted,
  onSetMuted
}: VideoControlsProps) => {
  const { t } = useTranslation()
  const [_counter, setCounter] = useState(0)

  useEffect(() => {
    video.volume = 0.5

    video.addEventListener('timeupdate', forceRender)
    video.addEventListener('play', forceRender)
    video.addEventListener('pause', forceRender)

    return () => {
      video.addEventListener('timeupdate', forceRender)
      video.removeEventListener('play', forceRender)
      video.removeEventListener('pause', forceRender)
    }
  }, [])

  const togglePlayback = () => {
    if (video?.paused) {
      video?.play()
    } else {
      video?.pause()
    }
  }

  const toggleMuted = () => {
    onSetMuted(!muted)
  }

  const replay = () => {
    if (video) {
      video.currentTime = 0
      video.play()
    }
  }

  const forceRender = () => {
    setCounter(value => value + 1)
  }

  return (
    <Flex flexDir="column" pos="absolute" bottom={0} w="full" px={8}>
      <Flex justifyContent="space-between">
        <HStack>
          {video.duration - video.currentTime > 0.01 ? (
            <IconButton
              size="xs"
              variant="ghost"
              icon={<Icon as={video.paused ? IconPlayerPlay : IconPlayerPause} boxSize={6} />}
              aria-label={video.paused ? t('actions.play') : t('actions.pause')}
              onClick={togglePlayback}
            />
          ) : (
            <IconButton
              size="xs"
              variant="ghost"
              icon={<Icon as={IconArrowBackUp} boxSize={6} />}
              aria-label={t('actions.replay')}
              onClick={replay}
            />
          )}
          {/* Current time/duration */}
          <Text fontWeight="medium" noOfLines={1} mr={1}>
            {formatTime(video.currentTime)} / {formatTime(video.duration)}
          </Text>
        </HStack>

        <IconButton
          size="xs"
          variant="ghost"
          icon={<Icon as={muted ? IconVolumeOff : IconVolume} boxSize={6} />}
          aria-label={muted ? t('actions.unmute') : t('actions.mute')}
          onClick={toggleMuted}
        />
      </Flex>

      <VideoScrubber
        video={video}
        label={t('actions.seek')}
      />
    </Flex>
  )
}

interface VideoScrubberProps {
  video: HTMLVideoElement
  label: string
}

const VideoScrubber = ({
  video,
  label
}: VideoScrubberProps) => {
  const [bufferedRange, setBufferedRange] = useState<readonly [number, number]>()
  const [_counter, setCounter] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      const range = getBufferedPercentageRange(video)
      setBufferedRange(range)
      if (range[0] === 0 && range[1] === 100) {
        clearInterval(timer)
      }
    }, 500)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (video.paused) return

    let handle: number = 0
    const update = () => {
      forceRender()
      handle = requestAnimationFrame(update)
    }
    handle = requestAnimationFrame(update)

    return () => {
      cancelAnimationFrame(handle)
    }
  }, [video.paused])

  const seek = (time: number) => {
    video.currentTime = time
    if (video.paused) {
      forceRender()
    }
  }

  const forceRender = () => {
    setCounter(value => value + 1)
  }

  return (
    <Slider
      h="50px" w="full"
      value={video.currentTime}
      min={0}
      max={video.duration}
      step={0.001}
      aria-label={label}
      onChange={seek}
    >
      <SliderTrack top="15px !important" h="6px" rounded="full" bg="rgba(255,255,255,0.3)">
        {bufferedRange && (
          <Box
            pos="absolute" top={0} bottom={0}
            left={`${bufferedRange[0]}%`} w={`${bufferedRange[1]}%`}
            bg="rgba(255,255,255,0.4)"
          />
        )}
        <SliderFilledTrack bg="brand.500" rounded="full" />
      </SliderTrack>
      <SliderThumb top="15px" />
    </Slider>
  )
}

const formatTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = Math.floor(timeInSeconds % 60)
  return `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`
}

function getBufferedPercentageRange(video: HTMLVideoElement) {
  let i = 0
  const bf = video.buffered
  const time = video.currentTime

  if (!bf.length) {
    return [0, 0] as const
  }

  for (i; i < bf.length; i++) {
    if (bf.start(i) <= time && time <= bf.end(i)) {
      break
    }
  }

  if (i >= bf.length) {
    i = bf.length - 1
  }

  return [
    Math.floor((bf.start(i) / video.duration) * 100),
    Math.ceil((bf.end(i) / video.duration) * 100)
  ] as const
}
