import { Box, Flex, HStack, Skeleton, Stack, Text } from '@chakra-ui/react'
import { memo, ReactElement, ReactNode, useEffect, useMemo } from 'react'
import { useInfiniteQueryTotal, useQueryItems, useResetScrollXCache } from '../../hooks'
import { HInfiniteList, HInfiniteListProps } from './HInfiniteList'
import { CARD_SPACING_S, ContentSection, ContentSectionProps, CONTENT_PADDING } from './layout'

export interface OptionalHCardListSectionProps<T> extends
  Pick<HInfiniteListProps<T>, 'itemSpacing' | 'query' | 'items' | 'scrollPreservationKey' | 'onScrollStart'>,
  ContentSectionProps {
  isLoading?: boolean
  isFetching?: boolean
  isError?: boolean
  placeholder?: string
  heroCardHeight?: number
  renderHeroCard?: (item: T) => ReactElement
}

export interface HCardListSectionProps<T> extends
  OptionalHCardListSectionProps<T>,
  Pick<HInfiniteListProps<T>, 'renderItem'> {
  itemWidth: number
  itemHeight: number
}

export const HCardListSection = <T,>({
  query,
  items,
  scrollPreservationKey,
  renderItem,
  itemWidth,
  itemHeight,
  itemSpacing = CARD_SPACING_S,
  isLoading = query?.isLoading,
  isFetching = query?.isFetching,
  isError = query?.isError,
  placeholder,
  total,
  heroCardHeight,
  renderHeroCard,
  onScrollStart,
  ...props
}: HCardListSectionProps<T>) => {
  const resetScrollXCache = useResetScrollXCache()
  const queryItems = useQueryItems(items ? undefined : query)
  const _items = items ?? queryItems
  const isEmpty = !!_items && _items.length === 0

  const hListItems = useMemo(() => {
    return renderHeroCard ? _items?.slice(1) : _items
  }, [_items, renderHeroCard])

  useEffect(() => {
    if (isFetching && scrollPreservationKey) {
      resetScrollXCache(scrollPreservationKey)
    }
  }, [isFetching])

  const queryTotal = useInfiniteQueryTotal(total === undefined ? query : undefined)
  const _total = typeof total === 'boolean' ? undefined : total ?? queryTotal

  if ((isEmpty || (!_items && isError)) && !isLoading && !placeholder) {
    return null
  }

  return (
    <ContentSection
      isLoading={isLoading}
      total={_total}
      {...props}
    >
      <Stack spacing={5}>
        {renderHeroCard && (
          <Box px={CONTENT_PADDING}>
            {isLoading ? (
              <Skeleton h={`${heroCardHeight}px`} rounded="15px" />
            ) : (
              !isEmpty && renderHeroCard(_items![0])
            )}
          </Box>
        )}

        {isLoading ? (
          <HCardListSkeleton itemWidth={itemWidth} itemHeight={itemHeight} itemSpacing={itemSpacing} />
        ) : isEmpty ? (
          <HCardListPlaceholder itemWidth={itemWidth} itemHeight={itemHeight} itemSpacing={itemSpacing}>
            {placeholder}
          </HCardListPlaceholder>
        ) : !!hListItems?.length && (
          <HInfiniteList<T>
            h={`${itemHeight}px`}
            contentPaddingX={CONTENT_PADDING}
            query={query}
            items={hListItems}
            estimateItemSize={() => itemWidth}
            itemSpacing={itemSpacing}
            scrollPreservationKey={scrollPreservationKey}
            renderItem={renderItem}
            onScrollStart={onScrollStart}
          />
        )}
      </Stack>
    </ContentSection>
  )
}

const HCardListSkeleton = memo(({ itemWidth, itemHeight, itemSpacing }: {
  itemWidth: number
  itemHeight: number
  itemSpacing: number
}) => (
  <HStack pl={CONTENT_PADDING} spacing={`${itemSpacing}px`} overflow="hidden">
    {new Array(3).fill(0).map((_, i) => (
      <Skeleton
        key={i} w={`${itemWidth}px`} h={`${itemHeight}px`}
        flexShrink={0} rounded="15px"
      />
    ))}
  </HStack>
))

export const HCardListPlaceholder = memo(({ itemWidth, itemHeight, itemSpacing, children }: {
  itemWidth: number
  itemHeight: number
  itemSpacing: number
  children: ReactNode
}) => (
  <HStack pl={CONTENT_PADDING} spacing={`${itemSpacing}px`} overflow="hidden">
    {new Array(3).fill(0).map((_, i) => (
      <Flex
        key={i}
        shrink={0}
        bg="gray.100"
        w={`${itemWidth}px`}
        h={`${itemHeight}px`}
        p={5}
        rounded={20}
      >
        {i === 0 && (
          <Text color="gray.400" fontSize="sm" lineHeight="18px">
            {children}
          </Text>
        )}
      </Flex>
    ))}
  </HStack>
))
