import { Button, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ContentContainer, NoResultsNotice, SimplePage } from '../components/core'
import { TipCard } from '../components/core/TipCard'
import { DiscoverJobsCard, JobListPage, JobsSection, MatchedJobsSection } from '../components/jobs'
import {
  useAccount, useDismissTip, useGeoCoordinates, useJobPreferencesQuery,
  useJobSearchQuery, useMatchedJobsQuery, useQueryItems
} from '../hooks'

export const MatchedJobs = () => {
  const { t } = useTranslation()
  const { data: jobPreferences } = useJobPreferencesQuery()
  const query = useMatchedJobsQuery()
  const items = useQueryItems(query)
  const dismissTip = useDismissTip('matchedJobs')

  if (!jobPreferences) {
    return (
      <SimplePage title={t('views.jobs.justForYou')}>
        <MatchedJobsSection />
      </SimplePage>
    )
  }

  return (
    <JobListPage
      title={t('views.jobs.justForYou')}
      topContent={
        dismissTip && <TipCard mb="20px" message={t('tips.matchedJobs')} onDismiss={dismissTip} />
      }
      query={query}
      items={items}
      renderNoResults={null}
    >
      {items && !items.length && <NoResults />}
    </JobListPage>
  )
}

const NoResults = () => {
  const { t } = useTranslation()
  const account = useAccount()
  const regionName = account?.preferences.regions[0]?.name
  const coordinates = useGeoCoordinates()
  const searchQuery = useJobSearchQuery({ coordinates })

  return (
    <Stack spacing="50px" w="full">
      <NoResultsNotice
        title={t('views.jobs.noResultsNotice.title')}
        message={t('views.jobs.noMatches', { region: regionName })}
        button={
          <>
            <Button
              as={Link} to="/match-preferences"
              variant="tertiary"
            >
              {t('actions.changePreferences')}
            </Button>
            <Button
              as={Link} to="/home/settings/starting-location"
              variant="tertiary"
            >
              {t('actions.changeLocation')}
            </Button>
          </>
        }
      />

      <JobsSection
        title={t('views.jobs.jobsYouMightLike')}
        query={searchQuery}
        scrollPreservationKey="searchResults"
      />

      <ContentContainer>
        <DiscoverJobsCard />
      </ContentContainer>
    </Stack>
  )
}
