import { forwardRef, Icon, IconButton, IconButtonProps, IconProps } from '@chakra-ui/react'
import { IconChevronLeft, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import {
  ButtonWithOptionalAriaProps, IconAlignJustifiedReverse, IconButtonWithOptionalAriaProps
} from '..'
import { useAnalyticsParams, useGoBack, useLayoutContext } from '../../../contexts'
import { analytics } from '../../../services'

export interface HeaderIconButtonProps extends IconButtonProps {
  renderIcon?: (props: IconProps) => IconButtonProps['icon']
}

export const HeaderIconButton = forwardRef<HeaderIconButtonProps, 'button'>(({
  renderIcon,
  ...props
}, ref) => (
  <IconButton
    ref={ref}
    size="xs"
    icon={renderIcon?.({ color: 'gray.900', boxSize: 6 })}
    borderRadius={10} boxSize={10}
    {...props}
  />
))

export const NavDrawerButton = (props: ButtonWithOptionalAriaProps) => {
  const { t } = useTranslation()
  const { navDrawerButtonRef, navDrawerDisclosure } = useLayoutContext()
  const { page } = useAnalyticsParams()

  return (
    <HeaderIconButton
      ref={navDrawerButtonRef}
      title={t('actions.openMenu')}
      aria-label={t('actions.openMenu')}
      renderIcon={iconProps => (
        <Icon
          as={IconAlignJustifiedReverse}
          {...iconProps}
        />
      )}
      onClick={() => {
        navDrawerDisclosure.onOpen()
        analytics.trackEvent('View Hamburger Menu', { page: page ?? document.title })
      }}
      {...props}
    />
  )
}

export interface BackButtonProps extends IconButtonWithOptionalAriaProps {
  kind?: 'back' | 'close'
}

export const BackButton = ({
  kind = 'back',
  onClick,
  ...props
}: BackButtonProps) => {
  const { t } = useTranslation()
  const goBack = useGoBack()

  if (!onClick) {
    onClick = goBack
  }

  return (
    <IconButton
      aria-label={t('actions.goBack')}
      size="md"
      icon={<Icon as={kind === 'close' ? IconX : IconChevronLeft} color="gray.900" boxSize={6} />}
      onClick={onClick}
      {...props}
    />
  )
}

export const DismissButton = ({ bg, ...props }: IconButtonWithOptionalAriaProps) => {
  const { t } = useTranslation()
  return (
    <IconButton
      aria-label={t('actions.dismiss')}
      bg={bg}
      borderRadius="lg"
      boxSize={10}
      _hover={{ bg }}
      icon={<Icon as={IconX} color="gray.900" boxSize={6} />}
      {...props}
    />
  )
}
