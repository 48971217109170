import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as locales from './locales'

const resources: InitOptions['resources'] = {}
for (const [lng, bundle] of Object.entries(locales)) {
  resources[lng] = { translation: bundle }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
})
  .catch(console.error)
