import { ButtonProps, Flex, Icon, Image, ListItem, ListProps, UnorderedList } from '@chakra-ui/react'
import { IconLocation } from '@tabler/icons-react'
import { UseComboboxReturnValue } from 'downshift'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import googleLogoSrc from '../../assets/images/logotype-google.png'
import { SubmitButton } from '../core'
import { AutocompleteMenuItem } from './AutocompleteMenuItem'

export interface GooglePlacesAutocompleteMenuProps extends ListProps {
  items: google.maps.places.AutocompletePrediction[]
  isOpen: boolean
  highlightedIndex: number
  isLoading: boolean
  getItemProps: UseComboboxReturnValue<google.maps.places.AutocompletePrediction>['getItemProps']
  onUseCurrentLocation: () => void
}

export const GooglePlacesAutocompleteMenu = forwardRef<HTMLUListElement, GooglePlacesAutocompleteMenuProps>(({
  items,
  isOpen,
  highlightedIndex,
  isLoading,
  getItemProps,
  onUseCurrentLocation,
  ...props
}, ref) => {
  const { t } = useTranslation()
  return (
    <UnorderedList ref={ref} margin={0} listStyleType="none" {...props}>
      {isOpen && (
        <>
          <Flex as={ListItem} alignItems="center" mt={-2}>
            <UseCurrentLocationButton
              isLoading={isLoading}
              onClick={e => {
                e.stopPropagation()
                onUseCurrentLocation()
              }}
            />
            {!!items.length && (
              <Image src={googleLogoSrc} ml="auto" h={18} alt={t('images.logoGoogle')} />
            )}
          </Flex>

          {items.map((item, index) => {
            return (
              <AutocompleteMenuItem
                key={item.place_id}
                title={item.structured_formatting.main_text}
                subtitle={item.structured_formatting.secondary_text}
                highlighed={index === highlightedIndex}
                {...getItemProps({ item, index })}
              />
            )
          })}
        </>
      )}
    </UnorderedList>
  )
})

export const UseCurrentLocationButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  if (!navigator.geolocation) return null

  return (
    <SubmitButton
      variant="unstyled" size="sm" color="#208EF3" textTransform="uppercase"
      fontFamily="Soehne" fontSize="12px" fontWeight="400"
      leftIcon={<Icon as={IconLocation} boxSize={3} />}
      _hover={{ textDecoration: 'underline' }}
      {...props}
    >
      {t('actions.useMyLocation')}
    </SubmitButton>
  )
}
