import { Interpolation } from '@emotion/react'

export const BOX_SHADOW_1 = '0px 2.30916px 4.61832px rgba(0, 0, 0, 0.25)'
export const BOX_SHADOW_2 = '0px 2.30916px 4.61832px rgba(0, 0, 0, 0.1)'
export const BOX_SHADOW_3 = '0px 5px 8px -5px rgba(0, 0, 0, 0.65)'
export const FOOTER_BOX_SHADOW = '0 -2px 8px -5px rgba(0, 0, 0, 0.65)'

export const SCROLLBAR_HIDDEN: Interpolation<{}> = {
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '&::MsOverflowStyle': 'none',
  'scrollbarWidth': 'none'
}
