import { useDisclosure } from '@chakra-ui/react'
import { ComponentPropsWithoutRef, ComponentType, createContext, createElement, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UNSAFE_DataRouterContext as DataRouterContext } from 'react-router'

type ModalComponent = ComponentType<any>

interface ModalManagerContextValue {
  open: <T extends ModalComponent>(component: T, props?: Omit<ComponentPropsWithoutRef<T>, 'isOpen' | 'onClose'> & { onClose?: ComponentPropsWithoutRef<T>['onClose'] }) => void
  close: () => void
}

export const ModalManagerContext = createContext({} as ModalManagerContextValue)

export const ModalManagerProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentModal, setCurrentModal] = useState<{ component: ModalComponent, props?: any }>()
  const { router } = useContext(DataRouterContext)!

  const contextValue = useMemo(() => {
    const value: ModalManagerContextValue = {
      open: (component, props) => {
        setCurrentModal({ component, props })
        onOpen()
      },
      close: onClose
    }
    return value
  }, [])

  const handleClose = useCallback((...args: any) => {
    const props = currentModal?.props
    if (props?.onClose) {
      props.onClose(...args)
    }
    onClose()
  }, [currentModal])

  const handleCloseComplete = useCallback(() => {
    if (currentModal) {
      setCurrentModal(undefined)
    }
  }, [currentModal])

  // Close modal when location changes
  useEffect(() => {
    const unsubscribe = router.subscribe(() => {
      setCurrentModal(undefined)
    })
    return unsubscribe
  }, [location])

  return (
    <ModalManagerContext.Provider value={contextValue}>
      <>
        {children}
        {currentModal && (
          createElement(currentModal.component, {
            ...currentModal.props,
            isOpen,
            onClose: handleClose,
            onCloseComplete: handleCloseComplete
          })
        )}
      </>
    </ModalManagerContext.Provider>
  )
}

export function useModalManager() {
  return useContext(ModalManagerContext)
}
