import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const badgeTheme = defineStyleConfig({
  baseStyle: defineStyle({
    px: 2.5,
    rounded: 5
  }),
  variants: {
    primary: defineStyle({
      fontWeight: 'medium',
      lineHeight: '18px',
      bgColor: 'yellow.300',
      textTransform: 'none',
      whiteSpace: 'normal'
    }),
    secondary: defineStyle({
      fontFamily: 'Soehne',
      fontWeight: 'normal',
      lineHeight: '18px',
      bgColor: 'gray.900',
      color: 'white',
      rounded: '7px',
      px: '5px'
    }),
    subtle: defineStyle({
      fontFamily: 'Soehne',
      fontWeight: 'normal',
      lineHeight: '12px',
      py: '5px'
    })
  },
  sizes: {
    md: defineStyle({
      py: '4px'
    }),
    sm: defineStyle({
      fontSize: '10px',
      px: '5px',
      py: 0.5
    })
  }
})
