/**
 * NOTE: Changes to the apply flow usually require modifications
 * to the following files:
 *
 * - ApplyButton.tsx
 * - ApplicationRedirect.tsx
 * - AuthStart.tsx
 * - JobDetail/index.tsx
 */

import { Alert, AlertIcon, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { AuthPanel } from '../../components/auth/AuthPanel'
import { PageHeader, PageHeading, StatusOverlay } from '../../components/core'
import { CompactJobCard } from '../../components/jobs'
import { useAuthPage } from '../../hooks'
import { AuthMethod } from '../../types'

export const AuthStart = ({ method }: { method: AuthMethod }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const {
    referrer,
    source,
    jobSlug,
    job,
    redirectTo,
    isLoading
  } = useAuthPage({ type: 'full page', method })

  const invalidToken = searchParams.get('reason') === 'invalid_token'

  return (
    <Box mb={5}>
      <PageHeader />
      <PageHeading mt={2} mb={5}>
        {t(`views.auth.${method}.title.${jobSlug ? 'apply' : 'default'}`)}
      </PageHeading>

      {invalidToken && (
        <Alert status="error" mb={5}>
          <AlertIcon />
          {t('messages.errors.authTokenInvalid')}
        </Alert>
      )}

      {job ? (
        <>
          <CompactJobCard job={job} mb={5} />

          <AuthPanel
            method={method}
            redirectTo={redirectTo}
            jobSlug={jobSlug}
            referrer={referrer}
            source={source}
          />
        </>
      ) : (
        <AuthPanel method={method} />
      )}

      {jobSlug && <StatusOverlay isLoading={isLoading} />}
    </Box>
  )
}
