/**
 * NOTE: Changes to the apply flow usually require modifications
 * to the following files:
 *
 * - ApplyButton.tsx
 * - ApplicationRedirect.tsx
 * - AuthStart.tsx
 * - JobDetail/index.tsx
 */

import { ButtonProps, Icon, Link as RawLink } from '@chakra-ui/react'
import { IconCheck, IconClockHour2 } from '@tabler/icons-react'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, To, useNavigate } from 'react-router-dom'
import { useAnalyticsParams, useAppState, useModalManager } from '../../contexts'
import { addQueryString, buildJobUrl } from '../../helpers'
import { useCreateApplication, useLocalJobState } from '../../hooks'
import { ApplicationStatus, JobBase } from '../../models'
import { analytics } from '../../services'
import { AuthDialog } from '../auth/AuthDialog'
import { IconLightningBolt, SubmitButton } from '../core'

export interface ApplyButtonProps extends ButtonProps {
  job: JobBase
  labelLength?: 'long' | 'short'
  label?: string
  hasExternalApplyLabelVariant?: boolean
  icon?: FC | null
}

export const ApplyButton = memo(({
  job,
  variant,
  size,
  labelLength = size === 'sm' ? 'short' : 'long',
  hasExternalApplyLabelVariant,
  label,
  icon,
  onClick,
  ...props
}: ApplyButtonProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [{ authenticated }] = useAppState()
  const [_localState, updateLocalState] = useLocalJobState(job.id)
  const modalManager = useModalManager()
  const { page, source } = useAnalyticsParams()
  const applicationCreate = useCreateApplication(job.id)

  const isExternalApplication = !!job.application_url
  const { application } = job
  const { isLoading } = applicationCreate

  let _variant: ButtonProps['variant'] = 'primary'
  let _label: string | undefined
  let _icon: FC | undefined
  let iconSpacing = -1.5
  let iconSizeMultiplier = 1 // eslint-disable-line prefer-const
  let handleClick: (() => void) | undefined
  let linkTo: To | undefined
  let externalHref: string | undefined
  let className: string | undefined

  const handleCreateApplication = () => {
    applicationCreate.mutate(undefined, {
      onSuccess(data) {
        navigate(`/applications/${data.id}`)
      }
    })
  }

  if (isExternalApplication) {
    if (hasExternalApplyLabelVariant) {
      _label = t(`actions.viewApplication.${labelLength}`)
    } else {
      const estimatedApplyTime = job.estimated_apply_time
      if (estimatedApplyTime) {
        _icon = IconClockHour2
        iconSpacing = -0.5
        _label = t(`actions.minuteApply.${labelLength}`, { time: estimatedApplyTime })
      }
    }

    if (!authenticated) {
      externalHref = addQueryString('/sign-up', { job: job.slug, ref: page, src: source })
    } else {
      if (job.has_prescreeners) {
        if (!application) {
          handleClick = handleCreateApplication
        } else if (application.status !== ApplicationStatus.SUBMITTED) {
          _variant = 'tertiary'
          _label = t('actions.finishApplying')
          externalHref = `/applications/${application.id}`
        }
      } else {
        externalHref = `/jobs/${job.slug}/application-redirect`
      }
    }
  } else {
    if (!authenticated) {
      _icon = IconLightningBolt
      _label = t('actions.fastApply')
      className = 'fast-apply-border'

      handleClick = () => {
        modalManager.open(AuthDialog, {
          variant: 'apply',
          redirectTo: buildJobUrl(job.slug, { action: 'apply', ref: page, src: source })
        })
      }
    } else if (!application) {
      _icon = IconLightningBolt
      _label = t('actions.fastApply')
      className = 'fast-apply-border'
      handleClick = handleCreateApplication
    } else if (application.status !== ApplicationStatus.SUBMITTED) {
      _variant = 'tertiary'
      _label = t('actions.finishApplying')
      handleClick = () => navigate(`/applications/${application.id}`)
    } else {
      _variant = 'inactive2'
      _icon = IconCheck
      _label = t('terms.applied')
      handleClick = () => navigate(`/applications/${application.id}`)
    }
  }

  if (variant) {
    _variant = variant
  }

  if (label) {
    _label = label
  } else if (!_label) {
    _label = t('actions.apply')
  }

  if (icon || icon === null) {
    _icon = icon ?? undefined
  }

  return (
    <SubmitButton
      size={size}
      {...linkTo ? {
        as: Link,
        to: linkTo
      } : externalHref && {
        as: RawLink,
        href: externalHref,
        isExternal: true
      }}
      className={className}
      variant={_variant}
      leftIcon={_icon && (
        <Icon as={_icon} boxSize={`${(size === 'sm' ? 18 : 24) * iconSizeMultiplier}px`} mr={iconSpacing} />
      )}
      _after={{
        borderRadius: size === 'sm' ? '3px' : '8px'
      }}
      isLoading={isLoading}
      onClick={(e) => {
        if (authenticated) {
          const analyticsParams = { job, page, source }
          if (application) {
            analytics.trackApplyResume(analyticsParams)
          } else {
            analytics.trackApplyStart(analyticsParams)
          }
        }

        updateLocalState({ isViewed: true })
        handleClick?.()
        onClick?.(e)
      }}
      {...props}
    >
      {_label}
    </SubmitButton>
  )
})
