import { useTranslation } from 'react-i18next'
import { phoneNumberAutoFormat } from '../helpers'
import * as dateHelper from '../helpers/date'
import * as formatHelper from '../helpers/format'

const useFormattersCache = {
  language: '',
  formatters: {
    number: formatHelper.formatNumber,
    currency: formatHelper.formatCurrency,
    percent: formatHelper.formatPercent,
    distance: formatHelper.formatDistance,
    wage: formatHelper.formatWage,
    date: dateHelper.formatDate,
    dateRange: dateHelper.formatDateRange,
    dateObject: dateHelper.formatDateObject,
    relativeTime: dateHelper.formatRelativeTime,
    relativeTimeCompact: dateHelper.formatRelativeTimeCompact,
    phoneNumber: phoneNumberAutoFormat,
    address: formatHelper.formatAddress
  }
}

/**
 * Returns an object of formatters, memoized by the i18n language.
 */
export const useFormatters = () => {
  const { i18n } = useTranslation()
  if (i18n.language !== useFormattersCache.language) {
    useFormattersCache.language = i18n.language
    useFormattersCache.formatters = { ...useFormattersCache.formatters }
  }
  return useFormattersCache.formatters
}
