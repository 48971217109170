import { AnalyticsParams, useAnalyticsParams } from '../contexts'
import { analytics } from '../services'
import { AnalyticsName } from '../services/analytics'

export function useTrack(defaultParams?: AnalyticsParams) {
  const contextParams = useAnalyticsParams()

  return {
    event: (name: string, eventParams?: { [key: string]: any }) => {
      analytics.trackEvent(name, { ...contextParams, ...defaultParams, ...eventParams })
    },
    open: (name: AnalyticsName, cta?: string) => {
      analytics.trackOpen(name, cta, { ...contextParams, ...defaultParams })
    }
  } as const
}
