import { Box, BoxProps, Button, Flex, HStack, Icon, Image, List, ListItem, Stack, Text, Wrap } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFormatters, useGeoCoordinates, useJobUrl, useTrack } from '../../hooks'
import { JobBase } from '../../models'
import { BOX_SHADOW_1 } from '../../styles'
import { CompanyLogo } from '../companies'
import { FeedCard } from '../feed/cards/FeedCard'
import { RegionBadge } from '../RegionBadge'
import { DISPLAYED_SCHEDULE_TYPES } from './common'
import { FavoriteButton } from './elements'
import { JobCardDetailsHeader } from './JobCardDetails'
import { SchedulePill } from './SchedulePill'
import { SMALL_JOB_CARD_WIDTH } from './SmallJobCard'
import { WagePill } from './WagePill'

export const JOB_CARD_HEIGHT = 305

export interface JobCardImageProps {
  color: string
  imageSrc: string
  imageAlt: string
  imageSize?: BoxProps['boxSize']
}

export interface JobCardProps extends JobCardImageProps, Omit<BoxProps, 'color'> {
  job: JobBase
}

export const JobCard = memo(({ job, color, imageSrc, imageAlt, imageSize = 'full', ...props }: JobCardProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const geoCoordinates = useGeoCoordinates()
  const url = useJobUrl(job.slug)
  const track = useTrack()
  const { schedule } = job

  const filteredSchedule = schedule && DISPLAYED_SCHEDULE_TYPES.filter(scheduleType => {
    return schedule.includes(scheduleType)
  })

  const content = (
    <Flex w="full" h="full" bgColor="white">
      <Flex
        w={{ base: '24%', sm: SMALL_JOB_CARD_WIDTH }} h="full"
        flexShrink={0} align="center" justify="center"
        bgColor={color}
      >
        <Image
          src={imageSrc}
          alt={imageAlt}
          boxSize={imageSize}
          objectFit="contain"
        />
      </Flex>
      <Flex flexDir="column" pos="relative" flexGrow={1} justify="flex-end" p={15}>
        <HStack pos="absolute" top={1} right={1} spacing={0}>
          <FavoriteButton
            job={job}
            iconSize={6}
            color="gray.200"
            zIndex={2}
          />
        </HStack>

        <CompanyLogo company={job.company} boxSize={42} mb={2} />

        <Stack spacing={2.5}>
          <Box>
            <JobCardDetailsHeader job={job} size="lg" />
            <RegionBadge mt={1} size="sm" record={job} distanceFrom={geoCoordinates} />
          </Box>

          <Wrap spacing={1.5} h="24px" overflow="hidden">
            <WagePill job={job} size="sm" />
            {filteredSchedule?.map(scheduleType => (
              <SchedulePill key={scheduleType} scheduleType={scheduleType} size="sm" />
            ))}
          </Wrap>

          <List>
            {job.requirements?.slice(0, 2).map((requirement, i) => (
              <ListItem key={i} fontSize={10} noOfLines={1} wordBreak="break-all">
                <Icon as={IconCheck} display="inline-block" boxSize={3} mr={1} mb={-0.5} />
                {requirement}
              </ListItem>
            ))}
          </List>

          <Text fontSize="xs" color="gray.400">
            {t('views.jobs.postedAt', { at: fmt.relativeTimeCompact(job.posted_date ?? job.created_at) })}
          </Text>

          <Button
            mt="auto"
            as={Link} to={url}
            variant="primary" w="full" size="sm"
            zIndex={2}
            onClick={() => track.open('Job Detail', 'See job details')}
          >
            {t('actions.seeJobDetails')}
          </Button>
        </Stack>
      </Flex>
    </Flex>
  )

  return (
    <FeedCard
      as={Flex}
      h={`${JOB_CARD_HEIGHT}px`}
      flexDir="column"
      boxShadow={BOX_SHADOW_1}
      {...props}
    >
      {content}

      {/* Absolute-positioned link with z-index: 1 */}
      <Flex
        as={Link}
        to={url}
        aria-label={t('actions.viewDetails')}
        height="100%"
        pos="absolute"
        top={0}
        left={0}
        w="100%"
        rounded={20}
        zIndex={1}
        onClick={() => track.open('Job Detail')}
      />
    </FeedCard>
  )
})
