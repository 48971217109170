import { createIcon } from '@chakra-ui/react'

export const IconHome = createIcon({
  displayName: 'IconHome',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="none"
      d="M19 8.71001L13.667 4.56201C13.199 4.19792 12.623 4.00024 12.03 4.00024C11.4371 4.00024 10.861 4.19792 10.393 4.56201L5.05903 8.71001C4.73847 8.9593 4.47912 9.27854 4.30078 9.64336C4.12245 10.0082 4.02984 10.4089 4.03003 10.815V18.015C4.03003 18.5454 4.24074 19.0542 4.61582 19.4292C4.99089 19.8043 5.4996 20.015 6.03003 20.015H18.03C18.5605 20.015 19.0692 19.8043 19.4442 19.4292C19.8193 19.0542 20.03 18.5454 20.03 18.015V10.815C20.03 9.99201 19.65 9.21501 19 8.71001Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})

export const IconAlignJustifiedReverse = createIcon({
  displayName: 'IconAlignJustifiedReverse',
  viewBox: '0 0 24 24',
  path: [
    /* eslint-disable react/jsx-key */
    <path fill="none" d="M0 0h24v24H0z" />,
    <line x1="4" y1="6" x2="20" y2="6" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" />,
    <line x1="4" y1="12" x2="20" y2="12" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" />,
    <line x1="8" y1="18" x2="20" y2="18" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" />,
  ],
})

export const IconApply = createIcon({
  displayName: 'IconApply',
  viewBox: '0 0 24 24',
  path: [
    <path
      fill="none"
      d="M11.5 7H6.5C5.96957 7 5.46086 7.21071 5.08579 7.58579C4.71071 7.96086 4.5 8.46957 4.5 9V18C4.5 18.5304 4.71071 19.0391 5.08579 19.4142C5.46086 19.7893 5.96957 20 6.5 20H15.5C16.0304 20 16.5391 19.7893 16.9142 19.4142C17.2893 19.0391 17.5 18.5304 17.5 18V13M10.5 14L20.5 4M15.5 4H20.5V9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ],
})

export const IconFileSmile = createIcon({
  displayName: 'IconFileSmile',
  viewBox: '0 0 24 24',
  path: (
    <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z"/>
      <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2zm-7 -7h.01m3.99 0h.01"/>
      <path d="M10 17a3.5 3.5 0 0 0 4 0"/>
    </g>
  ),
})

export const IconLightningBolt = createIcon({
  displayName: 'IconLightningBolt',
  viewBox: '0 0 18 20',
  path: (
    <path d="M10 1V8H16L8 19V12H2L10 1Z" fill="none" stroke="currentColor"strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  ),
})
