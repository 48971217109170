import { HStack, Icon, Image, StackProps, Text, TextProps } from '@chakra-ui/react'
import { FC, isValidElement, ReactNode } from 'react'

interface SizeStyles {
  px: number | string
  h: string
  fontSize: number | string
  iconBoxSize: number | string
  hIcon?: string
}

export const PILL_SIZE_CONFIG: Record<string, SizeStyles> = {
  xs: {
    px: 1,
    h: '18px',
    fontSize: 10,
    iconBoxSize: '11.5px'
  },
  sm: {
    px: '5px',
    h: '22px',
    fontSize: 10,
    iconBoxSize: 4
  },
  '2sm': {
    px: 2,
    h: '24px',
    fontSize: '2sm',
    iconBoxSize: '15px',
  },
  md: {
    px: 2.5,
    h: '28px',
    fontSize: 'xs',
    iconBoxSize: '19px'
  },
  lg: {
    px: 2.5,
    h: '28px',
    hIcon: '34px',
    fontSize: 'sm',
    iconBoxSize: '24px'
  },
}

export interface PillProps extends StackProps {
  icon?: ReactNode | FC
  size?: 'xs' | 'sm' | '2sm' | 'md' | 'lg'
  /**
   * This property exists to work around a Safari font rendering
   * bug with "The Future" font. Certain characters (c,g,t) following
   * a hyphen cause erroneous text overflow behavior when noOfLines=1.
   */
  multiLine?: boolean
}

export const Pill = ({
  icon,
  size = 'md',
  multiLine = false,
  children,
  ...props
}: PillProps) => {
  const sizeStyles: SizeStyles = PILL_SIZE_CONFIG[size]

  const renderIcon = () => {
    // Render JSX element as-is
    if (isValidElement(icon)) {
      return icon
    }

    const defaultProps = {
      boxSize: sizeStyles.iconBoxSize
    }

    // Image src (url or imported)
    if (typeof icon === 'string') {
      return <Image src={icon} {...defaultProps} />
    }

    // Component
    if (icon) {
      return <Icon as={icon as any} {...defaultProps} />
    }
  }

  let textProps: TextProps | undefined

  if (!multiLine) {
    textProps = {
      noOfLines: 1,
      wordBreak: 'break-all'
    }
  }

  return (
    <HStack
      px={sizeStyles.px}
      h={icon ? (sizeStyles.hIcon ?? sizeStyles.h) : sizeStyles.h}
      w="fit-content"
      fontSize={sizeStyles.fontSize}
      fontWeight="medium"
      color="gray.900"
      bg="gray.100"
      borderRadius={20}
      spacing={1}
      {...props}
    >
      {renderIcon()}
      <Text as="span" {...textProps}>
        {children}
      </Text>
    </HStack>
  )
}
