import { formErrorAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle
} from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyleText = defineStyle({
  mt: 1.5,
  fontSize: 'xs'
})

const baseStyle = definePartsStyle({
  text: baseStyleText
})

export const formErrorTheme = defineMultiStyleConfig({
  baseStyle,
})
