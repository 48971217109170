/**
 * NOTE: Changes to the apply flow usually require modifications
 * to the following files:
 *
 * - ApplyButton.tsx
 * - ApplicationRedirect.tsx
 * - AuthStart.tsx
 * - JobDetail/index.tsx
 */

import { Helmet } from 'react-helmet-async'
import { PageHeader, StatusOverlay } from '../../../components/core'
import { DetailSkeleton } from '../../../components/detail'
import { JobUnavailable } from '../../../components/jobs'
import { AnalyticsParamsProvider } from '../../../contexts'
import { Job } from '../../../models'
import { JobDetailVariantProps } from '../common'
import { useJobDetailLoaded } from '../hooks/useJobDetailLoaded'
import { JobDetailContent } from './JobDetailContent'

export const JobDetailDefault = (props: JobDetailVariantProps) => {
  const {
    slug,
    jobQuery,
    job,
    searchLinks,
    notFound,
    initialNotFound
  } = props

  return (
    <AnalyticsParamsProvider page="Job Detail">
      {!job && <PageHeader variant="overlay-light" search cart />}

      {job ? (
        <Loaded {...props} job={job} />
      ) : notFound ? (
        <JobUnavailable slug={slug} searchLinks={searchLinks} />
      ) : null}

      {!initialNotFound && (
        <StatusOverlay
          query={jobQuery}
          error={notFound ? null : jobQuery.error}
          renderLoading={() => <DetailSkeleton />}
        />
      )}
    </AnalyticsParamsProvider>
  )
}

interface LoadedProps extends JobDetailVariantProps {
  job: Job
}

const Loaded = (props: LoadedProps) => {
  const { searchLinks } = props
  const {
    job,
    applicationCreate,
    pageTitle,
    canonicalUrl
  } = useJobDetailLoaded({
    ...props,
    pageType: 'Full JD'
  })

  return (
    <AnalyticsParamsProvider key={job.slug} page="Job Detail">
      <Helmet title={pageTitle}>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <JobDetailContent
        job={job}
        applicationCreate={applicationCreate}
        searchLinks={searchLinks}
      />
    </AnalyticsParamsProvider>
  )
}
