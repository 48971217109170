import { IconWorld } from '@tabler/icons-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkplaceType } from '../../models'
import { Pill, PillProps } from '../core'

const ICONS = {
  [WorkplaceType.REMOTE]: IconWorld
}

interface WorkplacePillProps extends PillProps {
  workplace: WorkplaceType
  showIcon?: boolean
}

export const WorkplacePill = memo(({ workplace, showIcon = true, ...props }: WorkplacePillProps) => {
  const { t } = useTranslation()
  const icon = ICONS[workplace]
  if (!icon) return null

  return (
    <Pill icon={showIcon ? icon : undefined} multiLine {...props}>
      {t(`views.jobs.workplace.${workplace}`)}
    </Pill>
  )
})
