import { Button, ButtonProps, HStack, ModalProps, Stack, StackDivider, Text, TextProps, ThemingProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { ModalSheet, ModalSheetProps } from './ModalSheet'

export interface DialogProps extends Omit<ModalProps, 'children' | 'onClose'>, ModalSheetProps {
  header?: ReactNode
  title?: ReactNode
  titleSize?: TextProps['fontSize']
  message?: ReactNode
  disclaimer?: ReactNode
  primaryButton?: ReactNode
  primaryButtonVariant?: ThemingProps<'Button'>['variant']
  secondaryButton?: ReactNode
  children?: ReactNode
  onClose: (action?: 'secondary' | 'primary') => void
  onPrimaryClick?: () => void
  onSecondaryClick?: () => void
}

export const Dialog = ({
  isOpen,
  header,
  title,
  titleSize = 'xl',
  message,
  disclaimer,
  primaryButton,
  primaryButtonVariant = 'primary',
  secondaryButton,
  children,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  ...props
}: DialogProps) => {

  const dialogContent = <>
    <Stack
      spacing={8}
      divider={<StackDivider borderColor="white" />}
    >
      {header}

      <Stack spacing={5}>
        {!!title && (
          <Text fontSize={titleSize} fontWeight="medium" lineHeight={1.3}>
            {title}
          </Text>
        )}
        {!!message && (
          <Text lineHeight={1.3}>
            {message}
          </Text>
        )}
        {!!disclaimer && (
          <Text fontSize="sm" lineHeight={1.3}>
            {disclaimer}
          </Text>
        )}
        {children}
      </Stack>
    </Stack>

    {(secondaryButton || primaryButton) && (
      <HStack
        mt={10}
        spacing={2.5}
      >
        {secondaryButton && (
          <DialogButton
            variant="secondary"
            w={primaryButton ? '50%' : undefined}
            border="1px"
            borderColor="gray.200"
            onClick={() => {
              onSecondaryClick?.()
              onClose('secondary')
            }}
          >
            {secondaryButton}
          </DialogButton>
        )}
        {primaryButton && (
          <DialogButton
            variant={primaryButtonVariant}
            w={secondaryButton ? '50%' : undefined}
            onClick={() => {
              onPrimaryClick?.()
              onClose('primary')
            }}
          >
            {primaryButton}
          </DialogButton>
        )}
      </HStack>
    )}
  </>


  return (
    <ModalSheet {...props} isOpen={isOpen} onClose={onClose}>
      {dialogContent}
    </ModalSheet>
  )
}

const DialogButton = (props: ButtonProps) => (
  <Button
    h={10}
    {...props}
  />
)
