import { API_URL, APP_VERSION } from '../config'
import { AccountUpdateRequest, ApplicationAnswerRequest, ApplicationAnswerResponse, ApplyOrderCreateRequest, ApplyOrderItemAnswerUpdateRequest, ApplyOrderSubmitRequest, AuthLinkRequest, AuthResponse, BaseJobSearchParams, BioRequest, FileCreateRequest, FileUploadRequest, FileUploadResponse, GoogleAuthRequest, JobSearchParams, PaginationParams, PayEstimateRequest, PayEstimateResponse, PayRange, RecommendedJobs, SortParams } from '../dtos'
import { ApiCursorResponse, ApiIndexResponse, ApiObjectResponse, createApiClient } from '../lib/apiClient'
import { ApplicationIndexRecord, ApplicationItem, ApplyOrder, ApplyOrderItem, EducationRecord, EducationRecordBase, EmploymentRecord, EmploymentRecordBase, FeedItem, Job, JobCategory, JobIndexRecord, JobPreferences, LatLng, Location, RawAccount, RawApplication, RawFileRecord, RawResumeAnalysis, Region, SuggestionItem } from '../models'
import { UserEvent } from '../models/UserEvent'

const client = createApiClient({
  baseURL: API_URL,
  headers: {
    'Gigs-App-Id': 'co.getgigs.app',
    'Gigs-App-Version': APP_VERSION
  },
  withCredentials: true
})

export default {
  auth: {
    async createLink(data: AuthLinkRequest) {
      await client.post('/auth/link', data)
    },
    async google(data: GoogleAuthRequest) {
      const res = await client.post<ApiObjectResponse<AuthResponse>>('/auth/google', data)
      return res.data
    }
  },
  account: {
    async get() {
      const res = await client.get<ApiObjectResponse<RawAccount>>('/account')
      return res.data
    },
    async update(data: AccountUpdateRequest) {
      const res = await client.patch<ApiObjectResponse<RawAccount>>('/account', data)
      return res.data
    },
    async register() {
      const res = await client.post<ApiObjectResponse<RawAccount>>('/account/register')
      return res.data
    },
    async delete() {
      await client.delete('/account')
    },
    jobPreferences: {
      async get() {
        const res = await client.get<ApiObjectResponse<JobPreferences | null>>('/account/job-preferences')
        return res.data
      },
      async update(data: JobPreferences) {
        const res = await client.patch<ApiObjectResponse<JobPreferences>>('/account/job-preferences', data)
        return res.data
      }
    }
  },
  applications: {
    async list(params?: { cursor?: string }) {
      const res = await client.get<ApiCursorResponse<ApplicationIndexRecord>>('/applications', { params })
      return res.data
    },
    async find(id: string) {
      const res = await client.get<ApiObjectResponse<RawApplication>>(`/applications/${id}`)
      return res.data
    },
    async create(data: { job_id: string }) {
      const res = await client.post<ApiObjectResponse<RawApplication>>('/applications', data)
      return res.data
    },
    async evaluate(id: string) {
      const res = await client.post<ApiObjectResponse<RawApplication>>(`/applications/${id}/evaluate`)
      return res.data
    },
    async submit(id: string) {
      const res = await client.post<ApiObjectResponse<RawApplication>>(`/applications/${id}/submit`)
      return res.data
    },
    answers: {
      async update(id: string, questionId: string, data: ApplicationAnswerRequest) {
        const res = await client.put<ApiObjectResponse<ApplicationAnswerResponse>>(
          `/applications/${id}/answers/${questionId}`,
          data
        )
        return res.data
      }
    }
  },
  applicationsItems: {
    async list(params?: SortParams & PaginationParams) {
      const res = await client.get<ApiCursorResponse<ApplicationItem>>('/application-items', { params })
      return res.data
    },
  },
  applyOrderItems: {
    async find(id: string) {
      const res = await client.get<ApiObjectResponse<ApplyOrderItem>>(`/apply-order-items/${id}`)
      return res.data
    },
    async approve(id: string) {
      const res = await client.post<ApiObjectResponse<ApplyOrderItem>>(
        `/apply-order-items/${id}/approve`,
      )
      return res.data
    },
    answers: {
      async update(id: string, questionId: string, data: ApplyOrderItemAnswerUpdateRequest) {
        const res = await client.put<ApiObjectResponse<ApplyOrderItem>>(
          `/apply-order-items/${id}/answers/${questionId}`,
          data
        )
        return res.data
      }
    }
  },
  applyOrders: {
    async list(params?: SortParams & PaginationParams) {
      const res = await client.get<ApiIndexResponse<ApplyOrder>>('/apply-orders', { params })
      return res.data
    },
    async find(id: string) {
      const res = await client.get<ApiObjectResponse<ApplyOrder>>(`/apply-orders/${id}`)
      return res.data
    },
    async create(data: ApplyOrderCreateRequest) {
      const res = await client.post<ApiObjectResponse<ApplyOrder>>('/apply-orders', data)
      return res.data
    },
    async submit(id: string, data: ApplyOrderSubmitRequest) {
      const res = await client.post<ApiObjectResponse<ApplyOrder>>(`/apply-orders/${id}/submit`, data)
      return res.data
    }
  },
  bio: {
    async create(data: BioRequest) {
      const res = await client.post<ApiObjectResponse<string>>('/bio', data)
      return res.data
    }
  },
  education: {
    async list() {
      const res = await client.get<ApiIndexResponse<EducationRecord>>('/education')
      return res.data
    },
    async create(data: EducationRecordBase) {
      const res = await client.post<ApiObjectResponse<EducationRecord>>('/education', data)
      return res.data
    },
    async update(id: string, data: EducationRecordBase) {
      const res = await client.put<ApiObjectResponse<EducationRecord>>(`/education/${id}`, data)
      return res.data
    },
    async updateAll(data: EducationRecordBase[]) {
      const res = await client.put<ApiIndexResponse<EducationRecord>>('/education', data)
      return res.data
    },
    async delete(id: string) {
      await client.delete(`/education/${id}`)
    },
  },
  employment: {
    async list() {
      const res = await client.get<ApiIndexResponse<EmploymentRecord>>('/employment')
      return res.data
    },
    async create(data: EmploymentRecordBase) {
      const res = await client.post<ApiObjectResponse<EmploymentRecord>>('/employment', data)
      return res.data
    },
    async update(id: string, data: EmploymentRecordBase) {
      const res = await client.put<ApiObjectResponse<EmploymentRecord>>(`/employment/${id}`, data)
      return res.data
    },
    async updateAll(data: EmploymentRecordBase[]) {
      const res = await client.put<ApiIndexResponse<EmploymentRecord>>('/employment', data)
      return res.data
    },
    async delete(id: string) {
      await client.delete(`/employment/${id}`)
    },
  },
  events: {
    async create(data: UserEvent) {
      await client.post('/events', data)
    }
  },
  feed: {
    async list(params?: { lat?: number, lng?: number, cursor?: string }) {
      const res = await client.get<ApiCursorResponse<FeedItem>>('/feed', { params })
      return res.data
    }
  },
  files: {
    async createUpload(data: FileUploadRequest) {
      const res = await client.post<ApiObjectResponse<FileUploadResponse>>('/files/uploads', data)
      return res.data
    },
    async create(data: FileCreateRequest) {
      const res = await client.post<ApiObjectResponse<RawFileRecord>>('/files', data)
      return res.data
    },
    async find(id: string) {
      const res = await client.get<ApiObjectResponse<RawFileRecord>>(`/files/${id}`)
      return res.data
    },
    async getResumeAnalysis(fileId: string) {
      const res = await client.get<ApiObjectResponse<RawResumeAnalysis>>(`/files/${fileId}/resume-analysis`)
      return res.data
    }
  },
  jobs: {
    async list(params?: { group?: string, ids?: string } & PaginationParams) {
      const res = await client.get<ApiCursorResponse<JobIndexRecord>>('/jobs', { params })
      return res.data
    },
    async find(slug: string, utmParams = {}) {
      const res = await client.get<ApiObjectResponse<Job>>(`/jobs/${slug}`, { params: utmParams })
      return res.data
    },
    async listBetterJobs(slug: string, params?: BaseJobSearchParams) {
      const res = await client.get<ApiCursorResponse<JobIndexRecord>>(`/jobs/${slug}/better-jobs`, { params })
      return res.data
    }
  },
  jobCategories: {
    async list() {
      const res = await client.get<ApiIndexResponse<JobCategory>>('/job-categories')
      return res.data
    },
    async find(slug: string, params: LatLng) {
      const res = await client.get<ApiObjectResponse<JobCategory>>(`/job-categories/${slug}`, { params })
      return res.data
    }
  },
  favoriteJobs: {
    async add(id: string) {
      const res = await client.put<ApiObjectResponse<JobIndexRecord>>(`/favorite-jobs/${id}`)
      return res.data
    },
    async delete(id: string) {
      await client.delete(`/favorite-jobs/${id}`)
    }
  },
  industries: {
    async list() {
      const res = await client.get<ApiIndexResponse<string>>('/industries')
      return res.data
    }
  },
  interests: {
    async list() {
      const res = await client.get<ApiIndexResponse<string>>('/interests')
      return res.data
    }
  },
  locations: {
    async list(params?: { company_id?: string, lat?: number, lng?: number, cursor?: string }) {
      const res = await client.get<ApiCursorResponse<Location>>('/locations', { params })
      return res.data
    },
    async find(id: string) {
      const res = await client.get<ApiObjectResponse<Location>>(`/locations/${id}`)
      return res.data
    }
  },
  matchedJobs: {
    async list(params?: { cursor?: string }) {
      const res = await client.get<ApiCursorResponse<JobIndexRecord>>('/matched-jobs', { params })
      return res.data
    },
    async createEstimate(data: JobPreferences & LatLng) {
      const res = await client.post<ApiObjectResponse<{ total: number }>>('/matched-jobs/estimates', data)
      return res.data
    }
  },
  payEstimates: {
    async create(data: PayEstimateRequest) {
      const res = await client.post<ApiObjectResponse<PayEstimateResponse>>('/pay-estimates', data)
      return res.data
    }
  },
  payRange: {
    async get(params?: { positions?: string } & LatLng) {
      const res = await client.get<ApiObjectResponse<PayRange>>('/pay-range', { params })
      return res.data
    }
  },
  positions: {
    async list(params?: { industries?: string }) {
      const res = await client.get<ApiIndexResponse<string>>('/positions', { params })
      return res.data
    }
  },
  recommendedJobs: {
    async list(params: { crd: string, similar_to: string }) {
      const res = await client.get<ApiObjectResponse<RecommendedJobs>>('/recommended-jobs', { params })
      return res.data
    }
  },
  regions: {
    async list(params?: { q?: string, lat?: number, lng?: number }) {
      const res = await client.get<ApiIndexResponse<Region>>('/regions', { params })
      return res.data
    }
  },
  search: {
    async listSuggestions(params?: { q?: string, lat?: number, lng?: number }) {
      const res = await client.get<ApiIndexResponse<SuggestionItem>>('/search/suggestions', { params })
      return res.data
    },
    async list(params?: JobSearchParams) {
      const res = await client.get<ApiCursorResponse<JobIndexRecord>>('/search', { params })
      return res.data
    }
  }
}
