import { BoxProps, Button, Card, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CompanyLogo } from '../../../components/companies'
import { ContentContainer } from '../../../components/core'
import { useAnalyticsParams } from '../../../contexts'
import { useInfiniteQueryTotal, useRelatedJobsQuery } from '../../../hooks'
import { JobIndexRecord } from '../../../models'
import { analytics } from '../../../services'

interface RelatedJobsSectionProps extends BoxProps {
  job: JobIndexRecord
}

export const RelatedJobsSection = ({
  job,
  ...props
}: RelatedJobsSectionProps) => {
  const { t } = useTranslation()
  const query = useRelatedJobsQuery(job)
  const queryTotal = useInfiniteQueryTotal(query)
  const { page } = useAnalyticsParams()
  const title = t('views.jobs.relatedJobs.title')
  const isLoaded = !!query.data

  if (query.isError || (isLoaded && queryTotal === 0)) {
    return null
  }

  return (
    <ContentContainer>
      <Card p={5} alignItems="center" {...props}>
        <Skeleton isLoaded={isLoaded} mb={3} rounded="full">
          <CompanyLogo boxSize="72px" company={job.company} />
        </Skeleton>
        <Skeleton isLoaded={isLoaded} rounded="lg" mb={3}>
          <Text
            textAlign="center" fontSize={{ base: '2xl' }} fontWeight="500"
            lineHeight={1.4} color="gray.900"
          >
            {title}
          </Text>
        </Skeleton>
        <Flex as={Skeleton} isLoaded={isLoaded} rounded="lg">
          <Button
            variant="tertiary"
            as={Link} to={`/jobs/${job.slug}/related`} rel="nofollow"
            onClick={() => analytics.trackSeeAll({ page, source: title })}
          >
            {t('actions.seeRelatedJobs', { count: queryTotal })}
          </Button>
        </Flex>
      </Card>
    </ContentContainer>
  )
}
