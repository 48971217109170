import { WizardState, WizardStep, WizardStepStatus } from '../components/core'

export function buildWizardSteps(
  completions: { [key: string]: any } | Array<[string, any]>,
  options: { nonLinear?: boolean } = {}
): WizardStep[] {
  const { nonLinear } = options
  let prevComplete = true
  const completionsArray = Array.isArray(completions)
    ? completions
    : Object.entries(completions)

  return completionsArray.map(([id, complete]) => {
    let status: WizardStepStatus = 'pending'

    if (complete) {
      if (prevComplete || nonLinear) {
        status = 'complete'
      }
    } else if (prevComplete) {
      status = 'visited'
    }
    prevComplete = complete
    return { id, status }
  })
}

export function deriveWizardState(steps: WizardStep[]): WizardState {
  let finishedCount = 0
  let firstUnfinishedStepId: string | undefined

  for (const step of steps) {
    if (step.status === 'complete' || step.status === 'skipped') {
      finishedCount++
    } else if (!firstUnfinishedStepId) {
      firstUnfinishedStepId = step.id
    }
  }

  const firstUnfinishedStepIndex = steps.findIndex(s => s.id === firstUnfinishedStepId)

  const pendingCount = steps.length - finishedCount
  const stepMap = steps.reduce<{ [key: string]: WizardStep }>((acc, q) => {
    acc[q.id] = q
    return acc
  }, {})

  return {
    steps,
    stepMap,
    finishedCount,
    pendingCount,
    firstUnfinishedStepId,
    firstUnfinishedStepIndex,
    lastStepId: steps[steps.length - 1]?.id,
    finished: pendingCount === 0
  }
}

export function getWizardStepState(wizard: WizardState, stepId?: string) {
  const { steps, stepMap, firstUnfinishedStepIndex } = wizard
  const step = stepMap[stepId!] ? stepMap[stepId!] : undefined
  const stepIndex = steps.findIndex(s => s.id === stepId)
  const prevStep = steps[stepIndex - 1]
  const prevStepId = prevStep ? prevStep.id : undefined
  const nextStep = steps[stepIndex + 1]
  const nextStepId = nextStep ? nextStep.id : undefined

  return {
    step,
    stepIndex,
    isAhead: firstUnfinishedStepIndex > -1 && stepIndex > firstUnfinishedStepIndex,
    isLastStep: stepIndex === steps.length - 1,
    isFinished: step && (step.status === 'complete' || step.status === 'skipped'),
    prevStepId,
    nextStepId
  }
}
