import { FormErrorMessage, FormErrorMessageProps } from '@chakra-ui/react'

export interface FieldErrorMessageProps extends FormErrorMessageProps {
  error?: any
}

export const FieldErrorMessage = ({ error, ...props }: FieldErrorMessageProps) => {
  return (
    <FormErrorMessage {...props}>
      {error ? typeof error === 'string' ? error : error.message : undefined}
    </FormErrorMessage>
  )
}
