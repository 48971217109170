import { ReactElement, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppState, useSessionStorageSetter } from '../contexts'
import { locationToUrl } from '../helpers'

export const AuthenticatedGuard = ({ children }: { children?: ReactElement }) => {
  const [{ authenticated }] = useAppState()
  const setPostAuthRedirect = useSessionStorageSetter('postAuthRedirect')
  const location = useLocation()

  useEffect(() => {
    if (!authenticated) {
      setPostAuthRedirect(locationToUrl(location))
    }
  }, [])

  return authenticated
    ? children ?? <Outlet />
    : <Navigate to="/sign-in" replace />
}
