import { PayEstimateRequest, PayEstimateResponse } from '../dtos'

export enum PayEstimateInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export type PayEstimate = PayEstimateRequest & PayEstimateResponse
