import { BoxProps, ButtonProps, Flex, Text } from '@chakra-ui/react'
import { Link, LinkProps, To } from 'react-router-dom'
import { useBulkApplyCart } from '../../hooks'
import { BOX_SHADOW_2 } from '../../styles'

export interface BulkApplyProgressBoxProps extends BoxProps {
  linkTo?: To
}

export const BulkApplyProgressBox = ({ linkTo, children, ...props }: BulkApplyProgressBoxProps) => {
  const cart = useBulkApplyCart()

  let linkProps: ButtonProps & LinkProps | undefined

  if (linkTo) {
    linkProps = {
      as: Link,
      to: linkTo,
      color: 'white',
      bg: 'brand.500',
      borderColor: 'brand.500'
    }
  }

  return (
    <Flex
      align="center" justify="space-between"
      w="full" h="54px" px={4}
      border="1px" borderColor="gray.200" borderRadius="10"
      boxShadow={BOX_SHADOW_2}
      {...linkProps as any}
      {...props}
    >
      <Text fontSize={{ base: 'sm', '2xs': 'md' }} fontWeight={linkTo ? 'bold' : 'medium'} lineHeight={1.3}>
        {children}
      </Text>

      <Flex
        ml={2}
        boxSize={6}
        flexShrink={0} align="center" justify="center"
        borderRadius="50%"
        bg={linkTo ? 'gray.900' : 'gray.200'}
        color={linkTo ? 'white' : undefined}
      >
        <Text fontSize="sm" fontWeight="bold">
          {cart.length}
        </Text>
      </Flex>
    </Flex>
  )
}
