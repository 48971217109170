import { Icon, IconButton } from '@chakra-ui/react'
import { IconInfoCircle } from '@tabler/icons-react'
import { IconButtonWithOptionalAriaProps } from '../core'

interface InfoButtonProps extends IconButtonWithOptionalAriaProps {
  iconSize?: IconButtonWithOptionalAriaProps['boxSize']
}

export const InfoButton = ({
  'aria-label': ariaLabel = 'Information',
  iconSize = '18px',
  onClick,
  ...props
}: InfoButtonProps) => {

  return (
    <IconButton
      size="xs"
      variant="ghost"
      color="gray.300"
      aria-label={ariaLabel}
      icon={<Icon as={IconInfoCircle} boxSize={iconSize} />}
      onClick={onClick}
      {...props}
    />
  )
}
