import { cx } from '@chakra-ui/shared-utils'
import {
  AnimatePresence,
  HTMLMotionProps,
  motion,
  Variants as _Variants
} from 'framer-motion'
import { forwardRef } from 'react'
import {
  TRANSITION_DEFAULTS,
  Variants,
  withDelay,
  WithTransitionConfig
} from './transitionUtils'

interface TranslateOptions {
  /**
   * The offset on the horizontal or `x` axis
   * @default 0
   */
  offsetX?: string | number
  /**
   * The offset on the vertical or `y` axis
   * @default 8
   */
  offsetY?: string | number
  /**
   * If `true`, the element will be transitioned back to the offset when it leaves.
   * Otherwise, it'll only fade out
   * @default true
   */
  reverse?: boolean
}

const variants: Variants<TranslateOptions> = {
  initial: ({ offsetX, offsetY, transition, transitionEnd, delay }) => ({
    x: offsetX,
    y: offsetY,
    transition:
      transition?.exit ?? withDelay.exit(TRANSITION_DEFAULTS.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
  enter: ({ transition, transitionEnd, delay }) => ({
    x: 0,
    y: 0,
    transition:
      transition?.enter ?? withDelay.enter(TRANSITION_DEFAULTS.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ offsetY, offsetX, transition, transitionEnd, reverse, delay }) => {
    const offset = { x: offsetX, y: offsetY }
    return {
      transition:
        transition?.exit ?? withDelay.exit(TRANSITION_DEFAULTS.exit, delay),
      ...(reverse
        ? { ...offset, transitionEnd: transitionEnd?.exit }
        : { transitionEnd: { ...offset, ...transitionEnd?.exit } }),
    }
  },
}

export const translateConfig: HTMLMotionProps<'div'> = {
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants: variants as unknown as _Variants,
}

export interface TranslateProps
  extends TranslateOptions, WithTransitionConfig<HTMLMotionProps<'div'>> {}

export const Translate = forwardRef<HTMLDivElement, TranslateProps>(
  function Translate(props, ref) {
    const {
      unmountOnExit,
      in: isOpen,
      reverse = true,
      className,
      offsetX = 0,
      offsetY = 8,
      transition,
      transitionEnd,
      delay,
      ...rest
    } = props

    const show = unmountOnExit ? isOpen && unmountOnExit : true
    const animate = !!isOpen || unmountOnExit ? 'enter' : 'exit'

    const custom = {
      offsetX,
      offsetY,
      reverse,
      transition,
      transitionEnd,
      delay,
    }

    return (
      <AnimatePresence custom={custom}>
        {show && (
          <motion.div
            ref={ref}
            className={cx('chakra-offset-slide', className)}
            custom={custom}
            {...translateConfig}
            animate={animate}
            {...rest}
          />
        )}
      </AnimatePresence>
    )
  },
)

Translate.displayName = 'Translate'
