import { Box, Flex, HStack, Skeleton, Stack } from '@chakra-ui/react'
import { CONTENT_PADDING } from '../core'
import { DETAIL_MEDIA_HEIGHT } from './DetailHeader'

export const DetailSkeleton = () => (
  <Flex w="full" flexDir="column" justify="center">
    <Skeleton h={DETAIL_MEDIA_HEIGHT} />

    <Flex mt={10} px={CONTENT_PADDING} flexDir="column" align="center">
      <Box
        position="relative" mt="-75px" mb={4} boxSize="72px" rounded="full" overflow="hidden"
        bg="white" border="3px solid" borderColor="white"
      >
        <Skeleton boxSize="100%" />
      </Box>

      <Stack w="full" spacing={3} align="center">
        <Stack w="full" spacing={1.5} align="center">
          <Skeleton w="80%" h={6} rounded="md" />
          <Skeleton w="60%" h={6} rounded="md" />
        </Stack>
        <Skeleton w="40%" h={4} rounded="md" />
        <Stack w="full" pt={1.5} spacing={2} align="center">
          <HStack w="full" h="28px" spacing={2.5} justify="center">
            <Skeleton w="35%" h="full" rounded="md" />
            <Skeleton w="50%" h="full" rounded="md" />
          </HStack>
          <HStack w="full" h="28px" spacing={2.5} justify="center">
            <Skeleton w="30%" h="full" rounded="md" />
            <Skeleton w="25%" h="full" rounded="md" />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  </Flex>
)
