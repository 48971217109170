import { JobBase, UserEvent, UserEventType, UtmParams } from '../models'

export function prepareJobUserEventParams(
  job: JobBase,
  paramsFromLocalStorage?: UtmParams,
  paramsFromPage?: UtmParams
) {
  const isLocalStorageParamsValid = paramsFromLocalStorage && paramsFromLocalStorage.jobSlug === job.slug

  if (paramsFromPage) {
    return { ...paramsFromPage, job_id: job.id, jobSlug: job.slug }
  } else if (isLocalStorageParamsValid) {
    return paramsFromLocalStorage
  }
}

export function prepareJobUserEvent(jobId: string, eventType: UserEventType, eventParams?: UtmParams): UserEvent {
  const userEvent: UserEvent = {
    type: eventType,
    job_id: jobId
  }

  if (eventParams && eventParams.job_id === jobId) {
    const data: any = { ...eventParams }
    delete data.jobSlug
    delete data.job_id
    userEvent.data = data as UtmParams
  }

  return userEvent
}
