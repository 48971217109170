import { Box, Flex, useTheme } from '@chakra-ui/react'
import { PropsWithChildren, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { NavDrawer } from '../components/navigation'
import { LayoutProvider } from '../contexts'

export const DefaultLayout = ({ children }: PropsWithChildren) => {
  const theme = useTheme()
  const breakpoints = theme.__breakpoints!.asObject

  const layoutValues = useMemo(() => ({
    pageLeft: {
      base: 0,
      sm: `calc(50% - (${breakpoints.sm} / 2))`
    },
    pageWidth: {
      base: '100%',
      sm: breakpoints.sm
    }
  }), [breakpoints])

  return (
    <LayoutProvider value={layoutValues}>
      <Box pos="fixed" top={0} w="100%" zIndex={-1}>
        <Box
          mx="auto" h="100vh" maxW={breakpoints.sm}
          outline="1px solid" outlineColor="gray.200"
        />
      </Box>

      <Flex pos="relative" mx="auto" maxW={breakpoints.sm} minH="100%">
        {children ?? <Outlet />}
      </Flex>

      <NavDrawer />
    </LayoutProvider>
  )
}
