import { Input, InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

export const EmailInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { t } = useTranslation()

  return (
    <Input
      ref={ref}
      type="email"
      autoComplete="email"
      inputMode="email"
      maxLength={100}
      placeholder={t('forms.common.fields.email.label')}
      {...props}
    />
  )
})
