import { Flex, FlexProps } from '@chakra-ui/react'
import { useLayoutContext } from '../../../contexts'
import { FOOTER_BOX_SHADOW } from '../../../styles'
import { MIN_PAGE_WIDTH } from './common'

export const ContentFooter = ({ children, ...props }: FlexProps) => {
  const { pageLeft, pageWidth, pageFooterHeight } = useLayoutContext()

  return (
    <Flex
      pos="fixed" left={pageLeft} bottom={`${pageFooterHeight}px`}
      w={pageWidth} minW={MIN_PAGE_WIDTH}
      bg="white" boxShadow={FOOTER_BOX_SHADOW}
      zIndex="1202"
      {...props}
    >
      {children}
    </Flex>
  )
}
