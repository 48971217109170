import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { SearchLink } from '../../models'
import { ContentContainer, PageContainer } from '../core'
import { SearchLinksSection } from '../search'
import { NoJobResultsNotice } from './NoJobResultsNotice'
import { SimilarJobsSection } from './SimilarJobsSection'

export const JobUnavailable = ({ slug, searchLinks }: { slug?: string, searchLinks?: SearchLink[] }) => {
  const { t } = useTranslation()
  return (
    <PageContainer px={0} pb={8}>
      <Helmet title={t('views.jobs.unavailableNotice.title')}>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <ContentContainer>
        <NoJobResultsNotice
          title={t('views.jobs.unavailableNotice.title')}
          message={t('views.jobs.unavailableNotice.message')}
        />
      </ContentContainer>

      {slug && (
        <SimilarJobsSection mt={8} jobSlug={slug} />
      )}

      {!!searchLinks?.length && (
        <SearchLinksSection mt={8} searchLinks={searchLinks} />
      )}
    </PageContainer>
  )
}
