import { Card, LayoutProps, SpaceProps, Text } from '@chakra-ui/react'
import { JobIndexRecord } from '../../models'
import { CompanyLogo } from '../companies'

interface CompactJobCardProps extends SpaceProps, LayoutProps {
  job: JobIndexRecord
}

export const CompactJobCard = ({ job, ...props }: CompactJobCardProps) => {
  return (
    <Card
      p={2.5} flexDir="row" align="center" textAlign="left"
      bg="gray.100" rounded="xl"
      {...props}
    >
      <CompanyLogo company={job.company} boxSize="24px" boxShadow="" mr={2.5} />
      <Text fontSize="2sm" flex={1} noOfLines={1}>
        <Text as="span" fontWeight="medium">
          {job.title}
        </Text>
        {' '}|{' '}{job.company.name}
      </Text>
    </Card>
  )
}
