import { Box, Button, Skeleton, Stack } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  CARD_SPACING_S, ContentContainer, InfiniteList, NoResultsNotice, StatusOverlay
} from '../../../components/core'
import {
  EndingCard, FeedSettingsCard, FEED_CARD_HEIGHTS, JobCategoryCard, JOB_CARD_HEIGHT
} from '../../../components/feed/cards'
import { SMALL_JOB_CARD_HEIGHT } from '../../../components/jobs'
import { useFeedQuery, useResetScrollXCache, useScrollYTracker } from '../../../hooks'
import { FeedItem, FeedItemType, JobCategoryFeedItem } from '../../../models'

export const Feed = ({ paddingStart = 0 }: { paddingStart?: number }) => {
  const { t } = useTranslation()
  const query = useFeedQuery()
  const initialScrollY = useScrollYTracker()
  const resetScrollXCache = useResetScrollXCache()

  const renderCard = (item: FeedItem) => {
    switch (item.type) {
      case FeedItemType.JOB_CATEGORY: {
        return <JobCategoryCard item={item as JobCategoryFeedItem} />
      }
      case FeedItemType.FEED_SETTINGS_PROMPT:
        return (
          <ContentContainer>
            <FeedSettingsCard />
          </ContentContainer>
        )
      default:
        return null
    }
  }

  const items = useMemo(() => {
    return query.data?.pages.flatMap(page => page.data)
      .filter(item => {
        if (item.type === FeedItemType.JOB_CATEGORY) {
          return (item as JobCategoryFeedItem).data.jobs.length > 1
        }
        return true
      })
  }, [query.data])

  useEffect(() => {
    if (query.isFetching) {
      resetScrollXCache()
    }
  }, [query.isFetching])

  return (
    <Box pos="relative" flexGrow={1}>
      <InfiniteList<FeedItem>
        query={query}
        items={items}
        itemSpacing={CARD_SPACING_S}
        estimateItemSize={item => FEED_CARD_HEIGHTS[item.type] ?? 0}
        tailSize={FEED_CARD_HEIGHTS.ending}
        initialOffset={initialScrollY}
        paddingStart={paddingStart}
        overscan={1}
        renderItem={item => renderCard(item)}
        renderTail={() => <EndingCard />}
      />
      <StatusOverlay
        top={paddingStart ? `${paddingStart}px` : undefined}
        query={query}
        renderLoading={() => (
          <ContentContainer>
            <Stack spacing={`${CARD_SPACING_S * 2}px`} bg="white">
              <Skeleton h="46px" rounded={10} />
              <Skeleton h={`${JOB_CARD_HEIGHT}px`} rounded={20} />
              <Skeleton h={`${SMALL_JOB_CARD_HEIGHT}px`} rounded={20} />
            </Stack>
          </ContentContainer>
        )}
        renderNoResults={() => (
          <NoResultsNotice
            title={t('views.feed.noResults.title')}
            message={t('views.feed.noResults.message')}
          >
            <Stack alignItems="center" mt={5} spacing={2.5}>
              <Button as={Link} to="settings/starting-location" variant="secondary">
                {t('actions.changeLocation')}
              </Button>
              <Button as={Link} to="/search" variant="tertiary">
                {t('actions.browseAllGigs')}
              </Button>
            </Stack>
          </NoResultsNotice>
        )}
      />
    </Box>
  )
}
