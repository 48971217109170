import { useLayoutEffect } from 'react'
import { useJobPreferencesWizardCompleted } from '../../../hooks'
import { Job } from '../../../models'
import { ContentContainer } from '../../core'
import { MatchPreferencesCard } from '../MatchPreferencesCard'
import { MatchedJobsListSection, MatchedJobsListSectionProps } from './MatchedJobsListSection'

interface MatchedJobsSectionProps extends MatchedJobsListSectionProps {
  job?: Job
  showHeroCard?: boolean
  onResize?: () => void
}

export const MatchedJobsSection = ({
  variant,
  job,
  showHeroCard,
  onResize
}: MatchedJobsSectionProps) => {
  const jobPrefsCompleted = useJobPreferencesWizardCompleted()

  useLayoutEffect(() => {
    if (!jobPrefsCompleted) {
      onResize?.()
    }
  })

  if (jobPrefsCompleted) {
    return (
      <MatchedJobsListSection
        variant={variant}
        jobId={job?.id}
        showHeroCard={showHeroCard}
        onLoad={onResize}
      />
    )
  }

  return (
    <ContentContainer>
      <MatchPreferencesCard />
    </ContentContainer>
  )
}
