import { PageContainer } from '../../../components/core'
import { useGoBack } from '../../../contexts'
import { StartingLocationForm } from '../../shared/StartingLocationForm'

export const StartingLocation = () => {
  const goBack = useGoBack()

  return (
    <PageContainer>
      <StartingLocationForm onSuccess={goBack} />
    </PageContainer>
  )
}
