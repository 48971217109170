import {
  Box, Button, Card, Divider, Flex, FormControl, FormLabel, Icon,
  InputProps, Link as RawLink, Select, SpaceProps, Stack, Text, TextProps, TypographyProps, VStack
} from '@chakra-ui/react'
import { IconReload } from '@tabler/icons-react'
import { memo, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, ControllerProps, ControllerRenderProps, Path, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SlotCounter from 'react-slot-counter'
import payCalculatorImg from '../../../assets/images/handful-of-cash.svg'
import { CurrencyInput, Dialog } from '../../../components/core'
import { NumericInput } from '../../../components/core/form/NumericInput'
import { InfoButton } from '../../../components/core/InfoButton'
import { AnalyticsParamsProvider, useAnalyticsParams, useModalManager } from '../../../contexts'
import { PayEstimateRequest } from '../../../dtos'
import {
  useAccount, useAccountMutation, useDebouncedCallback, useDefaultPayEstimateRequest, useFormatters,
  useFormSubmitHandler, useHourlyPayValues, useJobPreferences, useJobPreferencesEstimateQuery,
  usePayEstimateQuery, useUpdateEffect
} from '../../../hooks'
import { Job } from '../../../models'
import { TaxFilingStatus } from '../../../models/common'
import { PayEstimate, PayEstimateInterval } from '../../../models/PayEstimate'
import { analytics } from '../../../services'
import { JobImageHeader } from '../common'
import { ComparisonPill, ComparisonPillProps } from './ComparisonPill'

export interface PayCalculatorProps extends SpaceProps {
  job: Job
  titleFontWeight?: TextProps['fontWeight']
}

export const PayCalculator = ({ job, titleFontWeight, ...props }: PayCalculatorProps) => {
  const { t } = useTranslation()
  const defaultPayEstimateParams = useDefaultPayEstimateRequest(job)
  const [payEstimateParams, setPayEstimateParams] = useState(defaultPayEstimateParams)
  const [payEstimate, setPayEstimate] = useState<PayEstimate>()
  const didSubmitRef = useRef(false)

  const payEstimateQuery = usePayEstimateQuery(payEstimateParams, {
    keepPreviousData: true
  })

  const handleSubmit = useCallback((values: PayEstimateRequest) => {
    setPayEstimateParams(values)
    didSubmitRef.current = true
  }, [])

  // Delay payEstimate as a workaround for SlotCounter
  // rendering bug when mounting with a value.
  useEffect(() => {
    setTimeout(() => {
      setPayEstimate(payEstimateQuery.data)
    }, 100)
  }, [payEstimateQuery.data])

  useEffect(() => {
    if (didSubmitRef.current && payEstimate) {
      analytics.trackEvent('Pay Estimate Updated', payEstimate)
    }
  }, [payEstimate])

  return (
    <AnalyticsParamsProvider source="Pay Calculator">
      <Box {...props}>
        <ComparisonMessage payEstimate={payEstimate} />

        <JobImageHeader
          mb={6}
          title={
            <>
              <Text
                fontSize={{ base: 'sm' }} fontWeight={titleFontWeight}
                lineHeight="16.8px" color="gray.400"
              >
                {t('views.payCalculator.title')}
              </Text>

              <Box
                fontSize={{ base: 'xl', '2xs': '2xl' }} fontWeight="medium"
                lineHeight={1.2} color="gray.900"
              >
                {payEstimate ? <AnimatedCurrency value={payEstimate.net_income} decimals={2} /> : '--'}
              </Box>
            </>
          }
          src={payCalculatorImg}
          alt={t('images.handfulOfCash')}
          h={{ base: '47.5px' }}
        />

        <PayEstimateSection
          payEstimate={payEstimate}
          state={job.state!}
          showTips={!!job.max_tips}
        />

        <Divider my={6} borderColor="gray.200" />

        <PayCalculatorForm job={job} onSubmitValues={handleSubmit} />
      </Box>
    </AnalyticsParamsProvider>
  )
}

export const PayCalculatorCard = ({ job, ...cardProps }: PayCalculatorProps) => (
  <Card p={5} {...cardProps}>
    <PayCalculator job={job} />
  </Card>
)

interface PayCalculatorFormProps {
  job: Job
  onSubmitValues: (values: PayEstimateRequest) => void
}

const PayCalculatorForm: React.FC<PayCalculatorFormProps> = ({ job, onSubmitValues }) => {
  const { t } = useTranslation()
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.payCalculator' })
  const fmt = useFormatters()
  const modalManager = useModalManager()
  const account = useAccount()
  const { mutate: updateAccount } = useAccountMutation()
  const hourlyPayValues = useHourlyPayValues(job)
  const defaults = useDefaultPayEstimateRequest(job)
  const jobPreferences = useJobPreferences()
  const analyticsParams = useAnalyticsParams()

  const form = useForm<PayEstimateRequest>({
    defaultValues: defaults
  })
  const { control } = form
  const formValues = form.watch()

  const submit = useFormSubmitHandler<any>(form, {
    async onSubmit(values: PayEstimateRequest) {
      const normValues = {
        ...values,
        weekly_hours: normalizeNumeric(values.weekly_hours, { min: 1, max: 120 }),
        hourly_wage: normalizeNumeric(values.hourly_wage, { min: 1, max: 200 }),
        hourly_tips:
          values.hourly_tips
            ? normalizeNumeric(values.hourly_tips, { min: 0, max: 100 })
            : undefined
      }

      form.reset(normValues, { keepDefaultValues: true })

      onSubmitValues(normValues)

      if (account && account.tax_filing_status !== values.tax_filing_status) {
        updateAccount({ tax_filing_status: values.tax_filing_status })
      }
    }
  })

  const debouncedSubmit = useDebouncedCallback(submit, [], 500)

  useUpdateEffect(() => {
    debouncedSubmit()
  }, Object.values(formValues))

  const [wageEstimateMessage, tipsEstimateMessage] = useMemo(() => {
    const {
      minHourlyPay,
      maxHourlyPay,
      minHourlyTips,
      maxHourlyTips
    } = hourlyPayValues

    let wageEstimateMessage = ''
    if (minHourlyPay && maxHourlyPay) {
      if (minHourlyPay === maxHourlyPay) {
        wageEstimateMessage = vt('estimateMessage', {
          value: fmt.currency(maxHourlyPay)
        }) + '*'
      } else {
        wageEstimateMessage = vt('estimateMessage', {
          value: `${fmt.currency(minHourlyPay)}-${fmt.currency(maxHourlyPay)}`
        }) + '*'
      }
    }

    let tipsEstimateMessage = ''
    if (minHourlyTips && maxHourlyTips) {
      if (minHourlyTips === maxHourlyTips) {
        tipsEstimateMessage = vt('estimateMessage', {
          value: fmt.currency(maxHourlyTips)
        }) + '*'
      } else {
        tipsEstimateMessage = vt('estimateMessage', {
          value: `${fmt.currency(minHourlyTips)}-${fmt.currency(maxHourlyTips)}`
        }) + '*'
      }
    }

    return [wageEstimateMessage, tipsEstimateMessage]
  }, [hourlyPayValues])

  return (
    <Box as="form" onSubmit={submit}>
      <Stack spacing={5}>
        <Flex direction="row" justifyContent="space-between" gap={4}>
          {/* Interval */}
          <PayCalculatorControl
            control={control}
            name="interval"
            label={vt('calculatePer')}
            renderInput={inputProps => (
              <Select {...inputProps}>
                {Object.values(PayEstimateInterval).map((value) => (
                  <option key={value} value={value}>
                    {vt(`intervals.${value}`)}
                  </option>
                ))}
              </Select>
            )}
          />

          {/* Weekly Hours */}
          <PayCalculatorControl
            control={control}
            name="weekly_hours"
            label={vt('weeklyHours')}
            helpText={vt('hoursEstimateMessage', { value: job.weekly_hours || 0 }) + '*'}
            renderInput={({ onChange, ...inputProps }) => (
              <NumericInput
                {...inputProps}
                onValueChange={onChange}
              />
            )}
            infoTitle={vt('weeklyHoursInfo.title')}
            infoMessage={
              <Trans i18nKey="views.payCalculator.weeklyHoursInfo.message">
                <RawLink
                  textDecoration="underline"
                  fontWeight={500}
                  target="_blank"
                  href="https://www.bls.gov/news.release/empsit.t18.htm"
                />
              </Trans>
            }
          />
        </Flex>

        <Flex direction="row" justifyContent="space-between" gap={4} mb={5}>
          {/* Hourly Wage */}
          <PayCalculatorControl
            control={control}
            name="hourly_wage"
            label={vt('hourlyWage')}
            renderInput={({ onChange, ...inputProps }) => (
              <CurrencyInput
                {...inputProps}
                onValueChange={onChange}
              />
            )}
            helpText={wageEstimateMessage}
            infoTitle={t('views.payCalculator.hourlyWageInfo.title')}
            infoMessage={
              <Trans i18nKey="views.payCalculator.hourlyWageInfo.message">
                <RawLink
                  textDecoration="underline"
                  fontWeight={500}
                  target="_blank"
                  href="https://www.bls.gov/bls/blswage.htm"
                />
              </Trans>
            }
          />

          {/* Hourly Tips */}
          {job.min_tips && job.max_tips && (
            <PayCalculatorControl
              control={control}
              name="hourly_tips"
              label={vt('hourlyTips')}
              renderInput={({ onChange, ...inputProps }) => (
                <CurrencyInput
                  {...inputProps}
                  onValueChange={onChange}
                />
              )}
              helpText={tipsEstimateMessage}
              infoTitle={vt('hourlyTipsInfo.title')}
              infoMessage={vt('hourlyTipsInfo.message')}
            />
          )}
        </Flex>

        {/* Tax Filing Status */}
        <PayCalculatorControl
          control={control}
          name="tax_filing_status"
          label={vt('taxFilingStatus')}
          renderInput={inputProps => (
            <Select {...inputProps}>
              {Object.values(TaxFilingStatus).map((value) => (
                <option key={value} value={value}>
                  {vt(`taxFilingStatuses.${value}`)}
                </option>
              ))}
            </Select>
          )}
          infoTitle={vt('taxFilingStatusInfo.title')}
          infoMessage={<Trans i18nKey="views.payCalculator.taxFilingStatusInfo.message" />}
        />

        <Stack spacing={3} align="flex-start">
          <Flex width="full" align="center" justify="center" mt={-2} mb={-1}>
            <Divider color="gray.200" />
            <Button
              variant="ghost" size="sm" color="gray.400"
              flexShrink={0}
              rightIcon={<Icon as={IconReload} boxSize={5} mx={-1} />}
              isDisabled={!form.formState.isDirty}
              onClick={() => form.reset()}
            >
              {t('actions.resetAll')}
            </Button>
            <Divider color="gray.200" />
          </Flex>

          {!jobPreferences?.pay && (
            <Button
              as={Link} to="/match-preferences" variant="tertiary" width="full"
              onClick={() => analytics.trackTakeTheJobQuizClick(analyticsParams)}
            >
              {vt('preferencesLinkTitle')}
            </Button>
          )}

          <Text fontSize="xs" lineHeight={1.4} fontWeight="400" color="gray.400">
            {vt('footnote')}
          </Text>

          <Button
            variant="link" size="sm" color="gray.400" textDecoration="underline"
            onClick={() => {
              modalManager.open(Dialog, {
                title: vt<string>('disclaimer.title'),
                message: vt<string>('disclaimer.message')
              })
            }}
          >
            {t('terms.disclaimer')}
          </Button>
        </Stack>
      </Stack>
      {/* Ensure form fires submit event when enter key is pressed */}
      <Button type="submit" display="none"></Button>
    </Box >
  )
}

type PayCalculatorInputProps
  <TName extends Path<PayEstimateRequest>> =
  ControllerRenderProps<PayEstimateRequest, TName> & Pick<InputProps, 'size' | 'height' | 'borderRadius'> & TypographyProps

interface PayCalculatorControlProps<TName extends Path<PayEstimateRequest>>
  extends Pick<ControllerProps<PayEstimateRequest, TName>, 'control' | 'name' | 'rules'> {
  label: string
  renderInput: (inputProps: PayCalculatorInputProps<TName>) => ReactElement
  helpText?: ReactNode
  infoTitle?: string
  infoMessage?: string | ReactNode
}

const PayCalculatorControl = <TName extends Path<PayEstimateRequest>>({
  control,
  name,
  rules,
  label,
  renderInput,
  helpText,
  infoTitle,
  infoMessage,
}: PayCalculatorControlProps<TName>) => {
  const modalManager = useModalManager()

  return (
    <Controller<PayEstimateRequest, TName>
      control={control}
      name={name}
      rules={{ required: true, ...rules }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={!!fieldState.error}>
          <FormLabel
            htmlFor={name}
            display="flex"
            alignItems="center"
            mb={2} mr={0}
            fontSize="sm" lineHeight={1.1} color="gray.900"
          >
            {label}
            {infoTitle && infoMessage && (
              <InfoButton
                boxSize="16px" ml={1}
                iconSize="16px"
                aria-label={infoTitle}
                onClick={() => {
                  modalManager.open(Dialog, { title: infoTitle, message: infoMessage })
                }}
              />
            )}
          </FormLabel>

          {renderInput({
            ...field,
            size: 'lg',
            height: '54px',
            fontFamily: 'Soehne',
            fontSize: 'md',
            borderRadius: '10px'
          })}

          {helpText && (
            <Text
              mt={2}
              fontSize={{ base: '2xs', '2xs': '11px' }}
              lineHeight={1.3}
              fontWeight="400"
              color="gray.400"
              letterSpacing={0.02}
            >
              {helpText}
            </Text>
          )}
        </FormControl>
      )}
    />
  )
}

interface AnimatedCurrencyProps {
  value: number
  decimals?: number
  negative?: boolean
}

const AnimatedCurrency = ({ value, decimals = 0, negative = false }: AnimatedCurrencyProps) => {
  const fmt = useFormatters()
  let formattedValue = fmt.currency(Math.round(value), { decimals })
  if (negative) formattedValue = `-${formattedValue}`

  return (
    <Box position="relative">
      <Box sx={{
        userSelect: 'none',
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        WebkitTapHighlightColor: 'transparent',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        pointerEvents: 'none',
        cursor: 'default',
      }}>
        <SlotCounter
          startValueOnce
          value={formattedValue}
          animateUnchanged
        />
      </Box>
      {/* overlay for select/copy of animated text */}
      <Box
        position="absolute"
        top="3px" // to align overlay text with
        left="0"
        color="transparent"
        letterSpacing="1.5px"
        sx={{
          userSelect: 'text',
          WebkitUserSelect: 'text',
          MozUserSelect: 'text',
          msUserSelect: 'text',
          cursor: 'text',
        }}
      >
        {formattedValue}
      </Box>
    </Box>
  )
}

interface PayEstimateSectionProps {
  payEstimate?: PayEstimate
  state: string
  showTips: boolean
}

const PayEstimateSection = memo(({ payEstimate, state, showTips }: PayEstimateSectionProps) => {
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.payCalculator' })

  let wageWidth = 100
  let tipWidth = 0
  let taxWidth = 0

  if (payEstimate) {
    const grossIncome = payEstimate.gross_income
    wageWidth = Math.min((payEstimate.gross_wage_income / grossIncome) * 100, 100)
    tipWidth = Math.min((payEstimate.gross_tip_income / grossIncome) * 100, 100)
    taxWidth = Math.min((payEstimate.total_taxes / grossIncome) * 100, 100)
  }

  return (
    <>
      <Flex mb={6} direction="column" alignItems="flex-start">
        <IncomeBar wageWidth={wageWidth} tipWidth={tipWidth} showTips={showTips} />
        <TaxBar taxWidth={taxWidth} />
      </Flex>
      <VStack mb={0} spacing={3} direction="row" alignItems="flex-start">
        <AmountRow
          color="#05A3FF"
          value={payEstimate?.gross_wage_income}
          label={vt('estimatedWages')}
        />
        {showTips && (
          <AmountRow
            color="#FFAA01"
            value={payEstimate?.gross_tip_income}
            label={vt('estimatedTips')}
          />
        )}
        <AmountRow
          negative
          color="red.500"
          value={payEstimate?.total_taxes}
          label={vt('estimatedTaxes', { state })}
        />
      </VStack>
    </>
  )
})

const IncomeBar: React.FC<{ wageWidth: number, tipWidth: number, showTips: boolean }> = ({
  wageWidth,
  tipWidth,
  showTips
}) => {
  const { t } = useTranslation()
  return (
    <Flex mb={2} direction="row" w="full" borderRadius="xl" overflow="hidden">
      <Box
        bg="#05A3FF"
        py={1}
        w={`${wageWidth}%`} h="24px" minW="0"
        display="flex" flexWrap="wrap" alignItems="center" justifyContent="center"
        overflow="hidden"
        transition="width 1.5s ease, border-radius 1s ease"
      >
        <Box display="inline-flex" mb={1} w="0px" h="100%" />
        <Text
          mx={2} mb={1}
          display="inline-flex" h="100%" w="min-content" alignItems="center"
          fontSize="sm" lineHeight={1} fontWeight="500" color="gray.900" textAlign="center"
        >
          {t('views.payCalculator.wages')}
        </Text>
      </Box>

      {showTips && (
        <Box
          bg="#FFAA01"
          ml={tipWidth > 0 ? 0.5 : 0} py={1}
          w={`${tipWidth}%`} h="24px"
          display="flex" flexWrap="wrap" alignItems="center" justifyContent="center"
          overflow="hidden"
          transition="width 1.5s ease"
        >
          <Box display="inline-flex" mb={1} w="0px" h="100%" />
          <Text
            mx={2} mb={1}
            display="inline-flex" h="100%" w="min-content" alignItems="center"
            fontSize="sm" lineHeight={1} fontWeight="500" color="gray.900" textAlign="center"
          >
            {t('views.payCalculator.tips')}
          </Text>
        </Box>
      )}
    </Flex>
  )
}

const TaxBar: React.FC<{ taxWidth: number }> = ({ taxWidth }) => {
  const { t } = useTranslation()
  return (
    <Box
      bg="#FF3434"
      py={1}
      w={`${taxWidth}%`} h="24px"
      display="flex" flexWrap="wrap" alignItems="center" justifyContent="center"
      overflow="hidden"
      borderRadius="xl"
      transition="width 1.5s ease"
    >
      <Box display="inline-flex" mb={1} w="0px" h="100%" />
      <Text
        mx={2} mb={1}
        display="inline-flex" h="100%" w="min-content" alignItems="center"
        fontSize="sm" lineHeight={1} fontWeight="500" color="gray.900" textAlign="center"
      >
        {t('views.payCalculator.taxes')}
      </Text>
    </Box >
  )
}

interface AmountRowProps {
  label: string
  value?: number
  color: string
  negative?: boolean
}

const AmountRow = ({ label, value, color, negative = false }: AmountRowProps) => (
  <Flex direction="row" w="100%">
    <Box
      flexShrink={0}
      h="8px" w="8px"
      mt={1.5} mr={2}
      bg={color}
      borderRadius="lg"
    />
    <Text mr={2} fontSize="sm" lineHeight={1.2} fontWeight={400} color="gray.900" letterSpacing="0.28px">
      {label}
    </Text>
    <Text as="div" ml="auto" mt={-0.5} fontSize="sm" lineHeight={1.2} fontWeight={500} color="gray.900" letterSpacing="0.28px">
      {value === undefined ? '--' : <AnimatedCurrency value={value} negative={negative} />}
    </Text>
  </Flex >
)

function normalizeNumeric(value: string, params: { min?: number, max?: number } = {}) {
  let num = parseFloat(value)
  if (isNaN(num)) {
    num = 0
  }
  if (params.min !== undefined && num < params.min) {
    num = params.min
  }
  if (params.max !== undefined && num > params.max) {
    num = params.max
  }
  return String(num)
}

interface ComparisonMessageProps {
  payEstimate?: PayEstimate
}

const ComparisonMessage = ({ payEstimate }: ComparisonMessageProps) => {
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.payCalculator' })
  const fmt = useFormatters()
  const jobPreferences = useJobPreferences()
  const { data: prefsPayEstimate } = useJobPreferencesEstimateQuery(jobPreferences, payEstimate)
  const pillPropsRef = useRef<ComparisonPillProps>()

  const pillProps = useMemo(() => {
    if (payEstimate && prefsPayEstimate) {
      const payDiff = (payEstimate.net_income * 100 - prefsPayEstimate.net_income * 100) / 100
      const variant = payDiff === 0 ? 'same' : payDiff > 0 ? 'better' : 'worse'

      const message = vt(`comparisonMessage.${variant}`, {
        amount: fmt.currency(Math.abs(payDiff)),
        interval: vt(`intervals.${prefsPayEstimate.interval}`).toLowerCase()
      })

      pillPropsRef.current = { variant, children: message }
    }

    return pillPropsRef.current
  }, [payEstimate, prefsPayEstimate])

  if (!jobPreferences?.pay) {
    return null
  }

  return (
    <ComparisonPill
      mb={5} mr="auto"
      multiLine
      isLoading={!pillProps}
      {...pillProps}
    />
  )
}
