import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useMemo } from 'react'
import { PayEstimateRequest } from '../dtos'
import { Job, JobPreferences, PayEstimate, PayEstimateInterval, TaxFilingStatus } from '../models'
import { api } from '../services'
import { QKey } from '../types'
import { useAccount } from './account'
import { useHourlyPayValues } from './jobs'

const SUPPORTED_STATES = ['AL', 'CA', 'MA', 'NH', 'NY', 'AK', 'FL', 'NV', 'SD', 'TN', 'TX', 'WA', 'WY']

export function useIsPayEstimateSupported(job: Job) {
  return useMemo(() => {
    return !!(
      job.zipcode &&
      job.min_pay &&
      job.max_pay &&
      job.weekly_hours &&
      SUPPORTED_STATES.includes(job.state!)
    )
  }, [job])
}

export function useDefaultPayEstimateRequest(job: Job): PayEstimateRequest {
  const account = useAccount()
  const { avgHourlyPay, avgHourlyTips } = useHourlyPayValues(job)

  return {
    interval: PayEstimateInterval.WEEK,
    weekly_hours: String(job.weekly_hours || 0),
    hourly_wage: String(avgHourlyPay),
    hourly_tips: String(avgHourlyTips),
    tax_filing_status: account?.tax_filing_status ?? TaxFilingStatus.SINGLE,
    is_self_employed: !!job.is_gig,
    zipcode: job.zipcode!,
  }
}

export function usePayEstimateQuery(
  params?: PayEstimateRequest,
  options: UseQueryOptions<PayEstimateRequest, Error, PayEstimate, any> = {}
) {
  const { enabled = true, ...restOptions } = options

  return useQuery([QKey.PAY_ESTIMATE, params], async () => {
    const body = await api.payEstimates.create(params!)
    return { ...params, ...body.data } as PayEstimate
  }, {
    enabled: !!params && enabled,
    staleTime: Infinity,
    ...restOptions
  })
}

export function useJobPreferencesEstimateQuery(
  jobPreferences?: JobPreferences | null,
  payEstimate?: PayEstimate
) {
  let params: PayEstimateRequest | undefined

  if (jobPreferences?.pay && payEstimate) {
    params = {
      interval: payEstimate.interval,
      weekly_hours: payEstimate.weekly_hours,
      tax_filing_status: payEstimate.tax_filing_status,
      is_self_employed: payEstimate.is_self_employed,
      zipcode: payEstimate.zipcode,
      hourly_wage: String(jobPreferences.pay)
    }
  }

  return usePayEstimateQuery(params)
}
