import { BoxProps, HStack, Stack, Text } from '@chakra-ui/react'
import { useFormatters } from '../../hooks'
import { Company, Location } from '../../models'
import { ContentFooter } from '../core'
import { CompanyLogo } from './CompanyLogo'

export const COMPANY_INFO_FOOTER_HEIGHT = 76

interface CompanyInfoFooterProps extends BoxProps {
  company: Company
  location?: Location
}

export const CompanyInfoFooter = ({
  company,
  location,
  ...props
}: CompanyInfoFooterProps) => {
  const fmt = useFormatters()

  const content = (
    <HStack
      flex={1}
      backgroundColor="gray.900"
      px="21px" py="18px"
      spacing={2.5}
    >
      <CompanyLogo company={company} boxSize={10} />

      <Stack color="white" lineHeight={4} spacing="1px">
        <Text fontWeight="medium" fontSize="sm">
          {company.name}
        </Text>
        {!!location && (
          <Text fontSize={11}>
            {fmt.address({
              address: location.address,
              address2: location.address2,
              city: location.city,
              state: location.state,
              zipcode: location.zipcode,
            })}
          </Text>
        )}
      </Stack>
    </HStack>
  )

  return (
    <ContentFooter h={`${COMPANY_INFO_FOOTER_HEIGHT}px`} {...props}>
      {content}
    </ContentFooter>
  )
}
