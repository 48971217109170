import { useTranslation } from 'react-i18next'
import { HorizontalLocationListSection } from '../../../components/locations'
import { useInfiniteQueryTotal, useLocationsQuery, useQueryItems } from '../../../hooks'
import { Location } from '../../../models'
import { analytics } from '../../../services'

interface OtherLocationsSectionProps {
  location: Location
}

export const OtherLocationsSection = ({ location }: OtherLocationsSectionProps) => {
  const { t } = useTranslation()
  const query = useLocationsQuery({ companyId: location.company.id })
  const companyLocations = useQueryItems(query)
  const otherLocations = companyLocations?.filter(otherLocation => otherLocation.id !== location.id)
  const total = useInfiniteQueryTotal(query)

  return (
    <HorizontalLocationListSection
      title={t('views.locations.otherLocations')}
      query={query}
      items={otherLocations}
      total={total && total - 1}
      linkTo={`/companies/${location.company.id}/locations`}
      scrollPreservationKey="otherLocations"
      onScrollStart={() => analytics.trackHorizontalScroll('other locations', { location_id: location.id })}
    />
  )
}
