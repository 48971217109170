import snakeCase from 'lodash/snakeCase'
import { APP_ENV } from '../config'
import { getParty } from '../helpers'
import { Job, JobBase, JobPreferences } from '../models'
import { AuthMethod } from '../types'

declare global {
  interface Window {
    dataLayer?: any
    _hsq?: any
    fbq?: any
    mixpanel?: any
    rdt?: any
    ttq?: any
    lintrk?: any
  }
}

export type AnalyticsName =
  'Apply Order Item Detail' |
  'Auto Apply Questionnaire Wizard' |
  'Better Jobs' |
  'Bulk Apply Cart' |
  'Dislike Modal' |
  'Gamify' |
  'Job Detail' |
  'Job Detail Modal' |
  'Jobs at this location' |
  'Match Preferences' |
  'Search' |
  'Search Filters'

export interface ContextParams {
  page?: string
  source?: string
}

export default {
  // Initialized by AnalyticsSetup
  splitClient: undefined as SplitIO.IBrowserClient | null | undefined,

  identifyUser(id: string) {
    pushMixpanel(['identify', id])
  },
  setUserProperties(params: { [key: string]: any }) {
    pushMixpanel(['people.set', params])
    this.splitClient?.setAttributes(params)
  },
  deleteUser() {
    pushMixpanel(['people.delete_user'])
  },
  resetUser() {
    pushMixpanel(['reset'])
  },
  trackEvent(name: string, params?: { [key: string]: any }) {
    pushMixpanel(['track', name, params])
    this.splitClient?.track('anonymous', snakeCase(name), undefined, params)
  },
  trackPageView() {
    pushMixpanel(['track_pageview'])
    pushMeta('PageView')
    pushLinkedIn()
  },
  // Track search and job detail page views
  trackSpecialPageView() {
    loadTalrooTrackingScript()
  },
  trackAuth(params: { provider: 'email' | 'google' }) {
    this.trackEvent('Authenticated', params)
  },
  trackConversion(_conversionId: string) {
    pushTikTok('Contact')
    this.trackEvent('RegistrationStart')
  },
  trackDescriptionView() {
    this.trackEvent('Open job description')
  },
  trackDiscoverJobs() {
    this.trackEvent('Go to search from job detail')
  },
  trackOpen(name: AnalyticsName, cta: string | undefined, params: ContextParams) {
    this.trackEvent(`Open ${name}`, {
      page: params.page,
      container: params.source,
      cta
    })
  },
  trackNav(title: string, params?: { source?: string }) {
    this.trackEvent(`Navigate to ${title}`, params)
  },
  trackLoadRegistration(params: { type: string, method: AuthMethod, variant: string, emailAuthType: string }) {
    this.trackEvent('Load Registration', {
      ...params,
      method: params.method === AuthMethod.SIGN_UP ? 'Sign Up' : 'Sign In'
    })
  },
  trackRegistrationComplete() {
    this.trackEvent('Complete registration')
    pushTikTok('CompleteRegistration')
  },
  trackHorizontalScroll(section: string, params?: object) {
    this.trackEvent(`Scroll for ${section}`, params)
  },
  trackStartApplication(params?: object) {
    this.trackEvent('Start application', params)
  },
  trackAnswerApplicationQuestion(params?: object) {
    this.trackEvent('Answer application question', params)
  },
  trackSkipApplicationQuestion(params?: object) {
    this.trackEvent('Skip application question', params)
  },
  trackApplicationEvaluated(params: { job_id: string, qualified: boolean }) {
    this.trackEvent('Application Evaluated', params)
  },
  trackApplicationSubmitted(params: { job_id: string, qualified: boolean }) {
    this.trackEvent('Application Submitted', params)
  },
  trackPrescreenersSubmitted(params: { job_id: string, qualified: boolean }) {
    this.trackEvent('Pre-Screener Submitted', params)
  },
  trackJobView(job: Job, params: { pageType: string, source?: string }) {
    this.trackSpecialPageView()
    pushTikTok('ViewContent', { content_id: job.id })
    this.trackEvent('Load job detail', {
      job_id: job.id,
      company: job.company.name,
      position: job.position,
      clean_title: job.clean_title,
      applyType: getApplyType(job),
      interestTags: job.interest_tags,
      ...params
    })
  },
  trackJobExpired(job_slug: string, params?: object) {
    this.trackEvent('Job expired', { job_slug, ...params })
  },
  trackSearch(params?: object) {
    this.trackEvent('Search', params)
    pushTikTok('Search')
  },
  trackSearchSorted(type: string) {
    this.trackEvent('Search Sorted', { type })
  },
  trackFavorite(params?: object) {
    pushTikTok('AddToWishlist')
    this.trackEvent('Add job to favorites', params)
  },
  trackApplyStart(params: { job: JobBase, page?: string, source?: string }) {
    this.trackEvent('ApplyStart', {
      job_id: params.job.id,
      party: getParty(params.job),
      page: params.page,
      source: params.source,
      applyType: getApplyType(params.job),
      interestTags: params.job.interest_tags,
    })
  },
  trackApplyResume(params: { job: JobBase, page?: string, source?: string }) {
    this.trackEvent('Finish Applying', {
      job_id: params.job.id,
      party: getParty(params.job),
      page: params.page,
      source: params.source
    })
  },
  trackApply(
    params?: { type: 'external' | 'internal', job_id: string }
  ) {
    const mixPanelParams = params
      ? { type: params.type, job_id: params.job_id }
      : undefined
    this.trackEvent('Apply', mixPanelParams)
    pushTikTok('InitiateCheckout')
  },
  trackFindMoreGigsPostApplication(params?: object) {
    this.trackEvent('See more gigs post application', params)
  },
  trackSeeMoreJobsInSearch() {
    this.trackEvent('See more jobs in search')
  },
  trackPlayVideo(params?: object) {
    this.trackEvent('Play video', params)
  },
  trackSwipeMediaCarousel(params?: object) {
    this.trackEvent('Swipe media carousel', params)
  },
  trackInterstitialClosed(params?: object) {
    this.trackEvent('Complete application interstitial', params)
  },
  trackSocialClick(params?: object) {
    this.trackEvent('Click on social icon', params)
  },
  trackGoToLocation(params?: object) {
    this.trackEvent('Go to location', params)
  },
  trackTakeTheJobQuizClick(params: { page?: string, source?: string }) {
    this.trackEvent('Take the job quiz', params)
  },
  trackMatchPreferencesProgress(step: string, values?: Partial<JobPreferences>) {
    this.trackEvent('Survey progress', { step, preferences: values })
  },
  trackPostC2AModalLoaded() {
    this.trackEvent('Load Post-C2A modal')
  },
  trackPostC2AModalViewed() {
    this.trackEvent('View Post-C2A Modal')
  },
  trackSortMenuView() {
    this.trackEvent('View Sort Menu')
  },
  trackApplicationRedirect() {
    this.trackEvent('ApplicationRedirect')
  },
  trackSeeAll(params: { page?: string, source?: string }) {
    if (Object.keys(params).length) {
      this.trackEvent('Tapped See All', params)
    }
  }
}

function pushMixpanel(params: any[]) {
  if (window.mixpanel) {
    const [functionName, ...args] = params

    const functionPath = functionName.split('.')
    let context = window.mixpanel

    try {
      for (let i = 0; i < functionPath.length; i++) {
        if (i === functionPath.length - 1) {
          if (typeof context[functionPath[i]] === 'function') {
            context[functionPath[i]].apply(context, args)
          }
        } else {
          context = context[functionPath[i]]
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (APP_ENV === 'local') {
    console.log('[mixpanel]', ...params)
  }
}

function pushTikTok(...args: [event: string, params?: object]) {
  if (window.ttq) {
    try {
      window.ttq.track(...args)
    } catch (e) {
      console.error(e)
    }
  }
}

function pushMeta(...args: [event: string, params?: object]) {
  if (window.fbq) {
    try {
      window.fbq('track', ...args)
    } catch (e) {
      console.error(e)
    }
  }
}

function pushLinkedIn(...args: [params?: object]) {
  if (window.lintrk) {
    try {
      window.lintrk('track', ...args)
    } catch (e) {
      console.error(e)
    }
  }
}

function loadTalrooTrackingScript(conversionId: string = '') {
  loadPixel('https://www.jobs2careers.com/conversion' + conversionId + '.php?p=9290')
}

function loadPixel(src: string) {
  if (APP_ENV !== 'production') return

  const imgEl = document.createElement('img')
  imgEl.src = src
  imgEl.width = 1
  imgEl.height = 1
  imgEl.addEventListener('load', imgEl.remove)
  document.body.appendChild(imgEl)
}

function getApplyType(job: JobBase) {
  const isExternalApplication = !!job.application_url

  if (isExternalApplication) {
    if (job.estimated_apply_time) {
      return 'Minute Apply'
    }
    return 'Apply'
  }

  return 'Fast Apply'
}
