import { Box } from '@chakra-ui/react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { PageContainer } from '../../components/core'
import { MatchedJobsSection } from '../../components/jobs'
import { AnalyticsParamsProvider } from '../../contexts'
import { Feed } from './partials/Feed'
import { FeedHeader } from './partials/FeedHeader'

export const Home = () => {
  const { t } = useTranslation()
  const topSectionRef = useRef<HTMLDivElement | null>(null)
  const [feedOffset, setFeedOffset] = useState(0)

  const updateFeedOffset = () => {
    if (topSectionRef.current) {
      const topSectionHeight = topSectionRef.current.clientHeight
      setFeedOffset(topSectionHeight ? topSectionHeight + 40 : 0)
    }
  }

  const handleJobMatchesResize = useCallback(updateFeedOffset, [])
  useLayoutEffect(updateFeedOffset, [])

  return (
    <AnalyticsParamsProvider page="Home">
      <Helmet title={t('links.home')} />
      <FeedHeader />

      <PageContainer px={0}>
        <Feed paddingStart={feedOffset} />
        <Box pos="absolute" w="full" top={2.5} ref={topSectionRef}>
          <MatchedJobsSection showHeroCard onResize={handleJobMatchesResize} />
        </Box>
      </PageContainer>
    </AnalyticsParamsProvider>
  )
}
