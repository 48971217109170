import { ComponentType } from 'react'

export function createLazyFactory<T = { [key: string]: ComponentType }>(
  factory: () => Promise<T>
) {
  return (componentName: keyof T) => async () => {
    const module = await factory()
    return { Component: module[componentName] as ComponentType }
  }
}
