import { motion, Variants } from 'framer-motion'
import { memo, PropsWithChildren, useMemo } from 'react'

export interface StackSlideProps extends PropsWithChildren {
  variants: Variants
  direction: number
}

export const StackSlide = memo(({
  variants,
  direction,
  children
}: StackSlideProps) => {
  return (
    <motion.div
      style={{ position: 'absolute', width: '100%', height: '100%' }}
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.15 }
      } as any}
    >
      {children}
    </motion.div>
  )
})

export function useStackSlideVariants(offset: number = 500): Variants {
  return useMemo(() => {
    return {
      enter: (direction: number) => {
        return {
          x: direction > 0 ? offset : -offset,
          opacity: 0,
        }
      },
      center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
      },
      exit: (direction: number) => {
        return {
          zIndex: 0,
          x: direction < 0 ? offset : -offset,
          opacity: 0,
        }
      }
    }
  }, [offset])
}
