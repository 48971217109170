import { Box, Divider, Flex, Heading, useModalContext } from '@chakra-ui/react'
import { useEffect } from 'react'
import { CONTENT_PADDING, DismissButton, ModalSheet } from '../../../components/core'
import { JobList } from '../../../components/jobs'
import { AnalyticsParamsProvider } from '../../../contexts'
import { useRecommendedJobsQuery } from '../../../hooks'
import { Job, JobIndexRecord } from '../../../models'
import { analytics } from '../../../services'

interface C2AModalProps {
  isOpen: boolean
  job: Job
  onClose: () => void
}

export const C2AModal = ({ isOpen = false, job, onClose }: C2AModalProps) => {
  const { data } = useRecommendedJobsQuery(job)
  const jobs = data?.jobs

  if (!jobs?.length) {
    return null
  }

  return (
    <ModalSheet
      isOpen={isOpen}
      header={<C2AModalHeader title={data?.type} subtitle={data?.job_title} />}
      onClose={onClose}
    >
      <C2AModalContent jobs={jobs} />
    </ModalSheet>
  )
}

const C2AModalContent = ({ jobs }: { jobs: JobIndexRecord[] }) => {
  useEffect(() => {
    analytics.trackPostC2AModalLoaded()

    let didTrackView = false
    const trackViewOnce = () => {
      if (!didTrackView) {
        analytics.trackPostC2AModalViewed()
        didTrackView = true
      }
    }

    setTimeout(() => {
      if (document.hasFocus()) {
        trackViewOnce()
      }
    }, 500)

    window.addEventListener('focus', trackViewOnce)

    return () => {
      window.removeEventListener('focus', trackViewOnce)
    }
  }, [])

  return (
    <AnalyticsParamsProvider source="Post C2A Modal">
      <JobList items={jobs} px={0} />
    </AnalyticsParamsProvider>
  )
}

interface C2AModalHeaderProps {
  title?: string
  subtitle?: string
}

const C2AModalHeader = ({ title, subtitle }: C2AModalHeaderProps) => {
  const { onClose } = useModalContext()

  return (
    <Box px={CONTENT_PADDING}>
      <Flex py={5}>
        <Box flex={1}>
          <Heading as="h1" fontSize="2xl" fontWeight="medium">
            {title}
          </Heading>

          <Heading as="h2" fontSize="2xl" fontWeight="normal" color="gray.300">
            {subtitle}
          </Heading>
        </Box>

        <DismissButton onClick={onClose} />
      </Flex>

      <Divider />
    </Box>
  )
}
