import { BoxProps, Stack, Text, Wrap } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { SchedulePill, WagePill } from '.'
import { useFormatters, useGeoCoordinates } from '../../hooks'
import { Job, JobBase, LatLng } from '../../models'
import { CONTENT_PADDING } from '../core'
import { RegionBadge } from '../RegionBadge'

export interface JobDetailInfoProps extends BoxProps {
  job: Job | JobBase
  distanceFrom?: LatLng
  variant?: 'full' | 'partial'
}

export const JobDetailInfo = ({
  job,
  distanceFrom,
  variant = 'full',
  ...props
}: JobDetailInfoProps) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const geoCoordinates = useGeoCoordinates()

  const locationHref = 'location_id' in job && job.location_id
    ? `/locations/${job.location_id}`
    : undefined

  return (
    <Stack px={CONTENT_PADDING} spacing={4} {...props}>
      <Wrap spacingX={2.5} spacingY={2} justify="center">
        <RegionBadge
          record={job}
          distanceFrom={distanceFrom ?? geoCoordinates}
          linkTo={locationHref}
        />

        {!!job.min_pay && (
          <WagePill
            displaySupplementalPay
            job={job}
            showIcon={false}
            borderRadius="5px"
          />
        )}

        {variant === 'full' && job.schedule?.map((scheduleType) => (
          <SchedulePill
            key={scheduleType}
            scheduleType={scheduleType}
            showIcon={false}
            borderRadius="5px" />
        ))}
      </Wrap>

      {variant === 'full' && (
        <Text fontSize="13px" lineHeight={1.2} fontWeight={400} color="gray.300" alignSelf="center">
          {t('views.jobs.postedAt', {
            at: fmt.relativeTimeCompact(job.posted_date ?? job.created_at)
          })}
        </Text>
      )}
    </Stack>
  )
}
