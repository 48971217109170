import { Button } from '@chakra-ui/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import artworkSrc from '../../../assets/images/location.png'
import { HighlightCard } from '../../core'

export const FeedSettingsCard = memo(() => {
  const { t } = useTranslation()

  return (
    <HighlightCard
      title={t('views.feed.cards.feedSettings.title')}
      body={t('views.feed.cards.feedSettings.body')}
      imageSrc={artworkSrc}
      imageAlt={t('images.location')}
      imageWidth={115}
      imageHeight={150}
      bg="blue.300"
    >
      <Button as={Link} to="settings/starting-location" variant="secondary">
        {t('actions.changeLocation')}
      </Button>
    </HighlightCard>
  )
})
