import { Box, BoxProps, Flex, Icon, IconButton, IconProps, Image } from '@chakra-ui/react'
import { IconEye, IconHeart } from '@tabler/icons-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppState, useModalManager } from '../../contexts'
import { buildJobUrl } from '../../helpers'
import { useLocalJobState, useMediaImage, useToggleFavoriteJob } from '../../hooks'
import { JobBase } from '../../models'
import { AuthDialog } from '../auth/AuthDialog'
import { IconButtonWithOptionalAriaProps } from '../core'

const DROP_SHADOW_FILTER = 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2))'

export interface SmallJobCardImageProps extends BoxProps {
  job: JobBase
  fade?: boolean
}

export const SmallJobCardImage = ({
  job,
  children,
  fade,
  ...props
}: SmallJobCardImageProps) => {
  const media = useMediaImage(job.media[0], 'sm')

  return (
    <Box pos="relative" flexShrink={0} {...props}>
      <Image
        src={media.url}
        alt={media.description}
        w="full"
        h="full"
        objectFit="cover"
      />

      {fade && (
        <Box
          pos="absolute" left={0} right={0} top={0} bottom={0}
          bgImage={'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1))'}
        />
      )}

      <Flex
        pos="absolute" top={0} left={0} right={0} bottom={0}
        flexDir="column"
      >
        <Flex p={3}>
          <ViewedIcon
            mt={-1} ml={-0.5}
            hasDropShadow
            color="white"
            opacity={0.8}
            boxSize="20px"
            job={job}
          />

          <FavoriteButton
            ml="auto" mt={-2.5} mr={-2.5}
            hasDropShadow
            job={job}
            zIndex={2}
          />
        </Flex>

        {children}
      </Flex>
    </Box>
  )
}

export interface FavoriteButtonProps extends IconButtonWithOptionalAriaProps {
  job: JobBase
  color?: string
  fillColor?: string
  iconSize?: IconProps['boxSize']
  hasDropShadow?: boolean
}

export const FavoriteButton = ({
  job,
  color = 'white',
  iconSize = '22px',
  fillColor,
  hasDropShadow,
  ...props
}: FavoriteButtonProps) => {
  const [{ authenticated }] = useAppState()
  const { t } = useTranslation()
  const [localState] = useLocalJobState(job.id)
  const isFavorite = localState.isFavorite !== undefined ? localState.isFavorite : job.is_favorite
  const { mutate: toggleFavorite, isLoading } = useToggleFavoriteJob(job)
  const modalManager = useModalManager()

  const handleClick = useCallback(() => {
    if (authenticated) {
      toggleFavorite()
    } else {
      modalManager.open(AuthDialog, {
        redirectTo: buildJobUrl(job.slug, { action: 'favorite' })
      })
    }
  }, [authenticated])

  return (
    <IconButton
      variant="ghost"
      aria-label={
        job.is_favorite
          ? t('actions.removeFavorite')
          : t('actions.addFavorite')
      }
      icon={
        <Icon
          fill={isFavorite ? fillColor ?? color : 'none'}
          as={IconHeart}
          boxSize={iconSize}
          color={isFavorite ? fillColor ?? color : color}
        />
      }
      filter={hasDropShadow ? DROP_SHADOW_FILTER : undefined}
      onClick={isLoading ? undefined : handleClick}
      {...props}
    />
  )
}

export const ViewedIcon = ({ job, hasDropShadow, ...props }: { job: JobBase, hasDropShadow?: boolean } & IconProps) => {
  const [localState] = useLocalJobState(job.id)

  return (
    <>
      {(job.is_viewed || localState.isViewed) && (
        <Icon
          as={IconEye}
          boxSize={22}
          color="gray.200"
          filter={hasDropShadow ? DROP_SHADOW_FILTER : undefined}
          {...props}
        />
      )}
    </>
  )
}
