import { Stack } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { DetailHeader } from '../../../components/detail'
import { useGeoCoordinates } from '../../../hooks'
import { Location } from '../../../models'
import { LocationDetailInfo } from './LocationDetailInfo'
import { LocationHeaderAction } from './LocationHeaderAction'
import { LocationJobsSection } from './LocationJobsSection'
import { OtherLocationsSection } from './OtherLocationsSection'

interface LocationDetailContentProps {
  location: Location
}

export const LocationDetailContent = ({ location }: LocationDetailContentProps) => {
  const geoCoordinates = useGeoCoordinates()

  return (
    <>
      <Helmet title={`${location.company.name} (${location.region_name})`} />

      <DetailHeader
        company={location.company}
        mediaItems={location.company.media}
        title={location.company.name}
        subtitle={location.company.description}
        mediaAnalyticsParams={{ location_id: location.id }}
        videoPlayerModalActions={<LocationHeaderAction location={location} />}
      />

      <LocationDetailInfo
        mt={4}
        location={location}
        distanceFrom={geoCoordinates}
      />

      <Stack spacing={30} mt={7} mb="30px">
        <LocationJobsSection location={location} />
        <OtherLocationsSection location={location} />
      </Stack>
    </>
  )
}
