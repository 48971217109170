import { BoxProps, Button, Image } from '@chakra-ui/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, To } from 'react-router-dom'
import artworkSrc from '../../assets/images/discover-jobs.png'
import { analytics } from '../../services'
import { FeatureCard } from '../core'

export interface DiscoverJobsCardProps extends BoxProps {
  linkTo?: To
}

export const DiscoverJobsCard = memo(({
  linkTo,
  ...props
}: DiscoverJobsCardProps) => {
  const { t } = useTranslation()

  return (
    <FeatureCard
      image={<Image src={artworkSrc} alt={t('images.discoverJobs')} h={32} />}
      title={t('views.jobs.discoverJobs.title')}
      body={t('views.jobs.discoverJobs.body')}
      {...props}
    >
      <Button
        as={Link} to={linkTo ?? '/search'}
        variant="tertiary" size="lg" w="full"
        onClick={() => {
          analytics.trackDiscoverJobs()
        }}
      >
        {t('actions.showMe')}
      </Button>
    </FeatureCard>
  )
})
