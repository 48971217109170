import { QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getQuestionTitles, prepareJobUserEvent } from '../helpers'
import { useSendUserEvent } from '../hooks'
import { isApiNotFoundError } from '../lib/apiClient'
import { Application, ApplicationAnswer, RawApplication, UserEventType, UtmParams } from '../models'
import { selectDecoratedApplication } from '../selectors/application'
import { analytics, api } from '../services'
import { QKey } from '../types'
import { useBasicProfileWizardState } from './account'
import { useQueryErrorHandler } from './app'

export function useApplicationsQuery() {
  return useInfiniteQuery([QKey.APPLICATIONS], async ({ pageParam }) => {
    return api.applications.list({ cursor: pageParam })
  }, {
    staleTime: 10_000,
    getNextPageParam: lastPage => lastPage.cursor
  })
}

export function useApplicationQuery(id?: string) {
  return useQuery([QKey.APPLICATIONS, id!], async () => {
    const { data } = await api.applications.find(id!)
    return data
  }, {
    select: selectDecoratedApplication,
    enabled: !!id,
    staleTime: 10_000
  })
}

export function useCreateApplication(jobId: string, jobEventDataUtmParams?: UtmParams) {
  const queryClient = useQueryClient()

  const sendUserEvent = useSendUserEvent()

  return useMutation(async () => {
    const { data } = await api.applications.create({ job_id: jobId })
    return data
  }, {
    onSuccess(application) {
      queryClient.setQueryData([QKey.APPLICATIONS, application.id], application)
      invalidateApplicationQueries(queryClient)

      analytics.trackStartApplication({ job_id: jobId })

      const { job } = application

      analytics.trackApply({
        job_id: job.id,
        type: job.application_url ? 'external' : 'internal',
      })

      const userEvent = prepareJobUserEvent(application.job.id, UserEventType.APPLY_START, jobEventDataUtmParams)
      sendUserEvent(userEvent)
    }
  })
}

export function useUpdateApplicationAnswer(
  id: string,
  questionId: string
) {
  const queryClient = useQueryClient()
  const handleError = useQueryErrorHandler({
    notify: err => !isApiNotFoundError(err)
  })

  return useMutation(async (values: { [key: string]: any } | null) => {
    const { data } = await api.applications.answers
      .update(id, questionId, { question_id: questionId, values })
    return data
  }, {
    mutationKey: [],
    onSuccess({ answer, questions }, variables) {
      const applicationKey = [QKey.APPLICATIONS, id]
      const application = queryClient.getQueryData<RawApplication>(applicationKey)
      if (!application) return

      const answers = application.answers.slice()
      const answerIndex = answers
        .findIndex(a => a.question_id === answer.question_id)

      if (answerIndex < 0) {
        answers.push(answer)
      } else {
        answers[answerIndex] = answer
      }

      const updatedApplication = { ...application, answers }

      // If questions are present in the response, that means
      // they have changed (EEOC opt-in).
      if (questions) {
        updatedApplication.questions = questions
        updatedApplication.answers = answers.reduce<ApplicationAnswer[]>((acc, a) => {
          if (questions.find(q => q.id === a.question_id)) {
            acc.push(a)
          }
          return acc
        }, [])
      }

      queryClient.setQueryData(applicationKey, updatedApplication)

      // Analytics
      if (variables !== null) {
        analytics.trackAnswerApplicationQuestion({
          formDataKeys: Object.keys(variables),
          job_id: application.job.id,
          application_id: id,
        })
      } else {
        const question = application.questions.find(q => q.id === questionId)
        const titles = getQuestionTitles(question!.form_schema)
        analytics.trackSkipApplicationQuestion({ 'question': titles[0] })
      }
    },
    onError: handleError
  })
}

export function useSubmitApplication(id: string) {
  const queryClient = useQueryClient()
  const handleError = useQueryErrorHandler({
    notify: err => !isApiNotFoundError(err)
  })

  return useMutation(async () => {
    const { data } = await api.applications.submit(id)
    return data
  }, {
    onSuccess(data) {
      const { job, qualified } = data
      queryClient.setQueryData([QKey.APPLICATIONS, data.id], data)
      invalidateApplicationQueries(queryClient)
      queryClient.refetchQueries([QKey.JOBS, job.id])

      if (job.application_url) {
        analytics.trackPrescreenersSubmitted({ job_id: job.id, qualified })
      } else {
        analytics.trackApplicationSubmitted({ job_id: job.id, qualified })
      }
    },
    onError: handleError
  })
}

export function useEvaluateApplication(id: string, options?: { onSuccess?: (data: RawApplication) => void }) {
  const queryClient = useQueryClient()
  const handleError = useQueryErrorHandler({ notify: err => !isApiNotFoundError(err) })

  return useMutation(async () => {
    const { data } = await api.applications.evaluate(id)
    return data
  }, {
    onSuccess(data) {
      queryClient.setQueryData([QKey.APPLICATIONS, data.id], data)
      options?.onSuccess?.(data)
      analytics.trackApplicationEvaluated({ job_id: data.job.id, qualified: data.qualified })
    },
    onError: handleError
  })
}

export function useApplicationRedirectTo(application?: Application, currentSubpath?: string) {
  const profileWizard = useBasicProfileWizardState()

  const redirectPath = useMemo(() => {
    if (!application) return
    const questionPath = `questions/${application.firstIncompleteQuestionId ?? application.lastQuestionId}`

    if (!application.isSubmitted) {
      if (application.isExternalLink) {
        if (!application.isComplete) {
          return questionPath
        } else {
          return 'complete'
        }
      } else {
        if (!profileWizard.finished) {
          return 'profile'
        } else if (!application.isComplete) {
          return questionPath
        } else if (application.isEvaluated && !application.qualified) {
          return 'unqualified'
        } else {
          return 'complete'
        }
      }
    } else {
      return 'submitted'
    }
  }, [application])

  if (application && redirectPath !== currentSubpath) {
    return `/applications/${application.id}/${redirectPath}`
  }
}

function invalidateApplicationQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries([QKey.JOBS])
  queryClient.invalidateQueries([QKey.FEED])
  queryClient.invalidateQueries([QKey.APPLICATION_ITEMS])
  queryClient.invalidateQueries([QKey.APPLY_ORDER_ITEMS])
}
