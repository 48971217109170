import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { BridgedSessionState, BRIDGED_SESSION_KEYS, useAppState, useInjectedConfig, useLocalStorage, useLocalStorageSetter, useSessionStorageState } from '../contexts'
import { isApiNotFoundError, isApiUnauthorizedError } from '../lib/apiClient'
import { useRevokeAuth } from './auth'
import { useErrorToast } from './ui'

const SESSION_BRIDGE_TTL = 30 * 60_000 // 30 minutes

export function useInitialPageConfig() {
  const location = useLocation()
  const initialPage = useInjectedConfig()?.initial_page

  if (initialPage && location.pathname === initialPage.pathname) {
    return initialPage
  }
}

export function usePersistSessionBridge() {
  const [sessionStorageState] = useSessionStorageState()
  const setSessionBridge = useLocalStorageSetter('sessionBridge')

  return useCallback((values?: BridgedSessionState) => {
    const sessionValues: BridgedSessionState = {}
    for (const key of BRIDGED_SESSION_KEYS) {
      sessionValues[key] = sessionStorageState[key] as any
    }
    setSessionBridge({
      ...sessionValues,
      ...values,
      _ts: Date.now()
    })
  }, [sessionStorageState])
}

export function useRestoreBridgedSessionStorage() {
  const [sessionStorageState, setSessionStorageState] = useSessionStorageState()
  const [sessionBridge, setSessionBridge] = useLocalStorage('sessionBridge')

  useEffect(() => {
    if (!sessionBridge) return
    // Ensure we're in a new session.
    if (Object.keys(sessionStorageState).length) return

    if (Date.now() - sessionBridge._ts <= SESSION_BRIDGE_TTL) {
      setSessionStorageState(sessionBridge)
    }

    setSessionBridge(null)
  }, [])
}

export function useQueryErrorHandler(options: {
  notify?: string | boolean | ((err: unknown) => string | boolean)
  capture?: (err: unknown) => boolean
} = {}) {
  const { notify, capture } = options
  const { t } = useTranslation()
  const [appState] = useAppState()
  const revokeAuth = useRevokeAuth()
  const errorToast = useErrorToast()

  return (err: unknown) => {
    if (isApiUnauthorizedError(err) && appState.authenticated) {
      revokeAuth({ redirectToSignIn: true })
      errorToast(t('messages.signedOut'))
      return
    }

    if (!(err instanceof AxiosError) && !isApiNotFoundError(err)) {
      if (!capture || capture(err)) {
        Sentry.withScope(scope => {
          scope.setExtra('error', err)
          Sentry.captureException(err)
        })
        Sentry.flush()
      }
    }

    const _notify: string | boolean | undefined = typeof notify === 'function'
      ? notify(err)
      : notify

    if (_notify) {
      errorToast(typeof notify === 'string' ? notify : err)
    }
  }
}

export function useDismissNotice(id: string) {
  const [dismissedNotices, setDismissedNotices] = useLocalStorage('dismissedNotices')
  const dismiss = useCallback(() => {
    setDismissedNotices(value => ({ ...value, [id]: true }))
  }, [id])
  return dismissedNotices?.[id] ? undefined : dismiss
}

export function useDismissTip(id: string) {
  const [dismissedTips, setDismissedTips] = useLocalStorage('dismissedTips')
  const dismiss = useCallback(() => {
    setDismissedTips(value => ({ ...value, [id]: true }))
  }, [id])
  return dismissedTips?.[id] ? undefined : dismiss
}
