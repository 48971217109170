import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const outline = defineStyle({
  bg: 'gray.100'
})

export const textareaTheme = defineStyleConfig({
  variants: { outline },
  sizes: {
    lg: {
      fontSize: 'md'
    }
  }
})
