import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)

export const selectTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    field: {
      background: 'gray.100'
    }
  }),
  sizes: {
    lg: definePartsStyle({
      field: defineStyle({
        fontSize: '16px',
        h: '54px'
      })
    })
  }
})
