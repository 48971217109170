import { createSelector } from 'reselect'
import { Application, ApplicationStatus, RawApplication } from '../models'

export const selectDecoratedApplication = createSelector(
  (rawApplication: RawApplication) => rawApplication,
  (rawApplication): Application => {
    const { answers } = rawApplication
    const questionMap: Application['questionMap'] = {}
    const answerMap: Application['answerMap'] = {}
    let completeCount = 0
    let firstIncompleteQuestionId: string | undefined

    for (const answer of answers) {
      if (answer.complete) {
        completeCount++
      }
      answerMap[answer.question_id] = answer
    }

    const questions = rawApplication.questions.map(question => {
      // We remove the `$schema` prop because it breaks the
      // default ajv8 validator. Attempts to configure the ajv
      // validator to support `https://json-schema.org/draft/2020-12/schema`
      // were unsuccessful.
      const {
        form_schema: {
          '$schema': _schema,
          ...formSchema
        }
      } = question

      question = {
        ...question,
        form_schema: formSchema
      }

      questionMap[question.id] = question

      const answer = answerMap[question.id] ? answerMap[question.id] : undefined

      if (!answer?.complete && !firstIncompleteQuestionId) {
        firstIncompleteQuestionId = question.id
      }

      return question
    })

    const pendingQuestionCount = questions.length - completeCount
    const isSubmitted = rawApplication.status === ApplicationStatus.SUBMITTED

    return {
      ...rawApplication,
      questions,
      questionMap,
      answerMap,
      pendingQuestionCount,
      firstIncompleteQuestionId,
      lastQuestionId: questions.length ? questions[questions.length - 1].id : undefined,
      isExternalLink: !!rawApplication.job.application_url,
      isComplete: pendingQuestionCount === 0,
      isEvaluated: rawApplication.status === ApplicationStatus.EVALUATED || isSubmitted,
      isSubmitted
    }
  }
)
