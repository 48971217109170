import { Box, BoxProps, Button, ButtonProps, Card, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { IconDiscountCheck } from '@tabler/icons-react'
import { MouseEventHandler, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useGeoCoordinates, useJobUrl, useTrack } from '../../hooks'
import { JobBase, JobIndexRecord, LatLng } from '../../models'
import { CompanyLogo } from '../companies'
import { RegionBadge } from '../RegionBadge'
import { FavoriteButton } from './elements'
import { JobCardPills } from './JobCardPills'
import { Wage } from './Wage'

export const JOB_LIST_ITEM_HEIGHT = 300

export interface JobListItemProps extends BoxProps {
  job: JobIndexRecord
  distanceFrom?: LatLng
  header?: ReactNode
  favoriteButtonVisible?: boolean
  selected?: boolean
  actions?: ReactNode
  onDefaultActionClick?: () => void
}

export const JobListItem = ({
  job,
  distanceFrom,
  header,
  favoriteButtonVisible = true,
  selected,
  actions,
  onDefaultActionClick,
  ...props
}: JobListItemProps) => {
  const url = useJobUrl(job.slug)
  const geoCoordinates = useGeoCoordinates()
  const track = useTrack()

  const handleOpenJobDetail: MouseEventHandler<any> = (e) => {
    if (onDefaultActionClick) {
      e.preventDefault()
      onDefaultActionClick()
    } else {
      track.open('Job Detail')
    }
  }

  const cardSelectedProps = selected
    ? { outline: '2px solid', outlineColor: 'brand.500', boxShadow: '0px 2px 6px 0px rgba(0, 182, 145, 0.5)', bg: '#F2FBF9' }
    : {}

  return (
    <Card
      p={3}
      {...cardSelectedProps}
      {...props}
    >
      <JobListItemHeader
        mb={6} job={job}
        header={header}
        favoriteButtonVisible={favoriteButtonVisible}
        selected={selected}
      />

      <JobListItemBody job={job} distanceFrom={distanceFrom ?? geoCoordinates} selected={selected} />

      <JobListItemFooter
        url={url}
        actions={actions}
        onDetailActionClick={handleOpenJobDetail}
      />

      <Box
        as={Link} to={url}
        position="absolute"
        top={0} right={0} bottom={0} left={0}
        onClick={handleOpenJobDetail}
      />
    </Card >
  )
}

export function estimateJobListItemHeight(_job: JobBase) {
  return JOB_LIST_ITEM_HEIGHT
}

const JobListItemHeader = ({
  job,
  header,
  favoriteButtonVisible,
  selected,
  ...props
}: JobListItemProps) => (
  <Flex {...props}>
    <Stack flex={1} spacing={2}>
      {header}

      <Flex align="center">
        <CompanyLogo company={job.company} mr={3} boxSize={55} />

        <Box w={1} flex={1}>
          <Text
            pb={0.5} overflow="hidden"
            className="job-title" as="h3"
            fontSize={20} fontWeight="medium" lineHeight={1.2} noOfLines={2} color="gray.900"
          >
            {job.title}
          </Text>
          <Text
            pb={0.5} overflow="hidden"
            fontSize="sm" lineHeight={1.5} noOfLines={1} color="gray.400">
            {job.company.name}
          </Text>
        </Box>
      </Flex>
    </Stack>

    {favoriteButtonVisible && (
      <FavoriteButton
        mt={-2} mr={-2}
        job={job}
        iconSize="26px"
        flexShrink={0}
        color={selected ? 'green.200' : 'gray.200'}
        zIndex={1}
      />
    )}
  </Flex>
)

const JobListItemBody = ({ job, distanceFrom, selected }: JobListItemProps) => {
  const filteredRequirements = job.requirements?.slice(0, 2)

  return (
    <>
      <Flex align="center">
        <RegionBadge
          record={job}
          distanceFrom={distanceFrom}
          mr={2} maxW="50%"
          fontSize="2sm"
        />
        <Wage
          job={job}
          displaySupplementalPay={true}
          size="2sm"
        />
      </Flex>

      <JobCardPills job={job} size="2sm" mt={6} fontWeight="400" selected={selected} />

      {job.summary ? (
        <Text
          mt={6}
          fontSize="sm"
        >
          {job.summary}
        </Text>
      ) : !!filteredRequirements?.length && (
        <Stack mt={6} spacing={2}>
          {filteredRequirements.map(requirement => (
            <Flex key={requirement}>
              <Icon as={IconDiscountCheck} boxSize={4} mt={0.5} mr={1} />
              <Text fontSize="sm" lineHeight={1.3}>
                {requirement}
              </Text>
            </Flex>
          ))}
        </Stack>
      )}
    </>
  )
}

interface JobListItemFooterProps extends BoxProps {
  url: string
  actions?: ReactNode
  onDetailActionClick?: ButtonProps['onClick']
}

const JobListItemFooter = ({
  url,
  actions,
  onDetailActionClick
}: JobListItemFooterProps) => {
  const { t } = useTranslation()

  return (
    <HStack mt={6} spacing={2} zIndex={1}>
      {actions ?? (
        <Button
          as={Link} to={url} variant="primary" w="full"
          onClick={onDetailActionClick}
        >
          {t('actions.seeJobDetails')}
        </Button>
      )}
    </HStack>
  )
}
