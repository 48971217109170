import { useMutation } from '@tanstack/react-query'
import { retryCallback } from '../helpers'
import { UserEvent } from '../models/UserEvent'
import { api } from '../services'

export function useSendUserEvent() {
  const { mutate } = useMutation(async (event: UserEvent) => {
    await api.events.create(event)
  }, {
    retry: retryCallback,
    onError() {} // Don't show toast on error
  })

  return mutate
}
