import { JobScheduleType, WorkplaceType } from './Job'

export enum ExperienceLevel {
  ENTRY = 'entry',
  INTERMEDIATE = 'intermediate',
  MANAGEMENT = 'management'
}

export interface JobPreferences {
  schedule?: JobScheduleType[] | null
  industries?: string[] | null
  positions?: string[] | null
  experience_levels?: ExperienceLevel[] | null
  interests?: string[] | null
  pay?: number | null
  commute_distance?: number | null
  workplaces?: WorkplaceType[] | null
}
