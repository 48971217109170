import { RJSFSchema, UiSchema } from '@rjsf/utils'
import { Job } from './Job'

export enum ApplicationStatus {
  DRAFT = 'draft',
  EVALUATED = 'evaluated',
  SUBMITTED = 'submitted'
}

export enum ApplicationQuestionType {
  PRESCREENER = 'prescreener',
  EEOC = 'eeoc',
  INTERNAL = 'internal',
  ATS = 'ats'
}

export interface ApplicationQuestion {
  id: string
  type: ApplicationQuestionType
  form_schema: RJSFSchema
  ui_schema: UiSchema
  required: boolean
}

export interface ApplicationAnswer {
  question_id: string
  complete: boolean
  values: { [key: string]: any } | null
}

export interface ApplicationBase {
  id: string
  status: ApplicationStatus
  submitted_at: string | null
  created_at: string
  updated_at: string
}

export interface ApplicationIndexRecord extends ApplicationBase {
  job: Job
}

export interface RawApplication extends ApplicationIndexRecord {
  qualified: boolean
  questions: ApplicationQuestion[]
  answers: ApplicationAnswer[]
}

export interface Application extends RawApplication {
  questionMap: { [id: string]: ApplicationQuestion }
  answerMap: { [id: string]: ApplicationAnswer }
  pendingQuestionCount: number
  lastQuestionId?: string
  firstIncompleteQuestionId?: string
  isExternalLink: boolean
  isComplete: boolean
  isEvaluated: boolean
  isSubmitted: boolean
}
