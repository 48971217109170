import { FlexProps } from '@chakra-ui/react'
import { ReactNode, useCallback } from 'react'
import { useGeoCoordinates, useJobListFilter, useQueryItems } from '../../hooks'
import { JobIndexRecord, LatLng } from '../../models'
import { CardList, CardListParentProps } from '../core'
import { estimateJobListItemHeight, JobListItem, JOB_LIST_ITEM_HEIGHT } from './JobListItem'
import { NoJobResultsNotice } from './NoJobResultsNotice'

interface RenderJobProps {
  job: JobIndexRecord
  distanceFrom?: LatLng
}

export interface JobListProps extends CardListParentProps<JobIndexRecord> {
  pt?: FlexProps['pt']
  px?: FlexProps['px']
  distanceFrom?: LatLng
  renderJob?: (props: RenderJobProps) => ReactNode
}

export const JobList = ({
  distanceFrom,
  renderJob = defaultRenderJob,
  renderNoResults = defaultRenderNoResults,
  query,
  items,
  ...props
}: JobListProps) => {
  const geoCoordinates = useGeoCoordinates()
  const _distanceFrom = distanceFrom ?? geoCoordinates

  const queryItems = useQueryItems(items ? undefined : query)
  const _items = useJobListFilter(items ?? queryItems)

  const renderItem = useCallback((job: JobIndexRecord) => {
    return renderJob({ job, distanceFrom: _distanceFrom })
  }, [renderJob, _distanceFrom])

  return (
    <CardList<JobIndexRecord>
      query={query}
      items={_items}
      estimateItemSize={estimateJobListItemHeight}
      itemHeight={JOB_LIST_ITEM_HEIGHT}
      renderItem={renderItem}
      renderNoResults={renderNoResults}
      {...props}
    />
  )
}

function defaultRenderJob(props: RenderJobProps) {
  return <JobListItem {...props} />
}

function defaultRenderNoResults() {
  return <NoJobResultsNotice />
}
