import { Box, Collapse, Divider, Flex, SpaceProps, Stack, Switch, Text } from '@chakra-ui/react'
import { TFunction } from 'i18next'
import { PropsWithChildren } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useIndustriesQuery, useSearchChoiceLabelFormatters } from '../../hooks'
import { ExperienceLevel, SCHEDULE_CHOICES, WorkplaceType } from '../../models'
import { CheckboxGroup, InfiniteListProgress } from '../core'
import { DistanceSlider } from '../shared/DistanceSlider'
import { PaySlider } from '../shared/PaySlider'
import { SearchFilters } from './types'

export const FilterFields = (props: SpaceProps) => {
  const { t: vt } = useTranslation(undefined, { keyPrefix: 'views.search.fields' })
  const { control, watch } = useFormContext<SearchFilters>()
  const labelFmt = useSearchChoiceLabelFormatters()

  const livingWage = watch('living_wage')

  return (
    <Box {...props}>
      <Flex flexDirection="row" gap={4} alignItems="center">
        <Text fontSize="lg" fontWeight="medium">{vt('livingWage.title')}</Text>
        <Controller
          name="living_wage"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} />
          )}
        />
      </Flex>

      <FilterBox title={vt('pay.title')} isDisabled={livingWage}>
        <Controller
          name="min_pay"
          control={control}
          render={({ field }) => <PaySlider isDisabled={livingWage} {...field} />}
        />
      </FilterBox>

      <FilterBox title={vt('distance.title')}>
        <Controller
          name="radius"
          control={control}
          render={({ field }) => <DistanceSlider valueUnit="miles" {...field} />}
        />
      </FilterBox>

      <FilterBox title={vt('workplaces.title')}>
        <Controller
          name="workplaces"
          control={control}
          render={({ field }) => (
            <CheckboxGroup
              choices={Object.values(WorkplaceType)}
              columns={2}
              getLabel={labelFmt.workplaces}
              {...field}
            />
          )}
        />
      </FilterBox>

      <FilterBox title={vt('schedule.title')}>
        <Controller
          name="schedule"
          control={control}
          render={({ field }) => (
            <CheckboxGroup
              choices={SCHEDULE_CHOICES}
              columns={2}
              getLabel={labelFmt.schedule}
              {...field}
            />
          )}
        />
      </FilterBox>

      <FilterBox title={vt('experience.title')}>
        <Controller
          name="experience_levels"
          control={control}
          render={({ field }) => (
            <CheckboxGroup
              choices={Object.values(ExperienceLevel)}
              columns={2}
              getLabel={labelFmt.experience}
              {...field}
            />
          )}
        />
      </FilterBox>

      <IndustriesField vt={vt} />
    </Box>
  )
}

const IndustriesField = ({ vt }: { vt: TFunction }) => {
  const { control } = useFormContext<SearchFilters>()
  const { data: industries, isError } = useIndustriesQuery()

  if (!industries) {
    return isError ? null : <InfiniteListProgress />
  }

  return (
    <FilterBox title={vt('industries.title')}>
      <Controller
        name="industries"
        control={control}
        render={({ field }) => (
          <CheckboxGroup
            choices={industries}
            {...field}
          />
        )}
      />
    </FilterBox>
  )
}

interface FilterBoxProps extends SpaceProps, PropsWithChildren {
  title: string
  isDisabled?: boolean
  hasDivider?: boolean
}

const FilterBox = ({ title, children, isDisabled, hasDivider = true, ...props }: FilterBoxProps) => {
  return (
    <Collapse in={!isDisabled} unmountOnExit>
      <Stack spacing={5} py={5} {...props}>
        {hasDivider && <Divider />}
        <Box {...props}>
          <Text mb={5} fontSize="xl" fontWeight="medium">
            {title}
          </Text>

          {children}
        </Box>
      </Stack>
    </Collapse>
  )
}
