import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_MAX_DISTANCE } from '../../config'
import { FormatDistanceOptions, metersToMiles, milesToMeters } from '../../helpers'
import { useFormatters } from '../../hooks'
import { SimpleSlider, SimpleSliderProps } from '../core'

const DEFAULT_MARKS = [0, 5, 10, 15, 20, 25, 30]

type DistanceUnit = 'miles' | 'meters'

export interface DistanceSliderProps extends Omit<SimpleSliderProps, 'min'> {
  valueUnit?: DistanceUnit
}

export const DistanceSlider = forwardRef<HTMLDivElement, DistanceSliderProps>(({
  valueUnit = 'meters',
  max = DEFAULT_MAX_DISTANCE,
  value = 1,
  onChange,
  onChangeStart,
  onChangeEnd,
  ...props
}, ref) => {
  const { t } = useTranslation()
  const fmt = useFormatters()
  const fmtOptions: FormatDistanceOptions = { inputUnit: 'miles', display: 'short' }
  const miles = valueUnit === 'meters' ? Math.round(metersToMiles(value)) : value
  const normalizedMiles = miles === 1 ? 0 : miles

  const changeCallbacks = useMemo(() => {
    return {
      onChange: normalizedValueCallback(valueUnit, onChange),
      onChangeStart: normalizedValueCallback(valueUnit, onChangeStart),
      onChangeEnd: normalizedValueCallback(valueUnit, onChangeEnd)
    }
  }, [valueUnit, onChange, onChangeStart, onChangeEnd])

  return (
    <SimpleSlider
      ref={ref}
      value={normalizedMiles}
      min={0}
      max={max}
      step={5}
      stepMarksValues={DEFAULT_MARKS}
      showRangeLabels
      rangeLabelWidth={10}
      minRangeLabelValue={fmt.distance(1, fmtOptions)}
      maxRangeLabelValue={fmt.distance(max, fmtOptions)}
      tooltipLabel={fmt.distance(miles, { ...fmtOptions, display: 'long' })}
      aria-label={t('terms.distance')}
      {...changeCallbacks}
      {...props}
    />
  )
})

function normalizedValueCallback(valueUnit: DistanceUnit, cb: SimpleSliderProps['onChange']) {
  return (value: number) => {
    const normalizedMiles = value === 0 ? 1 : value
    if (valueUnit === 'meters') {
      value = Math.round(milesToMeters(normalizedMiles))
    } else {
      value = normalizedMiles
    }
    cb?.(value)
  }
}
